import { Data } from "@angular/router";
import { Model, ModelId } from "../../../common/rest-api/models/model";
import { EmployeeListing } from "../../../common/models/employee-listing.model";
import { GarmentReportDetail } from "./garment-report-detail.model";
import { GarmentReportSummary } from "./garment-report-summary.model";

export class GarmentReport extends Model
{
  get modelId(): ModelId {
    throw new Error("Method not implemented.");
  }

  employeeListing: EmployeeListing;
  summary: GarmentReportSummary[];
  details: GarmentReportDetail[];
  replacementCharge?: boolean;
  weeksAssigned?: number;
  replacementPrice?: number;
  garmentCode?: string;
  notes: { code: string, text: string }[] = [];

  public assign(data: Data): void {

    this.employeeListing = EmployeeListing.from(data.employeeListing);
    this.summary = data.summary.map(summary => GarmentReportSummary.from(summary));
    this.details = data.details.map(detail => GarmentReportDetail.from(detail));
    this.replacementCharge = data.replacementCharge;
    this.weeksAssigned = data.weeksAssigned;
    this.replacementPrice = data.replacementPrice;
    this.garmentCode = data.garmentCode;
    Object.assign(this.notes, data?.notes ?? []);
  }
}