import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DepartmentKey } from '../../../../app/common/models/department-key-model';
import { EmployeeKey } from '../../../../app/common/models/employee-key.model';
import { QueryResult } from '../../../common/rest-api/models/query-result';
import { RequestParams } from '../../../common/rest-api/models/request-params';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { GarmentHistory } from '../models/garment-history.model';
import { GarmentReport } from '../models/garment-report.model';


@Injectable({
  providedIn: 'root',
})
export class GarmentHistoryService extends RestApiService<GarmentHistory> {

  protected resourcePath = '/garment-history';
  protected modelType = GarmentHistory;

  async getHistoryDateRangeEmployee(employeeKey: EmployeeKey, startDate: string, endDate: string): Promise<GarmentHistory> {
    const params = new RequestParams();
    params.queryParams.params.CustomerCode = employeeKey.CustomerCode;
    params.queryParams.params.DepartmentCode = employeeKey.DepartmentCode;
    params.queryParams.params.EmployeeCode = employeeKey.EmployeeCode;

    const url = this.buildEndpointUrl(`${startDate}/${endDate}`, params);
    const response = await this.cloudApiMiddleware.cloudRequest<GarmentHistory>({
      url
    }, 'GET');

    return response;
  }
}