<ion-content class="main-admin">
    <app-header [isParentPage]="true" [title]="'Custom Reports'"></app-header>
    <div class="main-container">
        <div *ngIf="authService.user?.canViewCustomCancelReport" class="item" (click)="navigate('/custom-reports/cancel-order')">
            Customer Cancel Report
        </div>
        <div *ngIf="authService.user?.canViewGarmentReturnReview" class="item" (click)="navigate('/custom-reports/garment-review')">
            Garment Return Review
        </div>
    </div>
</ion-content>
