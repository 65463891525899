import { Data } from "@angular/router";
import { Model, ModelId } from "../../../common/rest-api/models/model";

export class GarmentHistory extends Model
{
  get modelId(): ModelId {
    throw new Error("Method not implemented.");
  }

  statusCode: string;
  statusDate: Date;
  customerCode: string;
  departmentCode: string;
  employeeCode: string;
  garmentCode: string;

  public assign(data: Data): void {
    Object.assign(this, data);
  }
}