import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { catchError, firstValueFrom } from "rxjs";
import { environment } from '../../../environments/environment';
import { GtrakAuthService } from '../../../app/features/authentication/services/gtrak-auth.service';
import { CloudApiMiddleware } from './cloud-api-middleware.service';

@Injectable({
    providedIn: 'root'
})
export class EmailService {
    API_BASE_URL: string;
  
    constructor(
        private httpClient: HttpClient,
        private authService: GtrakAuthService,
        private cloudApiMiddleware: CloudApiMiddleware
    ) {
        this.API_BASE_URL = environment.apiUrl;
    }

    async sendQuestionEmail(content: string): Promise<boolean> {
        const userId = this.authService.user.id.toString();
        const userName = this.authService.user.id.toString();

        // return await firstValueFrom(this.httpClient.post<boolean>(`${this.API_BASE_URL}/email/question`, {userId, userName, content}))
        return await this.cloudApiMiddleware.cloudRequest<boolean>({
            url: `${this.API_BASE_URL}/email/question`,
            body: {userId, userName, content}
        }, 'POST');
    }

    async sendOrderConfirmation(data: {email: string, type: string, id: number}): Promise<boolean> {
        const login = this.authService.user.id;
        const body = {...data, ...{login}};
        // return await firstValueFrom(this.httpClient.post<boolean>(`${this.API_BASE_URL}/email/work-order-confimation`, body))
        return await this.cloudApiMiddleware.cloudRequest<boolean>({
            url: `${this.API_BASE_URL}/email/work-order-confimation`,
            body
        }, 'POST');
    }

    async forgotPasswordEmail(email: string): Promise<boolean> {
        // return await firstValueFrom(this.httpClient.post<boolean>(`${this.API_BASE_URL}/email/forgot-password`, {email}))
        return await this.cloudApiMiddleware.cloudRequest<boolean>({
            url: `${this.API_BASE_URL}/email/forgot-password`,
            body: {email}
        }, 'POST');
    }

    async getUserAlertSettings(login: string): Promise<any> {
        // return await firstValueFrom(this.httpClient.get<any>(`${this.API_BASE_URL}/alert-settings/${login}`));
        return await this.cloudApiMiddleware.cloudRequest<any>({
            url: `${this.API_BASE_URL}/alert-settings/${login}`
        }, 'GET');
    }

    async setUserAlertSettings(login: string, settings: any): Promise<any> {
        // return await firstValueFrom(this.httpClient.post<any>(`${this.API_BASE_URL}/alert-settings/${login}`, settings));
        return await this.cloudApiMiddleware.cloudRequest<any>({
            url: `${this.API_BASE_URL}/alert-settings/${login}`,
            body: settings
        }, 'POST');
    }

}