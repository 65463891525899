import { Data } from "@angular/router";
import { Model, ModelId } from "../../../common/rest-api/models/model";
import { RouteLogModel } from "./route-log.model";

export class RouteStopModel extends Model
{
  get modelId(): ModelId {
    throw new Error("Method not implemented.");
  }

  stopCode: string;
  routeCode: string;
  description: string;
  instructions: string;
  deliveryTime: string;
  deliveryAddress1: string;
  deliveryAddress2: string;
  deliveryAddress3: string;
  deliveryCity: string;
  deliveryState: string;
  deliveryPostalCode: string;
  deliveryCountryCode: string;
  stopNumber: number;
  frequency: string;
  setupInstr: string;
  serviceTime: number;
  contactSequenceNbr: string;
  basketCnt: number;
  subStop: string;
  delInstr: string;
  expirationDate: string;
  routeLogs?: RouteLogModel[];
}