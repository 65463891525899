import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter } from '@ionic/angular';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';
import { ViewOrderService } from '../../services/view-order.service';

@Component({
  selector: 'app-view-order-header',
  templateUrl: 'header.component.html',
  styleUrls: [
    './header.component.scss',
  ],
})
export class ViewOrderHeaderComponent implements ViewWillEnter, OnInit {

    orderId: number = null;

    data: any[] = [];

    loaded: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: GtrakAuthService,
        private viewOrderService: ViewOrderService
    ) { 

    }

    ngOnInit(): void {

    }

    ionViewWillEnter(): void {

    }

    navigateHome(): void {
        this.router.navigate(['']);
    }

}