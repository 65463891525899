<app-top-bar></app-top-bar>
<ion-content>
    <div class="main-header">
        <span>Repair/Replace</span>
        <div (click)="navigateBack()" class="back-button-mobile">
            <ion-icon name="chevron-back-outline"></ion-icon>
        </div>
    </div>
    <div class="mobile-info">
        <ion-grid class="info-grid">
            <ion-row class="row">
                <span class="top-header-label">Cust:</span>
                <div *ngIf="loaded">{{garmentSummary.employeeListing.customerCode}} {{garmentSummary.employeeListing.customerName}}
                </div>
            </ion-row>
            <ion-row class="row">
                <span class="top-header-label">Stop:</span> <ng-container *ngIf="loaded">{{garmentSummary.employeeListing.stopCode}}
                    {{garmentSummary.employeeListing.stopName}}</ng-container>
            </ion-row>
            <ion-row class="bottom-row">
                <span class="top-header-label">Locker:</span> <ng-container *ngIf="loaded">{{garmentSummary.employeeListing.employeeCode}} <h6>
                    {{garmentSummary.employeeListing.lastName}}, {{garmentSummary.employeeListing.firstName}}</h6></ng-container>
            </ion-row>
        </ion-grid>
    </div>

    <div class="main-content">
        <ion-grid class="step-grid">
            <ion-row class="step-row">
                <ion-col class="border-right stop-label">Steps:</ion-col>
                <ion-col class="border-right" [ngClass]="{'step-selected': stepNumber === 1}"><strong>1</strong> Select Garments for Repair</ion-col>
                <ion-col class="border-right" [ngClass]="{'step-selected': stepNumber === 2}"><strong>2</strong> Verify Request</ion-col>
                <ion-col class="top-corner-radius" [ngClass]="{'step-selected': stepNumber === 3}"><strong>3</strong> Submit Request</ion-col>
            </ion-row>
            <ion-row class="step-text" *ngIf="stepNumber === 1">Specify which garments need Repair/Replace by checking the box on the right to choose from a list of all your garments</ion-row>
            <ion-row class="step-text" *ngIf="stepNumber === 2">Specify which type of Repair/Replace will be made to your garments by choosing from the drop down menu on the right</ion-row>
            <ion-row class="step-text" *ngIf="stepNumber === 3">Enter your e-mail address and click the Submit button to complete your order</ion-row>
        </ion-grid>

        <ion-grid class="garment-grid" *ngIf="stepNumber === 1">
            <ion-row class="garment-grid-row-head">
                <ion-col class="border-right check-box-col" size="1">
                    <input type="checkbox" (change)="checkAll($event)"/>
                </ion-col>
                <ion-col class="border-right" size="4">Garment Code</ion-col>
                <ion-col size="7" class="row-edit">Type of Repair</ion-col>
            </ion-row>
            <ion-row *ngFor="let garment of garments" class="garment-grid-row-content">
                <ion-col class="border-right check-box-col" size="1"><input type="checkbox" [(ngModel)]="garment.checked" (change)="addGarment(garment, $event)"/></ion-col>
                <ion-col size="4" (click)="expandGarment(garment)" class="expand overflow border-right">
                    <div *ngIf="!garment.expanded" class="sign">
                        <ion-icon name="add-outline"></ion-icon>
                    </div>
                    <div *ngIf="garment.expanded" class="sign">
                        <ion-icon name="remove-outline"></ion-icon>
                    </div>
                    <span class="garment-code">{{garment.garmentCode}}</span>
                </ion-col>
                <ion-col size="7">
                    <ion-select placeholder="Select Reason" interface="popover" [interfaceOptions]="{cssClass: 'wider-popover'}" (ionChange)="reasonSelect(garment, $event)" class="select" [(ngMode)]="garment.reason">
                        <ion-select-option value="N/A">Select Reason</ion-select-option>
                        <ion-select-option value="06">Relable Garment ID</ion-select-option>
                        <ion-select-option value="15">Repair - Belt Loop / Drawstring</ion-select-option>
                        <ion-select-option value="11">Repair - Buttons, Button Holes, Snaps</ion-select-option>
                        <ion-select-option value="13">Repair - Check All</ion-select-option>
                        <ion-select-option value="MSG0010064">Repair - Check Seat / Inner Thigh</ion-select-option>
                        <ion-select-option value="MSG0010031">Repair - Check Zipper</ion-select-option>
                        <ion-select-option value="21">Repair - Emblems</ion-select-option>
                        <ion-select-option value="14">Repair - Pockets</ion-select-option>
                        <ion-select-option value="12">Repair - Seams or Hems</ion-select-option>
                        <ion-select-option value="MSG0010070">Repair - Sleeve/Cuff</ion-select-option>
                        <ion-select-option value="MSG0010042">Repair - Tear</ion-select-option>
                        <ion-select-option value="MSG0010080">Replace - Inspect for Replacement</ion-select-option>
                        <ion-select-option value="05">Send to Customer Service</ion-select-option>
                        <ion-select-option value="MSG0010033">Verify - All Emblems</ion-select-option>
                        <ion-select-option value="MSG0010046">Verify - Name Emblem Spelling</ion-select-option>
                    </ion-select>
                </ion-col>
                <ion-row class="expanded-parent">
                    <div *ngIf="garment.expanded" class="expanded-content">
                        <ion-row>
                            <span><span class="bold-text">Product Code: </span> {{garment.productCode}}</span>
                        </ion-row>
                        <ion-row>
                            <span><span class="bold-text">Product Desc: </span> {{garment.description}}</span>
                        </ion-row>
                        <ion-row>
                            <span><span class="bold-text">Size: </span> {{garment.size1}}-{{garment.size2}}</span>
                        </ion-row>
                        <ion-row>
                            <span><span class="bold-text">Last Delivery: </span> {{garment.lastDelivery | date: 'MM/dd/yyyy'}}</span>
                        </ion-row>
                    </div>
                </ion-row>
            </ion-row>
        </ion-grid>

        <ion-grid class="garment-grid" *ngIf="stepNumber === 2">
            <ion-row class="garment-grid-row-head">
                <ion-col class="border-right" size="1"></ion-col>
                <ion-col class="border-right" size="4">Garment Code</ion-col>
                <ion-col size="7" class="row-edit">Type of Repair</ion-col>
            </ion-row>
            <ion-row *ngFor="let garment of selectedGarments" class="garment-grid-row-content">
                <ion-col class="border-right check-box-col" size="1"><input type="checkbox" [(ngModel)]="garment.checked" (change)="addGarment(garment, $event)"/></ion-col>
                <ion-col size="4" (click)="expandGarment(garment)" class="expand overflow border-right">
                    <div *ngIf="!garment.expanded" class="sign">
                        <ion-icon name="add-outline"></ion-icon>
                    </div>
                    <div *ngIf="garment.expanded" class="sign">
                        <ion-icon name="remove-outline"></ion-icon>
                    </div>
                    <span class="garment-code">{{garment.garmentCode}}</span>
                </ion-col>
                <ion-col size="7">
                    <ion-select placeholder="Select Reason" interface="popover" [interfaceOptions]="{cssClass: 'wider-popover'}" (ionChange)="reasonSelect(garment, $event)" class="select" [(ngModel)]="garment.reason">
                        <ion-select-option value="N/A">Select Reason</ion-select-option>
                        <ion-select-option value="06">Relable Garment ID</ion-select-option>
                        <ion-select-option value="15">Repair - Belt Loop / Drawstring</ion-select-option>
                        <ion-select-option value="11">Repair - Buttons, Button Holes, Snaps</ion-select-option>
                        <ion-select-option value="13">Repair - Check All</ion-select-option>
                        <ion-select-option value="MSG0010064">Repair - Check Seat / Inner Thigh</ion-select-option>
                        <ion-select-option value="MSG0010031">Repair - Check Zipper</ion-select-option>
                        <ion-select-option value="21">Repair - Emblems</ion-select-option>
                        <ion-select-option value="14">Repair - Pockets</ion-select-option>
                        <ion-select-option value="12">Repair - Seams or Hems</ion-select-option>
                        <ion-select-option value="MSG0010070">Repair - Sleeve/Cuff</ion-select-option>
                        <ion-select-option value="MSG0010042">Repair - Tear</ion-select-option>
                        <ion-select-option value="MSG0010080">Replace - Inspect for Replacement</ion-select-option>
                        <ion-select-option value="05">Send to Customer Service</ion-select-option>
                        <ion-select-option value="MSG0010033">Verify - All Emblems</ion-select-option>
                        <ion-select-option value="MSG0010046">Verify - Name Emblem Spelling</ion-select-option>
                    </ion-select>
                </ion-col>
                <ion-row class="expanded-parent">
                    <div *ngIf="garment.expanded" class="expanded-content">
                        <ion-row>
                            <span><span class="bold-text">Product Code: </span> {{garment.productCode}}</span>
                        </ion-row>
                        <ion-row>
                            <span><span class="bold-text">Product Desc: </span> {{garment.description}}</span>
                        </ion-row>
                        <ion-row>
                            <span><span class="bold-text">Size: </span> {{garment.size1}}-{{garment.size2}}</span>
                        </ion-row>
                        <ion-row>
                            <span><span class="bold-text">Last Delivery: </span> {{garment.lastDelivery | date: 'MM/dd/yyyy'}}</span>
                        </ion-row>
                    </div>
                </ion-row>
            </ion-row>
        </ion-grid>

        <div *ngIf="stepNumber === 3">
            <ion-row class="employee-information-container">
                <ion-row class="order-container">
                    <ion-col class="border-right" size="auto">
                        <span>Order For:</span>
                    </ion-col>
                    <ion-col class="border-right">
                        <span>{{empKey.CustomerCode}} {{empKey.DepartmentCode}} {{empKey.EmployeeCode}} {{employeeName}}</span>
                    </ion-col>
                    <ion-col size="auto">
                        <span>Date: {{currentDate | date: 'shortDate'}}</span>
                    </ion-col>
                </ion-row>
                <ion-row class="email-container">
                    <ion-col size="auto">
                        <span>Email: *</span>
                    </ion-col>
                    <ion-col>
                        <ion-input placeholder="Enter email" [(ngModel)]="userEmail"></ion-input>
                    </ion-col>
                </ion-row>
            </ion-row>

            <ion-grid class="garment-grid" *ngIf="stepNumber === 3">
                <ion-row class="garment-grid-row-head">
                    <ion-col class="border-right" size="4">Garment Code</ion-col>
                    <ion-col size="8" class="row-edit">Reason</ion-col>
                </ion-row>
                <ion-row *ngFor="let garment of selectedGarments" class="garment-grid-row-content">
                    <ion-col size="4" (click)="expandGarment(garment)" class="expand overflow border-right">
                        <div *ngIf="!garment.expanded" class="sign">
                            <ion-icon name="add-outline"></ion-icon>
                        </div>
                        <div *ngIf="garment.expanded" class="sign">
                            <ion-icon name="remove-outline"></ion-icon>
                        </div>
                        <span class="garment-code">{{garment.garmentCode}}</span>
                    </ion-col>
                    <ion-col class="reason-text" size="8">
                        {{reasonMap.get(garment?.reason?.toString()) ?? 'No Reason'}}
                    </ion-col>
                    <ion-row class="expanded-parent">
                        <div *ngIf="garment.expanded" class="expanded-content">
                            <ion-row>
                                <span><span class="bold-text">Product Code: </span> {{garment.productCode}}</span>
                            </ion-row>
                            <ion-row>
                                <span><span class="bold-text">Product Desc: </span> {{garment.description}}</span>
                            </ion-row>
                            <ion-row>
                                <span><span class="bold-text">Size: </span> {{garment.size1}}-{{garment.size2}}</span>
                            </ion-row>
                            <ion-row>
                                <span><span class="bold-text">Last Delivery: </span> {{garment.lastDelivery | date: 'MM/dd/yyyy'}}</span>
                            </ion-row>
                        </div>
                    </ion-row>
                </ion-row>
            </ion-grid>
        </div>

        <ion-row class="nav-button-container" *ngIf="loaded">
            <ion-col *ngIf="stepNumber === 2">
                <ion-button class="btn2" (click)="prevStep()">Back</ion-button>
            </ion-col>
            <ion-col *ngIf="stepNumber === 3">
                <ion-button class="btn2" (click)="cancel()">Cancel</ion-button>
            </ion-col>
            <ion-col *ngIf="stepNumber === 1 || stepNumber === 2">
                <ion-button class="btn1" (click)="nextStep()">Next</ion-button>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="stepNumber === 3">
            <ion-col>
                <ion-button [disabled]="!userEmail || (userEmail.length <= 0)" class="submit-button" (click)="submit()">Submit</ion-button>
            </ion-col>
        </ion-row>

    </div>

    <div class="spin" *ngIf="!loaded">
        <ion-spinner name="circular"></ion-spinner>
    </div>

</ion-content>
<app-footer></app-footer>