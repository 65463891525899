import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../../../../../../common/services/toaster.service';
import { GRouteLogService } from '../../../../../../features/g-route/services/g-route-log.service';
import { GtrakAuthService } from '../../../../../../features/authentication/services/gtrak-auth.service';
import { IRouteLogModel } from '../../../../../../features/g-route/models/route-log.model';
import { RouteDeliveryDetailsService } from '../../../../../../features/g-route/services/route-delivery-details.service';
import { MailService } from '../../../../../../features/g-route/services/mail.service';
import { CustomerContactsService } from '../../../../../../../app/features/g-route/services/customer-contacts.service';
import { RequestParams } from '../../../../../../../app/common/rest-api/models/request-params';
import { IStopContact } from '../contacts/models/stopContact.model';

@Component({
  selector: 'app-email-report-page',
  templateUrl: 'email-report.page.html',
  styleUrls: [
     './email-report.page.scss',
     '../../../../styles/g-route.styles.scss'
  ]
})
export class EmailReportPage implements OnInit {

    contacts: IStopContact[] = [];

    currentRoute: string;
    stopName: string;

    requestParams: RequestParams;

    sendInvoice: boolean = true;

    routeStopName: string = "";

    sendingEmail: boolean = false;

    comments: {text: string, selected: boolean}[] = [
        {text: 'Incorrect Delivery Day/Week', selected: false},
        {text: 'No Reports to Email', selected: false},
        {text: 'Not Open for Business', selected: false},
        {text: 'Recieves Paper Copies of Reports', selected: false},
        {text: 'Service Terminated', selected: false},
        {text: 'Late Signiture Due to Poor Signal', selected: false}
    ];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private toasterService: ToasterService,
        private routeLogService: GRouteLogService,
        public authService: GtrakAuthService,
        private routeDeliveryDetailsService: RouteDeliveryDetailsService,
        private mailService: MailService,
        private customerContactsService: CustomerContactsService
    ) { 
        this.currentRoute = null;
    }

    async ngOnInit(): Promise<void> {
        this.currentRoute = this.router.url;
        this.stopName = this.route.snapshot.paramMap.get('id');

        this.routeStopName = localStorage.getItem('routeStopName');

        this.requestParams = new RequestParams();
        this.requestParams.setPaging(1, 0);

        await this.getContacts();
    }

    async getContacts(): Promise<void> {
        const contacts = await this.customerContactsService.getContacts(this.stopName, this.requestParams);

        this.contacts = (contacts as any).contacts.filter(c => c.recievesEmails);
    }

    async submit(): Promise<void> {
        if (this.contacts.length <= 0) {
            this.toasterService.presentToast('danger', '', 'No valid contacts to email at this stop', 2000);
            return;
        }

        this.sendingEmail = true;

        const user = this.authService.user?.id;
        const logDate = this.authService.user?.routeDate;
        const results = await this.routeLogService.scheduleReportEmail(this.stopName, user, this.sendInvoice, logDate, false);

        const commentPromises = [];
        this.comments.filter(c => c.selected).forEach(c => {
            const log: IRouteLogModel = {
                logType: 99,
                routeCode: this.authService.user.routeCode,
                stopCode: this.stopName,
                odometer: 0,
                time: new Date(),
                driverCode: (this.authService.user.id as string),
                comment: c.text
            };

            commentPromises.push(this.routeLogService.createRouteLog(log));
        });

        await Promise.all(commentPromises);

        this.sendingEmail = false;

        this.toasterService.presentToast('primary', '', 'Emails and comments have been scheduled successfully', 2000);
        this.router.navigate([`/route/logsheet/stop/${this.stopName}/check-out`]);
    }


}