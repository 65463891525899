<ion-content class="garment-employee-lookup">
  <app-header [title]="(routeStopName ?? '') + ' (' + stopName + ') Work Order'"></app-header>
  <div class="content">
    <ion-select
      interface="popover"
      [interfaceOptions]="{size: 'cover'}"
      [(ngModel)]="type"
      (ionChange)="woChange($event)"
      class="select"
    >
      <ion-select-option value="XC">Cancel Complete</ion-select-option>
      <ion-select-option value="XI">Cancel Item</ion-select-option>
      <ion-select-option value="RSC">Rent Size Change</ion-select-option>
      <ion-select-option value="RC">Replace Garment</ion-select-option>
    </ion-select>
    <div *ngIf="type === 'XI'">
      <ion-row class="main-row">
        <ion-col>Select</ion-col>
        <ion-col>Product</ion-col>
        <ion-col>Description</ion-col>
        <ion-col>Size</ion-col>
      </ion-row>
      <ion-row
        *ngFor="let wo of woProductList; let i = index"
        [class.alternate]="i % 2 === 0"
      >
        <ion-col>
          <input type="checkbox" (input)="addSelected(wo, $event)" />
        </ion-col>
        <ion-col> {{wo.productCode}} </ion-col>
        <ion-col> {{wo.name}} </ion-col>
        <ion-col> {{wo.size1}}-{{wo.size2}} </ion-col>
      </ion-row>
      <ion-button class="submit-button" (click)="submitCancelItem()"
        >Submit</ion-button
      >
    </div>
    <div *ngIf="type === 'XC'">
      <ion-row class="main-row">
        <ion-col>Select</ion-col>
        <ion-col>Product</ion-col>
        <ion-col>Description</ion-col>
        <ion-col>Size</ion-col>
      </ion-row>
      <ion-row
        *ngFor="let wo of woProductList; let i = index"
        [class.alternate]="i % 2 === 0"
      >
        <ion-col>
          <input
            type="checkbox"
            (input)="addSelected(wo, $event)"
            checked="true"
            [disabled]="type === 'XC'"
          />
        </ion-col>
        <ion-col> {{wo.productCode}} </ion-col>
        <ion-col> {{wo.name}} </ion-col>
        <ion-col> {{wo.size1}}-{{wo.size2}} </ion-col>
      </ion-row>
      <ion-button class="submit-button" (click)="submitCancelItem()"
        >Submit</ion-button
      >
    </div>
    <div *ngIf="type === 'RSC'">
      <ion-row class="main-row">
        <ion-col>Select</ion-col>
        <ion-col>Product</ion-col>
        <ion-col>Description</ion-col>
        <ion-col>Size</ion-col>
        <ion-col>New Size</ion-col>
      </ion-row>
      <ion-row
        *ngFor="let wo of woProductList; let i = index"
        [class.alternate]="i % 2 === 0"
      >
        <ion-col>
          <input type="checkbox" (input)="addSelected(wo, $event)" />
        </ion-col>
        <ion-col> {{wo.productCode}} </ion-col>
        <ion-col> {{wo.name}} </ion-col>
        <ion-col> {{wo.size1}}-{{wo.size2}} </ion-col>
        <ion-col>
          <ion-select
            interface="popover"
            [interfaceOptions]="{size: 'cover'}"
            class="size-select"
            (ionChange)="changeSelectedSize(wo, $event)"
          >
            <ion-select-option *ngFor="let size of wo.sizes">
              {{size}}
            </ion-select-option>
          </ion-select>
        </ion-col>
      </ion-row>
      <ion-button class="submit-button" (click)="submitSizeChange()"
        >Submit</ion-button
      >
    </div>
    <div *ngIf="type === 'RC'">
      <ion-row class="main-row">
        <ion-col>Garment</ion-col>
        <ion-col>Product</ion-col>
        <ion-col>Weeks Assigned</ion-col>
        <ion-col>Replace Change?</ion-col>
      </ion-row>
      <ion-row
        *ngFor="let wo of woGarmentList; let i = index"
        [class.alternate]="i % 2 === 0"
      >
        <ion-col class="link">
          <span (click)="navigateToGarment(wo)">{{wo.garmentCode}}</span>
        </ion-col>
        <ion-col> {{wo.productCode}} </ion-col>
        <ion-col> {{wo.weeksAssigned}} </ion-col>
        <ion-col> {{wo.reissueChg === 0 ? "Yes" : "No"}} </ion-col>
      </ion-row>

      <ion-row class="border-row" *ngIf="garmentDetailsShow">
        <ion-col size="auto" class="left-col"> Garment </ion-col>
        <ion-col size="auto" class="right-col">
          {{garmentDetails.garmentCode}}
        </ion-col>
      </ion-row>
      <ion-row class="border-row" *ngIf="garmentDetailsShow">
        <ion-col size="auto" class="left-col"> Prod: </ion-col>
        <ion-col size="auto" class="right-col">
          <ion-row>
            <ion-col class="ion-no-padding">
              {{garmentSummary.productCode}}
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-no-padding"> {{garmentSummary.name}} </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-row class="border-row" *ngIf="garmentDetailsShow">
        <ion-col size="auto" class="left-col"> Size: </ion-col>
        <ion-col size="auto" class="right-col">
          {{garmentSummary.size1}}&nbsp;{{garmentSummary.size2}}
        </ion-col>
      </ion-row>
      <ion-row class="border-row" *ngIf="garmentDetailsShow">
        <ion-col size="auto" class="left-col"> Last Del: </ion-col>
        <ion-col size="auto" class="right-col">
          {{garmentDetails.lastDelivery | date:'shortDate'}}
        </ion-col>
      </ion-row>
      <ion-row class="border-row" *ngIf="garmentDetailsShow">
        <ion-col size="auto" class="left-col"> Assign Date: </ion-col>
        <ion-col size="auto" class="right-col">
          {{garmentSummary.startDate | date:'shortDate'}}
        </ion-col>
      </ion-row>
      <ion-row class="border-row" *ngIf="garmentDetailsShow">
        <ion-col size="auto" class="left-col"> Wks Assigned: </ion-col>
        <ion-col size="auto" class="right-col">
          {{garment.weeksAssigned}}
        </ion-col>
      </ion-row>
      <ion-row class="border-row" *ngIf="garmentDetailsShow">
        <ion-col size="auto" class="left-col"> Replacement Charge: </ion-col>
        <ion-col size="auto" class="right-col">
          {{garment.replacementCharge ? 'Available For Replacement: No Charge' :
          (garment.replacementPrice | currency: 'USD')}}
        </ion-col>
      </ion-row>
    </div>
  </div>
</ion-content>
