import { Component, Input, OnInit } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: 'confirmation-popup.component.html',
  styleUrls: [
     './confirmation-popup.component.scss'
  ]
})
export class ConfirmationPopupComponent implements ViewWillEnter, OnInit {

    currentRoute: string;
    event: string = '';
    stop: string = '';

    constructor(
        private modalController: ModalController
    ) { 
        this.currentRoute = null;
    }

    async ngOnInit(): Promise<void> {

    }

    async ionViewWillEnter(): Promise<void> {

    }

    close(status: boolean): void {
        this.modalController.dismiss(status);
    }

}