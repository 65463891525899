<ion-content class="main-content-box">
  <div class="modal-container">
    <div class="title">
      <h3 class="title-left">{{ order.webOrder.woId }}</h3>
      <h1 class="title-center">Order Information</h1>
      <div class="spacer"></div>
      <!-- Invisible spacer -->
    </div>
    <div class="content">
      <div class="content-top">
        <div>
          <span class="lbl">Order Type: </span>
          <span
            ><strong>{{ order.orderType }}</strong></span
          >
        </div>
        <div>
          <span class="lbl">Date: </span>
          <span
            ><strong>{{
              order.webOrder.enteredDate | date : "MM/dd/yyyy"
            }}</strong></span
          >
        </div>
      </div>
      <div class="content-center">
        <span class="lbl">Order For: </span>
        <span
          ><strong
            >{{ order.webOrder.customerCode }}
            {{ order.webOrder.departmentCode }}
            {{ order.webOrder.employeeCode }}
            {{ order.webOrder.firstName }} {{ order.webOrder.lastName }}</strong
          ></span
        >
      </div>
      <div class="content-bottom">
        <div>
          <span class="lbl">Email: </span>
          <span
            ><strong
              >{{order.webOrder.email}}</strong
            ></span
          >
        </div>
        <div>
          <span class="lbl">State: </span>
          <span
            ><strong
              >{{order.state}}</strong
            ></span
          >
        </div>
      </div>
    </div>
    <div class="table">
      <ion-row>
        <ion-col>
          <ion-row class="table-titles">
            <ion-col *ngIf="order.webOrder.orderType === 'repGar'" size="3">
              <ion-label>Garment</ion-label>
            </ion-col>
            <ion-col *ngIf="order.webOrder.orderType !== 'repGar'" size="1">
              <ion-label>Item</ion-label>
            </ion-col>
            <ion-col size="3">
              <ion-label>Product Code</ion-label>
            </ion-col>
            <ion-col size="3">
              <ion-label>Description</ion-label>
            </ion-col>
            <ion-col *ngIf="order.webOrder.orderType !== 'repGar'" size="1">
              <ion-label>Qty</ion-label>
            </ion-col>
            <ion-col *ngIf="order.webOrder.orderType !== 'repGar'" size="2">
              <ion-label>Old Size</ion-label>
            </ion-col>
            <ion-col *ngIf="order.webOrder.orderType !== 'repGar'" size="2">
              <ion-label>New Size</ion-label>
            </ion-col>
            <ion-col *ngIf="order.webOrder.orderType === 'repGar'" size="3">
              <ion-label>Reason</ion-label>
            </ion-col>
          </ion-row>
          <ng-container *ngIf="order.webOrder.orderType === 'repGar'">
            <ion-row class="table-row" *ngFor="let item of order.webGarments">
              <ion-col size="3">
                <ion-label>{{ item.garmentCode }}</ion-label>
              </ion-col>
              <ion-col size="3">
                <ion-label>{{ item.productCode }}</ion-label>
              </ion-col>
              <ion-col size="3">
                <ion-label>{{ item.name }}</ion-label>
              </ion-col>
              <ion-col size="3">
                <ion-label>{{ item.description }}</ion-label>
              </ion-col>
            </ion-row>
          </ng-container>
          <ng-container *ngIf="order.webOrder.orderType !== 'repGar'">
            <ion-row class="table-row" *ngFor="let item of order.webOrderItems">
              <ion-col size="1">
                <ion-label>{{ item.itemId }}</ion-label>
              </ion-col>
              <ion-col size="3">
                <ion-label>{{ item.productCode }}</ion-label>
              </ion-col>
              <ion-col size="3">
                <ion-label>{{ item.description }}</ion-label>
              </ion-col>
              <ion-col size="1">
                <ion-label>{{ item.qty }}</ion-label>
              </ion-col>
              <ion-col size="2">
                <ion-label>{{ item.oldSize1 }} {{item.oldSize2}}</ion-label>
              </ion-col>
              <ion-col size="2">
                <ion-label>{{ item.newSize1 }} {{item.newSize2}}</ion-label>
              </ion-col>
            </ion-row>
          </ng-container>
        </ion-col>
      </ion-row>     
    </div>
    <div class="submit">
      <ion-textarea
        placeholder="Enter a reason for cancelling this order"
        [(ngModel)]="cancelReason"></ion-textarea>
      <ion-button
        class="btn"
        color="danger"
        (click)="cancelOrder()"
        [disabled]="!cancelReason"
        >Cancel Order</ion-button>
    </div>
  </div>
</ion-content>
