import { Data } from "@angular/router";
import { Model, ModelId } from "../../../common/rest-api/models/model";

export class InvoiceSummary extends Model {

    get modelId(): ModelId {
        throw new Error("Method not implemented.");
    }
    
    invoiceCode: string;
    customerCode: string;
    departmentCode: string;
    departmentName: string;
    dateOfInvoice: string;
    amountDue: number;
    dueDate: string;
    openBalance: number;

    public assign(data: Data): void {
        Object.assign(this, data);
    }
}