import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { catchError, firstValueFrom } from "rxjs";
import { environment } from '../../../environments/environment';
import { GtrakAuthService } from '../../../app/features/authentication/services/gtrak-auth.service';
import { CloudApiMiddleware } from './cloud-api-middleware.service';

@Injectable({
    providedIn: 'root'
})
export class TokenService {
    API_BASE_URL: string;
  
    constructor(
        private httpClient: HttpClient,
        private authService: GtrakAuthService,
        private cloudApiMiddleware: CloudApiMiddleware
    ) {
        this.API_BASE_URL = environment.apiUrl;
    }

    async CheckToken(token: string): Promise<boolean> {
        // return await firstValueFrom(this.httpClient.post<boolean>(`${this.API_BASE_URL}/auth/check-token`, {token}))
        return await this.cloudApiMiddleware.cloudRequest<boolean>({
            url: `${this.API_BASE_URL}/auth/check-token`,
            body: {token}
        }, 'POST');
    }

    async ChangePassword(password: string, token: string): Promise<boolean> {
        // return await firstValueFrom(this.httpClient.post<boolean>(`${this.API_BASE_URL}/auth/change-password`, {password, token}))
        return await this.cloudApiMiddleware.cloudRequest<boolean>({
            url: `${this.API_BASE_URL}/auth/change-password`,
            body: {password, token}
        }, 'POST');
    }

}