import { AppRoute } from '../../../core/routing/AppRoute';
import { CancelOrderReportPage } from './pages/cancel-order/cancel-order.page';
import { AuthGuard } from '../../common/rest-api/services/auth-guard.service';
import { CustomReportPage } from './pages/custom-reports/custom-reports.page';
import { GarmentReturnPage } from './pages/garment-return/garment-return.page';
import { ReturnReportPage } from './pages/garment-return/pages/return-report/return-report.page';

// A feature route should be used in the App routing module in most cases and primarily provide
// a base url and module loading functionality.
// The feature route should employ a load children callback that loads the appropriate module
const Feature = new AppRoute({
  segment: 'custom-reports',
  title: 'Custom Report',
  // A component can also be included to be the content, or a separate route with a blank segment
  // can be defined like below to provide a dedicated route instance
  // component: GarmentListingPage,
  loadChildren: () => import('./custom-reports.module').then(m => m.CustomReportModule),
  canActive: [AuthGuard]
});

// "Sub-routes" should be the contentful endpoints for the feature. To provide content at the base
// level of the feature url, simply leave segment blank.
// Provide the parent parameter to be able to derive the fullPath of a route that includes the base
const Report = new AppRoute({
  segment: '',
  title: 'Custom Reports',
  component: CustomReportPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const CancelReport = new AppRoute({
  segment: 'cancel-order',
  title: 'Cancel Order Report',
  component: CancelOrderReportPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const GarmentReturnReport = new AppRoute({
  segment: 'garment-review',
  title: 'Garment Return Review',
  component: GarmentReturnPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const EmployeeGarmentReturnReport = new AppRoute({
  segment: 'garment-review/employee/:eid/:cid/:did',
  title: 'Garment Return Review',
  component: ReturnReportPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const DepartmentGarmentReturnReport = new AppRoute({
  segment: 'garment-review/department/:did/:cid',
  title: 'Garment Return Review',
  component: ReturnReportPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const CustomerGarmentReturnReport = new AppRoute({
  segment: 'garment-review/customer/:cid',
  title: 'Garment Return Review',
  component: ReturnReportPage,
  parent: Feature,
  canActive: [AuthGuard]
});

export const CustomReportRoutes = {
  Feature: Feature,
  Report: Report,
  CancelReport: CancelReport,
  GarmentReturnReport: GarmentReturnReport,
  EmployeeGarmentReturnReport: EmployeeGarmentReturnReport,
  DepartmentGarmentReturnReport: DepartmentGarmentReturnReport,
  CustomerGarmentReturnReport: CustomerGarmentReturnReport
}