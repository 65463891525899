import { Component, Input, OnInit, ViewChild, OnChanges, SimpleChanges, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { RouteDeliveryDetailsService } from '../../../../services/route-delivery-details.service';
import { GRouteService } from '../../../../services/g-route.service';
import { RouteModel } from '../../../../models/route.model';
import { GtrakAuthService } from '../../../../../../../app/features/authentication/services/gtrak-auth.service';
import { RouteDeliveryDetailsFreq, RouteDeliveryDetailsPickup } from '../../../../models/route-delivery-details.model';
import { CustomerProductsScheduleService } from '../../../../services/customer-products-schedule.service';
import { CustomerProductsSchedule } from '../../../../models/customer-products-schedule.model';
import { ToasterService } from '../../../../../../../app/common/services/toaster.service';

@Component({
  selector: 'app-non-garments-page',
  templateUrl: 'non-garments.page.html',
  styleUrls: ['./non-garments.page.scss', '../../../../styles/g-route.styles.scss'],
})
export class NonGarmentsPage implements OnInit, OnChanges, AfterViewInit {
  currentRoute: RouteModel;
  stopName: string;
  deliveryList: RouteDeliveryDetailsFreq[] = [];
  productsServiceList: RouteDeliveryDetailsPickup[] = [];
  offWeekProducts: CustomerProductsSchedule[] = [];
  frequencyDate: number;
  frequencyDay: number;
  customerCode: string;
  currentWeek: number = 0;
  productsWarningFlag: boolean = false;

  routeStopName: string = "";
  loaded: boolean = false;
  processingRequest: boolean = false;

  displayedColumnsDelivery: string[] = ['productCode', 'week', 'productQuota', 'productLastUsedQty'];
  displayedColumnsService: string[] = ['departmentCode', 'productCode', 'qty'];
  displayedColumnsOffWeek: string[] = ['product', 'weeks', 'plannedQty'];

  deliveryDataSource = new MatTableDataSource<RouteDeliveryDetailsFreq>([]);
  productsServiceDataSource = new MatTableDataSource<RouteDeliveryDetailsPickup>([]);
  offWeekProductsDataSource = new MatTableDataSource<CustomerProductsSchedule>([]);

  @ViewChild('deliveryPaginator') deliveryPaginator: MatPaginator;
  @ViewChild('servicePaginator') servicePaginator: MatPaginator;
  @ViewChild('offWeekPaginator') offWeekPaginator: MatPaginator;
  @ViewChild('deliverySort') deliverySort: MatSort;
  @ViewChild('serviceSort') serviceSort: MatSort;
  @ViewChild('offWeekSort') offWeekSort: MatSort;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private routeDeliveryDetailsService: RouteDeliveryDetailsService,
    private productsScheduleService: CustomerProductsScheduleService,
    private toasterService: ToasterService,
    private gRouteService: GRouteService,
    private authService: GtrakAuthService,
    private cdr: ChangeDetectorRef
  ) {
    this.currentRoute = null;
  }

  async ngOnInit() {
    this.routeStopName = localStorage.getItem('routeStopName');

    // const date1 = new Date('1/3/2000');
    // const date2 = new Date(this.authService.user.routeDate);
    // const diffTime = Math.abs(date2.getTime() - date1.getTime());
    // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    // this.currentWeek = diffDays % 28;
    // this.currentWeek = Math.floor(this.currentWeek / 7) + 1;

    this.stopName = this.route.snapshot.paramMap.get('id');

    const promises = [];

    this.currentRoute = await this.gRouteService.get(this.authService.user.routeCode);
    this.frequencyDate = this.currentRoute.frequencyOffset(this.authService.user.routeDate);
    this.frequencyDay = this.currentRoute.frequencyDay(this.authService.user.routeDate);

    this.currentWeek = this.frequencyDate % 28;
    this.currentWeek = Math.floor(this.currentWeek / 7) + 1;

    const customers = await this.productsScheduleService.GetCustomerList(this.stopName);

    this.customerCode = customers[0].cdCode?.split('/')[0];

    promises.push(this.routeDeliveryDetailsService.GetDeliveryList(
      this.stopName,
      this.authService.user.routeCode,
      this.authService.user.routeDate
    ));
    promises.push(this.productsScheduleService.GetOffWeekProducts(
      this.stopName,
      this.customerCode,
      this.authService.user.routeDate
    ));
    promises.push(this.routeDeliveryDetailsService.GetPickupList(
      this.stopName,
      this.authService.user.routeCode,
      this.authService.user.routeDate,
      this.frequencyDate
    ));

    const promiseData = await Promise.all(promises);

    this.processDeliveryList(promiseData[0]);
    this.processOffweekData(promiseData[1]);
    this.processPickupList(promiseData[2]);

    window.dispatchEvent(new Event('resize'));

    this.loaded = true;
    this.loadPage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.deliveryList || changes.productsServiceList || changes.offWeekProducts) {
      this.loadPage();
    }
  }

  ngAfterViewInit(): void {
    this.deliveryDataSource.sort = this.deliverySort;
    this.deliveryDataSource.paginator = this.deliveryPaginator;
    this.productsServiceDataSource.sort = this.serviceSort;
    this.productsServiceDataSource.paginator = this.servicePaginator;
    this.offWeekProductsDataSource.sort = this.offWeekSort;
    this.offWeekProductsDataSource.paginator = this.offWeekPaginator;
    this.cdr.detectChanges();
  }

  loadPage() {
    this.deliveryDataSource.data = this.deliveryList;
    this.productsServiceDataSource.data = this.productsServiceList;
    this.offWeekProductsDataSource.data = this.offWeekProducts;
    this.cdr.detectChanges();
  }

  processPickupList(response: any): void {
    this.productsServiceList = response ?? [];
    for (const obj of response ?? []) {
      if (isNaN(Number(obj.qtyAsString))) {
        this.productsWarningFlag = true;
        break;
      }
    }
  }

  processOffweekData(response: any): void {
    const returnData = [];
    
    (response ?? []).forEach((element) => {
      const freqValue = this.validFrequency(element.frequency);
      element.frequency = this.weekVal(element.frequency);

      if (!(element.frequency.includes(this.currentWeek.toString()) || element.frequency.trim() === "") && freqValue) {
        returnData.push(element);
      }
    });
    this.offWeekProducts = returnData;
  }

  validFrequency(frequency: string): boolean {
    var isValid = false;

    for (let i = 0; i < 4; i++) {
      const tmp = frequency.substr((i * 7) + (this.frequencyDay - 1), 1);
      if (parseInt(tmp, 10) > 0) {
        isValid = true;
        break;
      }
    }

    return isValid;
  }

  processDeliveryList(response: any): void {
    if (response.length === 0 || !Array.isArray(response)) {
      const customerProductsSchedule: CustomerProductsSchedule = response as unknown as CustomerProductsSchedule;
      this.customerCode = customerProductsSchedule.customerCode;
      return;
    }

    let temp = response.reverse();
    let seen = {};
    let out: RouteDeliveryDetailsFreq[] = [];
    temp.forEach((element) => {
      if (!seen[element.routeDeliveryDetail.seqNumber]) {
        seen[element.routeDeliveryDetail.seqNumber] = true;

        let frequency = element.frequency;
        let weeks = "";
        for (let i = 0; i < 4; i++) {
          let tmp = frequency.substr(i * 7, 7);
          if (parseInt(tmp, 10) > 0) {
            weeks += (i + 1).toString();
          }
        }
        element.routeDeliveryDetail.week = parseInt(weeks);

        out.push(element);
      }
    });
    out.forEach((element) => {
      if (element.routeDeliveryDetail.productCategory !== "MAT") {
        element.routeDeliveryDetail.productQuota = element.routeDeliveryDetail.productQuota / 2;
      }
    });

    this.customerCode = out[0].routeDeliveryDetail.customerCode;
    this.deliveryList = out;
  }

  saveProducts() {
    this.processingRequest = true;
    this.productsServiceList.forEach(p => {
      p.login = this.authService.user.id as string;
    });

    this.routeDeliveryDetailsService.UpdatePickupList(this.productsServiceList).then((response) => {
      if (response === "Saved") {
        this.toasterService.presentToast("primary", "", "Products Saved", 1000);
      } else {
        this.toasterService.presentToast("danger", "", "An error occurred", 1000);
      }
    }).finally(() => {
      this.processingRequest = false;
    });
  }

  weekVal(frequency: string): string {
    let res = "";
    if (!frequency) return "";

    for (let i = 0; i < 4; i++) {
      const tmp = frequency.substr(i * 7, 7);
      if (parseInt(tmp, 10) > 0) {
        res += (i + 1).toString();
      }
    }
    return res;
  }

  editQuantity(event, row) {
    row.qty = event.target.value;
  }
}
