<app-top-bar></app-top-bar>
<ion-content >

    <!-- <h1>Work Order</h1>
    <ion-grid class="ion-no-padding info-grid">
        <ion-row class="row">
            <h4>Cust:</h4>
            <div>{{customer.customerCode}} {{customer.customerName}}</div>
        </ion-row>
        <ion-row class="row">
            <h4>Stop:</h4> {{customer.stopCode}} {{customer.stopName}}
        </ion-row>
        <ion-row class="bottom-row">
            <h4>Locker:</h4> {{customer.employeeCode}} <h6>{{customer.lastName}}, {{customer.firstName}}</h6>
        </ion-row>
    </ion-grid> -->

    <div class="main-header">
        <span>Work Order</span>
        <div (click)="navigateBack()" class="back-button-mobile">
            <ion-icon name="chevron-back-outline"></ion-icon>
        </div>
    </div>
    <div class="mobile-info">
        <ion-grid class="info-grid">
            <ion-row class="row">
                <span class="top-header-label">Cust:</span>
                <div *ngIf="loaded">{{customer.customerCode}} {{customer.customerName}}
                </div>
            </ion-row>
            <ion-row class="row">
                <span class="top-header-label">Stop:</span> <ng-container *ngIf="loaded">{{customer.stopCode}}
                    {{customer.stopName}}</ng-container>
            </ion-row>
            <ion-row class="bottom-row">
                <span class="top-header-label">Locker:</span> <ng-container *ngIf="loaded">{{customer.employeeCode}} <h6>
                    {{customer.lastName}}, {{customer.firstName}}</h6></ng-container>
            </ion-row>
        </ion-grid>
    </div>

    <div *ngIf="loaded" class="select-container">
        <ion-row class="select-header">
            <ion-col size="4" class="border-right">
                <span>Order Date</span>
            </ion-col>
            <ion-col size="4" class="border-right">
                <span>Delivery Date</span>
            </ion-col>
            <ion-col size="4">
                <span>Product</span>
            </ion-col>
        </ion-row>
        <ion-row *ngFor="let order of report.report" class="product-container border-top" (click)="expandItem(order)">
            <ion-col size="4" class="border-right">
                <div *ngIf="!order.expanded" class="sign">
                    <ion-icon name="add-outline"></ion-icon>
                </div>
                <div *ngIf="order.expanded" class="sign">
                    <ion-icon name="remove-outline"></ion-icon>
                </div>
                <span>{{order.orderDate | date: 'MM/dd/yyyy'}}</span>
            </ion-col>
            <ion-col size="4" class="border-right">
                <span>{{order.deliveryDate | date: 'MM/dd/yyyy'}}</span>
            </ion-col>
            <ion-col size="4">
                <span>{{order.product}}</span>
            </ion-col>

            <ion-row class="expanded-parent">
                <div *ngIf="order.expanded" class="expanded-content">
                    <ion-row>
                        <span><span class="bold-text">Quantity: </span> {{order.qty}}</span>
                    </ion-row>
                    <ion-row>
                        <span><span class="bold-text">Reason: </span> {{order.reason}}</span>
                    </ion-row>
                </div>
            </ion-row>
        </ion-row>
    </div>

    <ion-row class="no-data-found" *ngIf="loaded && (report?.report?.length <= 0)">
        <ion-col>
            <span>No Data Found</span>
        </ion-col>
    </ion-row>

    <div class="spin" *ngIf="!loaded">
        <ion-spinner name="circular"></ion-spinner>
    </div>
</ion-content>
<app-footer></app-footer>