import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter} from '@ionic/angular';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';
import { MobileHomeRoutes } from '../../mobile.routes';
import { ToasterService } from '../../../../common/services/toaster.service';

@Component({
  selector: 'app-mobile-home-page',
  templateUrl: 'mobile-home.page.html',
  styleUrls: [
    './mobile-home.page.scss'
  ]
})
export class MobileHomePage implements ViewWillEnter {

  public routes = MobileHomeRoutes;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: GtrakAuthService,
    private toasterService: ToasterService
  ) { 
  
  }



  ionViewWillEnter(): void {


  }

  async logout(): Promise<void> {
    const user = this.authService.user;
    await this.authService.Logout();

    this.router.navigate([user.homeAuthLocation]);
    this.toasterService.presentToast("primary", "You have been logged out", "Success");
  }

}