<ion-content class="garment-employee-lookup">
    <app-header [title]="(routeStopName ?? '') + ' (' + stopName + ') Employee Lookup'"></app-header>
    <ion-grid class="top-grid">
        <ion-row class="scan-row">
            <ion-col>
                <ion-row class="top-label">
                    <ion-col class="ion-no-padding">
                        <ion-label>Search Employee</ion-label>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col class="ion-no-padding">
                        <ion-input (keydown.enter)="getEmployee(true)" [(ngModel)]="employeeName"></ion-input>
                    </ion-col>
                </ion-row>
            </ion-col>
            <ion-col size="auto">
                <ion-row class="top-label">
                    <ion-col class="ion-no-padding">
                        <ion-label>Active Filter</ion-label>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col class="ion-no-padding">
                        <ion-select interface="popover" (ionChange)="inactiveCheckboxUpdate($event)" [(ngModel)]="activeFilter">
                            <ion-select-option [value]="1">
                                Show All
                            </ion-select-option>
                            <ion-select-option [value]="2">
                                Active
                            </ion-select-option>
                            <ion-select-option [value]="3">
                                Inactive
                            </ion-select-option>
                        </ion-select>
                    </ion-col>
                </ion-row>
            </ion-col>
            <ion-col (click)="getEmployee(true)" size="auto">
                <ion-row class="top-label">
                    <ion-col class="ion-no-padding">
                        <ion-label style="opacity: 0;">1</ion-label>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-col class="ion-no-padding">
                        <ion-button>Submit</ion-button>
                    </ion-col>
                </ion-row>
            </ion-col>
        </ion-row>
        <ion-row class="checkbox-row">
            <ion-col>
                <ion-checkbox (ionChange)="searchAllBox($event)" [(ngModel)]="searchAll"></ion-checkbox>
                <span>Search All</span>
            </ion-col>
        </ion-row>
    </ion-grid>

    <ion-row>
        <ion-col>
            <div class="table-container page-area" *ngIf="totalPages > 0">
                <ion-row>
                    <ion-col></ion-col>
                    <ion-col size="auto">
                        <ion-label>Select Page:</ion-label>
                    </ion-col>
                    <ion-col class="selectable" size="auto" (click)="selectPage(1)">&#60;&#60;</ion-col>
                    <ion-col class="selectable" size="auto" (click)="selectPage(currPage - 1)">&#60;</ion-col>
                    <ion-col class="selectable" size="auto" *ngFor="let number of pageNumbers" 
                        [ngStyle]="{'display': currPage < 5 ? (number >= currPage - 5 && number <= 10 && number > 0 && number <= totalPages) ? 'block' : 'none' 
                            : (number > currPage - 5 && number <= currPage + 5 && number > 0 && number <= totalPages) ? 'block' : 'none'}"
                        [ngClass]="{'add-underline': number === currPage}" (click)="selectPage(number)">
                        {{number}}
                    </ion-col>
                    <ion-col class="selectable" size="auto" (click)="selectPage(currPage + 1)">&#62;</ion-col>
                    <ion-col class="selectable" size="auto" (click)="selectPage(totalPages)">&#62;&#62;</ion-col>
    
                    <ion-col></ion-col>
                </ion-row>
            </div>
        </ion-col>
    </ion-row>

    <ion-row class="table-header">
        <ion-col>
            Name
        </ion-col>
        <ion-col class="center-col">
            Make WO
        </ion-col>
        <ion-col class="center-col">
            Locker
        </ion-col>
        <ion-col class="center-col">
            Ref ID (Cust/Dept)
        </ion-col>
    </ion-row>

    <div *ngIf="loaded && (employees.length > 0)" class="scroll-area">
        <ion-row *ngFor="let employee of employees">
            <ion-col class="link">
                <span (click)="navigateToEmployee(employee)">{{employee.lastName}}, {{employee.firstName}}</span>
            </ion-col>
            <ion-col class="center-col">
                <ion-icon (click)="navigateToWorkOrder(employee)" name="clipboard-outline"></ion-icon>
            </ion-col>
            <ion-col class="center-col">
                {{employee.lockerNum}}
            </ion-col>
            <ion-col class="center-col">
                {{employee.customerCode}}/{{employee.departmentCode}}
            </ion-col>
        </ion-row>
    </div>

    <div class="no-data" *ngIf="loaded && (employees.length <= 0)">
        <ion-row>
            <ion-col>
                No Data Found
            </ion-col>
        </ion-row>
    </div>

</ion-content>