import { Component, OnInit } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController, ViewWillEnter, ViewDidLeave, ViewDidEnter } from '@ionic/angular';
import { GtrakAuthService } from '../../services/gtrak-auth.service';
import { AuthCreds, LoginMode } from '../../../../common/rest-api/models/login-creds';
import { ToastController } from '@ionic/angular';
import { ToasterService } from '../../../../../app/common/services/toaster.service';
import { RouteModel } from '../../../../../app/features/g-route/models/route.model';
import { GRouteService } from '../../../../../app/features/g-route/services/g-route.service';
import { RequestParams } from '../../../../common/rest-api/models/request-params';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-login-page',
  templateUrl: 'login.page.html',
  styleUrls: [
    './login.page.scss'
  ]
})
export class LoginPage implements ViewWillEnter, OnInit, ViewDidLeave, ViewDidEnter {

  LoginMode = LoginMode;

  userId: string;
  password: string;

  stopNumber: string;
  lockerNumber: string;
  lastName: string;

  forDate: string;

  passedInMode: boolean = false;

  routeCode: string;

  routeList: RouteModel[] = [];
  routeListParams: RequestParams;

  loginMode: LoginMode = LoginMode.Gtrak;

  creds: AuthCreds = new AuthCreds();

  showOptionsBar: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public menu: MenuController,
    public authService: GtrakAuthService,
    private toastr: ToasterService,
    private routeService: GRouteService,
    private navController: NavController
  ) { 
  
  }

  ngOnInit(): void {
    const url: string = this.router.url;

    if (this.authService.isNative) {
      this.loginMode = LoginMode.Gmobile;
      this.showOptionsBar = false;
    } else {
      if (url.includes('login')) {
        this.loginMode = LoginMode.Gtrak;
        this.showOptionsBar = false;
      } else if (url.includes('mobile')) {
        this.loginMode = LoginMode.Gmobile;
        this.showOptionsBar = false;
      } else if (url.includes('route')) {
        this.loginMode = LoginMode.Groute;
        this.showOptionsBar = false;
      } else if (url.includes('wearer')) {
        this.loginMode = LoginMode.GWearer;
        this.showOptionsBar = false;
      }
    }

    const mode: any = this.router.getCurrentNavigation()?.extras?.state?.mode;

    if (mode) {
      this.loginMode = mode as LoginMode;
      this.passedInMode = true;
    }

    this.routeListParams = new RequestParams();
    this.routeListParams.setOrdering('Description', 'asc');
    this.routeListParams.setPaging(1, 0);

    this.creds.routeCode = '1';
    this.creds.routeDate = this.getCurrentDayFormatted();
  }

  // Disable side menu for this page
  ionViewWillEnter(): void {
    const originalLoginMode = Number(localStorage.getItem('loginMode') ?? '-1');
    console.log(this.loginMode);
    if(this.authService.isAuthenticated && !this.passedInMode && (originalLoginMode === this.loginMode)) {
      this.router.navigate([this.authService.user?.homeLocation]);
    }

    // Cache the route list 
    const cachedRouteList = JSON.parse(localStorage.getItem('GRouteLoginRouteList'));

    if (cachedRouteList && cachedRouteList.ttl > new Date().getTime()) {
      this.routeList = cachedRouteList.data;
    } else {
      this.routeService.getRoutesList(this.routeListParams).then((routes) => {
        this.routeList = routes;
  
        // Set ttl to one day
        var day = 60 * 60 * 24 * 1000;
        const ttl = new Date().getTime() + day;
        // Cache routeList since it never really changes
        localStorage.setItem('GRouteLoginRouteList', JSON.stringify({data: this.routeList, ttl}));
      });
    }
  }

  ionViewDidEnter(): void {
    this.menu.enable(false);
  }

  // Restore to default when leaving this page
  ionViewDidLeave(): void {
    this.menu.enable(true);
  }

  changeLoginForm(mode: LoginMode): void {
    this.loginMode = mode;
  }

  selectRoute(data): void {
    this.creds.routeCode = data.detail.value;
  }

  async submit(): Promise<void> {
    // Clear fields based on login mode,
    this.creds.prepareForLoginMode(this.loginMode);

    if ((!this.creds.password || (this.creds.password.length === 0) || !this.creds.login || (this.creds.login.length === 0)) && 
    ((this.loginMode === LoginMode.Gtrak) || (this.loginMode === LoginMode.Groute) || (this.loginMode === LoginMode.Gmobile))) {
      this.toastr.presentToast('danger', '', 'Invalid Username or Password', 1000);
    } else if ((!this.creds.lockerNum || (this.creds.lockerNum.length === 0) || !this.creds.lastName || (this.creds.lastName.length === 0) || 
    !this.creds.stopCode || (this.creds.stopCode.length === 0)) && (this.loginMode === LoginMode.GWearer)) {
      this.toastr.presentToast('danger', '', 'Invalid Stop Code, Locker Number, and Last Name', 1000);
    } else {
      var status = await this.authService.Login(this.creds);

      if (this.authService.isNative && !this.authService?.user?.canAccessMobileApp) {
        this.toastr.presentToast('danger', '', 'Not a valid mobile login!', 1000);
      } else {
        if(status) {
          localStorage.setItem('loginMode', this.loginMode.toString());
          // TODO Potentially employ a switch for the three different modes
          if (this.authService.isNative || (this.loginMode === LoginMode.Gmobile && this.authService.user.isEmployeeUser) || (this.authService.user.isEmployeeUser)) {
            this.router.navigate(['/mobile']);
          } else if(this.loginMode === LoginMode.Groute || this.authService.user.isDriver) {
            this.router.navigate(['/route']);
          } else {
            this.router.navigate(['/garment-listing']);
          }
        } else {
          this.toastr.presentToast('danger', 'The credentials provided are not valid.', 'Invalid Credentials');
        }
      }
    }
  }

  navigateToBaseLogin(): void {
    var url = 'auth';

    if (this.loginMode === LoginMode.Gmobile) {
      url += '/mobile';
    } else if (this.loginMode === LoginMode.GWearer) {
      url += '/wearer';
    } else if (this.loginMode === LoginMode.Groute) {
      url += '/route';
    } else {
      url += '/login';
    }

    this.navController.navigateRoot([url]);
  }

  navigateToCreateAccount(): void {
    this.navController.navigateRoot(['auth/login/create-account']);
  }

  navigateToForgotPassword(): void {
    this.navController.navigateRoot(['auth/login/forgot-password']);
  }

  getCurrentDayFormatted(): string {
    const date = new Date();
    return `${date.getFullYear()}-${((date.getMonth() + 1) <= 9) ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1)}-${(date.getDate() <= 9) ? '0' : ''}${date.getDate()}`;
  }

}