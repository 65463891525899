import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';
import { WebOrderService } from '../../services/web-order.service';
import { ToasterService } from "../../../../common/services/toaster.service";
import { WebOrderApprovals } from '../../models/web-order-approvals.model';
import { ModalController } from '@ionic/angular';
import { WebOrderViewModalComponent } from '../../components/web-order-view-modal/web-order-view-modal.component';
import { WebOrderCancelModalComponent } from '../../components/web-order-cancel-modal/web-order-cancel-modal.component';

@Component({
  selector: 'app-wo-approval-page',
  templateUrl: 'wo-approval.page.html',
  styleUrls: [
    './wo-approval.page.scss',
  ]
})
export class WoApprovalPage {
    fromDate: string;
    toDate: string;
    status: string = '0';
    loaded: boolean = false;
    orders: WebOrderApprovals[];
    transferOrders: WebOrderApprovals[] = [];

    constructor(
        private router: Router,
        private authService: GtrakAuthService,
        private webOrderService: WebOrderService,
        private toasterService: ToasterService,
        private modalController: ModalController,
      ) { 
    }

    async getOrders() {
      //if there is one date set but not the other, throw an error
      if((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
        this.toasterService.presentToast('danger', '', 'Please select both a start and end date.', 3000);
        return;
      }

      //if both dates are not set then set todate to today and fromdate to 90 days ago
      if(!this.fromDate && !this.toDate) {
        let today = new Date();
        let ninetyDaysAgo = new Date();
        ninetyDaysAgo.setDate(today.getDate() - 90);
        this.fromDate = ninetyDaysAgo.toISOString().split('T')[0];
        this.toDate = today.toISOString().split('T')[0];
      }

      this.loaded = false;

      await this.webOrderService.getOrders(this.fromDate, this.toDate, this.status).then((response) => {
        this.orders = response;
      });

      this.loaded = true;

    }

    isOrderWithState2Present(): boolean {
      const present = this.orders.some(order => order.webOrder && order.webOrder.state === 2);
      return present;
    }

    async OpenViewModal(order: WebOrderApprovals) {
      const modal = await this.modalController.create({
        component: WebOrderViewModalComponent,
        cssClass: 'min-width-modal-md-wide grey-background',
        showBackdrop: true,
        backdropDismiss: true,
        keyboardClose: true,
        swipeToClose: true,
        componentProps: {
          order: order
        }
      });
  
      (await modal).present();
      const {data} = await (await modal).onDidDismiss();
    }

    async OpenCancelModal(order: WebOrderApprovals) {
      const modal = await this.modalController.create({
        component: WebOrderCancelModalComponent,
        cssClass: 'min-width-modal-lg grey-background',
        showBackdrop: true,
        backdropDismiss: true,
        keyboardClose: true,
        swipeToClose: true,
        componentProps: {
          order: order
        }
      });
  
      (await modal).present();
      const {data} = await (await modal).onDidDismiss();

      if (data) {
        this.getOrders();
      }
    }

    AddOrderToTransfer(order: WebOrderApprovals, event: any) {
      if(event.target.checked) {
        console.log(order);
        this.transferOrders.push(order);
      } else {
        this.transferOrders = this.transferOrders.filter(o => o.webOrder.woId !== order.webOrder.woId);
      }
    }

    TransferOrders() {
      //check if transfer orders is empty
      if(this.transferOrders.length === 0) {
        this.toasterService.presentToast('danger', '', 'Please select at least one order to transfer.', 3000);
        return;
      }

      //check if transfer orders have a selected transfer reason
      for(let order of this.transferOrders) {
        if(order.selectedTransferReason === null || order.selectedTransferReason === "") {
          this.toasterService.presentToast('danger', '', 'Please select a transfer reason for each order.', 3000);
          return;
        }
      }

      //call the api
      this.webOrderService.transferOrders(this.transferOrders).then((response) => {
        if(response == "Success") {
          this.toasterService.presentToast('primary', '', 'Orders have been transferred.', 3000);
        }
        else {
          this.toasterService.presentToast('danger', `${response}`, 'Orders failed to transfer.', 3000);
        }
        this.getOrders();
        //clear the transfer orders array
        this.transferOrders = [];
      });
    }
}