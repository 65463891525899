<ion-content class="garment-employee-lookup">
    <app-header [title]="(routeStopName ?? '') + ' (' + stopName + ') Employee Details'"></app-header>
    <div class="content">
        <ion-row class="table-header">
            <ion-col>
                Garment
            </ion-col>
            <ion-col class="center-col">
                Product
            </ion-col>
            <ion-col class="center-col">
                Last Delivery
            </ion-col>
        </ion-row>
    
        <div *ngIf="loaded && (garments.length > 0)" class="scroll-area">
            <ion-row *ngFor="let garment of garments; let i = index" [class.alternate]="i % 2 === 0">
                <ion-col class="link">
                    <span (click)="navigateToGarment(garment)">{{garment.garmentCode}}</span>
                </ion-col>
                <ion-col class="center-col">
                    {{garment.productCode}}
                </ion-col>
                <ion-col class="center-col">
                    {{garment.deliveryDate | date:'shortDate'}}
                </ion-col>
            </ion-row>
        </div>
    
        <div class="no-data" *ngIf="loaded && (garments.length <= 0)">
            <ion-row>
                <ion-col>
                    No Data Found
                </ion-col>
            </ion-row>
        </div>
    </div>
</ion-content>