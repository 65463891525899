import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DepartmentKey } from '../../../../app/common/models/department-key-model';
import { EmployeeKey } from '../../../../app/common/models/employee-key.model';
import { QueryResult } from '../../../common/rest-api/models/query-result';
import { RequestParams } from '../../../common/rest-api/models/request-params';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { RouteStopModel } from '../models/route-stop.model';
import { CustomerContactModel } from '../models/customer-contact.model';
import { AllStopContacts } from '../pages/stop-info-menu/detail-pages/contacts/models/stopContact.model';
  
@Injectable({
providedIn: 'root',
})
export class CustomerContactsService extends RestApiService<AllStopContacts> {

    protected resourcePath = '/route-stop';
    protected modelType = AllStopContacts;

    async getContacts(stopCode: string, params?: RequestParams): Promise<QueryResult<AllStopContacts>> {
        const url = this.buildEndpointUrl(`contacts/${stopCode}`, params);
        const response = await this.cloudApiMiddleware.cloudRequest<QueryResult<AllStopContacts>>({
            url,
        }, 'GET');

        return response;
    }

}