import { Component, OnInit } from "@angular/core";
import { ViewWillEnter } from "@ionic/angular";
import { ActivatedRoute, Router } from "@angular/router";
import { CreateWorkOrderService } from "../../../../services/create-work-order.service";
import { WorkOrderProduct } from "../../../../models/work-order-product.model";
import { WorkOrderGarment } from "../../../../models/work-order-garment.model";
import { ToasterService } from "../../../../../../common/services/toaster.service";
import { GtrakAuthService } from "../../../../../authentication/services/gtrak-auth.service";
import { GarmentReportService } from "../../../../../../../app/features/garment-listing/services/garment-report.service";
import { GarmentReportDetail } from "../../../../../../../app/features/garment-listing/models/garment-report-detail.model";
import { GarmentReportSummary } from "../../../../../../../app/features/garment-listing/models/garment-report-summary.model";
import { GarmentReport } from "../../../../../../../app/features/garment-listing/models/garment-report.model";

@Component({
  selector: "app-create-work-order-page",
  templateUrl: "create-work-order.page.html",
  styleUrls: [
    "./create-work-order.page.scss",
    "../../../../styles/g-route.styles.scss",
  ],
})
export class CreateWorkOrderPage implements ViewWillEnter, OnInit {
  currentRoute: string;
  stopName: string;
  customerCode: string;
  departmentCode: string;
  employeeCode: string;

  garment: GarmentReport = null;
  garmentSummary: GarmentReportSummary = null;
  garmentDetails: GarmentReportDetail = null;

  loaded: boolean = false;

  garmentDetailsShow: boolean = false;

  woProductList: WorkOrderProduct[] = [];
  selectedWoProductList: WorkOrderProduct[] = [];

  woGarmentList: WorkOrderGarment[] = [];

  userId: string | number = null;

  type: string = "XI";

  routeStopName: string = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private createWorkOrderService: CreateWorkOrderService,
    private toasterService: ToasterService,
    private gtrakAuthService: GtrakAuthService,
    private garmentReportService: GarmentReportService
  ) {
    this.currentRoute = null;
  }

  async ngOnInit(): Promise<void> {
    this.currentRoute = this.router.url;
    this.stopName = this.route.snapshot.paramMap.get("id");
    this.employeeCode = this.route.snapshot.paramMap.get("eId");
    this.departmentCode = this.route.snapshot.paramMap.get("dId");
    this.customerCode = this.route.snapshot.paramMap.get("cId");

    this.routeStopName = localStorage.getItem('routeStopName');

    this.userId = await this.gtrakAuthService.user.id;

    this.loadNonReplace();

    this.loaded = true;
  }

  ionViewWillEnter(): void {}

  submitCancelItem(): void {
    //check if selected list is empty
    if (this.selectedWoProductList.length === 0) {
      this.toasterService.presentToast(
        "warning",
        "",
        "Please select at least one garment",
        3000
      );
      return;
    }

    //submit
    this.createWorkOrderService
      .SubmitCancelItem(
        this.userId,
        this.customerCode,
        this.employeeCode,
        this.departmentCode,
        this.stopName,
        this.type,
        this.selectedWoProductList
      )
      .then((res) => {
        if (res === "Success") {
          this.toasterService.presentToast(
            "primary",
            "",
            "Garment cancel successfully",
            3000
          );
          this.woProductList = this.woProductList.filter(w => !this.selectedWoProductList.map(w => w.code).includes(w.code));
        } else {
          this.toasterService.presentToast("danger", "", `${res}`, 3000);
        }
      });
  }

  submitSizeChange(): void {
    //check if selected list is empty
    if (this.selectedWoProductList.length === 0) {
      this.toasterService.presentToast(
        "warning",
        "",
        "Please select at least one garment",
        3000
      );
      return;
    }
    //check if all selected sizes are filled
    this.selectedWoProductList.forEach((wo) => {
      if (
        wo.selectedSize === "" ||
        wo.selectedSize === null ||
        wo.selectedSize === undefined
      ) {
        this.toasterService.presentToast(
          "warning",
          "",
          "Please fill in all sizes",
          3000
        );
        return;
      }
    });
    //submit
    this.createWorkOrderService
      .SubmitSizeChange(
        this.userId,
        this.customerCode,
        this.employeeCode,
        this.departmentCode,
        this.stopName,
        this.selectedWoProductList
      )
      .then((res) => {
        if (res === "Success") {
          this.toasterService.presentToast(
            "primary",
            "",
            "Garment size change successfully",
            3000
          );

          this.woProductList = this.woProductList.filter(w => !this.selectedWoProductList.map(w => w.code).includes(w.code));
        } else {
          this.toasterService.presentToast("danger", "", `${res}`, 3000);
        }
      });
  }

  submitGarmentReplace(): void {
    //submit
    this.createWorkOrderService
      .SubmitGarmentReplace(
        this.userId,
        this.stopName,
        this.garmentDetails.garmentCode
      )
      .then((res) => {
        if (res === "Success") {
          this.toasterService.presentToast(
            "primary",
            "",
            "Garment replaced successfully",
            3000
          );
        } else {
          this.toasterService.presentToast("danger", "", `${res}`, 3000);
        }
      });
  }

  woChange(event) {
    if (event.detail.value === "XC") {
      //empty selected list
      this.selectedWoProductList = [];
      //add all to selected list
      this.selectedWoProductList = this.woProductList;
      this.loadNonReplace();
    }
    if (event.detail.value === "XI") {
      //empty selected list
      this.selectedWoProductList = [];
      this.loadNonReplace();
    }
    if (event.detail.value === "RSC") {
      //empty selected list
      this.selectedWoProductList = [];
      this.loadNonReplace();
    }
    if (event.detail.value === "RC") {
      //empty selected list
      this.selectedWoProductList = [];
      this.loadReplace();
    }
  }

  loadNonReplace() {
    this.createWorkOrderService
      .GetWoProductList(
        this.customerCode,
        this.employeeCode,
        this.departmentCode
      )
      .then((res) => {
        this.woProductList = res;
      });
  }

  loadReplace() {
    this.createWorkOrderService
      .GetWoGarmentList(
        this.customerCode,
        this.employeeCode,
        this.departmentCode
      )
      .then((res) => {
        this.woGarmentList = res;
      });
  }

  addSelected(wo: WorkOrderProduct, event) {
    if (event.target.checked) {
      this.selectedWoProductList.push(wo);
    } else {
      //splice it out
      this.selectedWoProductList.splice(
        this.selectedWoProductList.indexOf(wo),
        1
      );
    }
  }

  changeSelectedSize(wo: WorkOrderProduct, event) {
    //remove spaces
    wo.selectedSize = event.detail.value.replace(/\s/g, "");
  }

  showDetailsReplace(event) {
    this.garmentDetailsShow = false;
    this.garment = null;
    this.garmentSummary = null;
    this.garmentDetails = null;
    this.garmentReportService
      .findForGarment(event.target.textContent)
      .then((res) => {
        this.garmentDetailsShow = true;
        this.garment = res;
        this.garmentSummary = this.garment.summary[0];
        //set garmentDetails to garment.details where garment.details.garmentCode === event.target.textContent
        this.garmentDetails = this.garment.details.filter(
          (garment) => garment.garmentCode === event.target.textContent
        )[0];
      });
  }

  navigateToGarment(garment: GarmentReportDetail): void {
    this.router.navigate([`/route/logsheet/stop/${this.stopName}/lookup/${garment.garmentCode}`], {
      state: { replace: true },
    });
  }
}
