import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { catchError, firstValueFrom } from "rxjs";
import { environment } from '../../../environments/environment';
import { GtrakAuthService } from '../../../app/features/authentication/services/gtrak-auth.service';
import { CloudApiMiddleware } from './cloud-api-middleware.service';

@Injectable({
    providedIn: 'root'
})
export class SMSService {
    API_BASE_URL: string;
  
    constructor(
        private httpClient: HttpClient,
        private authService: GtrakAuthService,
        private cloudApiMiddleware: CloudApiMiddleware
    ) {
        this.API_BASE_URL = environment.apiUrl;
    }

    async sendWebOrderSMS(woId: number, token: string): Promise<boolean> {
        const userId = this.authService.user.id.toString();

        // return await firstValueFrom(this.httpClient.post<boolean>(`${this.API_BASE_URL}/sms/web-order`, {userId, woId, token}))
        return await this.cloudApiMiddleware.cloudRequest<boolean>({
            url: `${this.API_BASE_URL}/sms/web-order`,
            body: {userId, woId, token}
        }, 'POST');
    }

}