import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { GarmentModel } from '../../../../../../features/garment-listing/models/garment.model';
import { GarmentEmployeeDetailsService } from '../../../../services/garment-employee-details.service';

@Component({
  selector: 'app-garment-employee-details-page',
  templateUrl: 'garment-employee-details.page.html',
  styleUrls: [
     './garment-employee-details.page.scss',
     '../../../../styles/g-route.styles.scss'
  ]
})
export class GarmentEmployeeDetailsPage implements OnInit {

    currentRoute: string;
    stopName: string;
    customerCode: string;
    departmentCode: string;
    employeeCode: string;

    loaded: boolean = false;

    garments: GarmentModel[] = [];

    routeStopName: string = "";

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private garmentEmployeeDetailsService: GarmentEmployeeDetailsService
    ) { 
        this.currentRoute = null;
    }

    async ngOnInit(): Promise<void> {
        this.currentRoute = this.router.url;
        this.stopName = this.route.snapshot.paramMap.get('id');
        this.employeeCode = this.route.snapshot.paramMap.get('eId');
        this.departmentCode = this.route.snapshot.paramMap.get('dId');
        this.customerCode = this.route.snapshot.paramMap.get('cId');

        this.routeStopName = localStorage.getItem('routeStopName');

        await this.getGarments();

        this.loaded = true;
    }

    navigateToGarment(garment: GarmentModel): void {
        this.router.navigate([`/route/logsheet/stop/${this.stopName}/lookup/${garment.garmentCode}`]);
    }


    async getGarments(): Promise<void> {
        this.garmentEmployeeDetailsService.getGarments(this.employeeCode, this.customerCode, this.departmentCode).then((response) => {
            this.garments = response;
        });
    }
}