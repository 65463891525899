<mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

  <!-- Customer Column -->
  <ng-container matColumnDef="customerCode">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Customer </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <a (click)="routeCustomer(element.customerCode)" class="link">{{element.customerCode}}</a>
    </mat-cell>
  </ng-container>

  <!-- Department Column -->
  <ng-container matColumnDef="departmentCode">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Department </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <a (click)="routeDepartment(element)" class="link">{{element.departmentCode}}</a>
    </mat-cell>
  </ng-container>

  <!-- Employee Column -->
  <ng-container matColumnDef="employeeCode">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Employee </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <a (click)="routeEmployee(element)" class="link">{{element.employeeCode}}</a>
    </mat-cell>
  </ng-container>

  <!-- Last Name Column -->
  <ng-container matColumnDef="lastName">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.lastName}} </mat-cell>
  </ng-container>

  <!-- First Name Column -->
  <ng-container matColumnDef="firstName">
    <mat-header-cell *matHeaderCellDef mat-sort-header> First Name </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.firstName}} </mat-cell>
  </ng-container>

  <!-- Locker Column -->
  <ng-container matColumnDef="lockerNum">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Locker </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.lockerNum}} </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>

<mat-paginator [pageSize]="pageSize" [length]="totalElements" showFirstLastButtons (page)="changePage($event)">
</mat-paginator>

