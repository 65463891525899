import { Component, Input, OnInit } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { EmployeeListService } from '../../../../../../../app/common/services/employee-listing.service';
import { RequestParams } from '../../../../../../common/rest-api/models/request-params';
import { EmployeeListing } from '../../../../../../../app/common/models/employee-listing.model';
@Component({
  selector: 'app-garment-employee-lookup-page',
  templateUrl: 'garment-employee-lookup.page.html',
  styleUrls: [
     './garment-employee-lookup.page.scss',
     '../../../../styles/g-route.styles.scss'
  ]
})
export class GarmentEmployeeLookupPage implements ViewWillEnter, OnInit {

    currentRoute: string;
    stopName: string;

    loaded: boolean = false;

    searchAll: boolean = false;

    currPage: number;
    pageSize: number;

    totalPages: number;

    pageNumbers = [];

    params: RequestParams;

    employeeName: string = null;

    activeFilter: number = 1;

    employees: EmployeeListing[] = [];

    routeStopName: string = "";

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private employeeListService: EmployeeListService
    ) { 
        this.currentRoute = null;
    }

    async ngOnInit(): Promise<void> {
        this.currentRoute = this.router.url;
        this.stopName = this.route.snapshot.paramMap.get('id');

        this.routeStopName = localStorage.getItem('routeStopName');

        this.currPage = 1;
        this.pageSize = 100;

        this.params = new RequestParams();
        this.params.filters.StopCode = this.stopName;

        this.params.filters.ExcludeInactive = 1;

        this.params.setOrdering('LastName', 'asc')

        await this.getEmployee();

        this.loaded = true;
    }

    ionViewWillEnter(): void {
        
    }

    inactiveCheckboxUpdate(event: any): void {
        if (this.activeFilter === 1) {
            delete this.params.filters.ExcludeInactive;
            delete this.params.filters.InactiveOnly;
        } else if (this.activeFilter === 2) {
            delete this.params.filters.InactiveOnly;
            this.params.filters.ExcludeInactive = 1;
        } else if (this.activeFilter === 3) {
            delete this.params.filters.ExcludeInactive;
            this.params.filters.InactiveOnly = 1;
        }
    }

    navigateToEmployee(employee: EmployeeListing): void {
        this.router.navigate([`/route/logsheet/stop/${this.stopName}/employee-lookup/${employee.customerCode}/${employee.departmentCode}/${employee.employeeCode}`]);
    }

    navigateToWorkOrder(employee: EmployeeListing): void {
        this.router.navigate([`/route/logsheet/stop/${this.stopName}/employee-lookup/create-work-order/${employee.customerCode}/${employee.departmentCode}/${employee.employeeCode}`]);
    }

    searchAllBox($event): void {
        if ($event.detail.checked) {
            delete this.params.filters.StopCode;
        } else {
            this.params.filters.StopCode = this.stopName;
        }
    }

    async getEmployee(bySearch: boolean = false): Promise<void> {
        
        if (bySearch) {
            this.currPage = 1;
        }

        this.params.setPaging(this.currPage, this.pageSize);

        if (this.employeeName && (this.employeeName?.length > 0)) {
            this.params.filters.LastName = this.employeeName;
        } else {
            delete this.params.filters.LastName;
        }

        var employees = await this.employeeListService.query(this.params);

        this.totalPages = employees.totalPages;

        this.employees = employees.results;

        this.pageNumbers = Array(this.totalPages).fill(1).map((x, i) => i)
        this.pageNumbers.push(this.totalPages);

    }

    selectPage(number: number): void {
        if(number <= 0) return;
    
        this.currPage = number;
    
        this.getEmployee();
    }


}