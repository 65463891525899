<ion-app dir="{{textDir}}">


  <!-- when=false means that the split pane will never expand automatically -->
  <!-- For more info check https://ionicframework.com/docs/api/split-pane -->
  <ion-split-pane contentId="main-content" when="xs" [disabled]="false" [ngClass]="{'open-side-bar':sideBarOpen, 'minimized-side-bar':!sideBarOpen}">

    <!-- <app-menu></app-menu> -->

    <ion-menu *ngIf="!authService.isNative && ((isMobileObs | async) === false)" contentId="main-content" class="app-sidemenu no-print" >
      <ion-header>
        <ion-toolbar color="gallaghergreen">
          <ion-row [ngStyle]="{'justify-content': sideBarOpen ? 'left' : 'center', 'padding-top': '5px', 'padding-bottom': '5px', 'margin-left': sideBarOpen ? '20px' : ''}">
            <ion-col [ngStyle]="{'max-width': '60px'}">
              <ion-img class="close-menu-button" (click)="toggleMenu()" [ngClass]="{'open-side-bar':sideBarOpen, 'minimized-side-bar':!sideBarOpen}" [src]="'./assets/images/gallagherUniform-logo-white-125x125.png'"></ion-img>
            </ion-col>
            <ion-icon id="menu-toggle-arrow" size="large" name="chevron-{{sideBarOpen ? 'back' : 'forward'}}-outline" (click)="toggleMenu()"></ion-icon>
          </ion-row>
        </ion-toolbar>
      </ion-header>
      <ion-content class="sidemenu-content">

        <ion-list>

          <!-- <ion-list-header *ngIf="section.headerLabel && sideBarOpen">
            <ion-label>{{section.headerLabel}}</ion-label>
          </ion-list-header> -->
          <div *ngFor="let item of menuItems;">
            <ion-menu-toggle autoHide="false" *ngIf="item.canAccess()">
              <ion-item *ngIf="item.label !== 'Logout' && !item.hasImage" [routerLink]="authService.user.isWearer ? (item.alternativeRoute.path ?? item.route.path) : item.route.path">
                <ion-icon [ngClass]="{'icon-center': !sideBarOpen}" *ngIf="item.hasIcon" slot="start" [name]="item.ionicIcon ?? ''" [src]="item.customIcon ?? ''"></ion-icon>
                <ion-label *ngIf="sideBarOpen">
                  {{item.label}}
                </ion-label>
              </ion-item>
              <ion-item *ngIf="item.label !== 'Logout' && item.hasImage" [routerLink]="authService.user.isWearer ? (item.alternativeRoute.path ?? item.route.path) : item.route.path">
                <ion-img class="custom-img" [ngStyle]="{'scale': item.scale ?? 1}" [src]="item.customImg ?? ''"></ion-img>
                <ion-icon [ngClass]="{'icon-center': !sideBarOpen}" *ngIf="item.hasIcon" slot="start" [name]="item.ionicIcon ?? ''" [src]="item.customIcon ?? ''"></ion-icon>
                <ion-label *ngIf="sideBarOpen" [ngClass]="{'lbl': item.label === 'Admin Page'}">
                  {{item.label}}
                </ion-label>
              </ion-item>
              <ion-item *ngIf="item.label === 'Logout'" (click)="logout()" class="log-out" button="true">
                <ion-icon [ngClass]="{'icon-center': !sideBarOpen, 'icon-margin': sideBarOpen}" *ngIf="item.hasIcon" slot="start" [name]="item.ionicIcon ?? ''" [src]="item.customIcon ?? ''"></ion-icon>
                <ion-label *ngIf="sideBarOpen" class="neg-margin">
                  {{item.label}}
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div>
          
        </ion-list>

      </ion-content>
    </ion-menu>
    

    <!-- Main app content get's rendered in this router-outlet -->
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
