<div class="container">
    <app-view-order-header></app-view-order-header>

    <div class="table-container">
        <div class="header-container">
            <span>Web Order View</span>
        </div>

        <div class="data-table" *ngIf="loaded">
            <ion-row class="top-header-row">
                <ion-col class="label-col margin-right">
                    <span>{{data?.woId}}</span>
                </ion-col>
                <ion-col class="margin-right">
                    <span>Order Information</span>
                </ion-col>
                <ion-col>
                    <span></span>
                </ion-col>
            </ion-row>

            <ion-row class="data-row">
                <ion-col class="label-col border-right">
                    <span>Order Type:</span>
                </ion-col>
                <ion-col class="border-right">
                    <span class="bold-text">{{(orderViewType === 'szChg') ? 'Size Change' : ((orderViewType === 'repGar') ? 'Repair Garment' : 'Unknown')}}</span>
                </ion-col>
                <ion-col class="text-align-right">
                    <span>Date: <span class="bold-text">{{getEnteredData() | date: 'shortDate'}}</span></span>
                </ion-col>
            </ion-row>

            <ion-row class="data-row">
                <ion-col class="label-col border-right">
                    <span>Order For:</span>
                </ion-col>
                <ion-col *ngIf="(orderViewType === 'szChg')">
                    <span class="bold-text">{{data.customerCode}} {{data.departmentCode}} {{data.employeeCode}} {{data.firstName}} {{data.lastName}}</span>
                </ion-col>
                <ion-col *ngIf="(orderViewType === 'repGar')">
                    <span class="bold-text">{{data.customerCode}} {{data.departmentCode}} {{data.employeeCode}} {{data.firstName}} {{data.lastName}}</span>
                </ion-col>
            </ion-row>

            <ion-row class="data-row">
                <ion-col class="label-col border-right">
                    <span>Email:</span>
                </ion-col>
                <ion-col>
                    <span class="bold-text">{{data.email}}</span>
                </ion-col>
            </ion-row>

            <ion-row class="data-row">
                <ion-col class="label-col border-right">
                    <span>Status:</span>
                </ion-col>
                <ion-col>
                    <span class="bold-text">{{state.description}}</span>
                </ion-col>
            </ion-row>

            <ion-row class="data-row" *ngIf="dataItems.length > 0">
                <ion-col>
                    <ion-row class="top-header-row">
                        
                        <ng-container *ngIf="orderViewType === 'szChg'">
                            <ion-col class="margin-right size-10">
                                <span>Item</span>
                            </ion-col>
                            <ion-col class="margin-right size-25">
                                <span>Product Code</span>
                            </ion-col>
                            <ion-col class="margin-right size-35">
                                <span>Description</span>
                            </ion-col>
                            <ion-col class="margin-right size-10">
                                <span>Quantity</span>
                            </ion-col>
                            <ion-col class="margin-right size-10">
                                <span>Old size</span>
                            </ion-col>
                            <ion-col class="size-10">
                                <span>New size</span>
                            </ion-col>
                        </ng-container>

                        <ng-container *ngIf="orderViewType === 'repGar'">
                            <ion-col class="margin-right size-20">
                                <span>Garment Code</span>
                            </ion-col>
                            <ion-col class="margin-right size-25">
                                <span>Product Code</span>
                            </ion-col>
                            <ion-col class="margin-right size-35">
                                <span>Product Desc</span>
                            </ion-col>
                            <ion-col class="margin-right size-20">
                                <span>Reason</span>
                            </ion-col>
                        </ng-container>

                    </ion-row>

                    <ion-row *ngFor="let item of dataItems; let i = index" class="data-row">

                        <ng-container *ngIf="orderViewType === 'szChg'">
                            <ion-col class="size-10 border-right">
                                <span>{{item.itemId}}</span>
                            </ion-col>
                            <ion-col class="size-25 border-right">
                                <span>{{item.productCode}}</span>
                            </ion-col>
                            <ion-col class="size-35 border-right">
                                <span>{{item.description}}</span>
                            </ion-col>
                            <ion-col class="size-10 border-right">
                                <span>{{item.qty}}</span>
                            </ion-col>
                            <ion-col class="size-10 border-right">
                                <span>{{item.oldSize1}} - {{item.oldSize2}}</span>
                            </ion-col>
                            <ion-col class="size-10">
                                <span>{{item.newSize1}} - {{item.newSize2}}</span>
                            </ion-col>
                        </ng-container>

                        <ng-container *ngIf="orderViewType === 'repGar'">
                            <ion-col class="size-20 border-right">
                                <span>{{item.order.garmentCode}}</span>
                            </ion-col>
                            <ion-col class="size-25 border-right">
                                <span>{{item.product.productCode}}</span>
                            </ion-col>
                            <ion-col class="size-35 border-right">
                                <span>{{item.product.name}}</span>
                            </ion-col>
                            <ion-col class="size-20">
                                <span>{{getReason(item.order)}}</span>
                            </ion-col>
                        </ng-container>
                    </ion-row>
                </ion-col>
            </ion-row>
        </div>

        <div class="loading-container" *ngIf="!loaded">
            <ion-spinner name="circular"></ion-spinner>
        </div>
    </div>
</div>