import { Component, Input, OnInit } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { GarmentService } from '../../../../../../../app/features/garment-listing/services/garment.service';
import { GarmentModel } from '../../../../../../../app/features/garment-listing/models/garment.model';
import { GarmentReportService } from '../../../../../../../app/features/garment-listing/services/garment-report.service';
import { GarmentReportDetail } from '../../../../../../../app/features/garment-listing/models/garment-report-detail.model';
import { GarmentReportSummary } from '../../../../../../../app/features/garment-listing/models/garment-report-summary.model';
import { GarmentReport } from '../../../../../../../app/features/garment-listing/models/garment-report.model';
import { CreateWorkOrderService } from '../../../../services/create-work-order.service';
import { ToasterService } from '../../../../../../common/services/toaster.service';
import { GtrakAuthService } from '../../../../../authentication/services/gtrak-auth.service';

@Component({
  selector: 'app-garment-lookup-page',
  templateUrl: 'garment-lookup.page.html',
  styleUrls: [
    './garment-lookup.page.scss',
    '../../../../styles/g-route.styles.scss',
  ],
})
export class GarmentLookupPage implements ViewWillEnter, OnInit {
  currentRoute: string;
  stopName: string;

  garment: GarmentReport = null;
  garmentSummary: GarmentReportSummary = null;
  garmentDetails: GarmentReportDetail = null;
  garmentCode: string = null;
  error: boolean = false;
  garmentReplace: boolean = false;
  userId: string | number = null;

  routeStopName: string = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private garmentService: GarmentService,
    private garmentReportService: GarmentReportService,
    private createWorkOrderService: CreateWorkOrderService,
    private toasterService: ToasterService,
    private gtrakAuthService: GtrakAuthService
  ) {
    this.currentRoute = null;
  }

  async ngOnInit(): Promise<void> {
    this.userId = await this.gtrakAuthService.user.id;
    this.garmentReplace = history.state.replace;
    this.currentRoute = this.router.url;
    this.stopName = this.route.snapshot.paramMap.get('id');

    var garmentId = this.route.snapshot.paramMap.get('gId');

    this.routeStopName = localStorage.getItem('routeStopName');

    if (garmentId) {
      this.garmentCode = garmentId;
      this.getGarment();
    }
  }

  ionViewWillEnter(): void {}

  async getGarment(): Promise<void> {
    if (this.garmentCode && this.garmentCode.length > 0) {
      const garment = await this.garmentReportService.findForGarment(
        this.garmentCode
      );

      if (garment?.summary?.length > 0 && garment?.details?.length > 0) {
        this.garment = garment;
        this.garmentSummary = this.garment.summary[0];

        //set garmentdetails to equal garment.details where garment.details.garmentCode = this.garmentCode
        this.garmentDetails = this.garment.details.find(
          (x) => x.garmentCode == this.garmentCode
        );
        this.error = false;
      } else {
        this.error = true;
      }
    }
  }

  async replaceGarment(): Promise<void> {
    //submit
    this.createWorkOrderService
      .SubmitGarmentReplace(
        this.userId,
        this.stopName,
        this.garmentDetails.garmentCode
      )
      .then((res) => {
        if (res === 'Success') {
          this.toasterService.presentToast(
            'primary',
            '',
            'Garment replaced successfully',
            3000
          );
        } else {
          this.toasterService.presentToast('danger', '', `${res}`, 3000);
        }
      });
  }
}
