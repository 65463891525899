import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { catchError, firstValueFrom } from "rxjs";
import { environment } from '../../../environments/environment';
import { GtrakAuthService } from '../../../app/features/authentication/services/gtrak-auth.service';
import { EmployeeKey } from '../models/employee-key.model';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { Functions, getFunctions, httpsCallable, connectFunctionsEmulator, HttpsCallableResult } from "firebase/functions";
import { ToasterService } from './toaster.service';
import { NavController } from '@ionic/angular';

export interface ICloudApiRequest {
    url: string,
    params?: {key: string, value: string}[],
    headers?: {key: string, value: string}[],
    body?: any,
    jwtToken?: string,
    type?: HttpOptions
}

const HttpTypeCloudFunctionMap: Map<HttpOptions, string> = new Map<HttpOptions, string>([
    ['GET', 'getRequest'],
    ['POST', 'postRequest'],
    ['PUT', 'putRequest'],
    ['DELETE', 'deleteRequest']
]);

type HttpOptions = 'GET' | 'POST' | 'PUT' | 'DELETE';

@Injectable({
    providedIn: 'root'
})
export class CloudApiMiddleware {

    API_BASE_URL: string = null;

    private app: FirebaseApp | null = null;
    private functions: Functions | null = null;
  
    constructor(
        private httpClient: HttpClient,
        private authService: GtrakAuthService,
        private toasterService: ToasterService,
        private navController: NavController
    ) {
        this.API_BASE_URL = environment.apiUrl;
        this.initialize();
    }

    protected async initialize(): Promise<void> {
        this.app = initializeApp(environment.firebase);
        this.functions = getFunctions(this.app);

        if (environment.enableFunctionsEmulator) {
            connectFunctionsEmulator(this.functions, '127.0.0.1', 5001);
        }
    }

    async cloudRequest<T,>(content: ICloudApiRequest, type: HttpOptions): Promise<T> {
        try {
            const token = localStorage.getItem('auth_token');

            // If a token isnt passed in, set it using the token in local storage
            if (!content.jwtToken && token) {
                content.jwtToken = token;
            }

            content.type = type;

            // var data: HttpsCallableResult<any> = await httpsCallable(this.functions, HttpTypeCloudFunctionMap.get(type))(content);
            var data: HttpsCallableResult<any> = await httpsCallable(this.functions, 'processRequest')(content);

            if (data) {
                const initialParse = JSON.parse(data.data);
                // Check for Axios error response
                if (initialParse?.error) {
                    switch (initialParse.error?.status) {
                        case 401:
                            if ((initialParse.error?.config?.url.indexOf('auth') === -1) && initialParse.error?.config?.url.indexOf('view-order') === -1) {
                                // Set direction will reset the nav stack
                                this.toasterService.presentToast('danger', 'Please login again.', 'Authentication is invalid or expired.', );

                                // This causes a cyclical dep and cant be uncommented
                                // await this.authService.Logout();
                                //remove the JWT from local storage
                                localStorage.clear();

                                this.navController.navigateRoot([this.authService.user?.homeAuthLocation ?? '/auth/login']);
                            }
                            break;
                        case 404:
                            this.toasterService.presentToast('danger', '', 'The target is offline or unavailable. Please try again later.', )
                            break;
                        case 500:
                            this.toasterService.presentToast('danger', 'Please try again.', 'Something went wrong.', )
                            break;
                        default:
                            this.toasterService.presentToast('danger', 'Please try again.', 'Something went wrong.', )
                            break;
                        }
                } else {
                    const value = JSON.parse(data?.data ?? {data: null})?.data as T;
                    return (value as any !== '') ? value : null;
                }
            } else {
                return null;
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    }

}