import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { catchError, firstValueFrom } from "rxjs";
import { environment } from '../../../environments/environment';
import { GtrakAuthService } from '../../../app/features/authentication/services/gtrak-auth.service';
import { EmployeeKey } from '../models/employee-key.model';
import { CloudApiMiddleware } from './cloud-api-middleware.service';

@Injectable({
    providedIn: 'root'
})
export class CustomReportService {
    API_BASE_URL: string;
  
    constructor(
        private httpClient: HttpClient,
        private authService: GtrakAuthService,
        private cloudApiMiddleware: CloudApiMiddleware
    ) {
        this.API_BASE_URL = environment.apiUrl;
    }

    async getCancelHistory(data: EmployeeKey): Promise<boolean> {
        return await this.cloudApiMiddleware.cloudRequest<boolean>({
            url: `${this.API_BASE_URL}/get-cancel-history?CustomerCode=${data.CustomerCode}&DepartmentCode=${data.DepartmentCode}&EmployeeCode=${data.EmployeeCode}`
        }, 'GET');
    }

}