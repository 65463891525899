<app-top-bar></app-top-bar>
<ion-content>
    <div class="main-header">
        <span>Cancel Order</span>
        <div (click)="navigateBack()" class="back-button-mobile">
            <ion-icon name="chevron-back-outline"></ion-icon>
        </div>
    </div>
    <div class="mobile-info">
        <ion-grid class="info-grid">
            <ion-row class="row">
                <span class="top-header-label">Cust:</span>
                <div *ngIf="loaded">{{garmentSummary.employeeListing.customerCode}} {{garmentSummary.employeeListing.customerName}}
                </div>
            </ion-row>
            <ion-row class="row">
                <span class="top-header-label">Stop:</span> <ng-container *ngIf="loaded">{{garmentSummary.employeeListing.stopCode}}
                    {{garmentSummary.employeeListing.stopName}}</ng-container>
            </ion-row>
            <ion-row class="bottom-row">
                <span class="top-header-label">Locker:</span> <ng-container *ngIf="loaded">{{garmentSummary.employeeListing.employeeCode}} <h6>
                    {{garmentSummary.employeeListing.lastName}}, {{garmentSummary.employeeListing.firstName}}</h6></ng-container>
            </ion-row>
        </ion-grid>
    </div>

    <div>
        <ion-grid class="ion-no-padding margin-top content-grid">
            <ion-row class="titles">
                <ion-col class="border-right" size="7">
                    <span>Product</span>
                </ion-col>
                <ion-col class="" size="5">
                    <span>Date Collected</span>
                </ion-col>
            </ion-row>
            <ng-container *ngFor="let order of data">
                <ion-row *ngFor="let garment of order" (click)="expandItem(garment)" class="ion-no-padding content">
                    <ion-col class="center-content title-borders-bottom add-sign date-text border-right title-borders-left padding-4" size="7">
                        <div *ngIf="!garment.expanded" class="sign">
                            <ion-icon name="add-outline"></ion-icon>
                        </div>
                        <div *ngIf="garment.expanded" class="sign">
                            <ion-icon name="remove-outline"></ion-icon>
                        </div>
                        {{garment.product}}
                    </ion-col>
                    <ion-col class=" title-borders-bottom date-text title-borders-right padding-4" size="5">
                        {{garment.dateCollected | date: 'MM/dd/yyyy'}}
                    </ion-col>
                    <ion-row class="expanded-parent">
                        <div *ngIf="garment.expanded" class="expanded-content">
                            <ion-row>
                                <span><span class="bold-text">Description: </span> {{garment.description}}</span>
                            </ion-row>
                            <ion-row>
                                <span><span class="bold-text">Barcode #: </span> {{garment.barcode}}</span>
                            </ion-row>
                            <ion-row>
                                <span><span class="bold-text">Loss Charge: </span> {{garment.lossCharge | currency: 'USD'}}</span>
                            </ion-row>
                        </div>
                    </ion-row>
                </ion-row>
            </ng-container>

            <ion-row class="no-data-found" *ngIf="loaded && (data.length <= 0)">
                <ion-col>
                    <span>No Data Found</span>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>

    <div class="spin" *ngIf="!loaded">
        <ion-spinner name="circular"></ion-spinner>
    </div>
</ion-content>
<app-footer></app-footer>