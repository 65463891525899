import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forwardRef, Injectable, Type } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { firstValueFrom } from 'rxjs';
import { GtrakUser } from '../../../features/authentication/models/gtrak-user';
import { environment } from '../../../../environments/environment';
import { ApiUser } from '../models/api-user';
import { AuthCreds as LoginCreds } from '../models/login-creds';
import { Token } from '../models/tokens';
import { TokenService } from './token.service';
import { Platform } from '@ionic/angular';
import { ICreateMobileUser } from '../../../../app/features/authentication/models/create-mobile-user';
import { CloudApiMiddleware } from '../../services/cloud-api-middleware.service';

/**
 * Provider for base rest api service framework
 * 
 * Allows child service implementations to avoid constructors, or 
 * not having to inject HttpClient, by declaring
 * HttpClient a dependency of the base service.
 */
 export const AuthServiceProvider = {
  provide: forwardRef(() => AuthService),
  deps: [HttpClient, JwtHelperService, TokenService]
 };

@Injectable({
  providedIn: 'root',
})
export abstract class AuthService<T extends ApiUser> {

  protected abstract get userType(): Type<T>;

  protected loginEndpoint: string = environment.apiUrl + '/auth/login';

  protected logoutEndpoint: string = environment.apiUrl + '/auth/logout';

  protected forgotPasswordEndpoint: string = environment.apiUrl + '/auth/forgot-password';

  protected _user: T = null;
  
  get isAuthenticated() { return this.user != null && this.getToken() != null; }
  
  get isNative() { return this.platform.is('capacitor') }
  //get isNative() { return true }

  get user() { return this._user; }

  protected isInitialized: boolean = false;
  
  constructor(
    protected client: HttpClient = null,
    protected jwtHelper: JwtHelperService,
    protected tokenService: TokenService,
    protected platform: Platform,
    protected cloudApiMiddleware: CloudApiMiddleware
  ) { 
    this.Initialize();
  }

  protected headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json');
  
  protected httpOptions = {
    headers: this.headers
  };

  protected async Initialize()
  {
    // TODO Implement refresh tokens potentially

    var token = this.tokenService.get();

    if (token && !this.jwtHelper.isTokenExpired(token)) {
      this._user = ApiUser.fromType(this.userType, this.jwtHelper.decodeToken(token));
    } else {
      this._user = null;
    }
  }

  async Login(creds: LoginCreds): Promise<boolean> {

    const token = await this.cloudApiMiddleware.cloudRequest<Token>({
      url: this.loginEndpoint,
      body: creds
    }, 'POST');

    if (!token)
      return false;
    
    this._user = ApiUser.fromType(this.userType, this.jwtHelper.decodeToken(token));

    localStorage.setItem('auth_token', token);
    
    return true;
  }

  async Logout(): Promise<boolean> {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('admin_override_route');
    localStorage.removeItem('admin_override_date');
    this._user = null;

    await this.cloudApiMiddleware.cloudRequest<any>({
      url: this.logoutEndpoint,
      body: {}
    }, 'POST');
    
    return true;
  }

  async UserExists(stopCode: string, lastName: string, lockerNum: string): Promise<boolean> {
    const data: any = await this.cloudApiMiddleware.cloudRequest<any>({
      url: environment.apiUrl + `/auth/get-user-data/${stopCode}/${lastName}/${lockerNum}`,
    }, 'GET');

    return data;
  }

  async IsValidNewMobileUser(stopCode: string, lastName: string, lockerNum: string): Promise<boolean> {
    const data: any = await this.cloudApiMiddleware.cloudRequest<any>({
      url: environment.apiUrl + `/auth/is-valid-new-mobile-user/${stopCode}/${lastName}/${lockerNum}`,
    }, 'GET');

    return data;
  }

  async CreateNewMobileUser(data: ICreateMobileUser): Promise<any> {
    const returnData: any = await this.cloudApiMiddleware.cloudRequest<any>({
      url: environment.apiUrl + `/auth/create-new-mobile-user`,
      body: data
    }, 'POST');

    return returnData;
  }

  async DeleteMobileUser(): Promise<any> {
    const returnData: any = await this.cloudApiMiddleware.cloudRequest<any>({
      url: environment.apiUrl + `/auth/delete-account/${this.user.id}`,
    }, 'GET');

    return returnData;
  }

  getToken = () => localStorage.getItem('auth_token');
}