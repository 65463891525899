import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { QueryResult } from '../../../common/rest-api/models/query-result';
import { RequestParams } from '../../../common/rest-api/models/request-params';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { CustomerDetails } from '../models/customer-details.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerDetailsService extends RestApiService<CustomerDetails>{
  //generating customer list
  protected resourcePath = '/customer-details';
  protected modelType = CustomerDetails;


  async getCustomerDetails(params: RequestParams, custName: string) {
    const url = this.buildEndpointUrl(`query/${custName}`);

    const response = CustomerDetails.from(await this.cloudApiMiddleware.cloudRequest<QueryResult<CustomerDetails>>({
      url
    }, 'GET'));

    return response
  }

  async getCustomerByCustomerCode(custCode: string) {
    const url = this.buildEndpointUrl(`by-id/${custCode}`)

    const response = CustomerDetails.from(await this.cloudApiMiddleware.cloudRequest<QueryResult<any>>({
      url
    }, 'GET'))

    return response
  }
}