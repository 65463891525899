import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { CustomerProducts } from '../models/customer-products.model';

  
@Injectable({
providedIn: 'root',
})
export class CustomerProductsService extends RestApiService<CustomerProducts>{

    protected resourcePath = '/cust-product';
    protected modelType = CustomerProducts;

    async GetProductList(stopCode: string): Promise<CustomerProducts[]> {
        const url = this.buildEndpointUrl(`${stopCode}`);
        const response = await this.cloudApiMiddleware.cloudRequest<CustomerProducts[]>({
            url,
        }, 'GET');

        return response;
    }

}