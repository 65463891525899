<ion-content class="garment-pick-up">
    <ng-container>
      <app-header [title]="(routeStopName ?? '') + ' (' + stopName + ') Pick Up'"></app-header>
      <ion-grid>
        <ion-row>
          <ion-col class="align" *ngIf="showScanner">
            <ion-label class="label">Scan Garments:</ion-label>
            <textarea class="textarea" [(ngModel)]="garmentCodes"></textarea>
            <ion-button (click)="savePickUp()">Save</ion-button>
          </ion-col>
          <ion-col class="align">
            <ion-label class="label">Show Scans for Date: </ion-label>
            <ion-datetime (ionChange)="showScans($event)"></ion-datetime>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="showScansList">
          <div class="datatable">
            <mat-table [dataSource]="garmentDataSource" matSort class="mat-elevation-z8">
              <!-- Garment Column -->
              <ng-container matColumnDef="primaryKey">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Garment </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <a><span class="primaryKey"></span>&nbsp;{{element.primaryKey}}</a>
                </mat-cell>
              </ng-container>
  
              <!-- Product Column -->
              <ng-container matColumnDef="productCode">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Product </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <a class="productCode">{{element.productCode}}</a>
                </mat-cell>
              </ng-container>
  
              <!-- Employee Column -->
              <ng-container matColumnDef="employeeCode">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Employee </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <a class="employeeCode">{{element.employeeCode}}</a>
                </mat-cell>
              </ng-container>
  
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <mat-paginator [pageSize]="5" showFirstLastButtons (page)="changePage($event)"></mat-paginator>
          </div>
        </ion-row>
        <ion-row *ngIf="showScansList" class="align">
          <ion-button (click)="scanner()">Scan Again</ion-button>
        </ion-row>
      </ion-grid>
    </ng-container>
  </ion-content>
  