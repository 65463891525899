import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  AppMenuItem,
} from '../../../../core/app-menu/app-menu';
import { GtrakAuthService } from "../../../features/authentication/services/gtrak-auth.service";
import { ToasterService } from '../../services/toaster.service';

@Component({
  selector: 'app-menu-popover',
  templateUrl: 'menu-popover.component.html',
  styleUrls: ['./menu-popover.component.scss'],
})
export class MenuPopoverComponent  {
  @Input() title: string;

  @Input() menuItems: AppMenuItem[] = [];

  constructor(private router: Router,
    public authService: GtrakAuthService,
    private toasterService: ToasterService
    ) {}


  async logout(): Promise<void> {
    console.log("Logging out");
    const user = this.authService.user;
    await this.authService.Logout();

    this.router.navigate([user.homeAuthLocation]);
    this.toasterService.presentToast("primary", "You have been logged out", "Success");
  }

}
