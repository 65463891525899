import { Model, ModelId } from "../../../common/rest-api/models/model";

export type CancelOrderItem = {
    actionDate: string;
    cogItem: string;
    cpsalePrice: number;
    customerCode: string;
    deliveryAddress1: string;
    departmentCode: string;
    employeeCode: string;
    firstName: string;
    garmentCode: string;
    lastName: string;
    lockerNum: string;
    name: string;
    orderDate: string;
    pdsalePrice: number;
    shortName: string;
    size1: string;
    size2: string;
    stWoCategory: string;
    state: string
    stopCode: string;
    stopName: string;
    woCode: string;
    employeeProducts: any[];
    stWoItems: any[];
}

export class CancelOrderGarment extends Model {
    get modelId(): ModelId {
        throw new Error("Method not implemented.");
    }

    description: string = '';
    product: string = '';
    barcode: string = '';
    woCode: string = '';
    dateCollected: Date = new Date();
    lossCharge: string = '';
    departmentCode: string = '';
    stopName: string = '';
    orderDate: Date = new Date();
    pickUpDate: Date = new Date();
    firstName: string = '';
    lastName: string = '';
    size1: string = '';
    size2: string = '';    
    expanded: boolean = false;

}

export class CancelOrder extends Model {
    lossCharge: number;
    get modelId(): ModelId {
        throw new Error("Method not implemented.");
    }

    garments: CancelOrderGarment[] = [];
    totalLossCharge: number = 0;
}