import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CancelOrderService } from '../../services/cancel-order.service';
import { CancelOrder, CancelOrderGarment } from '../../models/cancel-order.model';

@Component({
    selector: 'app-employee-cancel-order-page',
    templateUrl: 'department-cancel-order.page.html',
    styleUrls: [
      './department-cancel-order.page.scss',
    ]
  })
  export class DepartmentCancelOrderPage implements OnInit {

    constructor(
      private router: Router,
      private route: ActivatedRoute,
      private cancelOrderService: CancelOrderService,
    ) { }

    cancelOrders: any[] = [];
    totalLossCharge: number = 0;
    loaded: boolean;

    async ngOnInit() {
      this.loaded = false;
      await this.cancelOrderService.getForDepartment(this.route.snapshot.params.id, this.route.snapshot.params.cid).then((cancelOrder) => {
        this.cancelOrders = cancelOrder;
      });
      for (let i = 0; i < this.cancelOrders.length; i++) {
        let woLossCharge = 0;
        const cancelOrderGarments = this.cancelOrders[i] as unknown as CancelOrderGarment[];
        for (let j = 0; j < cancelOrderGarments.length; j++) {
          if(cancelOrderGarments[j].dateCollected === null) {
            woLossCharge += parseFloat(cancelOrderGarments[j].lossCharge);
            this.totalLossCharge += parseFloat(cancelOrderGarments[j].lossCharge);
            //round to 2 decimal places
            woLossCharge = Math.round(woLossCharge * 100) / 100;
            this.totalLossCharge = Math.round(this.totalLossCharge * 100) / 100;
          }
        }
        this.cancelOrders[i].lossCharge = woLossCharge;
      }

      this.loaded = true;
    }

  }