import { Component, OnInit } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController, ViewWillEnter, ViewDidLeave, ViewDidEnter } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { GtrakAuthService } from '../../../authentication/services/gtrak-auth.service';
import { ToasterService } from '../../../../../app/common/services/toaster.service';
import { EmailService } from '../../../../../app/common/services/email.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-question-page',
  templateUrl: 'question.page.html',
  styleUrls: [
    './question.page.scss'
  ]
})
export class QuestionPage implements ViewWillEnter, OnInit {

  content: string = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: GtrakAuthService,
    private toasterService: ToasterService,
    private emailService: EmailService,
    private navController: NavController
  ) { 
  
  }

  ngOnInit(): void {

  }


  ionViewWillEnter(): void {


  }

  navigateBack(): void {
    this.navController.back();
  }

  //submits the text from the textarea to an email address
  async submitQuestion(): Promise<void> {
    if (this.content && (this.content.length > 0)) {
      const status = await this.emailService.sendQuestionEmail(this.content);

      if (status) {
        this.textReset();
        this.toasterService.presentToast('primary', '', 'Question sent successfully.', 2000);
      } else {
        this.toasterService.presentToast('danger', 'Please try again.', 'Something went wrong.', 2000);
      }

    } else {
      this.toasterService.presentToast('warning', '', 'Text box cannot be empty.', 2000);
    }
  }

  //clears out the text from the textarea
  textReset(): void {
    this.content = '';
  }

}