import { Component, Input, OnInit } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { GarmentRepairService } from '../../../../services/garment-repair.service';
import { ToasterService } from '../../../../../../../app/common/services/toaster.service';


@Component({
  selector: 'app-garment-repair-page',
  templateUrl: 'garment-repair.page.html',
  styleUrls: [
     './garment-repair.page.scss',
     '../../../../styles/g-route.styles.scss'
  ]
})
export class GarmentRepairPage implements ViewWillEnter, OnInit {

    currentRoute: string;
    stopName: string;

    messageCode: string;
    garmentCode: string;

    routeStopName: string = "";

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private garmentRepairService: GarmentRepairService,
        private toasterService: ToasterService
    ) { 
        this.currentRoute = null;
    }

    ngOnInit(): void {
        this.currentRoute = this.router.url;
        this.stopName = this.route.snapshot.paramMap.get('id');

        this.routeStopName = localStorage.getItem('routeStopName');
    }

    ionViewWillEnter(): void {
        
    }

    PostMessage(){
        this.garmentRepairService.postMessage(this.messageCode, this.garmentCode).then((response) => {
            if (response) {
                this.toasterService.presentToast('primary', '', 'Repair created successfully', 2000);
                this.messageCode = null;
                this.garmentCode = null;
            } else {
                this.toasterService.presentToast('danger', '', 'Something went wrong. Please try again.', 2000);
            }
        });
    }

    repairReasons: string[] = ['Relabel Garment ID']


}