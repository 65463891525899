import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter } from '@ionic/angular';
import { GtrakAuthService } from '../../../authentication/services/gtrak-auth.service';
import { ViewOrderService } from '../../services/view-order.service';
import { EmailService } from '../../../../../app/common/services/email.service';

@Component({
  selector: 'app-view-order-page',
  templateUrl: 'view-order.page.html',
  styleUrls: [
    './view-order.page.scss',
  ],
})
export class ViewOrderPage implements ViewWillEnter, OnInit {

    orderId: number = null;

    data: any = null;
    state: any = null;
    dataItems: any[] = [];
    userData: any = null;

    orderViewType: string = 'szChg';

    reasonMap: Map<string, string> = new Map<string, string>([
        ['06', 'Relable Garment ID'],
        ['15', 'Repair - Belt Loop / Drawstring'],
        ['11', 'Repair - Buttons, Button Holes, Snaps'],
        ['13', 'Repair - Check All'],
        ['MSG0010064', 'Repair - Check Seat / Inner Thigh'],
        ['MSG0010031', 'Repair - Check Zipper'],
        ['21', 'Repair - Emblems'],
        ['14', 'Repair - Pockets'],
        ['12', 'Repair - Seams or Hems'],
        ['MSG0010070', 'Repair - Sleeve/Cuff'],
        ['MSG0010042', 'Repair - Tear'],
        ['MSG0010080', 'Replace - Inspect for Replacement'],
        ['05', 'Send to Customer Service'],
        ['MSG0010033', 'Verify - All Emblems'],
        ['MSG0010046', 'Verify - Name Emblem Spelling'],
    ]);

    token: string = null;

    loaded: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authService: GtrakAuthService,
        private viewOrderService: ViewOrderService,
        private emailService: EmailService
    ) { 

    }

    async ngOnInit(): Promise<void> {
        const id = this.route.snapshot.paramMap.get('id');
        this.token = this.route.snapshot.queryParamMap.get('token');

        if (!id || !this.token) {
            this.router.navigate(['']);
        } else {
            this.orderId = Number(id);
            const promises: any[] = [];
            promises.push(this.getOrderData());
            // promises.push(this.getOrderItemData());
            this.getUserData();

            await Promise.all(promises);

            // Call after so it can use the order type in query
            await this.getOrderItemData()
        }

        this.loaded = true;
    }

    ionViewWillEnter(): void {

    }

    async getUserData(): Promise<void> {
        this.userData = this.authService?.user;
    }

    async getOrderData(): Promise<void> {
        const data = await this.viewOrderService.getWoData(this.orderId, this.token);

        if (data === 'Failed') {
            this.router.navigate(['']);
            return;
        }

        this.data = data.order;

        this.state = data.state;
        this.orderViewType = this.data?.orderType;
    }

    async getOrderItemData(): Promise<void> {
        if (this.orderViewType === 'szChg') {
            this.dataItems = await this.viewOrderService.viewSizeChange(this.orderId, this.token);
        } else if (this.orderViewType === 'repGar') {
            this.dataItems = await this.viewOrderService.viewRepairOrder(this.orderId, this.token);
        }
    }

    getEnteredData(): Date {
        if (this.dataItems?.length > 0) {
            return this.data?.enteredDate ?? new Date()
        } else {
            return new Date();
        }
    }

    getReason(order: any): string {
        return this.reasonMap.get(order.reasonCode);
    }
}