import { Data } from "@angular/router";
import { Model, ModelId } from "../../../common/rest-api/models/model";
import { StringUnitLength } from "luxon";

export class CustomerProducts extends Model {
  get modelId(): ModelId {
    throw new Error("Method not implemented.");
  }

  customerCode: string;
  productCode: string;
  productType: number;
  quota: number;
  salePrice: number;
  replacementPrice: number;
  commissionRate: number;
  commissionType: string;
  setupCharge: number;
  initialGrade: string;
  replacementGrade: string;
  retailPrice: number;
  protectRate: number;
  sizeChangeCharge: number;
  reissueCharge: number;
  activeCode: string;
  stockLocation: string;
  dcgRate: number;
  weeksMin: number;
}
