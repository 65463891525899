<app-top-bar></app-top-bar>
<ion-content>
    <!-- <h1>Delivery Status</h1>
    <div class="spin" *ngIf="!loaded">
        <ion-spinner name="circular"></ion-spinner>
    </div>
    <div *ngIf="loaded" class="mobile-info">
        <ion-grid  class="ion-no-padding info-grid">
            <ion-row class="row">
                <h4>Cust:</h4>
                <div>{{garmentSummary.employeeListing.customerCode}} {{garmentSummary.employeeListing.customerName}}</div>
            </ion-row>
            <ion-row class="row">
                <h4>Stop:</h4> {{garmentSummary.employeeListing.stopCode}} {{garmentSummary.employeeListing.stopDescription}}
            </ion-row>
            <ion-row class="bottom-row">
                <h4>Locker:</h4> {{garmentSummary.employeeListing.employeeCode}} <h6>{{garmentSummary.employeeListing.firstName}}, {{garmentSummary.employeeListing.lastName}}</h6>
            </ion-row>
        </ion-grid>
    </div> -->

    <div class="main-header">
        <span>Delivery Status</span>
        <div (click)="navigateBack()" class="back-button-mobile">
            <ion-icon name="chevron-back-outline"></ion-icon>
        </div>
    </div>
    <div class="mobile-info">
        <ion-grid class="info-grid">
            <ion-row class="row">
                <span class="top-header-label">Cust:</span>
                <div *ngIf="loaded">{{garmentSummary.employeeListing.customerCode}} {{garmentSummary.employeeListing.customerName}}
                </div>
            </ion-row>
            <ion-row class="row">
                <span class="top-header-label">Stop:</span> <ng-container *ngIf="loaded">{{garmentSummary.employeeListing.stopCode}}
                    {{garmentSummary.employeeListing.stopName}}</ng-container>
            </ion-row>
            <ion-row class="bottom-row">
                <span class="top-header-label">Locker:</span> <ng-container *ngIf="loaded">{{garmentSummary.employeeListing.employeeCode}} <h6>
                    {{garmentSummary.employeeListing.lastName}}, {{garmentSummary.employeeListing.firstName}}</h6></ng-container>
            </ion-row>
        </ion-grid>
    </div>

    <div *ngIf="loaded" class="content">
        <a [routerLink]="'garment-detail'">Click to view garment detail</a>
        <ion-grid class="products-detail">
            <ion-row class="products-title">
                Products Detail
            </ion-row>
            <ion-row class="products-header">
                <ion-col size="7.4" class="products-header-top">Product</ion-col>
                <ion-col size="2.6" class="products-header-row">Quantity</ion-col>
                <ion-col size="2" class="products-header-row">Size</ion-col>
            </ion-row>
            <ion-row *ngFor="let detail of garmentSummary.details" (click)="expandItem(detail)" class="products-details">
                <ion-col size="7.4" class="add-sign products-details-titles-top">
                    <div *ngIf="!detail.expanded" class="add-sign-content">
                        <ion-icon name="add-outline"></ion-icon>
                    </div>
                    <div *ngIf="detail.expanded" class="add-sign-content">
                        <ion-icon name="remove-outline"></ion-icon>
                    </div>
                    {{detail.productCode}}
                </ion-col>
                <ion-col size="2.6" class="products-details-titles">{{detail.inventory}}</ion-col>
                <ion-col size="2" class="products-details-titles">{{detail.size1}}-{{detail.size2}}</ion-col>
                <!-- <ion-row class="title-borders-bottom" [ngClass]="{'expanded-data': detail.expanded}">
                    <div *ngIf="detail.expanded">
                        <ion-row>
                            <h6>Product Description:</h6>{{detail.name}}
                        </ion-row>
                    </div>
                </ion-row> -->
                <ion-row class="expanded-parent">
                    <div *ngIf="detail.expanded" class="expanded-content">
                        <ion-row>
                            <span><span class="bold-text">Product Description: </span> {{detail.name}}</span>
                        </ion-row>
                    </div>
                </ion-row>
            </ion-row>
        </ion-grid>
        <ion-grid class="weekly-detail">
            <ion-row class="weekly-title">
                Weekly Detail
            </ion-row>
            <ion-row class="weekly-header">
                <ion-col class="weekly-header-top">Week Of</ion-col>
                <ion-col class="weekly-header-bottom">Shirt</ion-col>
            </ion-row>
            <ion-row class="this-week">
                <ion-col class="this-week-left">{{dateArray[0].date}}</ion-col>
                <ion-col class="this-week-right">{{dateArray[0].count}}</ion-col>
            </ion-row>
            <ion-row class="past-week-header">
                <ion-col>Past Weeks</ion-col>
            </ion-row>
            <ion-row *ngFor="let weeks of dateArray | slice: 1" class="past-weeks">
                <ion-col class="past-weeks-left">{{weeks.date}}</ion-col>
                <ion-col class="past-weeks-right">{{weeks.count}}</ion-col>
            </ion-row>
        </ion-grid>
    </div>
    <div class="spin" *ngIf="!loaded">
        <ion-spinner name="circular"></ion-spinner>
    </div>
</ion-content>
<app-footer></app-footer>