import { Component, OnInit } from "@angular/core";
import { Auth } from "@angular/fire/auth";
import { ActivatedRoute, Router } from "@angular/router";
import {
  MenuController,
  ViewWillEnter,
  ViewDidLeave,
  ViewDidEnter,
} from "@ionic/angular";
import { ToastController } from "@ionic/angular";
import { GtrakAuthService } from "../../../authentication/services/gtrak-auth.service";
import { GarmentSummaryService } from "../../../../features/garment-listing/services/garment-summary.service";
import { CancelOrderService } from "../../../../features/cancel-order/services/cancel-order.service";
import {
  CancelOrder,
  CancelOrderGarment,
} from "../../../../features/cancel-order/models/cancel-order.model";
import { NavController } from "@ionic/angular";

@Component({
  selector: "app-cancel-order-page",
  templateUrl: "cancel-order.page.html",
  styleUrls: ["./cancel-order.page.scss"],
})
export class CancelOrderPage implements ViewWillEnter, OnInit {

  garmentSummary: any;
  garmentHistory: any;
  loaded: boolean = false;
  empKey: any;
  data: CancelOrderGarment[][] = [];
  testData: CancelOrderGarment[][] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: GtrakAuthService,
    public summaryService: GarmentSummaryService,
    public cancelOrderService: CancelOrderService,
    private navController: NavController
  ) {}

  async ngOnInit() {
    this.loaded = false;
    
    this.empKey = {
      CustomerCode: this.authService.user.claims.cus_code,
      DepartmentCode: this.authService.user.claims.dep_code,
      EmployeeCode: this.authService.user.claims.emp_code,
    };

    const promises: any[] = [];

    promises.push(this.summaryService.getForEmployee(this.empKey));
    promises.push(this.cancelOrderService.getForEmployee(this.empKey.EmployeeCode, this.empKey.DepartmentCode, this.empKey.CustomerCode));

    const data = await Promise.all(promises);

    this.garmentSummary = data[0];
    this.data = data[1];

    this.generateExpanded();

    this.loaded = true;
  }

  ionViewWillEnter(): void {}

  navigateBack(): void {
    this.navController.back();
  }

  //toggle the expanded field for the details array in garment summary
  expandItem(item): void {
    item.expanded = !item.expanded;
  }

  //generate an expanded property for each garment in each order in the test data array
  generateExpanded() {
    for (let i = 0; i < this.testData.length; i++) {
      for (let j = 0; j < this.testData[i].length; j++) {
        this.testData[i][j].expanded = false;
      }
    }
  }
}
