<ion-content class="">
    <ng-container>
        <app-header [title]="'Customer List'"></app-header>
        <ion-grid>
            <ion-row class="table-row">
                <div class="ion-padding table-container">
                    <app-customer-list-table [mode]="'gl'" (paging)="setPage($event)" [pageSize]="pageSize" [pageNumber]="currPage" [rows]="rows" [currentRoute]="currentRoute" [totalElements]="totalElements" *ngIf="loaded"></app-customer-list-table>
                </div>
            </ion-row>
        </ion-grid>
    </ng-container>
</ion-content>