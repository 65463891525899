import { AppRoute } from '../../../core/routing/AppRoute';
import { EmployeeTransferPage } from './pages/employee-transfer-page/employee-transfer.page';

import { AuthGuard } from '../../common/rest-api/services/auth-guard.service';

// A feature route should be used in the App routing module in most cases and primarily provide
// a base url and module loading functionality.
// The feature route should employ a load children callback that loads the appropriate module
const Feature = new AppRoute({
  segment: 'employee-transfer',
  title: 'Employee Transfers',
  // A component can also be included to be the content, or a separate route with a blank segment
  // can be defined like below to provide a dedicated route instance
  // component: GarmentListingPage,
  loadChildren: () => import('./employee-transfer.module').then(m => m.EmployeeTransferModule),
  canActive: [AuthGuard]
});

// "Sub-routes" should be the contentful endpoints for the feature. To provide content at the base
// level of the feature url, simply leave segment blank.
// Provide the parent parameter to be able to derive the fullPath of a route that includes the base
const Report = new AppRoute({
  segment: '',
  title: 'Employee Transfer Report',
  component: EmployeeTransferPage,
  parent: Feature,
  canActive: [AuthGuard]
});


export const EmployeeTransferRoutes = {
  Feature: Feature,
  Report: Report,
}