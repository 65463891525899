import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { ActivatedRoute, Route, Router, NavigationStart } from '@angular/router';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';
import { RequestParams } from '../../../../common/rest-api/models/request-params';
import { GRouteStopService } from '../../services/g-route-stops.service';
import { RouteStopModel } from '../../models/route-stop.model';
import { RouteModel } from '../../models/route.model';
import { GRouteService } from '../../services/g-route.service';
import { DateTime } from 'luxon';
import { IRouteLogModel, RouteLogModel } from '../../models/route-log.model';
import { GRouteLogService } from '../../services/g-route-log.service';
import {
  AuthCreds,
  LoginMode,
} from '../../../../common/rest-api/models/login-creds';
import { RouteInfoPopupComponent } from './components/route-info-popup/route-info-popup.component';
import { ModalController } from '@ionic/angular';
import { RouteEndConfirmPopupComponent } from './components/route-end-confirm-popup/route-end-confirm-popup.component';

export enum LogType {
  START = 4,
  END = 1,
  START_LUNCH = 8,
  END_LUNCH = 9,
  CHECK_IN = 2,
  CHECK_OUT = 3,
}

@Component({
  selector: 'app-route-page',
  templateUrl: 'route.page.html',
  styleUrls: ['./route.page.scss', '../../styles/g-route.styles.scss'],
})
export class RoutePage implements ViewWillEnter, OnInit, OnDestroy {
  timeOut: any = null;
  logType = LogType;
  routeCode: string;

  routeLogs: RouteLogModel[] = [];
  lastEvent: LogType = null;

  truckNumber: string;
  beginningMileage: number;
  endMileage: number;

  timeLeaving: string;
  timeReturned: string;
  lunchTime: string;

  requestParams: RequestParams;

  routeStops: RouteStopModel[];
  specialInstStops: RouteStopModel[];
  currentRoute: RouteModel;

  lockStartFields: boolean = false;

  trucks: string[] = [];

  utc = new Date().toJSON().slice(0, 10).replace(/-/g, '/');

  popupIsHit: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public authService: GtrakAuthService,
    private stopService: GRouteStopService,
    private routeService: GRouteService,
    private routeLogService: GRouteLogService,
    private modalController: ModalController
  ) {
    this.router.events.subscribe(async (event) => {
      if (event instanceof NavigationStart) {
        const isModalOpened = await this.modalController.getTop();
        if (isModalOpened) {
          this.modalController.dismiss();
        }
      }
    });
  }

  async ngOnInit(): Promise<void> {
    if (!this.authService.user.canViewDriverRoutes) {
      this.router.navigate([this.authService.user.homeLocation]);
    }

    if (
      (!this.authService.user.routeCode || !this.authService.user.routeDate) &&
      !this.popupIsHit
    ) {
      //this.router.navigate(['/auth/login'], { state: {mode: LoginMode.Groute} });
      this.popupIsHit = true;
      await this.informationPopup();
    }

    this.requestParams = new RequestParams();
    this.requestParams.setOrdering('ServiceTime', 'asc');
    this.requestParams.setPaging(1, 0);
    this.requestParams.filters.RouteCode = this.authService.user.routeCode;
    this.currentRoute = await this.routeService.get(
      this.authService.user.routeCode
    );

    // difference between the starttime and todays date mod 28
    this.requestParams.filters.DayOfPeriod = this.currentRoute.frequencyOffset(
      this.authService.user.routeDate
    );

    const date = this.authService.user.routeDate;
    var stopData = await this.stopService.getStops(date.toISOString(), this.requestParams);

    this.routeStops = stopData.results;

    this.specialInstStops = this.routeStops.filter(
      (route) => route.delInstr && route.delInstr.trim().length > 0
    );
  }

  async informationPopup(): Promise<void> {
    const modal = this.modalController.create({
      component: RouteInfoPopupComponent,
      cssClass: 'min-width-modal-md',
      showBackdrop: true,
      componentProps: {},
      backdropDismiss: true,
      keyboardClose: true,
      swipeToClose: true,
    });

    (await modal).present();
    const { data } = await (await modal).onDidDismiss();
  }

  async ionViewWillEnter(): Promise<void> {
    this.setTimes();

    this.timeOut = setInterval(() => {
      this.setTimes();
    }, 60000);

    if (
      (!this.authService.user.routeCode || !this.authService.user.routeDate) &&
      !this.popupIsHit
    ) {
      //this.router.navigate(['/auth/login'], { state: {mode: LoginMode.Groute} });
      this.popupIsHit = true;
      await this.informationPopup();
    }

    const promiseData = await Promise.all([
      this.routeLogService.getTrucks(),
      this.routeLogService.getRouteLogs(
        this.authService.user.routeCode
      )
    ])

    this.trucks = promiseData[0];

    this.routeLogs = promiseData[1];

    if (this.routeLogs.length > 0) {
      if (
        this.routeLogs[0].logType === this.logType.START ||
        this.routeLogs[0].logType === this.logType.START_LUNCH ||
        this.routeLogs[0].logType === this.logType.END_LUNCH
      ) {
        this.truckNumber = this.routeLogs[0].truckCode;
        this.beginningMileage = this.routeLogs[0].odometer;
        this.lastEvent = this.routeLogs[0].logType;

        var leavingTimeSet: boolean = false;
        this.routeLogs.forEach((log) => {
          if (log.logType === this.logType.START && !leavingTimeSet) {
            this.timeLeaving = this.getTimeFromDate(log.logDate);
          }
        });
      }
    }

    this.lockStartFields =
      this.lastEvent && this.lastEvent !== this.logType.END;
  }

  setTimes(): void {
    if (!this.lockStartFields) {
      this.timeLeaving = this.getCurrentTime();
    }
    this.timeReturned = this.getCurrentTime();
    this.lunchTime = this.getCurrentTime();
  }

  ngOnDestroy(): void {
    clearInterval(this.timeOut);
  }

  getTimeFromDate(date: Date): string {
    var displayHours: string;
    var displayMinutes: string;
    var hours = new Date(date.toString() + 'Z').getHours();
    var minutes = new Date(date.toString() + 'Z').getMinutes();

    if (hours < 10) {
      displayHours = '0' + hours;
    } else {
      displayHours = String(hours);
    }

    if (minutes < 10) {
      displayMinutes = '0' + minutes;
    } else {
      displayMinutes = String(minutes);
    }

    return displayHours + ':' + displayMinutes;
  }

  getCurrentTime(): string {
    var displayHours: string;
    var displayMinutes: string;
    var hours = new Date().getHours();
    var minutes = new Date().getMinutes();

    hours < 10 ? (displayHours = '0' + hours) : (displayHours = String(hours));
    minutes < 10
      ? (displayMinutes = '0' + minutes)
      : (displayMinutes = String(minutes));

    return displayHours + ':' + displayMinutes;
  }

    async startOrEndRoute(logType: LogType): Promise<void> {
        const log: IRouteLogModel = {
            logType: logType,
            routeCode: this.authService.user.routeCode,
            truckCode: this.truckNumber,
            driverCode: (this.authService.user.id as string),
        };

    if (
      logType === this.logType.START ||
      logType === this.logType.START_LUNCH ||
      logType === this.logType.END_LUNCH
    ) {
      log.odometer = this.beginningMileage;
      log.time = new Date();
    } else {
      log.odometer = this.endMileage;
      log.time = new Date();
    }

    if(logType === this.logType.END) {
      const date = this.authService.user.routeDate;
      // send a request to check if all the route stops have been sent invoices with the log date
      const routeStopIds = await this.stopService.getInvoiceFreeStops(date.toISOString(), this.requestParams);
      const modal = this.modalController.create({
        component: RouteEndConfirmPopupComponent,
        cssClass: 'min-width-modal-md',
        showBackdrop: true,
        componentProps: {routeList: routeStopIds},
        backdropDismiss: false,
        keyboardClose: false,
        swipeToClose: false,
      });
  
      (await modal).present();
      const { data } = await (await modal).onDidDismiss();
  
      // Check if the modal was dismissed with a cancel action
      if (data && data.canceled) {
        return; // Early return if canceled
      }
    }

    const response = await this.routeLogService.createRouteLog(log);

    this.lastEvent = logType;
    this.lockStartFields =
      this.lastEvent && this.lastEvent !== this.logType.END;

    if (logType === this.logType.END) {
      this.truckNumber = null;
      this.beginningMileage = null;
      this.endMileage = null;
    }
  }

  getCurrentDayFormatted(): string {
    const date = this.authService.user.routeDate;
    if (date) {
      //check if date get full year returns a number
      if (isNaN(date.getFullYear())) {
        // return nothing if date is not a number
        return '';
      }
      return `${date.getFullYear()}-${
        date.getMonth() + 1 <= 9
          ? '0' + (date.getMonth() + 1).toString()
          : date.getMonth() + 1
      }-${date.getDate()}`;
    } else {
      return '';
    }
  }

  checkRouteUndefined() {
    //check if route is undefined
    if (this.authService.user.routeCode === undefined) {
      return '';
    } else {
      return this.authService.user.routeCode;
    }
  }
}
