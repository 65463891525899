import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter } from '@ionic/angular';
import { WorkOrderService } from '../../services/work-order.service';
import { RequestParams } from '../../../../common/rest-api/models/request-params';
import { WorkOrder } from '../../models/work-order.model';
import { WorkOrderSection } from '../../models/work-order-section.model';
import { DepartmentKey } from '../../../../../app/common/models/department-key-model';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';
import _ from "lodash";

@Component({
  selector: 'app-department-work-order-report-page',
  templateUrl: 'department-work-order-report.page.html',
  styleUrls: [
    './department-work-order-report.page.scss',
  ]
})
export class DepartmentWorkOrderReportPage implements ViewWillEnter, OnInit {

  departmentId: string;
  customerId: string;

  depKey: DepartmentKey;

  requestParams: any;

  results: any;
  reports: WorkOrderSection[] = [];

  pageSize: number;
  currentPage: number;

  currentDate = new Date();

  loaded: boolean = false;

  public viewChange: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private workOrderService: WorkOrderService,
    private router: Router,
    private authService: GtrakAuthService,
  ) { 

  }

  ngOnInit(): void {
    
    if(!this.authService.user.canViewWorkOrders) {
      this.router.navigate([this.authService.user.homeLocation]);
    }

    this.departmentId = this.route.snapshot.paramMap.get('id');
    this.customerId = this.route.snapshot.paramMap.get('cid');

    this.currentPage = 1;
    this.pageSize = 0;

    this.requestParams = new RequestParams();

    this.requestParams.setOrdering('LastName', 'asc');
    this.requestParams.setPaging(this.currentPage, this.pageSize);

    this.setData();
  }

  ionViewWillEnter(): void {
    // this.departmentId = this.route.snapshot.paramMap.get('id');
    // this.customerId = this.route.snapshot.paramMap.get('cid');

    // this.currentPage = 1;
    // this.pageSize = 0;

    // this.requestParams = new RequestParams();

    // this.requestParams.setOrdering('LastName', 'asc');
    // this.requestParams.setPaging(this.currentPage, this.pageSize);

    // this.setData();
  }

  changePrinterView(): void {
    this.viewChange = !this.viewChange;
    // Set a delay before opening the print dialog
    setTimeout(() => {
      print();
    }, 1000); // 2000 milliseconds = 2 seconds
  }

  async setData(): Promise<void> {

    this.depKey = {
      CustomerCode: this.customerId,
      DepartmentCode: this.departmentId
    };

    this.results = await this.workOrderService.findForDepartment(this.depKey, this.requestParams);


    _.each(this.results.results, (wo) => {
      if(!_.find(this.reports, ['employeeCode', wo.employeeCode])) {
        var section = new WorkOrderSection();
        var workOrdersList = [];

        workOrdersList.push(wo);

        section = {
          employeeCode: wo.employeeCode,
          departmentCode: wo.departmentCode,
          firstName: wo.firstName,
          lastName: wo.lastName,
          stop: wo.stopName,
          stopCode: wo.stopCode,
          workOrders: workOrdersList
        }
        this.reports.push(section);
      } else {
        _.find(this.reports, ['employeeCode', wo.employeeCode]).workOrders.push(wo);
      }
    })

    this.loaded = true;
  }


}