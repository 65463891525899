import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../../../common/rest-api/services/auth.service';
import { environment } from '../../../../environments/environment';
import { GtrakUser } from '../models/gtrak-user';


@Injectable({
  providedIn: 'root',
})
export class GtrakAuthService extends AuthService<GtrakUser> {

  protected get userType() { return GtrakUser; }
  protected loginEndpoint = environment.apiUrl + '/auth/login';
  protected logoutEndpoint = environment.apiUrl + '/auth/logout';
  
  protected headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json');
  
  protected httpOptions = {
    headers: this.headers
  };
}