import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DepartmentKey } from '../../../../app/common/models/department-key-model';
import { EmployeeKey } from '../../../../app/common/models/employee-key.model';
import { QueryResult } from '../../../common/rest-api/models/query-result';
import { RequestParams } from '../../../common/rest-api/models/request-params';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { RouteModel } from '../models/route.model';

@Injectable({
  providedIn: 'root',
})
export class GRouteService extends RestApiService<RouteModel> {

  protected resourcePath = '/route';
  protected modelType = RouteModel;

  async getRoutesList(params?: RequestParams): Promise<RouteModel[]> {
    const url = this.buildEndpointUrl(`list`, params);
    const response = await this.cloudApiMiddleware.cloudRequest<RouteModel[]>({
      url
    }, 'GET');

    return response;
  }


}