<ion-content class="ion-padding">
  <div class="header">
      <span>Are you sure you want to end route? (These invoices have not been sent!)</span>
  </div>
  <div class="content">
      <ion-list>
          <ion-item *ngFor="let route of routeList">
              <ion-checkbox slot="start" (ionChange)="onRouteSelected($event, route)"></ion-checkbox>
              <ion-label>{{route.description}}</ion-label>
          </ion-item>
      </ion-list>
  </div>
  <div class="footer">
      <ion-row>
          <ion-col size="auto">
              <ion-button color="danger" (click)="onEndRoute()">End Route</ion-button>
          </ion-col>
          <ion-col size="auto">
              <ion-button color="primary" (click)="onCancel()">Cancel</ion-button>
          </ion-col>
      </ion-row>
  </div>
</ion-content>