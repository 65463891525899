<ion-content class="invoice">
    <ng-container>
      <app-header [title]="(routeStopName ?? '') + ' (' + stopCode + ') Invoice'"></app-header>
      <ion-grid>
        <div class="div-container">
          <ion-row>
            <ion-header>Invoices</ion-header>
          </ion-row>
          <ion-row *ngIf="invoices">
            <div class="table-row">
              <mat-table [dataSource]="invoiceDataSource" matSort class="mat-elevation-z8">
  
                <!-- Invoice Column -->
                <ng-container matColumnDef="invoiceCode">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Invoice </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <a (click)="openInvoice(element.invoiceCode)" class="link">{{element.invoiceCode}}</a>
                  </mat-cell>
                </ng-container>
  
                <!-- Date Column -->
                <ng-container matColumnDef="dateOfInvoice">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <a>{{element.dateOfInvoice | date: 'mediumDate'}}</a>
                  </mat-cell>
                </ng-container>
  
                <!-- Amount Due Column -->
                <ng-container matColumnDef="amountDue">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Invoice Total </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <a>{{element.amountDue | currency: 'USD'}}</a>
                  </mat-cell>
                </ng-container>
  
                <!-- Due Date Column -->
                <ng-container matColumnDef="dueDate">
                  <mat-header-cell *matHeaderCellDef mat-sort-header> Due </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <a>{{element.dueDate | date: 'mediumDate'}}</a>
                  </mat-cell>
                </ng-container>
  
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
              <mat-paginator [pageSize]="5" showFirstLastButtons (page)="changePage($event)"></mat-paginator>
            </div>
          </ion-row>
        </div>
      </ion-grid>
    </ng-container>
  </ion-content>
  