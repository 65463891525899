import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GtrakAuthService } from "../../../../../app/features/authentication/services/gtrak-auth.service";
import { RequestParams } from "../../../../common/rest-api/models/request-params";
import { CustomerCostCenterService } from "../../services/customer-cost-center.service";
import { ToasterService } from "../../../../common/services/toaster.service";
import { ModalController } from '@ionic/angular';
import { CostCenterAddModalComponent } from "../../components/cost-center-add-modal/cost-center-add-modal.component";

@Component({
  selector: "app-cost-center-admin-page",
  templateUrl: "cost-center-admin.page.html",
  styleUrls: ["./cost-center-admin.page.scss"],
})
export class CostCenterAdminPage implements OnInit {
  constructor(
    private router: Router,
    private authService: GtrakAuthService,
    private costCenterService: CustomerCostCenterService,
    private toasterService: ToasterService,
    private modalController: ModalController,
  ) {}

  rows: any;

  currentRoute: string;

  loaded: boolean = false;

  totalPages: number = 0;
  totalElements: number = 0;

  requestParams: any;

  currPage: number;
  pageSize: number;

  costCenterCode: string;
  activeCode: string = '1';

  ngOnInit() {
    this.requestParams = new RequestParams();

    if (!this.authService.user.isAdmin) {
      this.router.navigate([this.authService.user.homeLocation]);
    }

    this.rows = [];
    this.currentRoute = this.router.url;

    if(this.requestParams.filters.CostCenterCode)
      delete this.requestParams.filters.CostCenterCode;

    this.currPage = 1;
    this.pageSize = 20;

    this.getCostCenters();
  }

  async getCostCenters() {
    this.loaded = false;
    this.requestParams.setPaging(this.currPage, this.pageSize);
    var costCenters = await this.costCenterService.query(this.requestParams);
    this.totalElements = costCenters.totalRecords;
    this.totalPages = costCenters.totalPages;
    this.rows = costCenters.results;

    this.loaded = true;
  }

  getDataCostCenter() {
    if (this.requestParams.filters.CostCenterCode)
      delete this.requestParams.filters.CostCenterCode;
    if (this.requestParams.filters.ActiveCode)
      delete this.requestParams.filters.ActiveCode;

    if (this.costCenterCode) {
      this.requestParams.filters.CostCenterCode = this.costCenterCode;
    }
    this.requestParams.filters.ActiveCode = this.activeCode;

    this.currPage = 1;
    this.getCostCenters();
  }

  setPage(page): void {
    this.currPage = page + 1;

    this.getCostCenters();
  }

  async addCostCenter() {
    const modal = await this.modalController.create({
      component: CostCenterAddModalComponent,
      cssClass: 'min-width-modal-md-wide grey-background',
      showBackdrop: true,
      backdropDismiss: true,
      keyboardClose: true,
      swipeToClose: true,
    });

    (await modal).present();
    const {data} = await (await modal).onDidDismiss();

    if (data) {
      this.getDataCostCenter();
    }
  }
}
