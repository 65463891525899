<ion-content class="g-route">
    <ng-container>
        <app-header [isParentPage]="true" [title]="'Route: ' + checkRouteUndefined() + ' For: ' + getCurrentDayFormatted()"></app-header>
        <ion-grid>
            <ion-row class="table-row">
                <div>
                    <ion-row>
                        <ion-col>
                            <ion-header>Start Route</ion-header>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-label>Truck Number</ion-label>
                            <ion-select [interfaceOptions]="{size: 'cover'}" interface="popover" [disabled]="lockStartFields"
                             [(ngModel)]="truckNumber" placeholder="Enter truck number" class="ion-text-left color-light">
                                <ion-select-option *ngFor="let truck of trucks" [value]="truck">
                                    {{truck}}
                                </ion-select-option>
                            </ion-select>
                            <!-- <ion-input [disabled]="lockStartFields" type="text" [(ngModel)]="truckNumber" placeholder="Enter truck number" class="ion-text-left color-light"></ion-input> -->
                        </ion-col>
                        <ion-col>
                            <ion-label>Beginning Mileage</ion-label>
                            <ion-input [disabled]="lockStartFields" type="number" [(ngModel)]="beginningMileage" placeholder="Enter beginning mileage" class="ion-text-left color-light"></ion-input>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-label>Time Leaving Plant</ion-label>
                            <ion-input type="Time" disabled="true" [(ngModel)]="timeLeaving" placeholder="Enter time leaving plant" class="ion-text-left color-light"></ion-input>
                        </ion-col>
                        <ion-col>
                            <ion-button [disabled]="!timeLeaving || !beginningMileage || !truckNumber || lockStartFields"
                            (click)="startOrEndRoute(logType.START)" color="gallaghergreen" class="btn-box-shadow btn-margin"><span>Start Route</span></ion-button>
                        </ion-col>
                    </ion-row>

                    <ion-row>
                        <ion-header>Lunch Break</ion-header>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-label>Lunch Time</ion-label>
                            <ion-input type="Time" disabled="true" [(ngModel)]="lunchTime" placeholder="Enter time of lunch break" class="ion-text-left color-light"></ion-input>
                        </ion-col>
                        <ion-col>
                            <ion-button *ngIf="lastEvent !== logType.START_LUNCH" [disabled]="!beginningMileage || !truckNumber" (click)="startOrEndRoute(logType.START_LUNCH)"
                             color="gallaghergreen" class="btn-box-shadow btn-margin"><span>Start Lunch</span></ion-button>

                             <ion-button *ngIf="lastEvent === logType.START_LUNCH" [disabled]="!beginningMileage || !truckNumber" (click)="startOrEndRoute(logType.END_LUNCH)"
                             color="gallaghergreen" class="btn-box-shadow btn-margin"><span>End Lunch</span></ion-button>
                        </ion-col>
                    </ion-row>

                    <ion-row>
                        <ion-header>End Route</ion-header>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-label>Ending Mileage</ion-label>
                            <ion-input type="number" [(ngModel)]="endMileage" placeholder="Enter end mileage" class="ion-text-left color-light"></ion-input>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-label>Plant Return Time</ion-label>
                            <ion-input type="Time" disabled="true" [(ngModel)]="timeReturned" placeholder="Enter plant return time class" class="ion-text-left color-light"></ion-input>
                        </ion-col>
                        <ion-col>
                            <ion-button [disabled]="!timeReturned || !endMileage || !truckNumber"
                             (click)="startOrEndRoute(logType.END)" color="gallaghergreen" class="btn-box-shadow btn-margin"><span>End Route</span></ion-button>
                        </ion-col>
                    </ion-row>
                    <ion-row class="log-upsell-row">
                        <ion-col>
                            <div>
                                <ion-button color="primary" class="btn-box-shadow" [routerLink]="['/route/logsheet']"><span>View Logsheet</span></ion-button>
                                <!-- <ion-button color="primary" class="btn-box-shadow" [routerLink]="['/route/upsell-results']"><span>View Upsell Results</span></ion-button> -->
                            </div>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-header>Stops With Special Instructions</ion-header>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <app-special-instruction-table [rows]="specialInstStops"></app-special-instruction-table>
                        </ion-col>
                    </ion-row>
                    <!-- <ion-row>
                        <ion-header>Open Upsell Items</ion-header>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <app-open-upsell-items-table [rows]=""></app-open-upsell-items-table>
                        </ion-col>
                    </ion-row> -->
                </div>
            </ion-row>
        </ion-grid>
    </ng-container>
</ion-content>