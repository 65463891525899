// import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
// import { Router } from '@angular/router';


// @Component({
//   selector: 'app-report-table',
//   templateUrl: 'report-table.component.html',
//   styleUrls: [
//     './report-table.component.scss',
//   ]
// })
// export class ReportTableComponent implements OnInit {

//     @Input() rows: any;
//     @Input() currentRoute: string;
//     @Input() totalElements: number;
//     @Input() pageNumber: number;
//     @Input() pageSize: number;

//     @Input() mode: string;

//     @Output() paging = new EventEmitter<number>();


//     constructor(
//       private router: Router,
//     ) { 

//     }

//     ngOnInit(): void {
//       window.dispatchEvent(new Event('resize'));
//     }

//     routeCustomer(customerId) {
//       this.router.navigate([this.currentRoute + '/customer/' + customerId]);
//     }

//     routeDepartment(data) {
//       this.router.navigate([this.currentRoute + '/department/' + data.departmentCode + '/' + data.customerCode]);
//     }

//     routeEmployee(data) {
//       this.router.navigate([this.currentRoute + '/employee/' + data.employeeCode + '/' + data.customerCode + '/' + data.departmentCode]); 
//     }

//     changePage(pageInfo: any) {
//       this.paging.emit(pageInfo.offset);
//     }

// }

import { Component, EventEmitter, Input, Output, OnInit, ViewChild, OnChanges, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-report-table',
  templateUrl: 'report-table.component.html',
  styleUrls: ['./report-table.component.scss'],
})
export class ReportTableComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() rows: any[];
  @Input() currentRoute: string;
  @Input() totalElements: number;
  @Input() pageNumber: number;
  @Input() pageSize: number;
  @Input() mode: string;

  @Output() paging = new EventEmitter<number>();

  displayedColumns: string[] = ['customerCode', 'departmentCode', 'employeeCode', 'lastName', 'firstName', 'lockerNum'];
  dataSource = new MatTableDataSource<any>([]);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.loadPage();
    window.dispatchEvent(new Event('resize'));
  }

  ngOnChanges(): void {
    this.loadPage();
  }

  ngAfterViewInit(): void {
    //this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  loadPage() {
    // Update data source for the current page
    this.dataSource.data = this.rows;
    if (this.paginator) {
      this.paginator.pageIndex = this.pageNumber - 1;
    }
  }

  routeCustomer(customerId: string) {
    this.router.navigate([`${this.currentRoute}/customer/${customerId}`]);
  }

  routeDepartment(data: any) {
    this.router.navigate([`${this.currentRoute}/department/${data.departmentCode}/${data.customerCode}`]);
  }

  routeEmployee(data: any) {
    this.router.navigate([`${this.currentRoute}/employee/${data.employeeCode}/${data.customerCode}/${data.departmentCode}`]);
  }

  changePage(event: PageEvent) {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.paging.emit(this.pageNumber);
  }
}


