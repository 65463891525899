<app-top-bar></app-top-bar>
<ion-content>
    <div class="main-header">
        <span>Size Change</span>
        <div (click)="navigateBack()" class="back-button-mobile">
            <ion-icon name="chevron-back-outline"></ion-icon>
        </div>
    </div>
    <div class="mobile-info">
        <ion-grid class="ion-no-padding info-grid">
            <ion-row class="row">
                <span class="top-header-label">Cust:</span>
                <div *ngIf="loaded">{{garmentSummary.employeeListing.customerCode}} {{garmentSummary.employeeListing.customerName}}
                </div>
            </ion-row>
            <ion-row class="row">
                <span class="top-header-label">Stop:</span> <ng-container *ngIf="loaded">{{garmentSummary.employeeListing.stopCode}}
                    {{garmentSummary.employeeListing.stopName}}</ng-container>
            </ion-row>
            <ion-row class="bottom-row">
                <span class="top-header-label">Locker:</span> <ng-container *ngIf="loaded">{{garmentSummary.employeeListing.employeeCode}} <h6>
                    {{garmentSummary.employeeListing.lastName}}, {{garmentSummary.employeeListing.firstName}}</h6></ng-container>
            </ion-row>
        </ion-grid>
    </div>

    <div class="main-content">

        <div class="steps-container">
            <ion-row class="steps-container-top">
                <ion-col class="border-right" size="auto">
                    <span>Steps:</span>
                </ion-col>
                <ion-col class="border-right" [ngClass]="{'step-selected': (currentStep === STEP.SELECT)}">
                    <span>1 Select Product from new size</span>
                </ion-col>
                <ion-col [ngClass]="{'step-selected': (currentStep === STEP.SUBMIT)}">
                    <span>2 Submit Size Change Request</span>
                </ion-col>
            </ion-row>
            <ion-row class="steps-container-bottom">
                <ion-col>
                    <span *ngIf="(currentStep === STEP.SELECT)">Please select product then the new size you would like to have.</span>
                    <span *ngIf="(currentStep === STEP.SUBMIT)">Verify the fields and press <span class="bold-text">Submit</span> button to have us start work on your order.</span>
                </ion-col>
            </ion-row>
        </div>

        <div *ngIf="currentStep === STEP.SELECT" class="select-container">
            <ion-row class="select-header">
                <ion-col size="1" class="border-right select-all-col">
                    <input type="checkbox" [(ngModel)]="selectAll" (change)="selectAllChange($event)"/>
                </ion-col>
                <ion-col size="5" class="border-right">
                    <span>Description</span>
                </ion-col>
                <ion-col size="6">
                    <span>New Size</span>
                </ion-col>
            </ion-row>
            <ng-container *ngFor="let product of products">
                <ion-row class="border-top product-container">
                    <ion-col size="1" class="border-right">
                        <input type="checkbox" [(ngModel)]="product.checked"/>
                    </ion-col>
                    <ion-col size="5" class="border-right description" (click)="product.expanded = !product.expanded">
                        <div *ngIf="!product.expanded" class="sign">
                            <ion-icon name="add-outline"></ion-icon>
                        </div>
                        <div *ngIf="product.expanded" class="sign">
                            <ion-icon name="remove-outline"></ion-icon>
                        </div>
                        <span class="product-name">{{product.name}}</span>
                    </ion-col>
                    <ion-col size="6">
                        <ion-select (ionChange)="sizeChangeEvent(product)" interface="popover" [interfaceOptions]="{size: 'cover'}" placeholder="Select an option" [(ngModel)]="product.selectedSize">
                            <ion-select-option [value]="size" *ngFor="let size of product.sizes">
                                {{size}}
                            </ion-select-option>
                        </ion-select>
                    </ion-col>
                </ion-row>
                <ion-row class="expanded-parent">
                    <div *ngIf="product.expanded" class="expanded-content">
                        <ion-row>
                            <span><span class="bold-text">Inventory: </span> {{product.quota}}</span>
                        </ion-row>
                        <ion-row>
                            <span><span class="bold-text">Size: </span> {{product.size1}}-{{product.size2}}</span>
                        </ion-row>
                    </div>
                </ion-row>
            </ng-container>
        </div>
    </div>

    <div *ngIf="currentStep === STEP.SUBMIT">
        <ion-row class="employee-information-container">
            <ion-row class="order-container">
                <ion-col class="border-right" size="auto">
                    <span>Order For:</span>
                </ion-col>
                <ion-col class="border-right">
                    <span>{{empKey.CustomerCode}} {{empKey.DepartmentCode}} {{empKey.EmployeeCode}} {{employeeName}}</span>
                </ion-col>
                <ion-col size="auto">
                    <span>Date: {{currentDate | date: 'shortDate'}}</span>
                </ion-col>
            </ion-row>
            <ion-row class="email-container">
                <ion-col size="auto">
                    <span>Email: *</span>
                </ion-col>
                <ion-col>
                    <ion-input placeholder="Enter email" [(ngModel)]="userEmail"></ion-input>
                </ion-col>
            </ion-row>
        </ion-row>

        <div class="submit-product-container">
            <ion-row class="select-product-header">
                <ion-col class="border-right"  size="2">
                    <span>Item</span>
                </ion-col>
                <ion-col class="border-right" size="7">
                    <span>Product Code</span>
                </ion-col>
                <ion-col size="3">
                    <span>New size</span>
                </ion-col>
            </ion-row>       
            <ng-container *ngFor="let product of getSelectedProducts(); let i = index;">
                <div class="border-top product-confimation-data" *ngIf="product.checked">
                    <ion-row>
                        <ion-col class="border-right" size="2">
                            <span>{{i + 1}}</span>
                        </ion-col>
                        <ion-col (click)="product.expanded = !product.expanded" class="border-right product-code-data" size="7">
                            <div *ngIf="!product.expanded" class="sign">
                                <ion-icon name="add-outline"></ion-icon>
                            </div>
                            <div *ngIf="product.expanded" class="sign">
                                <ion-icon name="remove-outline"></ion-icon>
                            </div>
                            <span>{{product.productCode}}</span>
                        </ion-col>
                        <ion-col size="3">
                            <span>{{product.selectedSize}}</span>
                        </ion-col>
                    </ion-row>  
                    <ion-row class="expanded-parent">
                        <div *ngIf="product.expanded" class="expanded-content">
                            <ion-row>
                                <span><span class="bold-text">Description: </span> {{product.name}}</span>
                            </ion-row>
                            <ion-row>
                                <span><span class="bold-text">Quantity: </span> {{product.quota}}</span>
                            </ion-row>
                            <ion-row>
                                <span><span class="bold-text">Old size: </span> {{product.size1}}-{{product.size2}}</span>
                            </ion-row>
                        </div>
                    </ion-row>
                </div>
            </ng-container> 
        </div>
    </div>

    <ion-row *ngIf="(this.currentStep === STEP.SUBMIT)" class="button-row">
        <ion-col class="back-button-col">
            <ion-button (click)="back()">Back</ion-button>
        </ion-col>
    </ion-row>

    <ion-row class="button-row">
        <ion-col class="next-button-col">
            <ion-button (click)="next()">{{(this.currentStep === STEP.SUBMIT) ? 'Submit' : 'Next'}}</ion-button>
        </ion-col>
    </ion-row>

    <div class="spin" *ngIf="!loaded">
        <ion-spinner name="circular"></ion-spinner>
    </div>

</ion-content>
<app-footer></app-footer>