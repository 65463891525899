import { Component, OnInit } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { CustomerLookupRoutes } from '../../customer-lookup.routes';
import { Router } from '@angular/router';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';
import { RequestParams } from '../../../../common/rest-api/models/request-params';
import { CustomerListService } from '../../services/customer-list.service';


@Component({
  selector: 'app-customer-lookup-page',
  templateUrl: 'customer-lookup.page.html',
  styleUrls: [
    './customer-lookup.page.scss',
    '../../styles/customer-lookup.styles.scss'
  ]
})
export class CustomerLookupPage implements OnInit, ViewWillEnter {

  customerCode: string;
  customerName: string;

  public rows: any;

  requestParams: any;

  currPage: number;
  pageSize: number;

  submitting: boolean;

  totalElements: number = 0;
  totalPages: number = 0;

  currentRoute: string;

  constructor(
    private router: Router,
    private authService: GtrakAuthService,
    private customerListService: CustomerListService,
  ) { 
    this.currentRoute = null;
  }

  ngOnInit(): void {

    this.requestParams = new RequestParams();

    if(!this.authService.user.canViewCustomerLookup) {
      this.router.navigate([this.authService.user.homeLocation]);
    }

    this.rows = [];
    this.currentRoute = this.router.url;

    this.currPage = 1;
    this.pageSize = 20;

    this.requestParams.setOrdering('Name', 'asc');

    this.getCustomerList();
  }

  ionViewWillEnter(): void {
    this.requestParams.setPaging(this.currPage, this.pageSize);
  }


  async getCustomerList() {
    this.submitting = true;
    this.requestParams.setPaging(this.currPage, this.pageSize);
    var customers = await this.customerListService.query(this.requestParams);
    
    this.totalElements = customers.totalRecords;
    this.totalPages = customers.totalPages;

    this.rows = customers.results;
    this.submitting = false;
  }

  async getCustomerListByCode() { 
    this.customerCode = this.customerCode ?? '';

    //empty string identifier
    if(this.customerCode === ''){
      delete this.requestParams.filters.Name;
      delete this.requestParams.filters.CustomerCode;
      this.getCustomerList();
      return;
    }

    //delete old filters
    if(this.requestParams.filters.Name?.trim() === '')
      delete this.requestParams.filters.Name;
    if(this.requestParams.filters.CustomerCode?.trim() !== '')
      delete this.requestParams.filters.CustomerCode;

    this.currPage = 1;

    this.requestParams.filters.CustomerCode = this.customerCode;

    this.requestParams.setOrdering('CustomerCode', 'asc');

    this.getCustomerList();
  }

  async getCustomerListByName() {
    this.customerName = this.customerName ?? '';

    //empty string identifier
    if(this.customerName === ''){
      delete this.requestParams.filters.Name;
      delete this.requestParams.filters.CustomerCode;
      this.getCustomerList();
      return;
    }
    
    //delete old filters
    if(this.requestParams.filters.Name?.trim() !== '')
      delete this.requestParams.filters.Name;
    if(this.requestParams.filters.CustomerCode?.trim() === '')
      delete this.requestParams.filters.CustomerCode;

    this.currPage = 1;

    this.requestParams.filters.Name = this.customerName;

    this.requestParams.setOrdering('Name', 'asc');

    this.getCustomerList();
  }

  setPage(page): void {
    this.currPage = page + 1;

    this.getCustomerList();
  }

}