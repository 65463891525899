import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { QueryResult } from '../../../common/rest-api/models/query-result';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { GarmentModel } from '../../garment-listing/models/garment.model';
  
@Injectable({
providedIn: 'root',
})
export class GarmentEmployeeDetailsService extends RestApiService<GarmentModel> {

    protected resourcePath = '/garment';
    protected modelType = GarmentModel;

    async getGarments(empCode: string, custCode: string, deptCode: string): Promise<GarmentModel[]> {
        const url = this.buildEndpointUrl(`employee/${empCode}/${deptCode}/${custCode}`);
        const response = await this.cloudApiMiddleware.cloudRequest<GarmentModel[]>({
            url,
        }, 'GET');

        return response;
    }

}