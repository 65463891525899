import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { CustomerCostCenters } from "../../models/customer-cost-centers.model";
import { CustomerCostCenterService } from "../../services/customer-cost-center.service";
import { ToasterService } from "../../../../common/services/toaster.service";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-cost-center-add-modal",
  templateUrl: "cost-center-add-modal.component.html",
  styleUrls: ["./cost-center-add-modal.component.scss"],
})
export class CostCenterAddModalComponent implements OnInit {

  @Input() editCustomer: string;
  @Input() editCostCode: string;
  @Input() editCostName: string;
  @Input() editActiveCode: string;
  @Input() edit: boolean;

  customerCode: string = "1116";
  costCenterCode: string;
  costCenterName: string;
  activeCode: string = "1";

  constructor(
    private router: Router,
    private costCenterService: CustomerCostCenterService,
    private toasterService: ToasterService,
    private modalController: ModalController
  ) {}

  ngOnInit(): void {
    if (this.editCustomer) {
      this.customerCode = this.editCustomer;
    }
    if (this.editCostCode) {
      this.costCenterCode = this.editCostCode;
    }
    if (this.editCostName) {
      this.costCenterName = this.editCostName;
    }
    if (this.editActiveCode) {
      this.activeCode = this.editActiveCode;
    }
  }
  
  closeModal(updatePage: boolean = false) {
    this.modalController.dismiss(updatePage);
  }

  addCostCenter() {
    //check if required fields are filled out
    if (!this.costCenterCode || !this.costCenterName) {
      this.toasterService.presentToast(
        "danger",
        "Error",
        "Please fill out all required fields."
      );
      return;
    }
    //create new cost center object
    let costCenter = new CustomerCostCenters();
    costCenter.customerCode = this.customerCode;
    costCenter.costCenterCode = this.costCenterCode;
    costCenter.costCenterName = this.costCenterName;
    costCenter.activeCode = this.activeCode;

    this.costCenterService.addCostCenter(costCenter).then((response) => {
      if (response === "Success") {
        this.toasterService.presentToast(
          "primary",
          "Cost center added successfully.",
          "Success"
        );
        this.closeModal(true);
      } else {
        this.toasterService.presentToast(
          "danger",
          `Error adding cost center. ${response}`,
          "Error"
        );
      }
    });
  }

  updateCostCenter() {
    //check if required fields are filled out
    if (!this.customerCode || !this.costCenterName) {
      this.toasterService.presentToast(
        "danger",
        "Error",
        "Please fill out all required fields."
      );
      return;
    }
    //create new cost center object
    let costCenter = new CustomerCostCenters();
    costCenter.customerCode = this.customerCode;
    costCenter.costCenterCode = this.costCenterCode;
    costCenter.costCenterName = this.costCenterName;
    costCenter.activeCode = this.activeCode;

    this.costCenterService.editCostCenter(costCenter).then((response) => {
      if (response === "Success") {
        this.toasterService.presentToast(
          "primary",
          "Cost center updated successfully.",
          "Success"
        );
        this.closeModal(true);
      } else {
        this.toasterService.presentToast(
          "danger",
          `Error updating cost center. ${response}`,
          "Error"
        );
      }
      this.closeModal();
      //refresh page
      window.location.reload();
    });
  }
}
