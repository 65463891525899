import { Injectable, Type } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { QueryResult } from '../../../common/rest-api/models/query-result';
import { RequestParams } from '../../../common/rest-api/models/request-params';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { InvoiceSummary } from '../models/invoice.model';
import { WeeklyHistoryItem } from '../../garment-listing/models/weekly-history-item.model';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService extends RestApiService<InvoiceSummary> {

  protected resourcePath = '/invoice';
  protected modelType = InvoiceSummary;
  invoicePath = 'invoice';

  async getInvoices(params: RequestParams) {
    const url = this.buildEndpointUrl(`query`, params);

    const response = InvoiceSummary.from(await this.cloudApiMiddleware.cloudRequest<QueryResult<InvoiceSummary>>({
      url
    }, 'GET'));

    return response;
  }

  async getWeeklyHistory(custCode: string, deptCode: string, empCode: string) {
    const url = this.buildEndpointUrl(`history/${custCode}/${deptCode}/${empCode}`);

    const response = await this.cloudApiMiddleware.cloudRequest<WeeklyHistoryItem[]>({
      url
    }, 'GET');

    return response;
  }



}