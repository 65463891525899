import { ApiUser } from "../../../common/rest-api/models/api-user"
import { Claims } from "../../../common/rest-api/models/tokens";

export class GtrakUser extends ApiUser {
  get name() { return this.claims.name; }
  get customerCode() { return this.claims.cus_code; }
  get departmentCode() { return this.claims.dep_code; }
  get employeeCode() { return this.claims.emp_code; }

  get routeCode() { return localStorage.getItem('admin_override_route') ?? this.claims.route_code; }
  get routeDate() { return localStorage.getItem('admin_override_date') ? new Date(localStorage.getItem('admin_override_date')) : new Date(this.claims.route_date); }

  get canPlaceOrder() { return this.claims.CanPlaceOrder != null; }
  get canViewInvoice() { return this.claims.CanViewInvoice != null; }
  get canViewEmployeeTransfer() { return this.claims.CanViewEmployeeTransfer != null; }
  get canViewCustomCancelReport() { return this.claims.CanViewCustomCancelReport != null; }
  get canViewGarmentListing() { return this.claims.role !== 'driver'; }
  get canViewWorkOrders() { return (this.claims.role !== 'driver' || this.claims.role !== 'user'); }
  get canViewCustomerLookup() { return (this.claims.role === 'admin'); }
  get canViewDriverRoutes() { return (this.claims.role === 'admin' || this.claims.role === 'driver'); }
  get canViewAccountManagement() { return (this.claims.role === 'admin'); }
  get canViewGarmentReturnReview() { return this.claims.CanViewGarmentReviewReport != null; }
  get isWearer() { return this.claims.isWearer != null; }
  get isCostCenterUser() { return this.claims.isCostCenterUser != null; }

  get canAccessMobileApp() { return this.claims.role === 'USER'; }

  get homeLocation() { return this.isDriver ? '/route' : (this.isEmployeeUser ? '/mobile' : '/garment-listing'); }
  get homeAuthLocation() { return `/auth/${this.isDriver ? 'route' : (this.isWearer ? 'wearer' : (this.isEmployeeUser ? 'mobile' : 'login')) }` }
  

  get isAdmin() { return this.claims.role === 'admin'; }
  get isEmployeeUser() { return this.claims.role === 'USER'; }
  get isCustomerUser() { return this.claims.role === 'user'; }
  get isDriver() { return this.claims.role === 'driver'; }

  constructor(claims: Claims) {
    super(claims);
  }
}