import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';

@Component({
  selector: 'app-main-admin-page',
  templateUrl: 'main-admin.page.html',
  styleUrls: [
    './main-admin.page.scss',
  ]
})
export class MainAdminPage {
    constructor(
        private router: Router,
        private authService: GtrakAuthService,
      ) { 
      }

      navigateCost() {
        this.router.navigate(['admin-page/cost-center']);
      }

      navigateWO() {
        this.router.navigate(['admin-page/wo-approval']);
      }
}