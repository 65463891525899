<ion-content class="garment-listing">
    <app-header [isParentPage]="true" [title]="'Garment Listing Report'"></app-header>
    <ng-container>
        <ion-grid>
            <ion-row class="filter-row">
                <ion-grid>
                    <ion-row>
                        <ion-col [size]="authService.user?.isCostCenterUser ? '5' : ''">
                            <div>
                                <ion-input type="text" [(ngModel)]="lastName" class="ion-text-left color-light" placeholder="{{authService.user?.isCostCenterUser ? 'Enter part of last name, stop code, or leave blank for all' : 'Enter part or all of last name, or leave blank for all'}}"></ion-input>
                                <ion-button *ngIf="!authService.user?.isCostCenterUser" color="gallaghergreen" class="btn-box-shadow" (click)="getDataByLastName()"><span>Show</span></ion-button>
                            </div>
                        </ion-col>
                        <ion-col size="3" *ngIf="authService.user?.isCostCenterUser">
                            <div>
                                <ng-select placeholder="Select Cost Center" class="select" (change)="changeCostCenter($event)">
                                    <ng-option *ngFor="let costCenter of loginDepts" value="{{costCenter.departmentCode}}">{{costCenter.departmentCode}}</ng-option>
                                </ng-select>
                                <ion-button color="gallaghergreen" class="btn-box-shadow" (click)="getDataCostCenter()"><span>Show</span></ion-button>
                            </div>
                        </ion-col>
                        <ion-col [size]="authService.user?.isCostCenterUser ? '4' : ''">
                            <div>
                                <ion-input type="text" [(ngModel)]="garmentCode" placeholder="Enter Garment Code or RFID" class="ion-text-left color-light"></ion-input>
                                <ion-button color="gallaghergreen" class="btn-box-shadow" (click)="getDataByGarmentCode()"><span>Show</span></ion-button>
                            </div>
                        </ion-col>
                    </ion-row>
                    <ion-row class="filter-row-2">
                        <ion-col>
                            <ion-input type="text" [(ngModel)]="customerCode" placeholder="Enter Customer Code" class="ion-text-left color-light"></ion-input>
                        </ion-col>
                        <ion-col>
                            <div>
                                <ion-input type="text" [(ngModel)]="departmentCode" placeholder="Enter Department Code" class="ion-text-left color-light"></ion-input>
                                <ion-button color="gallaghergreen" class="btn-box-shadow" (click)="getDataByCustomerAndDepartmentCode()"><span>Show</span></ion-button>
                            </div>
                        </ion-col>
                    </ion-row>
                </ion-grid>

            </ion-row>

            <ion-row class="table-row">
                <div *ngIf="!authService.user?.isWearer && !authService.user?.isCostCenterUser" class="ion-padding table-container">
                    <app-report-table [mode]="'gl'" (paging)="setPage($event)" [pageSize]="pageSize" [pageNumber]="currPage" [rows]="rows" [currentRoute]="currentRoute" [totalElements]="totalElements"></app-report-table>
                </div>
                <div *ngIf="authService.user?.isCostCenterUser" class="ion-padding table-container">
                    <app-cost-center-report-table  [mode]="'gl'" (paging)="setPage($event)" [pageSize]="pageSize" [pageNumber]="currPage" [rows]="rows" [currentRoute]="currentRoute" [totalElements]="totalElements"></app-cost-center-report-table>
                </div>
            </ion-row>
        </ion-grid>
    </ng-container>
</ion-content>
