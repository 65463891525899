import { Model, ModelId } from "../../../common/rest-api/models/model";

export class WebLoginDept extends Model {
  get modelId(): ModelId {
    throw new Error("Method not implemented.");
  }
  login: string;
  customerCode: string;
  departmentCode: string;
}
