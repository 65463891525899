import { Data } from "@angular/router";
import { Model, ModelId } from "../../../common/rest-api/models/model";

export class GarmentModel extends Model {

    get modelId(): ModelId {
        throw new Error("Method not implemented.");
    }

    productCode: string;  
    productCategory: string;  
    productType: string; 
    customerCode: string; 
    departmentCode: string;  
    employeeCode: string;  
    size1: string;  
    size2: string;  
    colorCode: string;  
    dateTimeDateLastAssigned: Date; 
    currentProcessTally: number; 
    totalProcessTally: number; 
    rotatingStock: string;  
    setCode: string;  
    statusCode: string;  
    dateTimeStockDate: Date; 
    decimalPurchasePrice: number; 
    decimalCurrentRevenue: number; 
    decimalTotalRevenue: number; 
    garmentCode: string;   
    dateTimeLastCheckInDate: Date; 
    dateTimeLastCheckOutDate: Date; 
    dateTimeLastMendingCheckInDate: Date; 
    dateTimeLastMendingCheckOutDate: Date; 
    grade: string;  
    dateTimeStatusChangeDate: Date; 
    dateTimeDateFirstAssigned 
    supplierCode: string;  
    weeksAssigned: number; 
    dateTimeDeliveryDate: Date; 
    tourCode: string;  
    stockLoc: string;  
    stockSubLoc: string;  
    sorterFlag: number 
}