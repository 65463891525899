<ion-content class="garment-lookup">
    <ng-container>
        <app-header [title]="(routeStopName ?? '') + ' (' + stopName + ') Lookup'"></app-header>
        <ion-grid class="top-grid">
            <ion-row class="scan-row">
                <ion-col size="auto">
                    <span class="scan-text">Scan Garment:</span>
                </ion-col>
                <ion-col>
                    <ion-input (keydown.enter)="getGarment()" [(ngModel)]="garmentCode"></ion-input>
                </ion-col>
                <ion-col (click)="getGarment()" *ngIf="!garmentReplace" size="auto">
                    <ion-button>Submit</ion-button>
                </ion-col>
                <ion-col (click)="replaceGarment()" *ngIf="garmentReplace" size="auto">
                    <ion-button>Replace</ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
        <div *ngIf="garment" class="data">
            <ion-row class="border-row">
                <ion-col size="auto" class="left-col">
                    Emp:
                </ion-col>
                <ion-col size="auto" class="right-col">
                    {{garmentSummary.lastName}}, {{garmentSummary.firstName}}
                </ion-col>
            </ion-row>
            <ion-row class="border-row">
                <ion-col size="auto" class="left-col">
                    Prod:
                </ion-col>
                <ion-col size="auto" class="right-col">
                    <ion-row>
                        <ion-col class="ion-no-padding">
                            {{garmentDetails.productCode}}
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col class="ion-no-padding">
                            {{garmentDetails.description}}
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>
            <ion-row class="border-row">
                <ion-col size="auto" class="left-col">
                    Size:
                </ion-col>
                <ion-col size="auto" class="right-col">
                    {{garmentDetails.size1}}&nbsp;{{garmentDetails.size2}}
                </ion-col>
            </ion-row>
            <ion-row class="border-row">
                <ion-col size="auto" class="left-col">
                    Last Del:
                </ion-col>
                <ion-col size="auto" class="right-col">
                    {{garmentDetails.lastDelivery | date:'shortDate'}}
                </ion-col>
            </ion-row>
            <ion-row class="border-row">
                <ion-col size="auto" class="left-col">
                    Assign Date:
                </ion-col>
                <ion-col size="auto" class="right-col">
                    {{garmentSummary.startDate | date:'shortDate'}}
                </ion-col>
            </ion-row>
            <ion-row class="border-row">
                <ion-col size="auto" class="left-col">
                    Wks Assigned:
                </ion-col>
                <ion-col size="auto" class="right-col">
                    {{garment.weeksAssigned}}
                </ion-col>
            </ion-row>
            <ion-row class="border-row"> 
                <ion-col size="auto" class="left-col">
                    Replacement Charge:
                </ion-col>
                <ion-col size="auto" class="right-col">
                    {{garment.replacementCharge ? 'Available For Replacement: No Charge' : (garment.replacementPrice | currency: 'USD')}}
                </ion-col>
            </ion-row>
        </div>
    </ng-container>
</ion-content>