import { Component, OnInit } from '@angular/core';
import { MenuController, ViewWillEnter } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { GtrakAuthService } from '../../services/gtrak-auth.service';
import { GarmentDetailService } from '../../../garment-listing/services/garment-detail.service';
import { ToasterService } from '../../../../../app/common/services/toaster.service';
import { Location } from '@angular/common';
import { EmailService } from '../../../../../app/common/services/email.service';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { TokenService } from '../../../../../app/common/services/token.service';

@Component({
  selector: 'app-reset-password-page',
  templateUrl: 'reset-password.page.html',
  styleUrls: [
    './reset-password.page.scss'
  ]
})
export class ResetPasswordPage implements ViewWillEnter, OnInit {

    token: string = null;

    password: string = null;
    reenterPassword: string = null; 

    loaded: boolean = false;

    constructor(
        public authService: GtrakAuthService,
        public garmentDetailService: GarmentDetailService,
        private toasterService: ToasterService,
        private location: Location,
        private emailService: EmailService,
        private navController: NavController,
        private route: ActivatedRoute,
        private tokenService: TokenService
    ) { 
    
    }

    async ngOnInit(): Promise<void> {
        this.token = this.route.snapshot.paramMap.get('token');

        if (!this.token) {
            this.navController.navigateRoot('');
        }

        const result = await this.tokenService.CheckToken(this.token);

        if (!result) {
            this.navController.navigateRoot('');
        }
    }

    navigateToBaseLogin(): void {
        this.location.back();
    }

    async ionViewWillEnter() {
        this.loaded = true;
    }

    async submit(): Promise<void> {
        if (this.password !== this.reenterPassword) {
            this.toasterService.presentToast('warning', '', 'Password do not match.', 2000);
        } else {
            if (this.password.length < 6) {
                this.toasterService.presentToast('warning', '', 'Passwords must be atleast 6 characters', 1800);
                return;
            }

            this.tokenService.ChangePassword(this.password, this.token);

            this.toasterService.presentToast('primary', '', 'Password reset successful.', 2000);
            this.navController.navigateRoot('');
        }
    }

}