import { Model, ModelId } from "../../../common/rest-api/models/model";
import { WebOrderItem } from "./web-order-item.model";
import { WebOrder } from "./web-order.model";
import { WebOrderGarmentItem } from "./web-order-garment-item.model";

export class WebOrderApprovals extends Model {
    get modelId(): ModelId {
        throw new Error("Method not implemented.");
    }

    webOrder: WebOrder;
    webOrderItems: WebOrderItem[];
    webGarments: WebOrderGarmentItem[];
    orderType: string;
    state: string;
    transferReason: string;
    selectedTransferReason: string;
}