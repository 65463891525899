<ion-content class="contacts">
  <ng-container>
    <app-header [title]="(routeStopName ?? '') + ' (' + stopName + ') Contacts'"></app-header>
    <ion-grid>
      <div class="div-container">
        <ion-row>
          <ion-header>Contacts</ion-header>
        </ion-row>
        <ion-row>
          <div class="table-row">
            <mat-table [dataSource]="contactsDataSource" matSort class="mat-elevation-z8">
              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <a>
                    <span *ngIf="element.recievesEmails" class="receives-emails">*</span>&nbsp;{{element.name}}
                  </a>
                </mat-cell>
              </ng-container>

              <!-- Phone Column -->
              <ng-container matColumnDef="phone">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Phone </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <a [href]="'tel:' + element.phone" class="link">{{element.phone}}</a>
                </mat-cell>
              </ng-container>

              <!-- Email Column -->
              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <a [href]="'mailto:' + element.email" class="link">{{element.email}}</a>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <mat-paginator [pageSize]="5" showFirstLastButtons></mat-paginator>
          </div>
        </ion-row>

        <ion-row>
          <ion-header>Other Contacts</ion-header>
        </ion-row>
        <ion-row>
          <div class="table-row">
            <mat-table [dataSource]="otherContactsDataSource" matSort class="mat-elevation-z8">
              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
              </ng-container>

              <!-- Phone Column -->
              <ng-container matColumnDef="phone">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Phone </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <a [href]="'tel:' + element.phone" class="link">{{element.phone}}</a>
                </mat-cell>
              </ng-container>

              <!-- Email Column -->
              <ng-container matColumnDef="email">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <a [href]="'mailto:' + element.email" class="link">{{element.email}}</a>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <mat-paginator [pageSize]="5" showFirstLastButtons></mat-paginator>
          </div>
        </ion-row>
        
        <ion-row class="bottom-message">
          <ion-col>
            <span class="receives-emails">*</span> Receives Emails For Stop
          </ion-col>
        </ion-row>
      </div>
    </ion-grid>
  </ng-container>
</ion-content>
