<ion-content class="container">
    <app-header class="hide-on-print" [title]="'Custom Cancel Report'"></app-header>
    <ng-container>

        <ion-row class="filter-row hide-on-print">
            <ion-grid>
                <ion-row>
                    <ion-col>
                        <ion-input type="text" [(ngModel)]="customerCode" placeholder="Enter customer code" class="ion-text-left color-light"></ion-input>
                    </ion-col>
                    <ion-col class="width-80px">
                        <ion-button (click)="searchCustomerCode()" color="gallaghergreen" class="btn-box-shadow btn-margin"><span>Show</span></ion-button>
                    </ion-col>
                    <ion-col>
                        <ion-input [disabled]="!submittedCustomerCode" type="text" [(ngModel)]="lastNameSearch" placeholder="Search last name" class="ion-text-left color-light"></ion-input>
                    </ion-col>
                    <!-- <ion-col class="width-80px">
                        <ion-button [disabled]="!submittedCustomerCode" (click)="setFilter()" color="gallaghergreen" class="btn-box-shadow btn-margin">
                            <span>Find</span>
                        </ion-button>
                    </ion-col> -->
                </ion-row>
            </ion-grid>
        </ion-row>

        <div class="spinner-container" *ngIf="!loaded">
            <ion-spinner></ion-spinner>
        </div>
        
        <div class="content-container" *ngIf="loaded">
            <div *ngIf="submittedCustomerCode" class="top-header">
                <ion-row>
                    <ion-col>
                        <span><span class="bold-text">Cust:</span> {{currentCustomerCode}}&nbsp;&nbsp;&nbsp;{{customer?.name ?? ''}}</span>
                    </ion-col>
    
                    <ion-col class="align-right">
                        <span><span class="bold-text">Report Date:</span> {{today | date:'shortDate' }}</span>
                    </ion-col>
                </ion-row>
                <ion-row class="hide-on-print">
                    <ion-col>
                       
                    </ion-col>
    
                    <ion-col class="align-right">
                        <button class="printer-button" (click)="changePrinterView()">Print</button>
                    </ion-col>
                </ion-row>
            </div>

            <div class="item-scroll-container">
                <ng-container *ngFor="let data of displayData">
                    <ng-container *ngIf="isValid(data)">
                        <div class="item-header">
                            <ion-row>
                                <ion-col>
                                    <span class="bold-text">{{data.lastName}}, {{data.firstName}} ({{data.depCode}}-{{data.empCode}})</span>
                                </ion-col>
                                <ion-col>
                                    <span><span class="bold-text">Cancel Order:</span> {{data.woCode}}</span>
                                </ion-col>
                                <ion-col class="align-right">
                                    <span><span class="bold-text">Start Date:</span> {{data.startDate | date:'shortDate'}}</span>
                                </ion-col>
                            </ion-row>
                            <ion-row>
                                <ion-col>
    
                                </ion-col>
                                <ion-col>
                                    <span><span class="bold-text">Stop: </span> {{data.stopName}}</span>
                                </ion-col>
                                <ion-col class="align-right">
                                    <span><span class="bold-text">Gar. ID Label:</span> {{data.stopCode}} {{data.lockerNum}}</span>
                                </ion-col>
                            </ion-row>
                            <ion-row class="label-header">
                                <ion-col>
                                    <span>Description</span>
                                </ion-col>
                                <ion-col>
                                    <span>Product</span>
                                </ion-col>
                                <ion-col>
                                    <span>Barcode #</span>
                                </ion-col>
                                <ion-col>
                                    <span>Date Collected</span>
                                </ion-col>
                                <ion-col>
                                    <span>Loss Charge</span>
                                </ion-col>
                            </ion-row>
                        </div>
                        
                        <div class="data-item" *ngFor="let item of data.items">
                            <ion-row>
                                <ion-col>
                                    <span>{{item.shortName}}</span>
                                </ion-col>
                                <ion-col>
                                    <span>{{item.name}}</span>
                                </ion-col>
                                <ion-col>
                                    <span>{{item.garmentCode}}</span>
                                </ion-col>
                                <ion-col>
                                    <span>{{item.actionDate | date:'shortDate'}}</span>
                                </ion-col>
                                <ion-col class="align-right">
                                    <span>{{(!item.cpsalePrice || item.cpsalePrice === -1) ? (item.pdsalePrice|currency:'USD') : (item.cpsalePrice|currency:'USD')}}</span>
                                </ion-col>
                            </ion-row>
                        </div>
    
                        <ion-row class="final-charge-row">
                            <ion-col>
                                <span><span class="bold-text">Final Pick Up Date and Potential Charges:</span> {{data.orderDate|date:'shortDate'}}</span>
                            </ion-col>
                            <ion-col class="align-right">
                                <span class="bold-text">{{data.total|currency:'USD'}}</span>
                            </ion-col>
                        </ion-row>
                    </ng-container>
                </ng-container>

                <ion-row *ngIf="submittedCustomerCode" class="final-grand-total-row">
                    <ion-col>
                        <span class="bold-text">Grand Total Potential Loss Charge:</span>
                    </ion-col>
                    <ion-col class="align-right">
                        <span class="bold-text">{{grandTotalCharge|currency:'USD'}}</span>
                    </ion-col>
                </ion-row>
            </div>
        </div>
    </ng-container>
</ion-content>