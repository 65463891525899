import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DepartmentKey } from '../../../../app/common/models/department-key-model';
import { EmployeeKey } from '../../../../app/common/models/employee-key.model';
import { QueryResult } from '../../../common/rest-api/models/query-result';
import { RequestParams } from '../../../common/rest-api/models/request-params';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { RouteLogModel, IRouteLogModel } from '../models/route-log.model';

@Injectable({
  providedIn: 'root',
})
export class GRouteLogService extends RestApiService<RouteLogModel> {

  protected resourcePath = '/route-log';
  protected modelType = RouteLogModel;

  async createRouteLog(body: IRouteLogModel): Promise<RouteLogModel> {
    const url = this.buildEndpointUrl(``);

    const response = await this.cloudApiMiddleware.cloudRequest<RouteLogModel>({
      url,
      body
    }, 'POST');
    
    return response;
  }

  async scheduleReportEmail(stopCode: string, userId: string | number, sendInvoice: boolean, logDate: Date, onlyInvoice: boolean): Promise<RouteLogModel> {
    const url = this.buildEndpointUrl(`schedule-reports/${stopCode}/${userId}/${logDate.toISOString()}/${sendInvoice}/${onlyInvoice}`);

    const response = await this.cloudApiMiddleware.cloudRequest<RouteLogModel>({
      url,
      body: {}
    }, 'POST');
    
    return response;
  }

  async getTrucks(): Promise<string[]> {
    const url = this.buildEndpointUrl(`get-trucks`);

    const response = await this.cloudApiMiddleware.cloudRequest<string[]>({
      url
    }, 'GET');
    
    return response;
  }

  async logCancel(body: IRouteLogModel): Promise<RouteLogModel> {
    const url = this.buildEndpointUrl(`cancel`);

    const response = await this.cloudApiMiddleware.cloudRequest<RouteLogModel>({
      url,
      body
    }, 'POST');
    
    return response;
  }

  async checkCancel(routeCode: string, stopCode: string, driverCode: string, date: Date): Promise<RouteLogModel[]> {
    //put date in mm-dd-yyyy format
    let day = date.getDate().toString().padStart(2, '0'); // Adds leading zero if needed
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Adds leading zero if needed, and adjusts month
    let year = date.getFullYear();
    let formattedDate = month + '-' + day + '-' + year;

    const url = this.buildEndpointUrl(`cancel/${routeCode}/${stopCode}/${driverCode}/${formattedDate}`);
    const response = await this.cloudApiMiddleware.cloudRequest<RouteLogModel[]>({
      url
    }, 'GET');

    return response;
  }

  async hasCancels(stopCode: string){
    const url = this.buildEndpointUrl(`has-cancel/${stopCode}`);
    const response = await this.cloudApiMiddleware.cloudRequest<RouteLogModel[]>({
      url
    }, 'GET');

    return response;
  }

  async getRouteLogs(routeCode: string): Promise<RouteLogModel[]> {
    const url = this.buildEndpointUrl(`route/${routeCode}/${new Date().toJSON()}`);
    const response = await this.cloudApiMiddleware.cloudRequest<RouteLogModel[]>({
      url
    }, 'GET');

    return response;
  }

  async getStopLogs(routeCode: string, stopCode): Promise<RouteLogModel[]> {
    const url = this.buildEndpointUrl(`route/${routeCode}/${stopCode}/${new Date().toJSON()}`);
    const response = await this.cloudApiMiddleware.cloudRequest<RouteLogModel[]>({
      url
    }, 'GET');

    return response;
  }


}