import { Data } from "@angular/router";
import { Model, ModelId } from "../../../common/rest-api/models/model";

export class WorkOrder extends Model
{
  get modelId(): ModelId {
    throw new Error("Method not implemented.");
  }

    stopCode: string;
    employeeCode: string;
    lastName: string;
    woCode: string;
    orderDate: string;
    deliveryDate: string;
    qty: number;
    reason: string;
    product: string;
    comments: string;
    customerCode: string;
    departmentCode: string;
    firstName: string;
    closeDate: string;
    state: number;
    lockerNum: string;
    stopName: string;
    deliveryAddress1: string;

}