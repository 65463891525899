import { Component, Input, OnInit } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { CancelOrderService } from '../../../../../cancel-order/services/cancel-order.service';
import { ToasterService } from '../../../../../../common/services/toaster.service';
import { IRouteLogModel } from '../../../../../../features/g-route/models/route-log.model';
import { GtrakAuthService } from '../../../../../../features/authentication/services/gtrak-auth.service';
import { GRouteLogService } from '../../../../../../features/g-route/services/g-route-log.service';



@Component({
  selector: 'app-garment-cancel-report-page',
  templateUrl: 'garment-cancel-report.page.html',
  styleUrls: [
     './garment-cancel-report.page.scss',
     '../../../../styles/g-route.styles.scss'
  ]
})
export class GarmentCancelReportPage implements ViewWillEnter, OnInit {
    reasonMap: Map<string, string> = new Map<string, string>([
        ['XC', 'Cancel Complete'],
        ['XI', 'Cancel Item'],
        ['RSC', 'Rent Size Change'],
        ['RC', 'Replace Garment']
    ])

    currentRoute: string;
    stopName: string;

    cancelOrderList: any[] = [];
    cancelOrderGroupedByWo: {woCode: string, reason: string, employeeCode: string, lockerNum: string, employeeName: string, departmentCode: string, pickupDate: string, items: any[]}[] = [];

    showAll: boolean = false;

    garmentChangeList: any[] = [];

    routeStopName: string = "";

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private cancelOrderService: CancelOrderService,
        private toasterService: ToasterService,
        public authService: GtrakAuthService,
        private routeLogService: GRouteLogService
    ) { 
        this.currentRoute = null;
    }

    async ngOnInit() {
        this.currentRoute = this.router.url;
        this.stopName = this.route.snapshot.paramMap.get('id');

        this.routeStopName = localStorage.getItem('routeStopName');

        //create a log for visiting the page
        const log: IRouteLogModel = {
            routeCode: this.authService.user.routeCode,
            stopCode: this.stopName,
            driverCode: (this.authService.user.id as string),
            logType: 5,
            time: new Date()
        }
        this.logCancel(log);

        await this.cancelOrderService.getForStop(this.stopName).then((data) => {
            this.cancelOrderList = data;
            console.log(data);

            this.cancelOrderList.forEach(c => {
                const existingWo = this.cancelOrderGroupedByWo.find(x => x.woCode === c.woCode && x.employeeCode === c.employeeCode);

                if (!c.size1 || !c.size2) {
                    const empProduct = c?.employeeProducts[0];

                    if (empProduct) {
                        c.size1 = empProduct?.size1;
                        c.size2 = empProduct?.size2;
                    }
                }

                if (existingWo) {
                    var existingItem = existingWo.items.find(i => i.name === c.name);

                    if (!existingItem) {
                        existingWo.items.push(c);
                    }
                } else {
                    this.cancelOrderGroupedByWo.push({
                        woCode: c.woCode,
                        employeeCode: c.employeeCode,
                        departmentCode: c.departmentCode,
                        lockerNum: c.lockerNum,
                        employeeName: `${c.lastName}, ${c.firstName}`,
                        pickupDate: c.pickupDate,
                        reason: this.reasonMap.get(c.reasonCode) ?? c.reasonCode,
                        items: [c]
                    });
                }
            });
        });
        console.log(this.cancelOrderGroupedByWo)
    }

    ionViewWillEnter(): void {
        
    }

    async logCancel(log: IRouteLogModel): Promise<void> {
        await this.routeLogService.logCancel(log);
    }

    showAllWO(event) {
        this.showAll = event.target.checked;
    }

    addToList(event, garment) {
        if(event.target.checked) {
            this.garmentChangeList.push({woCode: garment.woCode, garmentCode: garment.garmentCode});
        }
        else {
            this.garmentChangeList = this.garmentChangeList.filter(item => item.woCode !== garment.woCode && item.garmentCode !== garment.garmentCode);
        }
    }

    submit() {
        if(this.garmentChangeList.length === 0){
            this.toasterService.presentToast('danger', '', 'Please select at least one garment to pickup.');
        }
        else {
            this.cancelOrderService.updateCancelReport(this.garmentChangeList).then((data) => {
                this.cancelOrderGroupedByWo.forEach(c => {
                    c.items.forEach(i => {
                        this.garmentChangeList.forEach(g => {
                            i.stWoGarments = i.stWoGarments.filter(item => item.woCode === g.woCode && item.garmentCode !== g.garmentCode);
                        });
                    })
                });
                this.toasterService.presentToast('primary', '', 'Garments updated successfully.');
            });
        }
    }

}