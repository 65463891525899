import { Data } from "@angular/router";
import { Model, ModelId } from "../../../../../../../common/rest-api/models/model";

export interface IStopContact
{
  customerCode: string;
  departmentCode: string;
  email: string;
  phone: string;
  name: string;
  sequenceNbr: string;
  title: string;
  recievesEmails: boolean;
}

export class AllStopContacts extends Model {
    get modelId(): ModelId {
        throw new Error("Method not implemented.");
    }

    contacts: IStopContact[];
    otherContacts: IStopContact[];
}