
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DepartmentKey } from '../../../../app/common/models/department-key-model';
import { EmployeeKey } from '../../../../app/common/models/employee-key.model';
import { QueryResult } from '../../../common/rest-api/models/query-result';
import { RequestParams } from '../../../common/rest-api/models/request-params';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { RouteStopModel } from '../models/route-stop.model';
import { CustomerContactModel } from '../models/customer-contact.model';
import { AssignedMessages } from '../models/assigned-messages.model';
  
@Injectable({
providedIn: 'root',
})
export class GarmentRepairService extends RestApiService<AssignedMessages> {

    protected resourcePath = '/assigned-message';
    protected modelType = AssignedMessages;

    async postMessage(messageCode: string, garmentCode: string): Promise<QueryResult<AssignedMessages>> {
        const url = this.buildEndpointUrl(`${messageCode}/${garmentCode}`);
        const response = await this.cloudApiMiddleware.cloudRequest<QueryResult<AssignedMessages>>({
            url
        }, 'POST');

        return response;
    }

}