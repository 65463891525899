<ion-header>
  <ion-toolbar color="gallaghergreen">
    <ion-row>
      <ion-col size="auto" class="ion-no-padding">
        <ion-buttons class="burger">
          <ion-button (click)="toggleMenu()">
            <ion-icon slot="icon-only" name="menu"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-col>
      <ion-col size="auto" class="ion-no-padding">
        <ion-button *ngIf="!isParentPage" (click)="goBack()" class="back-arrow">
          <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col class="ion-no-padding title-col">
        <div class="title-wrapper">
          <ion-title>
            {{title}}
          </ion-title>
        </div>
      </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-header>
