export class AuthCreds {
  login?: string = '';
  password?: string = '';

  routeCode?: string = '';
  routeDate?: string = '';

  stopCode?: string = '';
  lockerNum?: string = '';
  lastName?: string = '';

  public prepareForLoginMode(mode: LoginMode) {
    switch (mode) {
      case LoginMode.Gtrak:
        this.routeCode = null;
        this.routeDate = null;
        this.stopCode = null;
        this.lockerNum = null;
        this.lastName = null;
        break;
      case LoginMode.Groute:
        this.stopCode = null;
        this.lockerNum = null;
        this.lastName = null;
        break;
      case LoginMode.GWearer:
        // TODO This may change base don clarification from Kevin
        this.login = null;
        this.password = null;
        break;
      case LoginMode.Gmobile:
        // TODO This may change base don clarification from Kevin
        // this.login = null;
        // this.password = null;
        this.stopCode = null;
        this.lockerNum = null;
        this.lastName = null;
        break;
      }
  }
}

export enum LoginMode {
  Gtrak,
  Groute,
  Gmobile,
  GWearer
}