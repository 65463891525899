import { Data } from "@angular/router";
import { Model, ModelId } from "../../../common/rest-api/models/model";

export class RouteLogModel extends Model
{
  get modelId(): ModelId {
    throw new Error("Method not implemented.");
  }

    routeCode?: string = null;
    stopCode?: string = null;
    logType: number = null;
    odometer?: number = null;
    driverCode?: string = null;
    truckCode?: string = null;
    logDate?: Date = null;
}

export class IRouteLogModel
{
    routeCode?: string = null;
    stopCode?: string = null;
    logType: number = null;
    odometer?: number = null;
    driverCode?: string = null;
    truckCode?: string = null;
    comment?: string = null;
    time?: Date = null;
}