import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class RequestHttpInterceptor implements HttpInterceptor {

    constructor() {
    
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var updatedRequest = request;
        const token = localStorage.getItem('auth_token');

        if (token) {
            updatedRequest = request.clone({
                headers: request.headers
                    .set('Authorization', `Bearer ${token}`)
            });
        }

        return next.handle(updatedRequest);
    }

}
