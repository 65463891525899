import { Data } from "@angular/router";
import { Model, ModelId } from "../../../common/rest-api/models/model";
import { StringUnitLength } from "luxon";

export class CustomerProductsSchedule extends Model {
  get modelId(): ModelId {
    throw new Error("Method not implemented.");
  }

  schId: number;
  customerCode: string;
  departmentCode: string;
  employeeCode: string;
  productCode: string;
  stopCode: string;
  frequency: string;
  quota: number;
  productType: number;
  alrPct: number;
  weightFactor: number;
  delGrade: string;
  isRouteCnt: string;
  isSoilCnt: string;
  isCleanCnt: string;
}
