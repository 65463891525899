import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { QueryResult } from '../../../common/rest-api/models/query-result';
import { RequestParams } from '../../../common/rest-api/models/request-params';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { CustomerEmployee } from '../models/customer-employees.model';
import { TempCustomerEmployee } from '../models/temp-customer-employee.model';

  
@Injectable({
providedIn: 'root',
})
export class GarmentAddWearerService extends RestApiService<CustomerEmployee>{

    protected resourcePath = '/cust-employee';
    protected modelType = CustomerEmployee;

    async AddUser(body: TempCustomerEmployee, stopCode: string, lockerNumber: string, customerCode: string, depCode: string, userID: string | number): Promise<CustomerEmployee> {
        
        const url = this.buildEndpointUrl(`wearer/${stopCode}/${lockerNumber}/${customerCode}/${depCode}/${userID}`);
    
        const response = await this.cloudApiMiddleware.cloudRequest<CustomerEmployee>({
            url,
            body
        }, 'POST');
        
        return response;
    }

    async LockerCheck(lockerNumber: string, stopCode: string, customerCode: string, depCode: string): Promise<string> {
        
        const url = this.buildEndpointUrl(`${stopCode}/${lockerNumber}/${customerCode}/${depCode}`);
    
        const response = await this.cloudApiMiddleware.cloudRequest<string>({
            url,
        }, 'GET');
        
        return response;
    }

}