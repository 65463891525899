import { Data } from "@angular/router";
import { Model, ModelId } from "../../../common/rest-api/models/model";
import { WorkOrder } from "./work-order.model";

export class WorkOrderSection
{

    firstName: string;
    lastName: string;
    employeeCode: string;
    departmentCode: string;
    stop: string;
    stopCode: string;
    workOrders: WorkOrder[];

}