import { Data } from "@angular/router";
import { Model, ModelId } from "../../../common/rest-api/models/model";
import { NumberUnitLength, StringUnitLength } from "luxon";

export class Invoice extends Model {

    get modelId(): ModelId {
        throw new Error("Method not implemented.");
    }


    
    
    public assign(data: Data): void {
        Object.assign(this, data);
    }
}