import { Component, OnInit } from '@angular/core';
import { EmployeeTransferRoutes } from '../../employee-transfer-routes';
import { RequestParams } from '../../../../common/rest-api/models/request-params';//'src/core/shared/rest-api/models/request-params';
import { EmployeeTransferService } from '../../services/employee-transfer.service';
import { EmployeeTransfer } from '../../models/employee-transfer.model';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';
import { Router } from '@angular/router';
import { ToasterService } from '../../../../common/services/toaster.service';

@Component({
  selector: 'app-employee-transfer-page',
  templateUrl: 'employee-transfer.page.html',
  styleUrls: [
    './employee-transfer.page.scss',
  ]
})
export class EmployeeTransferPage implements OnInit{

  customer: string;
  deptNo: string;
  startDate: Date;
  endDate: Date;
  transferType: string;

  rows: any;
  requestParams: any;

  currentPage: number;
  pageSize: number;

  totalElements: number;
  totalPages: number;
  pageNumbers = [];

  transfers: EmployeeTransfer[]; 

  searchProcessed: boolean = false;

  constructor(
    private employeeTransferService: EmployeeTransferService,
    public authService: GtrakAuthService,
    private toasterService: ToasterService,
    private router: Router
  ) { 

  }

  selectDepartmentStatus(ev) {
    this.transferType = ev.detail.value;
  }

  ngOnInit(): void {
    if(!this.authService.user.canViewEmployeeTransfer) {
      this.router.navigate([this.authService.user.homeLocation]);
    }

    if(this.authService.user.isCustomerUser) {
      this.customer = this.authService.user.customerCode;
    }

    this.customer = this.authService.user.customerCode;

    this.setDefaults();

    this.currentPage = 1;
    this.pageSize = 20;

    this.requestParams = new RequestParams();
  }

  setDefaults(): void {
    this.startDate = new Date(new Date().setDate(new Date().getDate() - 30)).toISOString().substring(0, 10) as any;
    this.endDate = new Date().toISOString().substring(0, 10) as any;

    this.transferType = "1";
  }

  async getTransferData(): Promise<void> {
    if (this.authService.user.customerCode?.length > 0 && this.customer !== this.authService.user.customerCode) {
      this.toasterService.presentToast('danger', '', 'Invalid Customer Code', 1600);
      this.customer = this.authService.user.customerCode;
      return;
    }

    this.requestParams.setPaging(this.currentPage, this.pageSize);
    var transfers = await this.employeeTransferService.findTransferReports(this.requestParams);

    this.transfers = transfers.results;

    this.totalElements = transfers.totalRecords;
    this.totalPages = transfers.totalPages;

    this.rows = transfers.results;

    //remove filters
    delete this.requestParams.filters.newCustCode;
    delete this.requestParams.filters.newDeptCode;
    delete this.requestParams.filters.preCustCode;
    delete this.requestParams.filters.preDeptCode;
    delete this.requestParams.filters.dateRange;

    this.pageNumbers = Array(this.totalPages).fill(1).map((x, i) => i)
    this.pageNumbers.push(this.totalPages);

    this.searchProcessed = true;
  }

  getTransfers(): void {
    this.currentPage = 1;
    //check if customer number is entered
    if(!this.customer && this.customer != '') {
      this.toasterService.presentToast('warning', '' , 'Please enter a customer number', 3000);
      return;
    }
    //check if end date and start date are entered
    if(!this.startDate && !this.endDate) {
      this.toasterService.presentToast('warning', '' , 'Please enter a start date and end date', 3000);
      return;
    }
    //check if department number is entered
    // if(!this.deptNo && this.deptNo != '') {
    //   this.toasterService.presentToast('warning', '' , 'Please enter a department number', 3000);
    //   return;
    // }
    //check if transfer type is selected
    if(!this.transferType && this.transferType != '') {
      this.toasterService.presentToast('warning', '' , 'Please select a transfer type', 3000);
      return;
    }
    // type 1 = into department
    // type 2 = out of department
    if(this.transferType == '1') {
      this.requestParams.filters.newCustCode = this.customer;
      if(this.deptNo && this.deptNo != '') {
        this.requestParams.filters.newDeptCode = this.deptNo;
      }
    } else {
      this.requestParams.filters.preCustCode = this.customer;
      if(this.deptNo && this.deptNo != '') {
        this.requestParams.filters.preDeptCode = this.deptNo;
      }
    }

    this.requestParams.filters.dateRange = this.startDate.toString() + ',' +  this.endDate.toString()

    this.getTransferData();
    
  }

  selectPage(number: number): void {
    if(number <= 0) return;

    this.currentPage = number;

    this.requestParams.setPaging(this.currentPage, this.pageSize);

    this.getTransferData();
  }

}