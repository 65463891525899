
    <ion-content class="customer-work-order">
        <ng-container>
            <app-header [title]="'Customer Work Order Report'"></app-header>

            <ion-grid>
                <ion-row class="search-header">
                    <ion-col size="8">
                        <ion-row>
                            <ion-col class="ion-no-padding">
                                <span class="top-label">Employee Search</span>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col class="ion-no-padding">
                                <ion-input placeholder="Search Employee Name" [(ngModel)]="nameSearch"></ion-input>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                    <ion-col size="4">
                        <ion-row>
                            <ion-col class="ion-no-padding">
                                <span class="top-label">Time Range</span>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col class="ion-no-padding">
                                <ion-select interface="popover" (ionChange)="timeRangeChange($event)" [(ngModel)]="timeSearch">
                                    <ion-select-option [value]="1">
                                        2 Weeks
                                    </ion-select-option>
                                    <ion-select-option [value]="2">
                                        1 Year
                                    </ion-select-option>
                                    <ion-select-option [value]="3">
                                        2 Year
                                    </ion-select-option>
                                </ion-select>
                            </ion-col>
                        </ion-row>
                    </ion-col>
                </ion-row>
                <ion-row>
                    <ion-grid class="inner-grid">
                        <ion-row>
                            <ion-col>Cust: &nbsp;&nbsp;{{customerId}}</ion-col>
                            <ion-col size="auto">
                                <button class="printer-button" (click)="changePrinterView()">{{viewChange === true ? 'Regular Version' : 'Printer Friendly Version'}}</button>
                            </ion-col>
                            <ion-col size="auto"><b>Report Date: </b> {{currentDate | date: 'shortDate'}}</ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-row>
                <ion-row *ngFor="let report of getReports()" class="page-break-test">
                    <ion-col>
                        <ion-grid>
                            <ion-row>
                                <ion-col class="small-text"><b>{{report.lastName}}, {{report.firstName}}</b>&nbsp;&nbsp;<span>{{report.departmentCode}}-{{report.employeeCode}}</span></ion-col>
                                <ion-col class="small-text"><b>Stop:</b>&nbsp;&nbsp;<span>{{report.stop}}</span></ion-col>
                                <ion-col class="small-text"><b>Garment ID Label: </b>&nbsp;&nbsp;<span>{{report.stopCode}} {{report.employeeCode}}</span></ion-col>
                            </ion-row>
                            <ion-row>
                                <ion-col>
                                    <app-work-order-table [rows]="report.workOrders" *ngIf="!viewChange"></app-work-order-table>
                                    <app-work-order-table-print class="print-styles" [rows]="report.workOrders" *ngIf="viewChange"></app-work-order-table-print>
                                </ion-col>
                            </ion-row>
                        </ion-grid>
                    </ion-col>
                </ion-row>
                <ion-row class="no-work-orders" *ngIf="!loaded">
                    <ion-col>
                        <ion-spinner></ion-spinner>
                    </ion-col>
                </ion-row>
                <ion-row class="no-work-orders" *ngIf="loaded && (getReports().length <= 0)">
                    <ion-col>
                        No Work Orders
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ng-container>
    </ion-content>

