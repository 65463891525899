import { Component, Input, OnInit } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';
import { RequestParams } from '../../../../common/rest-api/models/request-params';
import { GRouteStopService } from '../../services/g-route-stops.service';
import { RouteStopModel } from '../../models/route-stop.model';
import { IRouteLogModel, RouteLogModel } from '../../models/route-log.model';
import { GRouteLogService } from '../../services/g-route-log.service';
import { LogType } from '../route-page/route.page';
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';
import { ModalController } from '@ionic/angular';
import { ToasterService } from '../../../../../app/common/services/toaster.service';
import { GRouteWorkOrdersService } from '../../services/route-work-order.service';
import { CancelOrderService } from '../../../../../app/features/cancel-order/services/cancel-order.service';
import { RouteDeliveryDetailsService } from '../../services/route-delivery-details.service';
import { GRouteService } from '../../services/g-route.service';

@Component({
  selector: 'app-stop-info-menu-page',
  templateUrl: 'stop-info-menu.page.html',
  styleUrls: [
     './stop-info-menu.page.scss',
     '../../styles/g-route.styles.scss'
  ]
})
export class StopInfoMenuPage implements ViewWillEnter, OnInit {

    logType = LogType
    lastEvent: LogType = null;

    stopLogs: RouteLogModel[] = [];

    currentRoute: string;
    stopName: string;
    routeStop: RouteStopModel;

    options = [
        {name: 'Check In', time: '--:--', check: true, type: this.logType.CHECK_IN, isLocked: false},
        {name: 'Contacts', path: 'contacts'},
//      {name: 'Upsell Opp.', path: 'upsell-opp'},
        {name: 'Non-Garments', path: 'non-garments'},
        {name: 'Invoices', path: 'invoice'},
        {name: 'Check Out', time: '--:--', check: true, comment: true, type: this.logType.CHECK_OUT, isLocked: false}
    ];

    checkInOutOptions = [

    ]

    garmentOptions = [
        {name: 'Garment: New Deliveries', path: 'new-deliveries'},
        {name: 'Garment: Cancel Report', path: 'cancel-report'},
        {name: 'Garment: Pick Up', path: 'pick-up'},
        {name: 'Garment: Lookup', path: 'lookup'},
        {name: 'Garment: Repair', path: 'repair'},
        {name: 'Garment: Employee Lookup', path: 'employee-lookup'},
        {name: 'Garment: Add Wearer', path: 'add-wearer'}
    ]

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: GtrakAuthService,
        private stopService: GRouteStopService,
        private routeLogService: GRouteLogService,
        private modalController: ModalController,
        private toasterService: ToasterService,
        private routeWorkOrdersService: GRouteWorkOrdersService,
        private cancelOrderService: CancelOrderService,
        private routeDeliveryDetailsService: RouteDeliveryDetailsService,
        private gRouteService: GRouteService
    ) { 
        this.currentRoute = null;
    }

    async ngOnInit(): Promise<void> {
        if(!this.authService.user.canViewDriverRoutes) {
            this.router.navigate([this.authService.user.homeLocation]);
        }

        this.currentRoute = this.router.url;
        this.stopName = this.route.snapshot.paramMap.get('id');

        const stop = await this.stopService.getStopByStopCode(this.stopName);
        console.log(stop); 
        this.routeStop = stop as any;
        localStorage.setItem('routeStopName', this.routeStop?.description);

        const gRoute = await this.gRouteService.get(
            this.authService.user.routeCode
        );
      
        const frequencyDate = gRoute.frequencyOffset(
            this.authService.user.routeDate
        );

        const promiseData = await Promise.all([
            this.routeWorkOrdersService.getNewDeliveryNumber(this.stopName, this.authService.user.routeDate),
            this.cancelOrderService.getNumberForStop(this.stopName),
            this.routeDeliveryDetailsService.GetNumberOfPickups(this.stopName, this.authService.user.routeCode, this.authService.user.routeDate, frequencyDate)
        ]);

        const newDeliveries = promiseData[0];
        const cancelOrders = promiseData[1];
        const nonGarment = promiseData[2];

        this.garmentOptions[0].name = this.garmentOptions[0].name + ` (${newDeliveries ?? 0})`;
        this.garmentOptions[1].name = this.garmentOptions[1].name + ` (${cancelOrders ?? 0})`;
        this.options[2].name = this.options[2].name + ` (${nonGarment ?? 0})`;
    }

    async ionViewWillEnter(): Promise<void> {
        this.stopLogs = await this.routeLogService.getStopLogs(this.authService.user.routeCode, this.stopName);

        if (this.stopLogs.length > 0) {
            this.stopLogs.forEach((log: RouteLogModel) => {
                const element = this.options.find((option) => option.type === log.logType);
                element.time = this.getTimeFromDate(log.logDate);
                element.isLocked = true;
            });
        }
    }

    navigate(item: any): void {
        if(item.path){
            this.router.navigate([this.currentRoute + '/' + item.path]);
        }
    }

    async setTime(section: any): Promise<void> {
        //if section type is check out redirect to checkout page
        if(section.type === this.logType.CHECK_OUT) {
            this.router.navigate([this.currentRoute + '/check-out']);
            return;
        }
        //Add a pop up to confirm 
        if (!section.isLocked) {
            const modal = this.modalController.create({
                component: ConfirmationPopupComponent,
                cssClass: "min-width-modal",
                showBackdrop: true,
                componentProps: {
                    stop: this.stopName,
                    event: (section.type === this.logType.CHECK_IN) ? 'check in' : 'check out'
                },
                backdropDismiss: true,
                keyboardClose: true,
                swipeToClose: true,
            });
    
            (await modal).present();
            const {data} = await (await modal).onDidDismiss();
    
            if (data) {
    
                const log: IRouteLogModel = {
                    logType: section.type,
                    routeCode: this.authService.user.routeCode,
                    stopCode: this.stopName,
                    odometer: 0,
                    time: new Date(),
                    driverCode: (this.authService.user.id as string)
                };
        
                const response = await this.routeLogService.createRouteLog(log);
        
                this.lastEvent = section.type;
        
                this.options.find(x => x.name === section.name).time = this.getCurrentTime();
                
                section.isLocked = true;
            }
        } else {
            this.toasterService.presentToast('warning', '', `You are already ${(section.type === this.logType.CHECK_IN) ? 'checked in' : 'checked out'} of this stop.`, 1500);
        }    
    }

    getCurrentTime(): string {
        var displayHours: string;
        var displayMinutes: string;
        var hours = new Date().getHours();
        var minutes = new Date().getMinutes();

        if(hours < 10)
        {
            displayHours = '0' + hours;
        } else {
            displayHours = String(hours);
        }

        if(minutes < 10)
        {
            displayMinutes = '0' + minutes;
        } else {
            displayMinutes = String(minutes);
        }

        return displayHours + ':' + displayMinutes;
    }

    getTimeFromDate(date: Date): string {
        var displayHours: string;
        var displayMinutes: string;
        var hours = new Date(date.toString() + 'Z').getHours();
        var minutes = new Date(date.toString() + 'Z').getMinutes();

        if(hours < 10)
        {
            displayHours = '0' + hours;
        } else {
            displayHours = String(hours);
        }

        if(minutes < 10)
        {
            displayMinutes = '0' + minutes;
        } else {
            displayMinutes = String(minutes);
        }

        return displayHours + ':' + displayMinutes;
    }

}