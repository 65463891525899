import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GRouteService } from '../../../../services/g-route.service';
import { RouteStopModel } from '../../../../../../features/g-route/models/route-stop.model';
import { GtrakAuthService } from '../../../../../authentication/services/gtrak-auth.service';
import { GRouteLogService } from '../../../../../g-route/services/g-route-log.service';
import { ToasterService } from '../../../../../../common/services/toaster.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-route-end-confirm-popup',
  templateUrl: 'route-end-confirm-popup.component.html',
  styleUrls: ['route-end-confirm-popup.component.scss'],
})
export class RouteEndConfirmPopupComponent implements OnInit {
  @Input() routeList: RouteStopModel[];

  selectedRoutes: RouteStopModel[] = [];

  sendingEmail: boolean = false;
  stopName: string;

  constructor(
    private modalController: ModalController,
    private routeService: GRouteService,
    private authService: GtrakAuthService,
    private routeLogService: GRouteLogService,
    private toasterService: ToasterService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.stopName = this.authService.user.routeCode;
  }

  onRouteSelected(event: any, route: RouteStopModel) {
    if (event.detail.checked) {
      this.selectedRoutes.push(route);
    } else {
      const index = this.selectedRoutes.indexOf(route);
      if (index > -1) {
        this.selectedRoutes.splice(index, 1);
      }
    }
  }

  async onEndRoute() {
    //use the selected routes to send out emails
    console.log(this.selectedRoutes);

    if (this.selectedRoutes.length <= 0) {
      this.toasterService.presentToast(
        'danger',
        '',
        'No stops selected, no emails will be sent',
        2000
      );
      this.modalController.dismiss();
    }

    this.sendingEmail = true;

    //for each item in selectedRoutes, send out an email
    for (const route of this.selectedRoutes) {
      const user = this.authService.user?.id;
      const logDate = this.authService.user?.routeDate;
      const results = await this.routeLogService.scheduleReportEmail(
        route.stopCode,
        user,
        true,
        logDate,
        true
      );
    }

    this.sendingEmail = false;

    this.toasterService.presentToast(
      'primary',
      '',
      'Emails and comments have been scheduled successfully',
      2000
    );

    this.modalController.dismiss();
  }

  onCancel() {
    this.modalController.dismiss({
      canceled: true,
    });
  }
}
