import { Model, ModelId } from "../../../common/rest-api/models/model";

export class WeeklyHistoryWeek extends Model {

    get modelId(): ModelId {
        throw new Error("Method not implemented.");
    }

    week: Date;
    shirtQty: number;
    pantsQty: number;
}