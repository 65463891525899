import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DepartmentKey } from '../../../../app/common/models/department-key-model';
import { EmployeeKey } from '../../../../app/common/models/employee-key.model';
import { QueryResult } from '../../../common/rest-api/models/query-result';
import { RequestParams } from '../../../common/rest-api/models/request-params';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { GarmentModel } from '../models/garment.model';


@Injectable({
  providedIn: 'root',
})
export class GarmentService extends RestApiService<GarmentModel> {

  protected resourcePath = '/garment';
  protected modelType = GarmentModel;

  async getGarmentByGarmentCode(garmentCode: string, params?: RequestParams): Promise<GarmentModel> {
    const url = this.buildEndpointUrl(`${garmentCode}`, params);

    const response = GarmentModel.from(await this.cloudApiMiddleware.cloudRequest<QueryResult<GarmentModel>>({
      url
    }, 'GET'));

    return response;
  }
}