<ion-content class="add-user">
    <ng-container>
        <app-header [title]="'Add User'"></app-header>
        <ion-grid>
            <ion-row class="user-form">
                <ion-grid>
                    <ion-row>
                        <ion-col>
                            <ion-label>Login</ion-label>
                            <div>
                                <ion-input type="text" [(ngModel)]="login" placeholder="Enter login" class="ion-text-left color-light"></ion-input>
                            </div>
                        </ion-col>
                        <ion-col>
                            <ion-label>Password</ion-label>
                            <div>
                                <ion-input type="text" [(ngModel)]="password" placeholder="Enter password" class="ion-text-left color-light"></ion-input>
                            </div>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-label>First Name</ion-label>
                            <div>
                                <ion-input type="text" [(ngModel)]="firstName" placeholder="Enter first name" class="ion-text-left color-light"></ion-input>
                            </div>
                        </ion-col>
                        <ion-col>
                            <ion-label>Last Name</ion-label>
                            <div>
                                <ion-input type="text" [(ngModel)]="lastName" placeholder="Enter last name" class="ion-text-left color-light"></ion-input>
                            </div>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-label><span>User Status:</span></ion-label>
                            <ion-select interface="popover" placeholder="Select Active or Disabled" value="Active" (ionChange)="selectUserStatus($event)">
                                <ion-select-option value="Active">Active</ion-select-option>
                                <ion-select-option value="Disabled">Disabled</ion-select-option>
                            </ion-select>
                        </ion-col>
                        <ion-col>
                            <ion-label><span>User Type</span></ion-label>
                            <ion-select interface="popover" placeholder="Select User Type" value="User" (ionChange)="selectUserType($event)">
                                <ion-select-option value="User">User</ion-select-option>
                                <ion-select-option value="Admin">Admin</ion-select-option>
                                <ion-select-option value="Internal User">Internal User</ion-select-option>
                                <ion-select-option value="Route Driver">Route Driver</ion-select-option>
                            </ion-select>
                        </ion-col>
                    </ion-row>
                    <ion-row class="checkbox-row">
                        <ion-col>
                            <ion-item no-lines>
                                <ion-checkbox [(ngModel)]="placeOrders" slot="start"></ion-checkbox>
                                <ion-label>Place Orders</ion-label>
                            </ion-item>
                        </ion-col>
                        <ion-col>
                            <ion-item no-lines>
                                <ion-checkbox [(ngModel)]="viewInvoices" slot="start"></ion-checkbox>
                                <ion-label>View Invoices</ion-label>
                            </ion-item>
                        </ion-col>
                        <ion-col>
                            <ion-item no-lines>
                                <ion-checkbox [(ngModel)]="viewTransfers" slot="start"></ion-checkbox>
                                <ion-label>View Employee Transfers</ion-label>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                    <ion-row class="checkbox-row">
                        <ion-col>
                            <ion-item no-lines>
                                <ion-checkbox [(ngModel)]="customerCancelReport" slot="start"></ion-checkbox>
                                <ion-label>Customer Cancel Report</ion-label>
                            </ion-item>
                        </ion-col>
                        <ion-col>
                            <ion-item no-lines>
                                <ion-checkbox [(ngModel)]="garmentReturnReview" slot="start"></ion-checkbox>
                                <ion-label>Garment Return Review</ion-label>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-label>Customer Code</ion-label>
                            <div>
                                <ion-input type="text" [(ngModel)]="customer" placeholder="Enter customer code" class="ion-text-left color-light"></ion-input>
                            </div>
                        </ion-col>
                        <ion-col>
                            <ion-label>Department Code</ion-label>
                            <div>
                                <ion-input type="text" [(ngModel)]="department" placeholder="Enter department code" class="ion-text-left color-light"></ion-input>
                            </div>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col></ion-col>
                        <ion-col size="auto">
                            <ion-button color="gallaghergreen" class="btn-box-shadow btn-margin" (click)="AddNewUser()"><span>Submit</span></ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-row>

        </ion-grid>
    </ng-container>
</ion-content>