import { Model, ModelId } from "../../../common/rest-api/models/model";

export class CustomerCostCenters extends Model {
    get modelId(): ModelId {
        throw new Error("Method not implemented.");
    }

    customerCode: string = '';
    costCenterCode: string = '';
    costCenterName: string = '';
    activeCode: string = '';

}