import { Claims } from "./tokens";

export type ApiUserConstructor<T> = {
  new (claims: Claims): T;
}

export abstract class ApiUser {
  claims: Claims;
  
  id: string | number;
  
  role: string;

  constructor(claims: Claims)
  {
    this.claims = claims;
    this.id = claims.sub;
    this.role = claims.role;
  }

  static fromType<T extends ApiUser>(type: ApiUserConstructor<T>, claims?: Claims): T {
    return new type(claims);
  }

  static from<T extends ApiUser>(this: ApiUserConstructor<T>,claims?: Claims): T {
    return new this(claims);
  }
}