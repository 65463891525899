<ion-content class="upsell-results">
    <ng-container>
        <ion-grid>
            <ion-row class="title-row">
                <ion-col>
                    <app-header [title]="'Upsell Results'"></app-header>
                </ion-col>
            </ion-row>
            
        </ion-grid>
    </ng-container>
</ion-content>