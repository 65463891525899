import { Component, OnInit } from "@angular/core";
import { ViewWillEnter } from "@ionic/angular";
import { Router } from "@angular/router";
import { EmployeeListService } from "../../../../common/services/employee-listing.service"; ///app/common/services/employee-list.service';
import { RequestParams } from "../../../../common/rest-api/models/request-params"; //'src/core/shared/rest-api/models/request-params';
import { GtrakAuthService } from "../../../../../app/features/authentication/services/gtrak-auth.service";
import { WebLoginDept } from "../../../account-management/models/web-login-dept.model";
import { ToasterService } from "../../../../common/services/toaster.service";
import { AccountManagementService } from "../../../account-management/services/account-management.service";

const testData = require("../../../../common/test-data/report-data.json");

@Component({
  selector: "app-work-order-page",
  templateUrl: "work-order.page.html",
  styleUrls: ["./work-order.page.scss"],
})
export class WorkOrderPage implements OnInit, ViewWillEnter {
  lastName: string = "";

  public rows: any;

  currentRoute: string;

  totalPages: number = 0;
  totalElements: number = 0;

  requestParams: any;

  currPage: number;
  pageSize: number;

  loginDepts: WebLoginDept[];
  selectedCostCenter: string;

  customerCode: string;
  departmentCode: string;

  constructor(
    private router: Router,
    public authService: GtrakAuthService,
    private employeeListService: EmployeeListService,
    private toasterService: ToasterService,
    private accountManagementService: AccountManagementService
  ) {
    this.currentRoute = null;
  }

  async ngOnInit(): Promise<void> {
    //if cost center user get all the cost centers associated with that user
    this.accountManagementService
      .GetCostCenters(this.authService.user.id)
      .then((res) => {
        this.loginDepts = res;
      });

    this.requestParams = new RequestParams();

    if (!this.authService.user.canViewWorkOrders) {
      this.router.navigate([this.authService.user.homeLocation]);
    }

    if (this.authService.user.isWearer) {
      const wearerRoute = `/work-order/employee/${this.authService.user.employeeCode}/${this.authService.user.customerCode}/${this.authService.user.departmentCode}`;
      this.router.navigate([wearerRoute]);
    }

    this.customerCode = this.authService.user.customerCode;

    if (this.authService.user.isCustomerUser) {
      this.requestParams.filters.CustomerCode =
        this.authService.user.customerCode;
    } else if (this.authService.user.isEmployeeUser) {
      this.requestParams.filters.EmployeeCode =
        this.authService.user.employeeCode;
    }

    // For the listing view, we want to exclude any inactive employees.
    this.requestParams.filters.ExcludeInactive = true;
    this.requestParams.setOrdering("LastName", "asc");

    this.rows = [];
    this.currentRoute = this.router.url;

    this.currPage = 1;
    this.pageSize = 20;

    this.getCustomers();
  }

  ionViewWillEnter(): void {
    this.requestParams.setPaging(this.currPage, this.pageSize);
  }

  async getCustomers(): Promise<void> {
    this.requestParams.setPaging(this.currPage, this.pageSize);

    var employees = await this.employeeListService.query(this.requestParams);

    this.totalElements = employees.totalRecords;
    this.totalPages = employees.totalPages;

    this.rows = employees.results;
  }

  getDataByLastName(): void {
    if (this.requestParams.filters.LastName)
      delete this.requestParams.filters.LastName;

    this.currPage = 1;

    this.requestParams.filters.LastName = this.lastName;

    this.requestParams.setOrdering("LastName", "asc");

    this.getCustomers();
  }

  getDataByCustomerAndDepartmentCode(): void {
    if (this.authService.user.customerCode?.length > 0 && this.customerCode !== this.authService.user.customerCode) {
      this.toasterService.presentToast('danger', '', 'Invalid Customer Code', 1600);
      this.customerCode = this.authService.user.customerCode;
      return;
    }

    if (this.requestParams.filters.CustomerCode)
      delete this.requestParams.filters.CustomerCode;
    if (this.requestParams.filters.DepartmentCode)
      delete this.requestParams.filters.DepartmentCode;

    this.currPage = 1;

    //set the filters
    //if just customer code
    if (this.customerCode && !this.departmentCode) {
      this.requestParams.filters.CustomerCode = this.customerCode;
    } else if (!this.customerCode && this.departmentCode) {
      this.requestParams.filters.DepartmentCode = this.departmentCode;
    } else if (this.customerCode && this.departmentCode) {
      this.requestParams.filters.CustomerCode = this.customerCode;
      this.requestParams.filters.DepartmentCode = this.departmentCode;
    }

    this.requestParams.setOrdering("LastName", "asc");

    this.getCustomers();
  }

  getDataCostCenter(): void {
    //check if last name has both numbers and letters
    //if it does, then throw error and return
    //else, start search
    //regex to check if string has both numbers and letters
    const regex = /([a-zA-Z]+[0-9]|[0-9]+[a-zA-Z])[a-zA-Z0-9]*/;
    if (regex.test(this.lastName)) {
      this.toasterService.presentToast(
        "warning",
        "",
        "Please enter either a last name or stop code",
        3000
      );
      return;
    } else {
      //if last name is a number search by stop code
      if (!isNaN(Number(this.lastName))) {
        //always delete the filters first
        if (this.requestParams.filters.LastName)
          delete this.requestParams.filters.LastName;
        if (this.requestParams.filters.StopCode)
          delete this.requestParams.filters.StopCode;
        if (this.requestParams.filters.CostCenter)
          delete this.requestParams.filters.CostCenter;

        if (!this.lastName && this.selectedCostCenter) {
          this.requestParams.filters.CostCenter = this.selectedCostCenter;
        } else if (this.lastName && !this.selectedCostCenter) {
          this.requestParams.filters.StopCode = this.lastName;
        } else if (this.lastName && this.selectedCostCenter) {
          this.requestParams.filters.LastName = this.lastName;
          this.requestParams.filters.CostCenter = this.selectedCostCenter;
        }

        this.currPage = 1;

        this.requestParams.setOrdering("LastName", "asc");

        this.getCustomers();
      } else {
        //always delete the filters first
        if (this.requestParams.filters.LastName)
          delete this.requestParams.filters.LastName;
        if (this.requestParams.filters.StopCode)
          delete this.requestParams.filters.StopCode;
        if (this.requestParams.filters.CostCenter)
          delete this.requestParams.filters.CostCenter;

        if (!this.lastName && this.selectedCostCenter) {
          this.requestParams.filters.CostCenter = this.selectedCostCenter;
        } else if (this.lastName && !this.selectedCostCenter) {
          this.requestParams.filters.LastName = this.lastName;
        } else if (this.lastName && this.selectedCostCenter) {
          this.requestParams.filters.LastName = this.lastName;
          this.requestParams.filters.CostCenter = this.selectedCostCenter;
        }

        this.currPage = 1;

        this.requestParams.setOrdering("LastName", "asc");

        this.getCustomers();
      }
    }
  }

  setPage(page): void {
    this.currPage = page + 1;

    this.getCustomers();
  }

  changeCostCenter(event): void {
    this.selectedCostCenter = event;
  }
}
