import { Component, Input, OnInit } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { ActivatedRoute, Route, Router } from '@angular/router';



@Component({
  selector: 'app-upsell-opp-page',
  templateUrl: 'upsell-opp.page.html',
  styleUrls: [
     './upsell-opp.page.scss',
     '../../../../styles/g-route.styles.scss'
  ]
})
export class UpsellOppPage implements ViewWillEnter, OnInit {

    currentRoute: string;
    stopName: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
    ) { 
        this.currentRoute = null;
    }

    ngOnInit(): void {
        this.currentRoute = this.router.url;
        this.stopName = this.route.snapshot.paramMap.get('id');

    }

    ionViewWillEnter(): void {
        
    }


}