import { Model, ModelId } from '../../../common/rest-api/models/model';

export class MailQueue extends Model {
  get modelId(): ModelId {
    throw new Error('Method not implemented.');
  }
  seqNum: number | null;
  requestBy: string | null;
  requestDate: Date | null;
  requestSource: string | null;
  routeCode: string | null;
  stopCode: string | null;
  reportName: string | null;
  whereClause: string | null;
  toList: string | null;
  bccList: string | null;
  statusCode: string | null;
  fileName: string | null;
  statusMsg: string | null;
  lastOperationDate: Date | null;
  deliveryDate: Date | null;
  subject: string | null;
  emailComment: string | null;
}
