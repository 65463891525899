import { Component } from "@angular/core";
import { SplashScreen } from "@capacitor/splash-screen";
import { SeoService } from "../core/utils/seo/seo.service";
import { TranslateService } from "@ngx-translate/core";
import { HistoryHelperService } from "../core/utils/history-helper.service";
import { AppMenuItem } from "../core/app-menu/app-menu";
import { GarmentListingRoutes } from "./features/garment-listing/garment-listing.routes";
import { GtrakAuthService } from "./features/authentication/services/gtrak-auth.service";
import { WorkOrderRoutes } from "./features/work-order/work-order-routes";
import { CancelOrderRoutes } from "./features/cancel-order/cancel-order-routes";
import { EmployeeTransferRoutes } from "./features/employee-transfer/employee-transfer-routes";
import { CustomerLookupRoutes } from "./features/customer-lookup/customer-lookup.routes";
import { InvoiceRoutes } from "./features/invoice/invoice.routes";
import { AccountManagementRoutes } from "./features/account-management/account-management.routes";
import { MenuController } from "@ionic/angular";
import { AuthenticationRoutes } from "./features/authentication/authentication.routes";
import { GRouteRoutes } from "./features/g-route/g-route.routes";
import { CustomReportRoutes } from "./features/custom-reports/custom-reports-routes";
import { NavigationEnd, Router } from "@angular/router";
import { GtrakUser } from "./features/authentication/models/gtrak-user";
import { AdminRoutes } from "./features/admin-page/admin-page.routes";
import { filter } from "rxjs/operators";
import { ViewWillEnter } from "@ionic/angular";
import { ChangeDetectorRef } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ToasterService } from "./common/services/toaster.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: [
    "./app.component.scss",
    "../core/side-menu/styles/side-menu.scss",
    "../core/side-menu/styles/side-menu.shell.scss",
    "../core/side-menu/styles/side-menu.responsive.scss",
  ],
})
export class AppComponent implements ViewWillEnter {
  textDir = "ltr";

  //menuSections: AppMenuSection[] = [];

  menuItems: AppMenuItem[] = [];

  sideBarOpen = false;

  user: GtrakUser;

  isMobileRoute: boolean = false;

  private _isMobileSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isMobileObs: Observable<boolean> = this._isMobileSubject.asObservable();

  // Inject HistoryHelperService in the app.components.ts so its available app-wide
  constructor(
    public authService: GtrakAuthService,
    public translate: TranslateService,
    public historyHelper: HistoryHelperService,
    private seoService: SeoService,
    public menu: MenuController,
    public router: Router,
    private cdr: ChangeDetectorRef,
    private toasterService: ToasterService
  ) {
    //this.checkIfNative();
    this.initializeApp();
    this.setLanguage();

    // this.isMobileObs.subscribe(isMobile => this.isMobileRoute = isMobile);

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      filter((event: NavigationEnd) => !event.url.startsWith('/route')) // Check if URL does NOT start with '/route'
    ).subscribe((event: NavigationEnd) => {
      // Perform the redirection only if the user is a driver and the URL doesn't start with '/route'
      if (this.authService.user?.isDriver && !event.url.includes('invoice/details')) {
        this.router.navigate([this.authService.user.homeLocation]);
      }
  
      // Update isMobileRoute based on the current URL
      this.isMobileRoute = (event.url.startsWith('/mobile') || event.url.includes('create-account') || event.url.includes('forgot-password'));
      this._isMobileSubject.next(this.isMobileRoute);
    });
  }

  ionViewWillEnter(): void {

  }

  async initializeApp() {
    try {
      await SplashScreen.hide();
    } catch (err) {}
  
    //Report Section
    //const gtrakReportsSection = new AppMenuSection([], 'Reports');
    this.menuItems.push(
      new AppMenuItem({
        label: GarmentListingRoutes.Feature.title,
        //ionicIcon: 'reader-outline',
        customImg: "./assets/nav-icons/garmentlisting.png",
        route: GarmentListingRoutes.Feature,
        alternativeRoute: () => this.getAlternativeRoute("garment-listing"),
        canAccess: () =>
          this.authService.user?.canViewGarmentListing &&
          !this.authService.user?.isDriver,
        isDriver: () => this.authService.user?.isDriver,
      })
    );
    this.menuItems.push(
      new AppMenuItem({
        label: WorkOrderRoutes.Feature.title,
        customImg: "./assets/nav-icons/workorder.png",
        route: WorkOrderRoutes.Feature,
        alternativeRoute: () => this.getAlternativeRoute("work-order"),
        canAccess: () =>
          this.authService.user?.canViewWorkOrders &&
          !this.authService.user?.isDriver,
        isDriver: () => this.authService.user?.isDriver,
      })
    );
    this.menuItems.push(
      new AppMenuItem({
        label: CancelOrderRoutes.Feature.title,
        customImg: "./assets/nav-icons/cancelworkorder.png",
        route: CancelOrderRoutes.Feature,
        alternativeRoute: () => this.getAlternativeRoute("cancel-order"),
        canAccess: () =>
          this.authService.user?.canViewWorkOrders &&
          !this.authService.user?.isDriver,
        isDriver: () => this.authService.user?.isDriver,
      })
    );
    this.menuItems.push(
      new AppMenuItem({
        label: EmployeeTransferRoutes.Feature.title,
        customImg: "./assets/nav-icons/employeetransfer.png",
        route: EmployeeTransferRoutes.Feature,
        canAccess: () =>
          this.authService.user?.canViewEmployeeTransfer &&
          !this.authService.user?.isDriver,
        isDriver: () => this.authService.user?.isDriver,
      })
    );

    //Customer Section
    //const customerSection = new AppMenuSection([], 'Customer');
    this.menuItems.push(
      new AppMenuItem({
        label: CustomerLookupRoutes.Feature.title,
        customImg: "./assets/nav-icons/customer-lookup.png",
        route: CustomerLookupRoutes.Feature,
        canAccess: () =>
          this.authService.user?.canViewCustomerLookup &&
          !this.authService.user?.isDriver,
        isDriver: () => this.authService.user?.isDriver,
      })
    );
    this.menuItems.push(
      new AppMenuItem({
        label: InvoiceRoutes.Feature.title,
        customImg: "./assets/nav-icons/invoice.png",
        route: InvoiceRoutes.Feature,
        canAccess: () =>
          this.authService.user?.canViewInvoice &&
          !this.authService.user?.isDriver,
        isDriver: () => this.authService.user?.isDriver,
      })
    );

    //G-route section
    //const routeSection = new AppMenuSection([], 'Routing');
    this.menuItems.push(
      new AppMenuItem({
        label: "Route",
        customImg: "./assets/nav-icons/driver-route.png",
        route: GRouteRoutes.Feature,
        canAccess: () =>
          this.authService.user?.canViewDriverRoutes &&
          (this.authService.user?.isDriver || this.authService.user?.isAdmin),
        isDriver: () => this.authService.user?.isDriver,
      })
    );

    //Admin Section
    //const adminSection = new AppMenuSection([], 'Admin');
    this.menuItems.push(
      new AppMenuItem({
        label: AccountManagementRoutes.Feature.title,
        customImg: "./assets/nav-icons/account-management.png",
        route: AccountManagementRoutes.Feature,
        canAccess: () =>
          this.authService.user?.canViewAccountManagement &&
          !this.authService.user?.isDriver,
        isDriver: () => this.authService.user?.isDriver,
      })
    );

    //Admin Section
    //const adminSection = new AppMenuSection([], 'Admin');
    this.menuItems.push(
      new AppMenuItem({
        label: AdminRoutes.Feature.title,
        customImg: "./assets/nav-icons/gear.png",
        route: AdminRoutes.Feature,
        scale: .9,
        canAccess: () =>
          this.authService.user?.canViewAccountManagement &&
          !this.authService.user?.isDriver,
        isDriver: () => this.authService.user?.isDriver,
      })
    );

    this.menuItems.push(
      new AppMenuItem({
        label: 'Custom Report',
        customImg: "./assets/nav-icons/reader-outline.svg",
        route: CustomReportRoutes.Feature,
        scale: .9,
        canAccess: () =>
          this.authService.user?.canViewCustomCancelReport ||
          this.authService.user?.canViewGarmentReturnReview,
        isDriver: () => this.authService.user?.isDriver,
      })
    );

    this.menuItems.push(
      new AppMenuItem({
        label: "Logout",
        ionicIcon: "log-out-outline",
        route: AuthenticationRoutes.Feature,
        canAccess: () => true,
        isDriver: () => this.authService.user?.isDriver,
      })
    );

    // this.menuSections.push(gtrakReportsSection);
    // this.menuSections.push(customerSection);
    // this.menuSections.push(routeSection);
    // this.menuSections.push(adminSection);
  }

  getAlternativeRoute(context: string): any {
    switch (context) {
      case "garment-listing":
        return {
          path: `/garment-listing/employee/${this.authService.user.employeeCode}/${this.authService.user.customerCode}/${this.authService.user.departmentCode}`,
        };
      case "work-order":
        return {
          path: `/work-order/employee/${this.authService.user.employeeCode}/${this.authService.user.customerCode}/${this.authService.user.departmentCode}`,
        };
      case "cancel-order":
        return {
          path: `/cancel-order/employee/${this.authService.user.employeeCode}/${this.authService.user.customerCode}/${this.authService.user.departmentCode}`,
        };
      default:
        return { path: "" };
    }
  }

  async logout(): Promise<void> {
    const user = this.authService.user;
    await this.authService.Logout();

    this.router.navigate([user.homeAuthLocation]);
    this.toasterService.presentToast("primary", "You have been logged out", "Success");
  }

  setLanguage() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang("en");

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use("en");

    // this is to determine the text direction depending on the selected language
    // for the purpose of this example we determine that only arabic and hebrew are RTL.
    // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.textDir = (event.lang === 'ar' || event.lang === 'iw') ? 'rtl' : 'ltr';
    // });
  }

  toggleMenu() {
    this.sideBarOpen = !this.sideBarOpen;
  }
}
