import { AppRoute } from '../../../core/routing/AppRoute';
import { CustomerDetailsPage } from './pages/customer-details-page/customer-details.page';
import { CustomerLookupPage } from './pages/customer-lookup-page/customer-lookup.page';

import { AuthGuard } from '../../common/rest-api/services/auth-guard.service';

// A feature route should be used in the App routing module in most cases and primarily provide
// a base url and module loading functionality.
// The feature route should employ a load children callback that loads the appropriate module
const Feature = new AppRoute({
  segment: 'customer-lookup',
  title: 'Customer Lookup',
  // A component can also be included to be the content, or a separate route with a blank segment
  // can be defined like below to provide a dedicated route instance
  // component: GarmentListingPage,
  loadChildren: () => import('./customer-lookup.module').then(m => m.CustomerLookupModule),
  canActive: [AuthGuard]
});

// "Sub-routes" should be the contentful endpoints for the feature. To provide content at the base
// level of the feature url, simply leave segment blank.
// Provide the parent parameter to be able to derive the fullPath of a route that includes the base
const Report = new AppRoute({
  segment: '',
  title: 'Customer Lookup',
  component: CustomerLookupPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const CustomerCode = new AppRoute({
  segment: 'customer/:code',
  title: 'Customer List',
  component: CustomerDetailsPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const CustomerName = new AppRoute({
  segment: 'customer/:name',
  title: 'Customer List',
  component: CustomerDetailsPage,
  parent: Feature,
  canActive: [AuthGuard]
});

export const CustomerLookupRoutes = {
  Feature: Feature,
  Report: Report,
  CustomerCode: CustomerCode,
  CustomerName: CustomerName,
}