<ion-content class="wo-approval">
  <app-header [title]="'WO Approval'"></app-header>
  <ng-container>
    <ion-row class="filter-row">
      <ion-col>
        <ion-label>From Date:</ion-label>
        <ion-input [(ngModel)]="fromDate" type="date"> </ion-input>
      </ion-col>
      <ion-col>
        <ion-label>To Date:</ion-label>
        <ion-input [(ngModel)]="toDate" type="date"> </ion-input>
      </ion-col>
      <ion-col>
        <div>
          <ion-select
            [(ngModel)]="status"
            placeholder="Status"
            [interfaceOptions]="{size: 'cover'}"
            interface="popover"
          >
            <ion-select-option value="0">All</ion-select-option>
            <ion-select-option value="1">Open Web Order</ion-select-option>
            <ion-select-option value="2"
              >Checked Out Web Order</ion-select-option
            >
            <ion-select-option value="3"
              >Web Order Transferred To Production</ion-select-option
            >
            <ion-select-option value="5"
              >User Canceled Web Order</ion-select-option
            >
            <ion-select-option value="6"
              >Production Canceled Web Order</ion-select-option
            >
            <ion-select-option value="7"
              >Web Admin Canceled Order</ion-select-option
            >
          </ion-select>
          <ion-button
            color="gallaghergreen"
            class="btn-box-shadow"
            (click)="getOrders()"
            ><span>Show</span></ion-button
          >
        </div>
      </ion-col>
    </ion-row>

    <ion-row class="table-placeholder" *ngIf="!loaded">
      <h1>Leave Blank for All and Past 90 Days</h1>
    </ion-row>

    <ion-row class="table-container" *ngIf="loaded">
      <ion-col>
        <ion-row class="top-row">
          <ion-col size="3">
            <ion-label>Order #</ion-label>
          </ion-col>
          <ion-col size="1">
            <ion-label>Date</ion-label>
          </ion-col>
          <ion-col size="2">
            <ion-label>Customer #</ion-label>
          </ion-col>
          <ion-col size="2">
            <ion-label>Department #</ion-label>
          </ion-col>
          <ion-col size="2">
            <ion-label>Employee</ion-label>
          </ion-col>
          <ion-col size="1">
            <ion-label>Order Type</ion-label>
          </ion-col>
          <ion-col size="1">
            <ion-label>State</ion-label>
          </ion-col>
        </ion-row>
        <ion-row class="order-line" *ngFor="let order of orders">
          <ion-col size="3">
            <ion-label
              >{{ order.webOrder.woId }}
              <a class="underscore-click" (click)="OpenViewModal(order)"
                >View</a
              >
              <a
                class="underscore-click"
                *ngIf="order.webOrder.state <= 2"
                (click)="OpenCancelModal(order)"
                >Cancel</a
              ></ion-label
            >
          </ion-col>
          <ion-col size="1">
            <ion-label
              >{{ order.webOrder.enteredDate | date: 'MM/dd/yyyy' }}</ion-label
            >
          </ion-col>
          <ion-col size="2">
            <ion-label>{{ order.webOrder.customerCode }}</ion-label>
          </ion-col>
          <ion-col size="2">
            <ion-label>{{ order.webOrder.departmentCode }}</ion-label>
          </ion-col>
          <ion-col size="2">
            <ion-label
              >{{ order.webOrder.employeeCode }} {{order.webOrder.lastName}},
              {{order.webOrder.firstName}}</ion-label
            >
          </ion-col>
          <ion-col size="1">
            <ion-label>{{ order.webOrder.orderType }}</ion-label>
          </ion-col>
          <ion-col size="1">
            <ion-label>{{ order.webOrder.state }}</ion-label>
          </ion-col>
          <ion-row
            class="detail-row"
            *ngIf="order.webOrder.orderType === 'repGar'"
          >
            <ion-col size="2" *ngIf="order.webOrder.state === 2">
              <ion-label class="transfer-box"
                >Transfer As:
                <ion-checkbox
                  class="transfer-check"
                  (ionChange)="AddOrderToTransfer(order, $event)"
                ></ion-checkbox
              ></ion-label>
              <ion-select
                placeholder="Select Reason"
                interface="popover"
                [interfaceOptions]="{'cssClass': 'wider-popover'}"
                [(ngModel)]="order.selectedTransferReason"
              >
                <ion-select-option (value)="order.transferReason"
                  >{{order.transferReason}}</ion-select-option
                >
                <!--<ion-select-option value="flag">Flag Garment</ion-select-option>-->
              </ion-select>
            </ion-col>
            <ion-col *ngIf="order.webOrder.state !== 2" size="2"></ion-col>
            <ion-col size="2" class="detail-col">
              <ion-label>Garment</ion-label>
            </ion-col>
            <ion-col size="2" class="detail-col">
              <ion-label>Product Code</ion-label>
            </ion-col>
            <ion-col size="2" class="detail-col">
              <ion-label>Description</ion-label>
            </ion-col>
            <ion-col size="2" class="detail-col">
              <ion-label>Reason</ion-label>
            </ion-col>
            <ion-col size="1" class="detail-col">
              <ion-label>Wks As</ion-label>
            </ion-col>
          </ion-row>
          <ng-container *ngIf="order.webOrder.orderType === 'repGar'">
            <ion-row
              style="width: 100%"
              *ngFor="let garment of order.webGarments; let evenRow = even; let oddRow = odd;"
            >
              <ion-col size="2"> </ion-col>
              <ion-col size="2" [class.alternate-row]="evenRow">
                <ion-label>{{garment.garmentCode}}</ion-label>
              </ion-col>
              <ion-col size="2" [class.alternate-row]="evenRow">
                <ion-label>{{garment.productCode}}</ion-label>
              </ion-col>
              <ion-col size="2" [class.alternate-row]="evenRow">
                <ion-label>{{garment.description}}</ion-label>
              </ion-col>
              <ion-col size="2" [class.alternate-row]="evenRow">
                <ion-label>{{garment.reasonCode}}</ion-label>
              </ion-col>
              <ion-col size="1" [class.alternate-row]="evenRow">
                <ion-label>{{garment.weeksAssigned}}</ion-label>
              </ion-col>
            </ion-row>
          </ng-container>
          <ion-row
            class="detail-row"
            *ngIf="order.webOrder.orderType === 'szChg' || order.webOrder.orderType === 'CanItem' || order.webOrder.orderType === 'newItem' || order.webOrder.orderType === 'invChg' || order.webOrder.orderType === 'itemXchg'"
          >
            <ion-col size="2" *ngIf="order.webOrder.state === 2">
              <ion-label class="transfer-box"
                >Transfer As:
                <ion-checkbox
                  class="transfer-check"
                  (ionChange)="AddOrderToTransfer(order, $event)"
                ></ion-checkbox
              ></ion-label>
              <ion-select
                placeholder="Select Reason"
                interface="popover"
                [interfaceOptions]="{'cssClass': 'wider-popover'}"
                [(ngModel)]="order.selectedTransferReason"
              >
                <ion-select-option (value)="order.transferReason"
                  >{{order.transferReason}}</ion-select-option
                >
              </ion-select>
            </ion-col>
            <ion-col *ngIf="order.webOrder.state !== 2" size="2"></ion-col>
            <ion-col size="2" class="detail-col">
              <ion-label>Item</ion-label>
            </ion-col>
            <ion-col size="2" class="detail-col">
              <ion-label>Product</ion-label>
            </ion-col>
            <ion-col size="2" class="detail-col">
              <ion-label>Description</ion-label>
            </ion-col>
            <ion-col size="1" class="detail-col">
              <ion-label>Qty</ion-label>
            </ion-col>
            <ion-col
              size="2"
              class="detail-col"
              *ngIf="order.webOrder.orderType === 'szChg'"
            >
              <ion-label>Old Size</ion-label>
            </ion-col>
            <ion-col
              size="2"
              class="detail-col"
              *ngIf="order.webOrder.orderType !== 'szChg'"
            >
              <ion-label>Size</ion-label>
            </ion-col>
            <ion-col
              size="1"
              class="detail-col"
              *ngIf="order.webOrder.orderType === 'szChg'"
            >
              <ion-label>New Size</ion-label>
            </ion-col>
          </ion-row>
          <ng-container
            *ngIf="order.webOrder.orderType === 'szChg' || order.webOrder.orderType === 'CanItem' || order.webOrder.orderType === 'newItem' || order.webOrder.orderType === 'invChg' || order.webOrder.orderType === 'itemXchg'"
          >
            <ion-row
              style="width: 100%"
              *ngFor="let orderItem of order.webOrderItems; let evenRow = even; let oddRow = odd;"
            >
              <ion-col size="2"> </ion-col>
              <ion-col size="2" [class.alternate-row]="evenRow">
                <ion-label>{{orderItem.itemId}}</ion-label>
              </ion-col>
              <ion-col size="2" [class.alternate-row]="evenRow">
                <ion-label>{{orderItem.productCode}}</ion-label>
              </ion-col>
              <ion-col size="2" [class.alternate-row]="evenRow">
                <ion-label>{{orderItem.description}}</ion-label>
              </ion-col>
              <ion-col size="1" [class.alternate-row]="evenRow">
                <ion-label>{{orderItem.qty}}</ion-label>
              </ion-col>
              <ion-col
                size="2"
                [class.alternate-row]="evenRow"
                *ngIf="order.webOrder.orderType === 'szChg'"
              >
                <ion-label
                  >{{orderItem.oldSize1}}-{{orderItem.oldSize2}}</ion-label
                >
              </ion-col>
              <ion-col
                size="2"
                [class.alternate-row]="evenRow"
                *ngIf="order.webOrder.orderType !== 'szChg'"
              >
                <ion-label
                  >{{orderItem.newSize1}}-{{orderItem.newSize2}}</ion-label
                >
              </ion-col>
              <ion-col
                size="1"
                [class.alternate-row]="evenRow"
                *ngIf="order.webOrder.orderType === 'szChg'"
              >
                <ion-label
                  >{{orderItem.newSize1}}-{{orderItem.newSize2}}</ion-label
                >
              </ion-col>
            </ion-row>
          </ng-container>
        </ion-row>
      </ion-col>
    </ion-row>
    <ng-container *ngIf="loaded">
      <div class="transfer">
        <ion-button
          *ngIf="isOrderWithState2Present()"
          color="gallaghergreen"
          class="btn-box-shadow"
          (click)="TransferOrders()"
          >Transfer</ion-button
        >
      </div>
    </ng-container>
  </ng-container>
</ion-content>
