import { Component, OnInit } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController, ViewWillEnter, ViewDidLeave, ViewDidEnter } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { GtrakAuthService } from '../../../authentication/services/gtrak-auth.service';
import { EmployeeKey } from '../../../../../app/common/models/employee-key.model';
import { WorkOrderService } from '../../../work-order/services/work-order.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-work-order-page',
  templateUrl: 'work-order.page.html',
  styleUrls: [
    './work-order.page.scss'
  ]
})
export class WorkOrderPage implements ViewWillEnter, OnInit {

  employeeId: string;
  customerId: string;
  departmentId: string;

  report: any;

  public customer: any;

  empKey: EmployeeKey;

  loaded: boolean = false;

  expanded: boolean = false;

  iconTitle: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private workOrderService: WorkOrderService,
    public authService: GtrakAuthService,
    private navController: NavController
  ) { 
  
  }

  async ngOnInit() {
    this.empKey = {
      CustomerCode: this.authService.user.claims.cus_code,
      DepartmentCode: this.authService.user.claims.dep_code,
      EmployeeCode: this.authService.user.claims.emp_code
    };

    await this.getData();
    this.generateExpanded();
  }


  ionViewWillEnter(): void {


  }

  navigateBack(): void {
    this.navController.back();
  }

  async getData() {
    this.loaded = false;
    this.report = await this.workOrderService.getForEmployee(this.empKey);

    this.customer = this.report.employeeListing;

    this.loaded = true;
  }
  
  expandItem(item): void {
    item.expanded = !item.expanded;
  }

  //add a new field to the report object to determine if the item is expanded or not
  generateExpanded() {
    this.report.report.forEach(element => {
      element.expanded = false;
    });
  }

}