import { Component, Input, OnInit } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { ReportTableComponent } from '../../../../common/components/report-table/report-table.component';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Time } from '@angular/common';
import { getTime } from 'date-fns';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';

//Test data for ngxtable
const testData = require('../../../../common/test-data/report-data.json');

@Component({
  selector: 'app-upsell-results-page',
  templateUrl: 'upsell-results.page.html',
  styleUrls: [
     './upsell-results.page.scss',
     '../../styles/g-route.styles.scss'
  ]
})
export class UpsellResultsPage implements ViewWillEnter, OnInit {

    currentRoute: string;


    constructor(
        private router: Router,
        private authService: GtrakAuthService
    ) { 
        this.currentRoute = null;
    }

    ngOnInit(): void {
        if(!this.authService.user.canViewDriverRoutes) {
            this.router.navigate([this.authService.user.homeLocation]);
        }

        this.currentRoute = this.router.url;
        //this.routeData = String(this.router.getCurrentNavigation().extras.state.routeData);
    }

    ionViewWillEnter(): void {
        
    }


}