import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DepartmentKey } from '../../../../app/common/models/department-key-model';
import { EmployeeKey } from '../../../../app/common/models/employee-key.model';
import { QueryResult } from '../../../common/rest-api/models/query-result';
import { RequestParams } from '../../../common/rest-api/models/request-params';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { RouteStopModel } from '../models/route-stop.model';
import { CustomerContactModel } from '../models/customer-contact.model';
import { GtrakAuthService } from '../../authentication/services/gtrak-auth.service';

@Injectable({
  providedIn: 'root',
})
export class GRouteStopService extends RestApiService<RouteStopModel> {

  protected resourcePath = '/route-stop';
  protected modelType = RouteStopModel;

  async getStops(date: string, params?: RequestParams): Promise<QueryResult<RouteStopModel>> {
    const url = this.buildEndpointUrl(`list/${date}`, params);
    const response = await this.cloudApiMiddleware.cloudRequest<QueryResult<RouteStopModel>>({
      url
    }, 'GET');

    response.results = response.results.map(i => RouteStopModel.fromType(this.modelType, i));

    return response;
  }

  async getStopByStopCode(stopCode: string, params?: RequestParams): Promise<QueryResult<RouteStopModel>> {
    const url = this.buildEndpointUrl(`${stopCode}`, params);
    const response = await this.cloudApiMiddleware.cloudRequest<QueryResult<RouteStopModel>>({
      url
    }, 'GET');

    return response;
  }

  async getInvoiceFreeStops(date: string, params?: RequestParams): Promise<RouteStopModel[]> {
    const url = this.buildEndpointUrl(`list-invoice/${date}`, params);
    const response = await this.cloudApiMiddleware.cloudRequest<RouteStopModel[]>({
      url
    }, 'GET');

    return response;
  }

}