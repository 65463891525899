import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

import { Claims, Token } from '../models/tokens';

@Injectable({
  providedIn: 'root',
})
export class TokenService {

  storageKey: string = 'auth_token';

  
  get() {
    return localStorage.getItem(this.storageKey);
  }

  set(token: Token) {
    localStorage.setItem(this.storageKey, token);
  }
  
  constructor() { }
  
}