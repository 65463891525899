import { Component, Input, OnInit, ViewChild, OnChanges, SimpleChanges, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { GarmnetPickupService } from '../../../../services/garment-pickup.service';
import { ToasterService } from '../../../../../../../app/common/services/toaster.service';

@Component({
  selector: 'app-garment-pick-up-page',
  templateUrl: 'garment-pick-up.page.html',
  styleUrls: [
    './garment-pick-up.page.scss',
    '../../../../styles/g-route.styles.scss'
  ]
})
export class GarmentPickUpPage implements OnInit, OnChanges, AfterViewInit {

  currentRoute: string;
  stopName: string;
  showScansList: boolean = false;
  showScanner: boolean = true;
  garmentCodes: string;
  garmentList: any;

  routeStopName: string = "";

  displayedColumns: string[] = ['primaryKey', 'productCode', 'employeeCode'];
  garmentDataSource = new MatTableDataSource<any>([]);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private garmentPickupService: GarmnetPickupService,
    private toasterService: ToasterService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.currentRoute = this.router.url;
    this.stopName = this.route.snapshot.paramMap.get('id');
    this.routeStopName = localStorage.getItem('routeStopName');
    window.dispatchEvent(new Event('resize'));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.garmentList) {
      this.loadPage();
    }
  }

  ngAfterViewInit(): void {
    this.garmentDataSource.sort = this.sort;
    this.garmentDataSource.paginator = this.paginator;
    this.cdr.detectChanges();
  }

  async savePickUp() {
    await this.garmentPickupService.PickUpGarment(this.garmentCodes);
    this.garmentCodes = "";
    this.toasterService.presentToast('primary', '', 'Garment pick up processed successfully');
  }

  scanner() {
    this.showScanner = true;
    this.showScansList = false;
  }

  async showScans(event: any) {
    this.showScansList = true;
    this.showScanner = false;
    const selectedDate = event.detail.value.split('T')[0];
    await this.garmentPickupService.GetGarmentList(selectedDate, this.stopName).then((res) => {
        this.garmentList = res;
    });
    this.loadPage();
  }

  loadPage() {
    this.garmentDataSource.data = this.garmentList;
    this.cdr.detectChanges();
  }

  changePage(event: PageEvent) {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.loadPage();
  }
}
