<ion-content class="employee-transfer">
    <ng-container>
        <app-header [isParentPage]="true" [title]="'Employee Transfers'"></app-header>
        <ion-grid class="container-grid">
            <ion-row class="filter-row">
                <ion-grid>
                    <ion-row>
                        <ion-col>
                            <ion-input [readonly]="authService.user.isCustomerUser" [disabled]="authService.user.isCustomerUser" type="text" [(ngModel)]="customer" placeholder="Enter customer number" class="ion-text-left color-light"></ion-input>
                        </ion-col>
                        <ion-col>
                            <ion-input type="text" [(ngModel)]="deptNo" placeholder="Enter department number" class="ion-text-left color-light"></ion-input>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-input placeholder="From" type="date" [(ngModel)]="startDate" class="ion-text-left color-light"></ion-input>
                        </ion-col>
                        <ion-col>
                            <ion-input placeholder="To" type="date" [(ngModel)]="endDate" class="ion-text-left color-light"></ion-input>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-select interface="popover" placeholder="Select Into or Out of Department" value="1" (ionChange)="selectDepartmentStatus($event)">
                                <ion-select-option value="1">Into Department</ion-select-option>
                                <ion-select-option value="2">Out of Department</ion-select-option>
                            </ion-select>
                        </ion-col>
                        <ion-col>
                            <ion-button (click)="getTransfers()" color="gallaghergreen" class="btn-box-shadow"><span>Show</span></ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-row>
            
            <ion-row *ngIf="(totalPages <= 0) && searchProcessed">
                <ion-col>
                    <div class="no-results">
                        <span>No Results Found</span>
                    </div>
                </ion-col>
            </ion-row>

            <ion-row>
                <ion-col>
                    <div class="table-container page-area" *ngIf="totalPages > 0">
                        <ion-row>
                            <ion-col></ion-col>
                            <ion-col size="auto">
                                <ion-label>Select Page:</ion-label>
                            </ion-col>
                            <ion-col class="selectable" size="auto" (click)="selectPage(1)">&#60;&#60;</ion-col>
                            <ion-col class="selectable" size="auto" (click)="selectPage(currentPage - 1)">&#60;</ion-col>
                            <ion-col class="selectable" size="auto" *ngFor="let number of pageNumbers" 
                                [ngStyle]="{'display': currentPage < 5 ? (number >= currentPage - 5 && number <= 10 && number > 0 && number <= totalPages) ? 'block' : 'none' 
                                    : (number > currentPage - 5 && number <= currentPage + 5 && number > 0 && number <= totalPages) ? 'block' : 'none'}"
                                [ngClass]="{'add-underline': number === currentPage}" (click)="selectPage(number)">
                                {{number}}
                            </ion-col>
                            <ion-col class="selectable" size="auto" (click)="selectPage(currentPage + 1)">&#62;</ion-col>
                            <ion-col class="selectable" size="auto" (click)="selectPage(totalPages)">&#62;&#62;</ion-col>

                            <ion-col></ion-col>
                        </ion-row>
                    </div>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <div class="page-area table-container" *ngIf="totalPages > 0">
                        <ion-grid>
                            <ion-row>
                                <ion-col><b>Transfer From: </b></ion-col>
                                <ion-col><b>To (Changes in <b [ngStyle]="{'color': 'blue'}">Blue</b>): </b></ion-col>
                                <ion-col size="auto"><b>Date:</b></ion-col>
                            </ion-row>
                        </ion-grid>
                    </div>
                </ion-col>
            </ion-row>
            <ion-row *ngFor="let transfer of transfers">
                <div class="table-container">
                    <ion-grid>
                        <ion-row>
                            <ion-col><b>{{transfer.firstName}}, {{transfer.lastName}}</b></ion-col>
                            <ion-col></ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>Dept: {{transfer.preDeptCode}}</ion-col>
                            <ion-col>Dept: <span [ngStyle]="{'color': (transfer.newDeptCode === transfer.preDeptCode) ? 'black' : 'blue'}">{{transfer.newDeptCode}}</span></ion-col>
                            <ion-col class="width-80" size="auto" [ngStyle]="{'opacity': 0}">{{transfer.actionDate | date: 'shortDate'}}</ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>Emp: {{transfer.preEmpCode}}</ion-col>
                            <ion-col>Emp: <span [ngStyle]="{'color': (transfer.newEmpCode === transfer.preEmpCode) ? 'black' : 'blue'}">{{transfer.newEmpCode}}</span></ion-col>
                            <ion-col class="width-80" size="auto">{{transfer.actionDate | date: 'shortDate'}}</ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>Stop: {{transfer.preStopCode}}</ion-col>
                            <ion-col>Stop: <span [ngStyle]="{'color': ((transfer.newStopCode ? transfer.newStopCode : '?') === transfer.preStopCode) ? 'black' : 'blue'}">{{transfer.newStopCode ? transfer.newStopCode : '?'}}</span></ion-col>
                            <ion-col class="width-80" size="auto" [ngStyle]="{'opacity': 0}">{{transfer.actionDate | date: 'shortDate'}}</ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>Locker: {{transfer.preLockerNum}}</ion-col>
                            <ion-col>Locker: <span [ngStyle]="{'color': (transfer.newLockerNum === transfer.preLockerNum) ? 'black' : 'blue'}">{{transfer.newLockerNum}}</span></ion-col>
                            <ion-col class="width-80" size="auto" [ngStyle]="{'opacity': 0}">{{transfer.actionDate | date: 'shortDate'}}</ion-col>
                        </ion-row>
                    </ion-grid>
                </div>
            </ion-row>
        </ion-grid>
    </ng-container>
</ion-content>