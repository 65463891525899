<ion-content class="employee-report">
    <ng-container>
        <app-header [title]="'Employee Report'"></app-header>
        <ion-grid>
            <ion-row *ngIf="doneLoading">
                <ion-col>
                    <ion-grid>
                        <div class="table-container">
                            <ion-row class="cust-info-row">
                                <ion-col size="auto">
                                    <ion-row>
                                        <ion-col>
                                            <button class="printer-button" (click)="changePrinterView()">{{viewChange === true ? 'Regular Version' : 'Printer Friendly Version'}}</button>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                            Customer:&nbsp;{{customer?.customerCode}}&nbsp;&nbsp;<b>{{customer?.customerName}}</b>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                            Department:&nbsp;{{customer?.departmentCode}}&nbsp;&nbsp;<b>{{customer?.departmentName}}</b>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                            Employee: &nbsp;{{customer?.employeeCode}}&nbsp;&nbsp;<b>{{customer?.firstName}} {{customer?.lastName}}</b>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                            Stop: &nbsp;{{customer?.stopCode}}&nbsp;&nbsp;<b>{{customer?.stopName}}</b>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                            Garment ID Label: &nbsp;{{customer?.stopCode}}&nbsp;{{customer?.employeeCode}}
                                        </ion-col>
                                    </ion-row>
                                </ion-col>
                            </ion-row>
                            <ion-row>
                                <ion-col>
                                    <ion-row>
                                        <ion-col class="label-col">
                                            <ion-label><strong>Garment Summary</strong></ion-label>
                                        </ion-col>
                                    </ion-row>           
                                    <app-garment-summary-table [highlightKeys]="garmentCodeProductCodes" [rows]="report.summary" *ngIf="!viewChange"></app-garment-summary-table>
                                    <app-garment-summary-table-print [rows]="report.summary" *ngIf="viewChange"></app-garment-summary-table-print>
                                </ion-col>
                            </ion-row>
                            <!-- <ion-row *ngIf="summaryNotes.length > 0">
                                <ion-col>
                                    <ion-row>
                                        <ion-col class="label-col">
                                            <ion-label>Notes</ion-label>
                                        </ion-col>
                                    </ion-row>   
                                    <app-garment-notes-summary-table [garmentNotes]="garmentSummaryStatusCodes" [rows]="summaryNotes"></app-garment-notes-summary-table>
                                </ion-col>
                            </ion-row> -->
                            <ion-row>
                                <ion-col>
                                    <ion-row>
                                        <ion-col class="label-col">
                                            <ion-label><strong>Garment Details</strong></ion-label>
                                        </ion-col>
                                    </ion-row>   
                                    <app-garment-detail-table [garmentCode]="garmentCode" [rows]="report.details" *ngIf="!viewChange"></app-garment-detail-table>
                                    <app-garment-detail-table-print [rows]="report.details" *ngIf="viewChange"></app-garment-detail-table-print>
                                </ion-col>
                            </ion-row>
                            <!-- <ion-row *ngIf="report.notes.length > 0">
                                <ion-col>
                                    <ion-row>
                                        <ion-col class="label-col">
                                            <ion-label>Notes</ion-label>
                                        </ion-col>
                                    </ion-row>   
                                    <app-garment-notes-table [garmentNotes]="garmentMessageCodes" [rows]="report.notes"></app-garment-notes-table>
                                </ion-col>
                            </ion-row> -->
                        </div>
                    </ion-grid>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ng-container>
</ion-content>