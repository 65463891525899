import { Model, ModelId } from "../../../common/rest-api/models/model";
import { WebLoginDept } from "./web-login-dept.model";
import { WebLogin } from "./web-login.model";

export class WebLoginWithReports extends Model {
  get modelId(): ModelId {
    throw new Error("Method not implemented.");
  }
  webLogin: WebLogin;
  webReports: any[];
  webLoginDept: WebLoginDept[];
}
