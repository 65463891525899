<ion-content>
    <app-top-bar></app-top-bar>
    <div class="main-header">
        <div class="chevron-left" (click)="goBack()">
            <ion-icon name="chevron-back-outline"></ion-icon>
        </div>
        <span>Garment Detail</span>
    </div>
    <ion-grid *ngIf="loaded" class="content">
        <ion-row class="titles">
            <ion-col class="title-row">
                Garment Code
            </ion-col>
            <ion-col class="title-row">
                Product Code
            </ion-col>
            <ion-col>
                Last Delivery
            </ion-col>
        </ion-row>
        <ion-row *ngFor="let garment of garmentDetail" class="info" (click)="expandItem(garment)">
            <ion-col class="info-row garment-code">
                <div *ngIf="!garment.value.expanded" class="add-sign-content">
                    <ion-icon name="add-outline"></ion-icon>
                </div>
                <div *ngIf="garment.value.expanded" class="add-sign-content">
                    <ion-icon name="remove-outline"></ion-icon>
                </div>
                {{garment.value.garmentCode}}
            </ion-col>
            <ion-col class="info-row info-content">
                {{garment.value.productCode}}
            </ion-col>
            <ion-col class="info-content">
                {{garment.value.lastDelivery | date : 'MM/dd/yyyy'}}
            </ion-col>
            <!-- <ion-row [ngClass]="{'expanded-data': garment.value.expanded}">
                <div *ngIf="garment.value.expanded">
                    <ion-row class="expanded-data-row">
                        <h6>Product Description:</h6>{{garment.value.description}}
                    </ion-row>
                    <ion-row class="expanded-data-row">
                        <h6>Size:</h6>{{garment.value.size1}}-{{garment.value.size2}}
                    </ion-row>
                </div>
            </ion-row> -->
            <ion-row class="expanded-parent">
                <div *ngIf="garment.value.expanded" class="expanded-content">
                    <ion-row>
                        <span><span class="bold-text">Product Description: </span> {{garment.value.description}}</span>
                    </ion-row>
                    <ion-row>
                        <span><span class="bold-text">Size: </span> {{garment.value.size1}}-{{garment.value.size2}}</span>
                    </ion-row>
                </div>
            </ion-row>
        </ion-row>
    </ion-grid>
    <div class="spin" *ngIf="!loaded">
        <ion-spinner name="circular"></ion-spinner>
    </div>
</ion-content>
<app-footer></app-footer>