import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomReportService } from '../../../../../../../app/common/services/custom-report.service';
import { EmployeeKey } from '../../../../../../../app/common/models/employee-key.model';
import { CustomerEmployee } from '../../../../../../../app/features/g-route/models/customer-employees.model';

export type GroupedHistory = {
    items: any[];
    name: string;
    cancelOrder: string;
    daysAllowed: string;
    stop: string;
    garmentId: string;
    departmentEmployeeCode: string;
}

@Component({
    selector: 'app-return-report-page',
    templateUrl: 'return-report.page.html',
    styleUrls: [
      './return-report.page.scss',
    ]
})
export class ReturnReportPage implements OnInit {

    employeeKey: EmployeeKey = null;

    employeeCode: string = null;
    departmentCode: string = null;
    customerCode: string = null;

    employee: CustomerEmployee = null;
    department: any = null;
    customer: any = null;
    history: any[] = [];
    groupedHistory: GroupedHistory[] = [];
    // This is in the original code. I am not sure how 56 is decided
    theGap: number = 56;

    currentDate: Date = new Date();

    loaded: boolean = false;

    constructor(
      private router: Router,
      private route: ActivatedRoute,
      private customReportService: CustomReportService
    ) { }

    async ngOnInit() {
        this.history = [];

        if (this.route.snapshot.paramMap.get('eid'))
            this.employeeCode = this.route.snapshot.paramMap.get('eid');
        if (this.route.snapshot.paramMap.get('did'))
            this.departmentCode = this.route.snapshot.paramMap.get('did');
        if (this.route.snapshot.paramMap.get('cid'))
            this.customerCode = this.route.snapshot.paramMap.get('cid');

        this.employeeKey = {
            EmployeeCode: this.employeeCode,
            DepartmentCode: this.departmentCode,
            CustomerCode: this.customerCode
        };

        this.getData();
    }

    async getData(): Promise<void> {
        this.loaded = false;
        const data: any = await this.customReportService.getCancelHistory(this.employeeKey);

        this.customer = data?.customer;
        this.department = data?.department;
        this.employee = data?.employee
        this.history = data.history;
        this.groupedHistory = [];

        this.setGroupedHistory();

        this.loaded = true;
    }

    changePrinterView(): void {
        // Set a delay before opening the print dialog
        setTimeout(() => {
          print();
        }, 200);
    }

    setGroupedHistory(): void {
        const groupedData = [];
        this.history.forEach(data => {
            var h = data.history;
            var garment = data.garmentHistory;
            const existingEntry = groupedData.find(g => g.cancelOrder === h.woCode);

            h.lateReturn = garment != null;

            if (existingEntry) {
                existingEntry.items.push(h);
            } else {
                groupedData.push({
                    items: [h],
                    departmentEmployeeCode: `${h.departmentCode}-${h.employeeCode}`,
                    name: `${h.lastName}, ${h.firstName}`,
                    cancelOrder: h.woCode,
                    daysAllowed: `${this.theGap}`,
                    stop: h.stopName,
                    garmentId: `${h.stopCode} ${h.employeeCode}`
                })
            }
        });

        this.groupedHistory = groupedData.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        });
    }

    getActionType(item: any): string {
        switch (item.actionType) {
            case 'S':
                return 'Charged';
            case 'P':
                return 'Planned Remove';
            case 'A':
                return 'Add';
            case 'D':
                return 'Discard';
            default: 
                return 'Collected';
        }
    }


}