import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SIZE_TO_MEDIA } from '@ionic/core/dist/collection/utils/media';
import { PopoverController } from '@ionic/angular';
import { MenuPopoverComponent } from '../menu-popover/menu-popover.component';
import {
  AppMenuItem,
} from '../../../../core/app-menu/app-menu';
import { WorkOrderRoutes } from "../../../features/work-order/work-order-routes";
import { CancelOrderRoutes } from "../../../features/cancel-order/cancel-order-routes";
import { EmployeeTransferRoutes } from "../../../features/employee-transfer/employee-transfer-routes";
import { CustomerLookupRoutes } from "../../../features/customer-lookup/customer-lookup.routes";
import { InvoiceRoutes } from "../../../features/invoice/invoice.routes";
import { AccountManagementRoutes } from "../../../features/account-management/account-management.routes";
import { GarmentListingRoutes } from "../../../features/garment-listing/garment-listing.routes";
import { AuthenticationRoutes } from "../../../features/authentication/authentication.routes";
import { GRouteRoutes } from "../../../features/g-route/g-route.routes";
import { AdminRoutes } from "../../../features/admin-page/admin-page.routes";
import { GtrakAuthService } from "../../../features/authentication/services/gtrak-auth.service";
import { Subscription, filter } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() title: string;
  @Input() isParentPage: boolean = false;

  subscription: Subscription = null;

  menuItems: AppMenuItem[] = [];

  constructor(
    private router: Router,
    public popoverController: PopoverController,
    public authService: GtrakAuthService,
    public location: Location
  ) {

    location.onUrlChange((url: string, state: any) => {
      // this.handleRouterPath();
    });
  }

  async ngOnInit(): Promise<void> {
    this.handleMobileRoutes();
    this.handleRouterPath();
  }

  goBack(): void {
    this.location.back();
  }

  handleRouterPath(): void {
    const urlTree = this.router.parseUrl(this.location.path());
    var segment = urlTree.root;
  }

  handleMobileRoutes(): void {
    this.menuItems.push(
      new AppMenuItem({
        label: GarmentListingRoutes.Feature.title,
        //ionicIcon: 'reader-outline',
        customImg: './assets/nav-icons/garmentlisting.png',
        route: GarmentListingRoutes.Feature,
        alternativeRoute: () => this.getAlternativeRoute('garment-listing'),
        canAccess: () =>
          this.authService.user?.canViewGarmentListing &&
          !this.authService.user?.isDriver,
        isDriver: () => this.authService.user?.isDriver,
      })
    );
    this.menuItems.push(
      new AppMenuItem({
        label: WorkOrderRoutes.Feature.title,
        customImg: './assets/nav-icons/workorder.png',
        route: WorkOrderRoutes.Feature,
        alternativeRoute: () => this.getAlternativeRoute('work-order'),
        canAccess: () =>
          this.authService.user?.canViewWorkOrders &&
          !this.authService.user?.isDriver,
        isDriver: () => this.authService.user?.isDriver,
      })
    );
    this.menuItems.push(
      new AppMenuItem({
        label: CancelOrderRoutes.Feature.title,
        customImg: './assets/nav-icons/cancelworkorder.png',
        route: CancelOrderRoutes.Feature,
        alternativeRoute: () => this.getAlternativeRoute('cancel-order'),
        canAccess: () =>
          this.authService.user?.canViewWorkOrders &&
          !this.authService.user?.isDriver,
        isDriver: () => this.authService.user?.isDriver,
      })
    );
    this.menuItems.push(
      new AppMenuItem({
        label: EmployeeTransferRoutes.Feature.title,
        customImg: './assets/nav-icons/employeetransfer.png',
        route: EmployeeTransferRoutes.Feature,
        canAccess: () =>
          this.authService.user?.canViewEmployeeTransfer &&
          !this.authService.user?.isDriver,
        isDriver: () => this.authService.user?.isDriver,
      })
    );

    //Customer Section
    //const customerSection = new AppMenuSection([], 'Customer');
    this.menuItems.push(
      new AppMenuItem({
        label: CustomerLookupRoutes.Feature.title,
        customImg: './assets/nav-icons/customer-lookup.png',
        route: CustomerLookupRoutes.Feature,
        canAccess: () =>
          this.authService.user?.canViewCustomerLookup &&
          !this.authService.user?.isDriver,
        isDriver: () => this.authService.user?.isDriver,
      })
    );
    this.menuItems.push(
      new AppMenuItem({
        label: InvoiceRoutes.Feature.title,
        customImg: './assets/nav-icons/invoice.png',
        route: InvoiceRoutes.Feature,
        canAccess: () =>
          this.authService.user?.canViewInvoice &&
          !this.authService.user?.isDriver,
        isDriver: () => this.authService.user?.isDriver,
      })
    );

    //G-route section
    //const routeSection = new AppMenuSection([], 'Routing');
    this.menuItems.push(
      new AppMenuItem({
        label: 'Route',
        customImg: './assets/nav-icons/driver-route.png',
        route: GRouteRoutes.Feature,
        canAccess: () =>
          this.authService.user?.canViewDriverRoutes &&
          (this.authService.user?.isDriver || this.authService.user?.isAdmin),
        isDriver: () => this.authService.user?.isDriver,
      })
    );

    //Admin Section
    //const adminSection = new AppMenuSection([], 'Admin');
    this.menuItems.push(
      new AppMenuItem({
        label: AccountManagementRoutes.Feature.title,
        customImg: './assets/nav-icons/account-management.png',
        route: AccountManagementRoutes.Feature,
        canAccess: () =>
          this.authService.user?.canViewAccountManagement &&
          !this.authService.user?.isDriver,
        isDriver: () => this.authService.user?.isDriver,
      })
    );

    //Admin Section
    //const adminSection = new AppMenuSection([], 'Admin');
    this.menuItems.push(
      new AppMenuItem({
        label: AdminRoutes.Feature.title,
        customImg: './assets/nav-icons/gear.png',
        route: AdminRoutes.Feature,
        scale: 0.9,
        canAccess: () =>
          this.authService.user?.canViewAccountManagement &&
          !this.authService.user?.isDriver,
        isDriver: () => this.authService.user?.isDriver,
      })
    );

    this.menuItems.push(
      new AppMenuItem({
        label: 'Logout',
        ionicIcon: 'log-out-outline',
        route: AuthenticationRoutes.Feature,
        canAccess: () => true,
        isDriver: () => this.authService.user?.isDriver,
      })
    );
  }

  getAlternativeRoute(context: string): any {
    switch (context) {
      case 'garment-listing':
        return {
          path: `/garment-listing/employee/${this.authService.user.employeeCode}/${this.authService.user.customerCode}/${this.authService.user.departmentCode}`,
        };
      case 'work-order':
        return {
          path: `/work-order/employee/${this.authService.user.employeeCode}/${this.authService.user.customerCode}/${this.authService.user.departmentCode}`,
        };
      case 'cancel-order':
        return {
          path: `/cancel-order/employee/${this.authService.user.employeeCode}/${this.authService.user.customerCode}/${this.authService.user.departmentCode}`,
        };
      default:
        return { path: '' };
    }
  }

  toggleMenu() {
    const popover = this.popoverController.create({
      component: MenuPopoverComponent,
      cssClass: 'menu-popover',
      event: event,
      translucent: true,
      componentProps: {
        menuItems: this.menuItems,
      },
    });
    popover.then((p) => p.present());
  }

}
