import { Component, OnInit } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { AccountManagementRoutes } from '../../account-management.routes';
import { Router } from '@angular/router';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';
import { RequestParams } from '../../../../common/rest-api/models/request-params';
import { AccountManagementService } from '../../services/account-management.service';



@Component({
  selector: 'app-account-management-page',
  templateUrl: 'account-management.page.html',
  styleUrls: [
    './account-management.page.scss',
    '../../styles/account-management.styles.scss'
  ]
})
export class AccountManagementPage implements OnInit, ViewWillEnter {

  public rows: any;
  currentRoute: string;

  disabled: number = 0;
  mobile: string = 'All';

  totalPages: number = 0;
  totalElements: number = 0;

  input: string = '';
  searchCriteria: number = 1;
  searchCritieriaString = 'Login ID';

  currPage: number;
  pageSize: number;

  loaded: boolean = false;

  requestParams: any;

  constructor(
    private router: Router,
    private authService: GtrakAuthService,
    private accountService: AccountManagementService,
  ) { 
    this.currentRoute = null;
  }

  ngOnInit(): void {
    this.loaded = false;
    this.requestParams = new RequestParams();

    if(!this.authService.user.canViewAccountManagement) {
      this.router.navigate([this.authService.user.homeLocation]);
    }
    
    this.rows = [];
    this.currentRoute = this.router.url;

    this.currPage = 1;
    this.pageSize = 20;

    this.getAccounts();
    this.loaded = true;
  }

  ionViewWillEnter(): void {

  }

  async getAccounts(): Promise<void> {
    this.requestParams.setPaging(this.currPage, this.pageSize);

    var accounts = await this.accountService.Get(this.requestParams);

    this.totalElements = accounts.totalRecords;
    this.totalPages = accounts.totalPages;

    this.rows = accounts.results;
  }

  selectActiveStatus(ev) {
    if(ev.detail.value === 'Disabled') {
        this.disabled = 1;
    } else {
        this.disabled = 0;
    }
  }

  selectMobileSearch(ev) {
    if(ev.detail.value === 'Mobile') {
      this.mobile = 'USER';
    } else if (ev.detail.value === 'All') {
      this.mobile = 'All';
    } else {
      this.mobile = 'user';
    }
  }

  selectSearchCriteria(ev) {
    if(ev.detail.value == 'Login') {
        this.searchCriteria = 1;
        this.searchCritieriaString = ev.detail.value;
    } else if(ev.detail.value == 'Last Name') {
        this.searchCriteria = 2;
        this.searchCritieriaString = ev.detail.value;
    } else {
        this.searchCriteria = 3;
        this.searchCritieriaString = ev.detail.value;
    }
  }

  search(): void {
    this.loaded = false
    delete this.requestParams.filters.UserType;
    delete this.requestParams.filters.Disabled;
    delete this.requestParams.filters.Login;
    delete this.requestParams.filters.Lname;
    delete this.requestParams.filters.CustomerCode;

    if(this.mobile === 'All') {
      delete this.requestParams.filters.UserType;
    }
    else {
      this.requestParams.filters.UserType = this.mobile;
    }

    this.requestParams.filters.Disabled = this.disabled;

    switch(this.searchCriteria) {
        case 1:
            this.requestParams.filters.Login = this.input;
            break;
        case 2:
            this.requestParams.filters.Lname = this.input;
            break;
        case 3:
            this.requestParams.filters.CustomerCode = this.input;
            break;
    }


    this.getAccounts();
    this.loaded = true;
  }

  setPage(page): void {
    this.currPage = page + 1;

    this.getAccounts();
  }

}