import { Data } from "@angular/router";
import { Model, ModelId } from "../../../common/rest-api/models/model";
import { DateTime } from 'luxon';
import * as moment from 'moment';

export class RouteModel extends Model
{
  get modelId(): ModelId {
    throw new Error("Method not implemented.");
  }

  routeCode: string;
  description: string;
  frequencyType: number;
  frequency: string;
  startTime: Date;
  estimateDistance: number;
  estimatedTime: number;
  branchCode: any;

  // Override default assign on model.ts to convert necessary data
  public assign(data: Data):void {
    super.assign(data);
    // Convert string date from API to date
    this.startTime = new Date(this.startTime);
  }

  frequencyOffset(selectedDate: Date) {
    const startDate = new Date('12/27/1999');

    const startTime = DateTime.fromISO(startDate.toISOString()).startOf('day');
    const currentDate = DateTime.fromISO(selectedDate.toISOString()).startOf('day');

    const diff = currentDate.diff(startTime, ['days']).days;
    const mod28 = diff % 28;

    const week = Math.floor(mod28 / 7) + 1;
    const currentDayOfWeek = selectedDate.getDay();

    // Monday = 1 and Sunday = 7
    const convertedDay = (currentDayOfWeek === 0) ? 7 : currentDayOfWeek;

    const offset = ((week - 1) * 7) + convertedDay;
  
    return offset;
  }

  frequencyDay(selectedDate: Date) {
    const startDate = new Date('12/27/1999');

    const startTime = DateTime.fromISO(startDate.toISOString()).startOf('day');
    const currentDate = DateTime.fromISO(selectedDate.toISOString()).startOf('day');

    const diff = currentDate.diff(startTime, ['days']).days;
    const mod28 = diff % 28;

    const week = Math.floor(mod28 / 7) + 1;
    const currentDayOfWeek = selectedDate.getDay();

    // Monday = 1 and Sunday = 7
    const convertedDay = (currentDayOfWeek === 0) ? 7 : currentDayOfWeek;

    return convertedDay;
  }

  dayOfPeriod(selectedDate: Date) { 
    //let now = moment();
    let firstMonday = moment().year(selectedDate.getFullYear()).month(selectedDate.getMonth()).date(1).day(8);
    if(firstMonday.date() > 7) {
      firstMonday.day(-6);
    }

    const startTime = DateTime.fromISO(firstMonday.toISOString()).startOf('day');
    const currentDate = DateTime.fromISO(selectedDate.toISOString()).startOf('day');

    return (currentDate.diff(startTime, ['days']).days) % 28;
  }
}