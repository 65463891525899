import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GarmentListingRoutes } from './features/garment-listing/garment-listing.routes';
import { WorkOrderRoutes } from './features/work-order/work-order-routes';
import { CancelOrderRoutes } from './features/cancel-order/cancel-order-routes';
import { EmployeeTransferRoutes } from './features/employee-transfer/employee-transfer-routes';
import { CustomerLookupRoutes } from './features/customer-lookup/customer-lookup.routes';
import { InvoiceRoutes } from './features/invoice/invoice.routes';
import { AccountManagementRoutes } from './features/account-management/account-management.routes';
import { GRouteRoutes } from './features/g-route/g-route.routes';
import { MobileHomeRoutes } from './features/mobile/mobile.routes';
import { ViewOrderRoutes } from './features/view-order/view-order.routes';
import { AdminRoutes } from './features/admin-page/admin-page.routes';
import { AuthGuard } from './common/rest-api/services/auth-guard.service';
import { AppModule } from './app.module';
import { CustomReportRoutes } from './features/custom-reports/custom-reports-routes';

const routes: Routes = [
  //Reports
  GarmentListingRoutes.Feature,
  WorkOrderRoutes.Feature,
  CancelOrderRoutes.Feature,
  EmployeeTransferRoutes.Feature,
  AdminRoutes.Feature,
  //Customer
  CustomerLookupRoutes.Feature,
  InvoiceRoutes.Feature,
  //Routing
  GRouteRoutes.Feature,
  //Admin
  AccountManagementRoutes.Feature,
  //Mobile
  MobileHomeRoutes.Feature,
  //View Order
  ViewOrderRoutes.Feature,
  CustomReportRoutes.Feature,

  // TODO [EMP] Replace with a framework auth module
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: 'auth/login',
    loadChildren: () => import('./features/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'auth/wearer',
    loadChildren: () => import('./features/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'auth/mobile',
    loadChildren: () => import('./features/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'auth/route',
    loadChildren: () => import('./features/authentication/authentication.module').then(m => m.AuthenticationModule)
  },


  // TODO [EMP] logout
 
  {
    path: '**',
    redirectTo: '/auth/login'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // This value is required for server-side rendering to work.
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
