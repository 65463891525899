import { Component, OnInit } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { AccountManagementRoutes } from '../../account-management.routes';
import { Router, ActivatedRoute } from '@angular/router';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';
import { WebLogin } from '../../models/web-login.model';
import { AccountManagementService } from '../../services/account-management.service';
import { ToasterService } from '../../../../../app/common/services/toaster.service';

@Component({
  selector: 'app-add-user-page',
  templateUrl: 'add-user.page.html',
  styleUrls: [
    './add-user.page.scss'
  ]
})
export class AddUserPage implements OnInit, ViewWillEnter {

  customerCancelReport: boolean = false;
  garmentReturnReview: boolean = false;

  currentRoute: string;

  login: string;
  password: string;
  firstName: string;
  lastName: string;
  userStatus: number = 0;
  userType: string = 'user';
  placeOrders: boolean;
  viewInvoices: boolean;
  viewTransfers: boolean;
  customer: string;
  department: string;
  response: any;

  statusCodes = {
    0: 'Active',
    1: 'Disabled'
  };

  userTypeCodes = {
    0: 'User',
    1: 'Admin',
    2: 'Internal User',
    4: 'Route Driver'
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: GtrakAuthService,
    private accountService: AccountManagementService,
    private toasterService: ToasterService
  ) { 
    this.currentRoute = null;
  }

  ngOnInit(): void {
    if(!this.authService.user.canViewAccountManagement) {
      this.router.navigate([this.authService.user.homeLocation]);
    }
    
    this.currentRoute = this.router.url;
  }

  ionViewWillEnter(): void {
    this.login = this.route.snapshot.paramMap.get('login');
  }

  selectUserStatus(status): void {
    if(status.detail.value == 'Disabled'){
      this.userStatus = 1;
    } else {
      this.userStatus = 0;
    }
  }

  selectUserType(type): void {
    if(type.detail.value == 'User'){
      this.userType = 'user';
    } else if(type.detail.value == 'Admin'){
      this.userType = 'admin';
    } else if(type.detail.value == 'Route Driver'){
      this.userType = 'driver';
    }
    else {
      this.userType = 'user';
    }
  }

  async AddNewUser(): Promise<void> {
    var webLogin = new WebLogin();
    webLogin.login = this.login;
    webLogin.password = this.password;
    webLogin.fname = this.firstName;
    webLogin.lname = this.lastName;
    webLogin.disabled = this.userStatus.toString();
    webLogin.userType = this.userType;
    if(this.placeOrders == true){
      webLogin.placeOrder = '1';
    } else {
      webLogin.placeOrder = '0';
    }
    if(this.viewInvoices == true){
      webLogin.viewInvoice = '1';
    } else {
      webLogin.viewInvoice = '0';
    }
    if(this.viewTransfers == true){
      webLogin.viewEmpTransfer = '1';
    } else {
      webLogin.viewEmpTransfer = '0';
    }
    webLogin.customerCode = this.customer ?? "";
    webLogin.departmentCode = this.department ?? "";
    webLogin.employeeCode = "";

    try {
      this.response = await this.accountService.AddUser(webLogin)

      // Remove user from cancel report
      if (this.customerCancelReport) {
        await this.accountService.AddReport(this.login, 9);
      }
  
      // Add user from cancel report
      if (this.garmentReturnReview) {
        await this.accountService.AddReport(this.login, 10);
      }

      this.toasterService.presentToast('primary', '', 'User Created successfully.', 1000);
      this.router.navigate(['/account-management']);
    } catch (error) {
      console.log(error);
      this.toasterService.presentToast('danger', '', 'An error occurred while creating user. Please try again.', 1000);
    }
  }

}