import { Data } from "@angular/router";
import { Model, ModelId } from "../../../common/rest-api/models/model";

export class EmployeeTransfer extends Model
{
  get modelId(): ModelId {
    throw new Error("Method not implemented.");
  }

  code: string;
  lastName: string;
  firstName: string;
  preCustCode: string;
  preDeptCode: string;
  preEmpCode: string;
  newCustCode: string;
  newDeptCode: string;
  newEmpCode: string;
  actionDate: string;
  userId: string;
  preStopCode: string;
  preLockerNum: string;
  newStopCode: string;
  newLockerNum: string;
}