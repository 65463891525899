<ion-content>
  <div class="modal-container">
    <ion-row>
      <ion-col size="12">
        <ion-item>
          <ion-label position="floating">Customer Code</ion-label>
          <ion-input type="text" [readonly]="!edit" value="1116" [(ngModel)]="customerCode"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <ion-item>
          <ion-label position="floating">Cost Center Code</ion-label>
          <ion-input type="text" maxlength="10" [readonly]="edit" [(ngModel)]="costCenterCode"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <ion-item>
          <ion-label position="floating">Cost Center Name</ion-label>
          <ion-input type="text" maxlength="100" [(ngModel)]="costCenterName"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <ion-item>
          <ion-label>Status</ion-label>
          <ion-select placeholder="Select One" [(ngModel)]="activeCode">
            <ion-select-option value="1">Active</ion-select-option>
            <ion-select-option value="0">Disabled</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row class="button-row" *ngIf="!edit">
        <ion-col size="12">
            <ion-button color="gallaghergreen" class="btn-box-shadow" (click)="addCostCenter()"><span>Add Cost Center</span></ion-button>
        </ion-col>
    </ion-row>

    <ion-row class="button-row" *ngIf="edit">
        <ion-col size="12">
            <ion-button color="gallaghergreen" class="btn-box-shadow" (click)="updateCostCenter()"><span>Update Cost Center</span></ion-button>
        </ion-col>
    </ion-row>
  </div>
</ion-content>
