import { Injectable } from '@angular/core';
import { RestApiService } from '../rest-api/services/rest-api.service';
import { EmployeeListing } from '../models/employee-listing.model';


@Injectable({
  providedIn: 'root',
})
export class EmployeeListService extends RestApiService<EmployeeListing> {

  protected resourcePath = '/employee-listing';
  protected modelType = EmployeeListing;

}