<app-top-bar></app-top-bar>
<ion-content>
    <div (click)="navigateBack()" class="back-button-mobile">
        <ion-icon name="chevron-back-outline"></ion-icon>
    </div>
    <h1>Account Settings for: <br> {{authService.user.claims.name}}</h1>
    <div class="content">
        <h2>Select Your Alert Settings</h2>
        <h4>Order Request Alerts:</h4>
        <div class="radio-content">
            <div class="radio">
                <input [(ngModel)]="radioSelect" class="radio-button" type="radio" value="Email" id="Email" name="request">
                <label for="Email">Email</label>
            </div>
            <div class="radio">
                <input [(ngModel)]="radioSelect" class="radio-button" type="radio" value="Text" id="Text" name="request">
                <label for="Text">Text</label>
            </div>
            <div class="radio">
                <input [(ngModel)]="radioSelect" class="radio-button" type="radio" value="Both" id="Both" name="request">
                <label for="Both">Both</label>
            </div>
        </div>
        <div class="dev-notif">
            <label class="dev-label" for="dev-notif"><h6>Delivery Notification:</h6>
                <input [(ngModel)]="delText" class="dev-check" type="checkbox" id="dev-notif" name="dev-notif" [value]="false">
            </label>
        </div>
        <div class="phone">
            <label for="phone">Phone Number for Text Alerts:</label>
            <input [(ngModel)]="phoneNumber" type="text" id="phone" name="phone">
        </div>
        <button (click)="submit()">Submit</button>

        <div class="additional-account-settings-button">
            <div (click)="showAdditionalSettings = !showAdditionalSettings">
                <span>{{showAdditionalSettings ? 'Hide' : 'Show'}} Additional Account Settings</span>
            </div>
        </div>

        <div class="delete-account-button-container" *ngIf="showAdditionalSettings">
            <button (click)="deleteAccount()">
                <span>Delete Account</span>
            </button>
        </div>
    </div>
</ion-content>
<app-footer></app-footer>