import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { QueryResult } from '../../../common/rest-api/models/query-result';
import { RequestParams } from '../../../common/rest-api/models/request-params';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { CustomerList } from '../models/customer-list.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerListService extends RestApiService<CustomerList>{
    //generating customer list
    protected resourcePath = '/customer-list';
    protected modelType = CustomerList;

}
