import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { ToasterService } from '../../../../app/common/services/toaster.service';
import { GtrakAuthService } from '../services/gtrak-auth.service';

@Injectable()
export class ResponseHttpInterceptor implements HttpInterceptor {

  constructor(
    private toasterService: ToasterService,
    private navController: NavController,
    private authService: GtrakAuthService
  ) {
    
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          console.log(error);

          switch (error.status) {
            case 401:
              console.log(error);
                if ((error.url.indexOf('auth') === -1) && error.url.indexOf('view-order') === -1) {
                    // Set direction will reset the nav stack
                    this.toasterService.presentToast('danger', 'Please login again.', 'Authentication is invalid or expired.', );
                    this.navController.navigateRoot([this.authService.user.homeAuthLocation]);

                    //remove the JWT from local storage
                    localStorage.clear();
                    
                    this.authService.Logout();
                }
                break;
            case 404:
                this.toasterService.presentToast('danger', '', 'The target is offline or unavailable. Please try again later.', )
                break;
            case 500:
                this.toasterService.presentToast('danger', 'Please try again.', 'Something went wrong.', )
                break;
          }

          return throwError(error);
        }
      )
    );
  }
}
