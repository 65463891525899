
<ion-content class="employee-work-order">
    <ng-container>
        <app-header [title]="'Employee Work Order Report'"></app-header>
        <ion-grid *ngIf="doneLoading">
            <ion-row>
                <ion-grid class="inner-grid">
                    <ion-row>
                        <ion-col class="ion-no-padding">
                            <ion-col size="auto">Cust: &nbsp;&nbsp;{{customerId}}</ion-col>
                            <ion-col size="auto">&nbsp;&nbsp;{{customer.customerName}}</ion-col>
                        </ion-col>
                        <ion-col class="ion-no-padding" size="auto">
                            <button class="printer-button" (click)="changePrinterView()">{{viewChange === true ? 'Regular Version' : 'Printer Friendly Version'}}</button>
                        </ion-col>
                        <ion-col class="ion-no-padding" size="auto"><b>Report Date: </b> {{currentDate | date: 'shortDate'}}</ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col class="ion-no-padding">
                            <ion-col size="auto">Dept: &nbsp;&nbsp;{{departmentId}}</ion-col>
                            <ion-col size="auto">&nbsp;&nbsp;{{customer.departmentName}}</ion-col>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col class="ion-no-padding">
                            <ion-col size="auto">Cust: &nbsp;&nbsp;{{employeeId}}</ion-col>
                            <ion-col size="auto">&nbsp;&nbsp;{{customer.lastName}}, {{customer.firstName}}</ion-col>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-row>
            <ion-row>
                <ion-col>
                    <ion-grid>
                        <ion-row>
                            <ion-col class="small-text"><b>{{customer.lastName}}, {{customer.firstName}}</b>&nbsp;&nbsp;<span>{{customer.departmentCode}}-{{customer.employeeCode}}</span></ion-col>
                            <ion-col class="small-text"><b>Stop:</b>&nbsp;&nbsp;<span>{{customer.stop}}</span></ion-col>
                            <ion-col class="small-text"><b>Garment ID Label: </b>&nbsp;&nbsp;<span>{{customer.stopCode}} {{customer.employeeCode}}</span></ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col>
                                <app-work-order-table [rows]="report.report" *ngIf="!viewChange"></app-work-order-table>
                                <app-work-order-table-print class="print-styles" [rows]="report.report" *ngIf="viewChange"></app-work-order-table-print>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ng-container>
</ion-content>