import { Injectable } from '@angular/core';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service'; 
import { firstValueFrom } from 'rxjs';
import { CustomerCostCenters } from '../models/customer-cost-centers.model';


@Injectable({
  providedIn: 'root',
})
export class CustomerCostCenterService extends RestApiService<CustomerCostCenters> {

  protected resourcePath = '/customer-cost-centers';
  protected modelType = CustomerCostCenters;

  async addCostCenter(costCenter: CustomerCostCenters): Promise<string> {
    const url = this.buildEndpointUrl('');

    const response = await this.cloudApiMiddleware.cloudRequest<string>({
      url,
      body: costCenter
    }, 'POST');
    
    return response;
  }

  async editCostCenter(costCenter: CustomerCostCenters): Promise<string> {
    const url = this.buildEndpointUrl('');

    const response = await this.cloudApiMiddleware.cloudRequest<string>({
      url,
      body: costCenter
    }, 'PUT');
    
    return response;
  }

}