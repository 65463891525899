import { AppRoute } from '../../../core/routing/AppRoute';
import { CancelOrderPage } from './pages/cancel-order-page/cancel-order.page';
import { CustomerCancelOrderPage } from './pages/customer-cancel-order/customer-cancel-order.page';
import { DepartmentCancelOrderPage } from './pages/department-cancel-order/department-cancel-order.page';
import { EmployeeCancelOrderPage } from './pages/employee-cancel-order/employee-cancel-order.page';
import { AuthGuard } from '../../common/rest-api/services/auth-guard.service';

// A feature route should be used in the App routing module in most cases and primarily provide
// a base url and module loading functionality.
// The feature route should employ a load children callback that loads the appropriate module
const Feature = new AppRoute({
  segment: 'cancel-order',
  title: 'Cancel Order Report',
  // A component can also be included to be the content, or a separate route with a blank segment
  // can be defined like below to provide a dedicated route instance
  // component: GarmentListingPage,
  loadChildren: () => import('./cancel-order.module').then(m => m.CancelOrderModule),
  canActive: [AuthGuard]
});

// "Sub-routes" should be the contentful endpoints for the feature. To provide content at the base
// level of the feature url, simply leave segment blank.
// Provide the parent parameter to be able to derive the fullPath of a route that includes the base
const Report = new AppRoute({
  segment: '',
  title: 'Cancel Order Report',
  component: CancelOrderPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const Customer = new AppRoute({
  segment: 'customer/:id',
  title: 'Cancel Order Customer',
  component: CustomerCancelOrderPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const Department = new AppRoute({
  segment: 'department/:id/:cid',
  title: 'Cancel Order Department',
  component: DepartmentCancelOrderPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const Employee = new AppRoute({
  segment: 'employee/:id/:cid/:did',
  title: 'Cancel Order Employee',
  component: EmployeeCancelOrderPage,
  parent: Feature,
  canActive: [AuthGuard]
});


export const CancelOrderRoutes = {
  Feature: Feature,
  Report: Report,
  Customer: Customer,
  Department: Department,
  Employee: Employee
}