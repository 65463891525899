<ion-content>
    <div class="modal-container">
        <ion-row class="header">
            <ion-col>
                <span>Why Register?</span>
            </ion-col>
        </ion-row>
        <div class="content-area">
            <ion-row class="second-header">
                <ion-col>
                    <span>When you register you can:</span>
                </ion-col>
            </ion-row>
            <ion-row class="data">
                <ion-col>
                    <ul>
                        <li>Order a repair or replacement</li>
                        <li>Order a size change</li>
                        <li>Ask for Information!</li>
                        <li>Receive text or email confirmation on:
                            <ul>
                                <li>Order requests</li>
                                <li>Order completion</li>
                                <li>Delivery notice each week</li>
                            </ul>
                        </li>
                    </ul>
                </ion-col>
            </ion-row>
            <ion-row class="bottom-text-container">
                <ion-col>
                    <span class="bottom-text">To register click <span class="register" (click)="registerPage()">here</span></span>
                </ion-col>
            </ion-row>
        </div>
    </div>
</ion-content>