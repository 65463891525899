import { Component, Input, OnInit, ViewChild, OnChanges, SimpleChanges, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { CustomerContactsService } from '../../../../../../../app/features/g-route/services/customer-contacts.service';
import { RequestParams } from '../../../../../../common/rest-api/models/request-params';
import { IStopContact } from './models/stopContact.model';

@Component({
  selector: 'app-contacts-page',
  templateUrl: 'contacts.page.html',
  styleUrls: [
    './contacts.page.scss',
    '../../../../styles/g-route.styles.scss'
  ]
})
export class ContactsPage implements OnInit, OnChanges, AfterViewInit {

  currentRoute: string;
  stopName: string;

  contacts: IStopContact[] = [];
  otherContacts: IStopContact[] = [];
  requestParams: RequestParams;

  routeStopName: string = "";

  displayedColumns: string[] = ['name', 'phone', 'email'];
  contactsDataSource = new MatTableDataSource<IStopContact>([]);
  otherContactsDataSource = new MatTableDataSource<IStopContact>([]);

  @ViewChild(MatPaginator) contactsPaginator: MatPaginator;
  @ViewChild(MatPaginator) otherContactsPaginator: MatPaginator;
  @ViewChild(MatSort) contactsSort: MatSort;
  @ViewChild(MatSort) otherContactsSort: MatSort;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private customerContactsService: CustomerContactsService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.currentRoute = this.router.url;
    this.requestParams = new RequestParams();
    this.requestParams.setPaging(1, 0);
    this.stopName = this.route.snapshot.paramMap.get('id');
    this.routeStopName = localStorage.getItem('routeStopName');
    window.dispatchEvent(new Event('resize'));
    this.getContacts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contacts || changes.otherContacts) {
      this.loadPage();
    }
  }

  ngAfterViewInit(): void {
    this.contactsDataSource.sort = this.contactsSort;
    this.contactsDataSource.paginator = this.contactsPaginator;
    this.otherContactsDataSource.sort = this.otherContactsSort;
    this.otherContactsDataSource.paginator = this.otherContactsPaginator;
    this.cdr.detectChanges();
  }

  async getContacts(): Promise<void> {
    const contacts = await this.customerContactsService.getContacts(this.stopName, this.requestParams);
    console.log(contacts);
    this.contacts = (contacts as any).contacts;
    this.otherContacts = (contacts as any).otherContacts;
    this.loadPage();
  }

  loadPage() {
    this.contactsDataSource.data = this.contacts;
    this.otherContactsDataSource.data = this.otherContacts;
    this.cdr.detectChanges();
  }
}
