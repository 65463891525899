import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController, ViewWillEnter, ViewDidLeave, ViewDidEnter } from '@ionic/angular';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-why-register-popup-page',
  templateUrl: 'why-register-popup.component.html',
  styleUrls: [
    './why-register-popup.component.scss'
  ]
})
export class WhyRegisterComponent implements ViewWillEnter, OnInit {


  loaded: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalController: ModalController,
    private location: Location
  ) { 
  
  }

  ngOnInit(): void {
    
  }

  registerPage(): void {
    this.modalController.dismiss();
    this.router.navigate(['auth/login/create-account']);
  }

  async ionViewWillEnter() {
    this.loaded = true;
  }

}