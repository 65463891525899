import { Model, ModelId } from "../../../common/rest-api/models/model";

export class WebOrder extends Model {
  get modelId(): ModelId {
    throw new Error("Method not implemented.");
  }
  firstName: string;
  lastName: string;
  woId: number;
  enteredDate?: Date;
  customerCode?: string;
  departmentCode?: string;
  employeeCode?: string;
  state?: number;
  orderType?: string;
  enteredBy?: string;
  refActualWoCode?: string;
  email?: string;
  cancelCmplt?: string;
}
