import { Component, OnInit } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController, ViewWillEnter, ViewDidLeave, ViewDidEnter } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { GtrakAuthService } from '../../services/gtrak-auth.service';
import { GarmentDetailService } from '../../../garment-listing/services/garment-detail.service';
import { Location } from '@angular/common';
import { WhyRegisterComponent } from './why-register-popup/why-register-popup.component';
import { ModalController } from '@ionic/angular';
import { ToasterService } from '../../../../../app/common/services/toaster.service';
import { ICreateMobileUser } from '../../models/create-mobile-user';
import { EmailService } from '../../../../../app/common/services/email.service';
import { NavController } from '@ionic/angular';

enum STEP {
  'CREATE' = 1,
  'DATA' = 2
}

@Component({
  selector: 'app-create-account-page',
  templateUrl: 'create-account.page.html',
  styleUrls: [
    './create-account.page.scss'
  ]
})
export class CreateAccountPage implements ViewWillEnter, OnInit {

  STEP = STEP;

  currentStep: STEP = STEP.CREATE;

  stopCode: string = null;
  lockerNumber: string = null;
  lastName: string = null;

  email: string = null;
  password: string = null;
  confirmationPassword: string = null;
  cellphoneNumber: string = null;

  loaded: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: GtrakAuthService,
    public garmentDetailService: GarmentDetailService,
    private location: Location,
    private modalController: ModalController,
    private toasterService: ToasterService,
    private emailService: EmailService,
    private navController: NavController
  ) { 
  
  }

  ngOnInit(): void {
    new Error("Method not implemented.");
  }

  navigateToBaseLogin(): void {
    this.location.back();
  }

  async continue(): Promise<void> {
    if (this.currentStep === STEP.CREATE) {
      // Check if data is valid
      const isValid = await this.authService.IsValidNewMobileUser(this.stopCode, this.lastName, this.lockerNumber)

      if (isValid) {
        this.currentStep = STEP.DATA;
      } else {
        this.toasterService.presentToast('danger', 'Please check your entries and try again.', 'Oops, we could not find you.', 2500)
      }
    } else {

      if (this.password !== this.confirmationPassword) {
        this.toasterService.presentToast('warning', '', 'Passwords do not match', 1800);
        return;
      }

      if (this.password.length < 6) {
        this.toasterService.presentToast('warning', '', 'Passwords must be atleast 6 characters', 1800);
        return;
      }

      const newUser: ICreateMobileUser = {
        stopCode: this.stopCode,
        lastName: this.lastName,
        lockerNumber: this.lockerNumber,
        email: this.email,
        phoneNumber: this.cellphoneNumber,
        password: this.password
      };

      const returnData = await this.authService.CreateNewMobileUser(newUser);

      if (returnData) {
        this.toasterService.presentToast('primary', '', 'Your account was created!', 4000);
        this.navController.navigateRoot(['auth/mobile']);
      } else {
        // Fail toaster
        this.toasterService.presentToast('danger', 'An error occured while creating your account. Please try again.', 'Something went wrong')
      }
    }
  }

  back(): void {
    this.currentStep = STEP.CREATE;
  }

  whyRegisterPopup(): void {

  }

  async openWhyRegisterPopup(): Promise<void> {
    const modal = await this.modalController.create({
      component: WhyRegisterComponent,
      cssClass: 'min-width-modal-md-wide grey-background',
      showBackdrop: true,
      backdropDismiss: true,
      keyboardClose: true,
      swipeToClose: true,
    });

    (await modal).present();
    const {data} = await (await modal).onDidDismiss();
  }

  async ionViewWillEnter() {
    this.loaded = true;
  }

}