import { Data } from "@angular/router";

/**
 * Simple internal constructor type for keeping signatures more readable
 */
export type ModelConstructor<T> = {
  new (data?: Data): T;
}

/**
 * Simple alias type for model ids for readability
 */
export type ModelId = number | string;

/**
 * Base model class that provides typing for generic functionality and
 * a static from() factory method to build model instances from provided data
 */
export abstract class Model {

  /** 
   * Get the model id in an agnostic way for models/schemas that
   * don't have a standardizes primary key.
   * 
   * Child models should return the appropriate primary key value
   */
  abstract get modelId(): ModelId;
  
  /**
   * Create an instance of a model from the specified data
   * 
   * Example: const myModel = OrderItem.from(data);
   * @param this Type constructor inferred from the child class
   * @param data The data to create the model from
   * @returns An instance of the model with the provided data
   */
  static from<T extends Model>(this: ModelConstructor<T>, data?: Data): T {
    const model = new this();
    if (data)
      model.assign(data);
    
    return model; 
  }

  /**
   * Create a model instance from a specified type and data
   * 
   * Example: const myModel = Model.fromType(OrderItem, data);
   * @param type The model type to create an instance of
   * @param data The data to include on the instance
   * @returns An instance of the model with the provided data
   */
  static fromType<T extends Model>(type: ModelConstructor<T>, data?: Data): T {
    const c = new type(data);
    if (data)
      c.assign(data);
    
    return c; 
  }

  /**
   * This method should setup the model properties based on the provided data.
   * 
   * The default implementation simply executes an Object.assign(), but if there are
   * any child elements that need to be instantiated or other special intialization
   * it should be overridden
   * 
   * IMPORTANT: data might be null and should be handled appropriately in the child
   * method implementation
   * 
   * @param data The data to instantiate the model with
   */
  public assign(data: Data): void {
    Object.assign(this, data);
  }
}