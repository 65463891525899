import { Component, Input, OnInit, ViewChild, OnChanges, SimpleChanges, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { RequestParams } from '../../../../../../common/rest-api/models/request-params';
import { StopInvoiceReport } from './models/stopInvoice.model';
import { StopInvoiceService } from './invoice-stop.service';
import { GtrakAuthService } from '../../../../../../../app/features/authentication/services/gtrak-auth.service';

@Component({
  selector: 'app-stop-invoice-page',
  templateUrl: 'invoice.page.html',
  styleUrls: [
    './invoice.page.scss',
    '../../../../styles/g-route.styles.scss'
  ]
})
export class InvoiceStopPage implements OnInit, OnChanges, AfterViewInit {

  currentRoute: string;
  stopCode: string;
  routeCode: string;

  invoices: StopInvoiceReport;
  requestParams: RequestParams;

  routeStopName: string = "";

  displayedColumns: string[] = ['invoiceCode', 'dateOfInvoice', 'amountDue', 'dueDate'];
  invoiceDataSource = new MatTableDataSource<any>([]);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private stopInvoiceService: StopInvoiceService,
    private authService: GtrakAuthService,
    private cdr: ChangeDetectorRef
  ) {
    this.currentRoute = null;
  }

  ngOnInit(): void {
    this.routeStopName = localStorage.getItem('routeStopName');

    this.currentRoute = this.router.url;
    this.stopCode = this.route.snapshot.paramMap.get('id');
    this.routeCode = this.authService.user.routeCode;

    this.requestParams = new RequestParams();
    this.requestParams.setPaging(1, 0);

    window.dispatchEvent(new Event('resize'));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.invoices) {
      this.loadPage();
    }
  }

  ngAfterViewInit(): void {
    this.invoiceDataSource.sort = this.sort;
    this.invoiceDataSource.paginator = this.paginator;
    this.cdr.detectChanges();
  }

  async ionViewWillEnter(): Promise<void> {
    this.invoices = await this.stopInvoiceService.getInvoices(this.stopCode, this.routeCode, this.requestParams);
    this.loadPage();
  }

  openInvoice(invoiceNum: any): void {
    this.router.navigate([`invoice/details/${invoiceNum}`]);
  }

  loadPage() {
    this.invoiceDataSource.data = this.invoices.invoices;
    this.cdr.detectChanges();
  }

  changePage(event: PageEvent) {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    this.loadPage();
  }
}
