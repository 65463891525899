import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { EmployeeProduct } from '../models/employee-products.model';
import { WorkOrderProduct } from '../models/work-order-product.model';
import { WorkOrderGarment } from '../models/work-order-garment.model';
import { IWebCatOrder } from '../../mobile/models/web-cat-order';

  
@Injectable({
providedIn: 'root',
})
export class CreateWorkOrderService extends RestApiService<EmployeeProduct>{

    //protected resourcePath = '/emp-product';
    protected modelType = EmployeeProduct;

    async GetWoProductList(custCode: string, empCode: string, depCode: string): Promise<WorkOrderProduct[]> {
        this.resourcePath = '/emp-products';
        const url = this.buildEndpointUrl(`wo-list/${custCode}/${empCode}/${depCode}`);
        const response = await this.cloudApiMiddleware.cloudRequest<WorkOrderProduct[]>({
            url
        }, 'GET');

        return response;
    }

    async GetWoGarmentList(custCode: string, empCode: string, depCode: string): Promise<WorkOrderGarment[]> {
        this.resourcePath = '/garment';
        const url = this.buildEndpointUrl(`wo-list/${custCode}/${empCode}/${depCode}`);
        const response = await this.cloudApiMiddleware.cloudRequest<WorkOrderGarment[]>({
            url
        }, 'GET');

        return response;
    }

    async SubmitWebCatSizeChange(userId: string | number, custCode: string, empCode: string, depCode: string, stopCode: string, body: WorkOrderProduct[], email: string): Promise<IWebCatOrder> {
        this.resourcePath = '/web-orders';
        const url = this.buildEndpointUrl(`cat-order-size-change/${userId}/${custCode}/${empCode}/${depCode}/${stopCode}/${email}`);
        const response = await this.cloudApiMiddleware.cloudRequest<IWebCatOrder>({
            url,
            body
        }, 'POST');

        return response;
    }

    async SubmitWebCatRepairReplace(userId: string | number, custCode: string, empCode: string, depCode: string, stopCode: string, body: WorkOrderProduct[], email: string): Promise<IWebCatOrder> {
        this.resourcePath = '/web-orders';
        const url = this.buildEndpointUrl(`cat-order-repair-replace/${userId}/${custCode}/${empCode}/${depCode}/${stopCode}/${email}`);
        const response = await this.cloudApiMiddleware.cloudRequest<IWebCatOrder>({
            url,
            body
        }, 'POST');

        return response;
    }

    async SubmitSizeChange(userId: string | number, custCode: string, empCode: string, depCode: string, stopCode: string, body: WorkOrderProduct[]): Promise<string> {
        this.resourcePath = '/route';
        const url = this.buildEndpointUrl(`size/${userId}/${custCode}/${empCode}/${depCode}/${stopCode}`);
        const response = await this.cloudApiMiddleware.cloudRequest<string>({
            url,
            body
        }, 'POST');

        return response;
    }

    async SubmitCancelItem(userId: string | number, custCode: string, empCode: string, depCode: string, stopCode: string, reasonCode: string, body: WorkOrderProduct[]): Promise<string> {
        this.resourcePath = '/route';
        const url = this.buildEndpointUrl(`cancel/${userId}/${custCode}/${empCode}/${depCode}/${stopCode}/${reasonCode}`);
        const response = await this.cloudApiMiddleware.cloudRequest<string>({
            url,
            body
        }, 'POST');

        return response;
    }

    async SubmitGarmentReplace(userId: string | number, stopCode: string, garmentCode: string): Promise<string> {
        this.resourcePath = '/route';
        const url = this.buildEndpointUrl(`garment-replace/${userId}/${stopCode}/${garmentCode}`);
        const response = await this.cloudApiMiddleware.cloudRequest<string>({
            url,
            body: null
        }, 'POST');
        
        return response;
    }

}