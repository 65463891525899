import { AppRoute } from '../../../core/routing/AppRoute';
import { GarmentListingPage } from './pages/garment-listing-page/garment-listing.page';
import { CustomerGarmentReportPage } from './pages/customer-garment-report/customer-garment-report.page';
import { DepartmentGarmentReportPage } from './pages/department-garment-report/department-garment-report.page';
import { EmployeeGarmentReportPage } from './pages/employee-garment-report/employee-garment-report.page';

import { AuthGuard } from '../../common/rest-api/services/auth-guard.service';

// A feature route should be used in the App routing module in most cases and primarily provide
// a base url and module loading functionality.
// The feature route should employ a load children callback that loads the appropriate module
const Feature = new AppRoute({
  segment: 'garment-listing',
  title: 'Garment Listings',
  // A component can also be included to be the content, or a separate route with a blank segment
  // can be defined like below to provide a dedicated route instance
  // component: GarmentListingPage,
  loadChildren: () => import('./garment-listing.module').then(m => m.GarmentListingModule),
  canActive: [AuthGuard]
});

// "Sub-routes" should be the contentful endpoints for the feature. To provide content at the base
// level of the feature url, simply leave segment blank.
// Provide the parent parameter to be able to derive the fullPath of a route that includes the base
const Report = new AppRoute({
  segment: '',
  title: 'Garment Report',
  component: GarmentListingPage,
  parent: Feature,
  canActive: [AuthGuard]
});

// TODO [EMP] Remove test/examples after examples and/or documentation is created
// To provide content at a sub url of the base, enter the appropriate endpoint in segment (not including the feature base url)
const Customer = new AppRoute({
  segment: 'customer/:id',
  title: 'Garment Report Customer',
  component: CustomerGarmentReportPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const Department = new AppRoute({
  segment: 'department/:id/:cid',
  title: 'Garment Report Department',
  component: DepartmentGarmentReportPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const Employee = new AppRoute({
  segment: 'employee/:id/:cid/:did',
  title: 'Garment Report Employee',
  component: EmployeeGarmentReportPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const GarmentCode = new AppRoute({
  segment: 'garment-code/:gid',
  title: 'Garment Report Employee',
  component: EmployeeGarmentReportPage,
  parent: Feature,
  canActive: [AuthGuard]
});

export const GarmentListingRoutes = {
  Feature: Feature,
  Report: Report,
  Customer: Customer,
  Department: Department,
  Employee: Employee,
  GarmentCode : GarmentCode
}