import { Component, Input, OnInit } from "@angular/core";
import { ViewWillEnter } from "@ionic/angular";
import { ActivatedRoute, Route, Router } from "@angular/router";
import { CustomerProductsService } from "../../../../services/customer-products.service";
import { CustomerProducts } from "../../../../models/customer-products.model";
import { GarmentAddWearerService } from "../../../../services/garment-add-wearer.service";
import { ToasterService } from "../../../../../../common/services/toaster.service";
import { ProductsGarmentsService } from "../../../../services/products-garments.service";
import { ProductVerify } from "../../../../models/product-verify.model";
import { CustomerProductsScheduleService } from "../../../../services/customer-products-schedule.service";
import { CustomerListItem } from "../../../../models/customer-list-item.model";
import { Emblem } from "../../../../models/emblem.model";
import { GtrakAuthService } from "../../../../../../features/authentication/services/gtrak-auth.service";
import { TempCustomerEmployee } from "../../../../models/temp-customer-employee.model";

export interface IProductQuantity {
  ProductCode: string;
  Quantity: number;
}

@Component({
  selector: "app-garment-add-wearer-page",
  templateUrl: "garment-add-wearer.page.html",
  styleUrls: [
    "./garment-add-wearer.page.scss",
    "../../../../styles/g-route.styles.scss",
  ],
})
export class GarmentAddWearerPage implements ViewWillEnter, OnInit {
  currentRoute: string;
  stopName: string;

  firstName: string = null;
  lastName: string = null;
  lockerNumber: string = null;
  autoGen: boolean = true;
  productList: CustomerProducts[] = [];
  step: number = 1;
  verifyProducts: ProductVerify[] = [];
  customerList: CustomerListItem[] = [];
  selectedCdCode: string = null;
  userID: string | number = null;

  products: IProductQuantity[] = [
    { ProductCode: null, Quantity: null },
    { ProductCode: null, Quantity: null },
    { ProductCode: null, Quantity: null },
    { ProductCode: null, Quantity: null },
    { ProductCode: null, Quantity: null },
  ];

  routeStopName: string = "";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private customerProductsService: CustomerProductsService,
    private customerProductsScheduleService: CustomerProductsScheduleService,
    private garmentAddWearerService: GarmentAddWearerService,
    private productsGarmentsService: ProductsGarmentsService,
    private toasterService: ToasterService,
    private gtrakAuthService: GtrakAuthService
  ) {
    this.currentRoute = null;
  }

  async ngOnInit() {
    this.routeStopName = localStorage.getItem('routeStopName');
    
    this.userID = await this.gtrakAuthService.user.id;

    this.currentRoute = this.router.url;
    this.stopName = this.route.snapshot.paramMap.get("id");
    await this.customerProductsService
      .GetProductList(this.stopName)
      .then((response) => {
        this.productList = response;
      });
  }

  ionViewWillEnter(): void {}

  moveToStep2(): void {
    //check if no products have been selected
    let count = 0;
    for (let i = 0; i < this.products.length; i++) {
      if (this.products[i].ProductCode === null) {
        count++;
      }
    }
    if (count === 5) {
      this.toasterService.presentToast(
        "danger",
        "Please select at least one product",
        "Error"
      );
      return;
    }
    //check if products has a product name but no quantity
    for (let i = 0; i < this.products.length; i++) {
      if (
        this.products[i].ProductCode !== null &&
        this.products[i].Quantity === null
      ) {
        this.toasterService.presentToast(
          "danger",
          "Please enter a quantity for each product",
          "Error"
        );
        return;
      }
    }
    //go through each of the products and if there is a null value, change the value to 0
    for (let i = 0; i < this.products.length; i++) {
      if (
        this.products[i].ProductCode === null ||
        this.products[i].Quantity === null
      ) {
        this.products[i].ProductCode = "nothing";
        this.products[i].Quantity = 0;
      }
    }
    //grab the data thats necessary for the next page
    this.productsGarmentsService
      .GetProductList(this.stopName, this.products)
      .then((response) => {
        this.verifyProducts = response; 
      });
    //grab the customer and dept codes
    this.customerProductsScheduleService
      .GetCustomerList(this.stopName)
      .then((response) => {
        this.customerList = response;
        this.selectedCdCode = this.customerList[0]?.cdCode;
      });
    this.step = 2;
  }

  checkboxAdd(product: ProductVerify, emblem: Emblem, event) {
    if (event.target.checked) {
      product.selectedEmblems.push(emblem);
    } else {
      const index = product.selectedEmblems.indexOf(emblem);
      product.selectedEmblems.splice(index, 1);
    }
  }

  reset(): void {
    this.firstName = null;
    this.lastName = null;
    this.lockerNumber = null;
    this.step = 1;

    this.products.forEach(p => {
      p.ProductCode = null;
      p.Quantity = null;
    });

    this.verifyProducts = [];
  }

  async submit(): Promise<void> {
    //check to make sure all items in verify products have a value in selectedSize
    for (let i = 0; i < this.verifyProducts.length; i++) {
      if (
        this.verifyProducts[i].selectedSize === null ||
        this.verifyProducts[i].selectedSize === undefined
      ) {
        this.toasterService.presentToast(
          "danger",
          "Please select a size for each product",
          "Error"
        );
        return;
      }
    }
    //check to ensure there is a value selected on selectedCdCode
    if (this.selectedCdCode === null) {
      this.toasterService.presentToast(
        "danger",
        "Please select a customer",
        "Error"
      );
      return;
    }
    //split the selectedCdCode into customerCode and depCode
    const customerCode = this.selectedCdCode.split("/")[0];
    const depCode = this.selectedCdCode.split("/")[1];
    if (this.autoGen !== true) {
      this.garmentAddWearerService
        .LockerCheck(this.lockerNumber, this.stopName, customerCode, depCode)
        .then((response) => {
          if (response === "Locker number already in use") {
            this.toasterService.presentToast(
              "danger",
              "Locker number already in use",
              "Error"
            );
            return;
          }
        });
    }

    //create the body for the post request
    const body: TempCustomerEmployee = {
      firstName: this.firstName,
      lastName: this.lastName,
      products: this.verifyProducts,
    };

    this.garmentAddWearerService.AddUser(body, this.stopName, this.lockerNumber, customerCode, depCode, this.userID).then((response) => {
      this.toasterService.presentToast(
        "primary",
        "User added successfully",
        "Success"
      );
      this.reset();
    });
  }
}
