<ion-content class="stop-info">
    <ng-container>
        <app-header class="title" [title]="(routeStop?.description ?? '') + ' (' + stopName + ') Details'"></app-header>
        <ion-grid *ngIf="routeStop">
            <ion-row>
                <ion-col>
                    <ion-list *ngFor="let section of options" (click)="navigate(section)">
                        <div *ngIf="!section.check">
                            <ion-label>{{section.name}}</ion-label>
                        </div>
                        <div *ngIf="section.check" (click)="setTime(section)">
                                <ion-label>{{section.name}}</ion-label>
                                <ion-label class="time-label">{{section.time}}</ion-label>
                        </div>
                        <ion-row *ngIf="section.comment && section.check">
                            <ion-col>
                                <span class="del-instr">NOTES: {{routeStop.delInstr}}</span>
                            </ion-col>
                        </ion-row>
                        <ion-row *ngIf="section.comment && section.check">
                            <ion-col>
                                <span class="del-instr">ADDRESS: {{routeStop.deliveryAddress1}} {{routeStop.deliveryAddress2}} {{routeStop.deliveryAddress3}} {{routeStop.deliveryCity}}, {{routeStop.deliveryState}} {{routeStop.deliveryPostalCode}}</span>
                            </ion-col>
                        </ion-row>
                    </ion-list>
                </ion-col>
                <ion-col>
                    <ion-list *ngFor="let section of garmentOptions" (click)="navigate(section)">
                        <div>
                            <ion-label>{{section.name}}</ion-label>
                        </div>
                    </ion-list>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ng-container>
</ion-content>