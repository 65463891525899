<ion-content class="check-out">
    <app-header [title]="stopName + ' Checkout'"></app-header>
    <ng-container>
        <div class="check-out-container">
            <ion-row class="header-row">
                <ion-col>Item</ion-col>
                <ion-col>Status</ion-col>
                <ion-col>Link</ion-col>
            </ion-row>
            <ion-row class="data-row">
                <ion-col>Check Cancel Report</ion-col>
                <ion-col>{{cancelStatus}}</ion-col>
                <ion-col class="link" (click)="cancelLink()">Go</ion-col>
            </ion-row>
            <ion-row>
                <ion-col>Enter Non Garment Count</ion-col>
                <ion-col>{{garmentStatus}}</ion-col>
                <ion-col class="link" (click)="garmentLink()">Go</ion-col>
            </ion-row>
            <ion-row class="data-row">
                <ion-col>Email Reports</ion-col>
                <ion-col>{{emailStatus}}</ion-col>
                <ion-col class="link" (click)="emailLink()">Go</ion-col>
            </ion-row>
        </div>
        <div class="button-div">
            <ion-button (click)="completeCheckOut()" color="gallaghergreen" class="btn-box-shadow btn-margin"><span>Check Out</span></ion-button>
        </div>
    </ng-container>
</ion-content>