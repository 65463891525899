import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ViewWillEnter } from '@ionic/angular';
import { EmployeeListService } from '../../../../common/services/employee-listing.service'; ///app/common/services/employee-list.service';
import { RequestParams } from '../../../../common/rest-api/models/request-params';
import { GarmentReportService } from '../../services/garment-report.service';
import { DepartmentKey } from '../../../../../app/common/models/department-key-model';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';
import _ from "lodash";


@Component({
  selector: 'app-department-garment-report-page',
  templateUrl: 'department-garment-report.page.html',
  styleUrls: [
    './department-garment-report.page.scss',
  ]
})
export class DepartmentGarmentReportPage implements ViewWillEnter, OnInit {

  departmentId: string;
  customerId: string;

  requestParams: any;

  results: any;

  currentPage: number;
  pageSize: number;

  reports: any;
  totalPages: number;
  totalRecords: number;
  pageNumbers = [];

  depKey: DepartmentKey;

  printing: boolean = false;

  selectedRowKey: string = null;

  public viewChange: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private employeeListService: EmployeeListService,
    private garmentReportService: GarmentReportService,
    private authService: GtrakAuthService,
  ) { 

  }

  ngOnInit(): void {

    if(!this.authService.user.canViewGarmentListing) {
      this.router.navigate([this.authService.user.homeLocation]);
    }

  }

  ionViewWillEnter(): void {
    this.departmentId = this.route.snapshot.paramMap.get('id');
    this.customerId = this.route.snapshot.paramMap.get('cid');

    this.depKey = {
      CustomerCode: this.customerId,
      DepartmentCode: this.departmentId
    };

    this.currentPage = 1;
    this.pageSize = 10;

    this.requestParams = new RequestParams();
    this.requestParams.setOrdering('LastName', 'asc');

    this.requestParams.setPaging(this.currentPage, this.pageSize);

    this.setData();
  }

  async setData(): Promise<void> {
    this.results = await this.garmentReportService.findForDepartment(this.depKey, this.requestParams);

    _.each(this.results.results, (gr) => {
      gr.notes = gr.notes.filter((test, index, array) =>
        index === array.findIndex((find) => find.code === test.code)
      );
    })

    _.each(this.results.results, (result) => {
      result.summaryNotes = [];
      _.each(result.summary, (sum) => {
        if(sum.status) {
          if(sum.status.length > 0) {
            result.summaryNotes.push({
              'status': sum.status,
              'statusDesc': sum.statusDesc
            })
          }
        }
      });

      result.summaryNotes = result.summaryNotes.filter((test, index, array) =>
        index === array.findIndex((find) => find.status === test.status)
      );
    });
    

    this.reports = this.results.results;

    this.totalPages = this.results.totalPages;
    this.totalRecords = this.results.totalRecords;

    this.pageNumbers = Array(this.totalPages).fill(1).map((x, i) => i)
    this.pageNumbers.push(this.totalPages);

  }

  selectPage(number: number): void {
    if(number <= 0) return;

    this.currentPage = number;

    this.requestParams.setPaging(this.currentPage, this.pageSize);

    this.selectedRowKey = null;

    this.setData();
  }

  goTo(location: string): void {
    window.location.hash = '';
    window.location.hash = location;
  }

  changePrinterView(): void {
    this.viewChange = !this.viewChange;
    // Set a delay before opening the print dialog
    setTimeout(() => {
      print();
    }, 1000); // 2000 milliseconds = 2 seconds
  }

  clearFilterByRowKey(): void {
    this.selectedRowKey = null;
  }

  filterByRowKey(key: string): void {
    this.selectedRowKey = key;
  }

  print() {
    this.printing = true;
    setTimeout(() => {
      window.print();
    },1);
  }

}