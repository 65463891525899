<div class="history-modal">
  <div class="main">
    <div class="week-of">
      <span class="code">Week of:</span> <strong>{{today | date:'MM/dd/yyyy'}}</strong>
    </div>
    <h1>Weekly History for:</h1>
    <div class="emp-data">
      <div>
        <span class="code">{{employee.customerCode}}</span> <strong class="title">{{employee.customerName}}</strong>
      </div>
      <div>
        <span class="code">{{employee.departmentCode}}</span> <strong class="title">{{employee.departmentName}}</strong>
      </div>
      <div>
        <span class="code">{{employee.employeeCode}}</span> <strong class="title">{{employee.firstName}} {{employee.lastName}}</strong>
      </div>
    </div>
  </div>
  <ion-grid>
    <ion-row class="grid-title">
      <ion-col>Week of:</ion-col>
      <ion-col>Pant</ion-col>
      <ion-col>Shirt</ion-col>
    </ion-row>
    <ion-row *ngFor="let week of weeklyHistory" class="alternate">
      <ion-col>{{week.week | date:'MM/dd/yyyy'}}</ion-col>
      <ion-col>{{week.pantsQty}}</ion-col>
      <ion-col>{{week.shirtQty}}</ion-col>
    </ion-row>
  </ion-grid>
</div>
