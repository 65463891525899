<div *ngFor="let item of menuItems">
  <ion-menu-toggle autoHide="false" *ngIf="item.canAccess()">
    <ion-item
      *ngIf="item.label !== 'Logout' && !item.hasImage"
      [routerLink]="
        authService.user.isWearer
          ? item.alternativeRoute.path ?? item.route.path
          : item.route.path
      "
    >
      <ion-icon
        *ngIf="item.hasIcon"
        slot="start"
        [name]="item.ionicIcon ?? ''"
        [src]="item.customIcon ?? ''"
        class="icon"
      ></ion-icon>
      <ion-label>
        {{ item.label }}
      </ion-label>
    </ion-item>
    <ion-item
      *ngIf="item.label !== 'Logout' && item.hasImage"
      [routerLink]="
        authService.user.isWearer
          ? item.alternativeRoute.path ?? item.route.path
          : item.route.path
      "
    >
      <ion-img
        [ngStyle]="{ scale: item.scale ?? 1 }"
        [src]="item.customImg ?? ''"
        class="img"
      ></ion-img>
      <ion-label
        [ngClass]="{ lbl: item.label === 'Admin Page' }"
      >
        {{ item.label }}
      </ion-label>
    </ion-item>
    <ion-item
      *ngIf="item.label === 'Logout'"
      (click)="logout()"
      class="log-out"
      button="true"
    >
      <ion-icon
        *ngIf="item.hasIcon"
        slot="start"
        [name]="item.ionicIcon ?? ''"
        [src]="item.customIcon ?? ''"
      ></ion-icon>
      <ion-label class="neg-margin">
        {{ item.label }}
      </ion-label>
    </ion-item>
  </ion-menu-toggle>
</div>
