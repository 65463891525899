import { Model, ModelId } from "../../../common/rest-api/models/model";

export class ProductsGarment extends Model {
  get modelId(): ModelId {
    throw new Error("Method not implemented.");
  }
  productCode: string;
  name: string;
  categoryCode: string;
  supplierCode: string;
  manufacturerCode?: string;
  measureCode: string;
  colorCode: string;
  genderCode: string;
  closureCode: string;
  unitsInStock?: number;
  unitsAssigned?: number;
  rawUnits?: number;
  unitsOnOrder?: number;
  reorderQuantity?: number;
  lastOrderQuantity?: number;
  leadTime?: string;
  reorderPoint?: number;
  unitPrice?: number;
  lastPrice?: number;
  salePrice?: number;
  averagePrice?: number;
  unitCharge?: number;
  expectedWashings?: number;
  maxWashings?: number;
  averageLife?: number;
  totalPurchased?: number;
  totalDiscarded?: number;
  totalSold?: number;
  totalUsed?: number;
  activeCode?: string;
  productType?: string;
  materialBlend?: string;
  altSupplierCode?: string;
  location?: string;
  supplierProductCode?: string;
  altSupplierProductCode?: string;
  washCode?: string;
  finishCode?: string;
  productionStandard?: number;
  productionUnits?: string;
  replacementBoxQty?: number;
  deprAmount?: number;
  deprMonths?: number;
  commissionRate?: number;
  commissionType?: string;
  sugRetailPrice?: number;
  replacementChargeRate?: number;
  supplierListPrice?: number;
  supplierDiscPct?: number;
  oversizeAdjPct?: number;
  specialcutAdjPct?: number;
  cogItem?: string;
  sizeCode?: string;
  garProtectRate?: number;
  catalogItem?: string;
  catalogOnly?: string;
  reissueChg?: number;
  weight?: number;
  garType?: string;
  hasRfid?: string;
  isDedGar?: string;
}
