import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { QueryResult } from '../../../common/rest-api/models/query-result';
import { RequestParams } from '../../../common/rest-api/models/request-params';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { WebLogin } from '../models/web-login.model';
import { WebLoginDept } from '../models/web-login-dept.model';
import { WebLoginWithReports } from '../models/web-login-with-reports.model';
import { Customer } from '../../../common/models/customer-model';
import { CustomerDepartment } from '../../../common/models/customer-department-model';

@Injectable({
  providedIn: 'root',
})
export class AccountManagementService extends RestApiService<WebLogin> {

  protected resourcePath = '/web-login';
  protected modelType = WebLogin;

  protected departmentPath = 'department';
  protected customerPath = 'customer';
  

  async Get(params: RequestParams): Promise<QueryResult<WebLogin>> {

    const url = this.buildEndpointUrl('query', params);

    const response = await this.cloudApiMiddleware.cloudRequest<QueryResult<WebLogin>>({url}, 'GET');

    response.results = response.results.map(i => WebLogin.fromType(this.modelType, i));


    return response;
  }

  async Access(params: RequestParams): Promise<QueryResult<WebLogin>> {

    const url = this.buildEndpointUrl('access', params);

    const response = await this.cloudApiMiddleware.cloudRequest<QueryResult<WebLogin>>({url}, 'GET');

    response.results = response.results.map(i => WebLogin.fromType(this.modelType, i));


    return response;
  }

  async AddUser(body: WebLogin): Promise<WebLogin> {
    const url = this.buildEndpointUrl('user');

    const response = await this.cloudApiMiddleware.cloudRequest<WebLogin>({url, body}, 'POST');
    
    return response;
  }

  async EditUser(body: WebLoginWithReports): Promise<WebLoginWithReports> {
    const url = this.buildEndpointUrl('edit-user');

    const response = await this.cloudApiMiddleware.cloudRequest<WebLoginWithReports>({url, body}, 'PUT');
    
    return response;
  }

  async GetUserByLogin(login: string): Promise<WebLoginWithReports> {
    const url = this.buildEndpointUrl(`get-by-login/${login}`);

    const response = await this.cloudApiMiddleware.cloudRequest<WebLoginWithReports>({url}, 'GET');
    
    return response;
  }

  async AddReport(login: string, reportId: number): Promise<any> {
    const url = this.buildEndpointUrl(`add-report/${login}/${reportId}`);

    const response = await this.cloudApiMiddleware.cloudRequest<any>({url, body: {}}, 'POST');
    
    return response;
  }

  async RemoveReport(login: string, reportId: number): Promise<any> {
    const url = this.buildEndpointUrl(`remove-report/${login}/${reportId}`);

    const response = await this.cloudApiMiddleware.cloudRequest<any>({url, body: {}}, 'POST');
    
    return response;
  }

  async GetCostCenters(login: string | number): Promise<WebLoginDept[]> {
    const url = this.buildEndpointUrl('cost-centers');

    //ensure login is a string
    login = login.toString();

    const response = await this.cloudApiMiddleware.cloudRequest<WebLoginDept[]>({url, headers: [{key: 'login', value: login}]}, 'GET');

    return response;

  }

  async GetCustomerCodes(): Promise<Customer[]> {
    const url = this.buildEndpointUrl('customer-codes');

    const response = await this.cloudApiMiddleware.cloudRequest<Customer[]>({url}, 'GET');

    return response;
  }

  async GetDepartmentsByCustomer(customer: string): Promise<CustomerDepartment[]> {
    const url = this.buildEndpointUrl('departments-by-customer');

    const response = await this.cloudApiMiddleware.cloudRequest<CustomerDepartment[]>({url, headers: [{key: 'customer', value: customer}]}, 'GET');

    return response;
  }

}