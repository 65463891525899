import { APP_INITIALIZER, NgModule, Optional, PLATFORM_ID } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule, Title } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { CommonModule, isPlatformServer } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from '../core/components/components.module';
import { environment } from '../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { RestServiceProvider } from './common/rest-api/services/rest-api.service';

import { AuthServiceProvider } from './common/rest-api/services/auth.service';
import { JwtHelperService, JwtInterceptor, JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';

import { TokenService } from './common/rest-api/services/token.service';

import { AuthGuard } from './common/rest-api/services/auth-guard.service';

import { EmployeeListService } from './common/services/employee-listing.service';
import { GarmentReportService } from './features/garment-listing/services/garment-report.service';
import { CustomReportService } from './common/services/custom-report.service';

import { ResponseHttpInterceptor } from './features/authentication/interceptors/response.interceptor';
import { RequestHttpInterceptor } from './features/authentication/interceptors/request.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function jwtOptionsFactory(tokenService) {
  return {
    tokenGetter: () => tokenService.get(),
    allowedDomains: ["localhost:9200", "90da-50-219-161-162.ngrok.io"],
    disallowedRoutes: ["/auth/login"]
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    IonicModule.forRoot(),
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    NgxDatatableModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [TokenService]
      }
    }),
    BrowserAnimationsModule
    
  ],
  providers: [
    Title,
    Storage,
    AuthServiceProvider,
    RestServiceProvider,
    AuthGuard,
    EmployeeListService,
    GarmentReportService,
    JwtHelperService,
    CustomReportService,
    { provide: HTTP_INTERCEPTORS, useClass: ResponseHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RequestHttpInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: (platformId: object, response: any) => {
        return () => {
          // In the server.ts we added a custom response header with information about the device requesting the app
          if (isPlatformServer(platformId)) {
            if (response && response !== null) {
              // Get custom header from the response sent from the server.ts
              const mobileDeviceHeader = response.get('mobile-device');

              // Set Ionic config mode?
            }
          }
        };
      },
      deps: [PLATFORM_ID, [new Optional(), RESPONSE]],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
