import { Data } from "@angular/router";
import { Model, ModelId } from "../../../common/rest-api/models/model";


export class NewDeliveryModel extends Model
{
    get modelId(): ModelId {
        throw new Error("Method not implemented.");
    }

    workOrder: RouteWorkOrder = null;
    items: WorkOrderItem[] = [];
    employee: EmployeeModel = null;
}

export class EmployeeModel extends Model
{
    get modelId(): ModelId {
        throw new Error("Method not implemented.");
    }

    EmployeeCode: string = null;
    DepartmentCode: string = null;
    EmployeeType: string= null;
    SocialSecurityNumber: string= null;
    FirstName: string= null;
    MiddleName: string= null;
    LastName: string= null;
    Title: string= null;
    Email: string= null;
    Address1: string= null;
    Address2: string= null;
    Address3: string= null;
    City: string= null;
    State: string= null;
    PostalCode: string= null;
    CountryCode: string= null;
    HomePhone: string= null;
    WorkPhone: string= null;
    Extension: string= null;
    BirthDate: Date = null;
    DateHired: Date = null;
    SalaryPay: number = null;
    HourlyPay: number = null;
    SupervisorCode: string= null;
    SpouseName: string= null;
    EmergencyContact: string= null;
    EmergencyPhone: string= null;
    Location: string= null;
    DrugTest: string= null;
    DriversLicense: string= null;
    ActiveCode: string= null;
    Notes: string= null;
    UserId: string= null;
    KeyCode: string= null;
}

export class WorkOrderItem extends Model
{
    get modelId(): ModelId {
        throw new Error("Method not implemented.");
    }

    WoCode: string= null;
    ItemId: number = null;
    ProductCode: string= null;
    ProductType: number = null;
    Size1: string= null;
    Size2: string= null;
    Qty: number = null;
    GradeRequest: string= null;
    ExpectedPickCount: number = null;
    ActualOrderCount: number = null;
    EmpProductCode: string= null;
    PurchReqCode: string= null;
    RemovedEmpProd: string= null;
    DeliveryDate: Date = null;
    SalePrice: number = null;
    CloseDate: Date = null;
    NoLabel: string= null;
    Comment: string= null;
    MakeCharge: number = null;
}

export class RouteWorkOrder extends Model
{
    get modelId(): ModelId {
        throw new Error("Method not implemented.");
    }

    woCode: string= null;
    BatchCode: string= null;
    EnteredBy: string= null;
    EnteredDate: Date = null;
    OrderDate: Date = null;
    CloseDate: Date = null;
    CustomerCode: string= null;
    DepartmentCode: string= null;
    EmployeeCode: string= null;
    deliveryDate: Date = null;
    SourceCode: string= null;
    ReasonCode: string= null;
    State: number = null;
    PickerCode: string= null;
    StopCode: string= null;
    GarmentTotal: number = null;
    PurchaseReqCode: string= null;
    Comments: string= null;
    SamplesUsed: string= null;
    FittedBy: string= null;
    FittingComments: string= null;
    SizeChangeReason: string= null;
    InProcessDate: Date = null;
    PurchReqDate: Date = null;
    CancelDate: Date = null;
    CancelReason: string= null;
    CancelledBy: string= null;
    GarCollectDate: Date = null;
    RVer: number[] = [];
    Priority: number = null;
}