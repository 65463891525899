<ion-content class="non-garments">
  <app-header [title]="(routeStopName ?? '') + ' (' + stopName + ') Non-Garments'"></app-header>
  <ng-container>
    <ion-grid>
      <div class="div-container" *ngIf="loaded">
        <ion-row class="align">
          <ion-header>Delivery List</ion-header>
        </ion-row>
        <ion-row>
          <div class="table-row" *ngIf="loaded">
            <mat-table [dataSource]="deliveryDataSource" matSort class="mat-elevation-z8">
              <!-- Product Column -->
              <ng-container matColumnDef="productCode">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Product </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <a><span class="productCode"></span>&nbsp;{{element.routeDeliveryDetail.productCode}}</a>
                </mat-cell>
              </ng-container>

              <!-- Weeks Column -->
              <ng-container matColumnDef="week">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Weeks </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <a class="weeks">{{element.routeDeliveryDetail.week}}</a>
                </mat-cell>
              </ng-container>

              <!-- Total Inventory Column -->
              <ng-container matColumnDef="productQuota">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Total Inventory </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <a class="productQuota">{{element.routeDeliveryDetail.productQuota}}</a>
                </mat-cell>
              </ng-container>

              <!-- Delivered Quantity Column -->
              <ng-container matColumnDef="productLastUsedQty">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Delivered Quantity </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <a class="productLastUsedQty">{{element.routeDeliveryDetail.productLastUsedQty}}</a>
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumnsDelivery"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsDelivery;"></mat-row>
            </mat-table>
            <mat-paginator [pageSize]="5" showFirstLastButtons></mat-paginator>
          </div>
        </ion-row>

        <ion-row class="align">
          <ion-header *ngIf="!productsWarningFlag">Products to Service Today</ion-header>
          <ion-header *ngIf="productsWarningFlag">Pick Up List <span style="color: red">Warning Some Counts Not Entered</span></ion-header>
        </ion-row>
        <ion-row class="products-service">
          <div class="table-row" *ngIf="loaded">
            <mat-table [dataSource]="productsServiceDataSource" matSort class="mat-elevation-z8">
              <!-- Department Column -->
              <ng-container matColumnDef="departmentCode">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Department </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <a><span class="departmentCode"></span>&nbsp;{{element.departmentCode}}</a>
                </mat-cell>
              </ng-container>

              <!-- Product Column -->
              <ng-container matColumnDef="productCode">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Product </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <a class="productCode">{{element.productCode}}</a>
                </mat-cell>
              </ng-container>

              <!-- Quantity Column -->
              <ng-container matColumnDef="qty">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Quantity </mat-header-cell>
                <mat-cell *matCellDef="let element">
                  <input type="number" [value]="element.qty" (input)="editQuantity($event, element)" />
                </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumnsService"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsService;"></mat-row>
            </mat-table>
            <mat-paginator [pageSize]="5" showFirstLastButtons></mat-paginator>
          </div>
          <ion-button [disabled]="processingRequest" class="service-button" (click)="saveProducts()">Save</ion-button>
        </ion-row>

        <ion-row class="align">
          <ion-header>Off Week Products</ion-header>
        </ion-row>
        <ion-row>
          <div class="table-row">
            <mat-table [dataSource]="offWeekProductsDataSource" matSort class="mat-elevation-z8">
              <!-- Product Column -->
              <ng-container matColumnDef="product">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Product </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.productCode}} </mat-cell>
              </ng-container>

              <!-- Weeks Column -->
              <ng-container matColumnDef="weeks">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Weeks </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.frequency}} </mat-cell>
              </ng-container>

              <!-- Planned Quantity Column -->
              <ng-container matColumnDef="plannedQty">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Planned Quantity </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.quota}} </mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="displayedColumnsOffWeek"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumnsOffWeek;"></mat-row>
            </mat-table>
            <mat-paginator [pageSize]="5" showFirstLastButtons></mat-paginator>
          </div>
        </ion-row>
      </div>
      <div class="loading-container" *ngIf="!loaded">
        <ion-spinner></ion-spinner>
      </div>
    </ion-grid>
  </ng-container>
</ion-content>
