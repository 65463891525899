import { Component, OnInit } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController, ViewWillEnter, ViewDidLeave, ViewDidEnter } from '@ionic/angular';
import { ToasterService } from '../../../../../app/common/services/toaster.service';
import { GtrakAuthService } from '../../../authentication/services/gtrak-auth.service';
import { EmailService } from '../../../../../app/common/services/email.service';
import { IAlertSettings } from '../../models/alert-settings';
import { AlertController } from '@ionic/angular';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-settings-page',
  templateUrl: 'settings.page.html',
  styleUrls: [
    './settings.page.scss'
  ]
})
export class SettingsPage implements ViewWillEnter, OnInit {

  userSettings: any = null;

  radioSelect: string = null;

  delText: boolean = false;

  phoneNumber: string = null;

  showAdditionalSettings: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: GtrakAuthService,
    private emailService: EmailService,
    private toasterService: ToasterService,
    private alertController: AlertController,
    private navController: NavController
  ) { 
  
  }

  async ngOnInit(): Promise<void> {
    this.getUserSettings();
  }

  async getUserSettings(): Promise<void> {
    this.userSettings = await this.emailService.getUserAlertSettings(this.authService.user.id as string);

    if ((this.userSettings.sendText === '1') && (this.userSettings.sendEmail === '1')) {
      this.radioSelect = 'Both';
    } else if ((this.userSettings.sendEmail === '1')) {
      this.radioSelect = 'Email';
    } else if ((this.userSettings.sendText === '1')) {
      this.radioSelect = 'Text';
    }

    this.delText = this.userSettings.sendDelText === '1';

    this.phoneNumber = this.userSettings.phoneNumber;
  }


  ionViewWillEnter(): void {


  }

  navigateBack(): void {
    this.navController.back();
  }

  async submit(): Promise<void> {
    const settings: IAlertSettings = {
      onlyEmail: this.radioSelect === 'Email',
      onlyText: this.radioSelect === 'Text',
      both: this.radioSelect === 'Both',
      phoneNumber: this.phoneNumber,
      delText: this.delText
    }

    const result = await this.emailService.setUserAlertSettings(this.authService.user.id as string, settings);

    if (result) {
      this.toasterService.presentToast('primary', '', 'Alert settings updated successfully', 3000);
    } else {
      this.toasterService.presentToast('primary', '', 'Alert settings updated successfully', 3000);
    }
    
  }

  async deleteAccount(): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Delete Account',
      message: 'Are you sure you want to delete your account? This will permanently erase your account and data.',
      cssClass: 'custom-alert alert-button',
      buttons: [
        {
          text: 'Delete',
          handler: async () => {
            await this.authService.DeleteMobileUser();
            this.toasterService.presentToast('primary', '', 'Account has been permanently deleted.', 3000);
            this.authService.Logout();
          },
          cssClass: 'alert-button red-text-popup'
        },
        {
          text: 'Cancel',
          cssClass: 'alert-button'
        }
      ],
    });

    await alert.present();
    await alert.onDidDismiss(); 
  }


}