<ion-content class="garment-add-wearer">
  <app-header [title]="(routeStopName ?? '') + ' (' + stopName + ') Add Wearer'"></app-header>
  <div class="content" *ngIf="step === 1">
    <ion-row class="name-row">
      <ion-col class="name-col">
        <span>First Name</span>
        <ion-input [(ngModel)]="firstName"></ion-input>
      </ion-col>
      <ion-col class="name-col">
        <span>Last Name</span>
        <ion-input [(ngModel)]="lastName"></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="name-row">
      <ion-col class="name-col">
        <span>Locker Number</span>
        <ion-input [(ngModel)]="lockerNumber" [disabled]="autoGen"></ion-input>
      </ion-col>
      <ion-col class="checkbox-col">
        <ion-checkbox [(ngModel)]="autoGen"></ion-checkbox>
        <span>AutoGen</span>
      </ion-col>
    </ion-row>
    <ion-row class="table-header">
      <ion-col> Products </ion-col>
      <ion-col> Quantity </ion-col>
    </ion-row>
    <ion-row *ngFor="let product of products">
      <ion-col>
        <ion-select
          interface="popover"
          [(ngModel)]="product.ProductCode"
          [interfaceOptions]="{size: 'cover'}"
        >
          <ion-select-option
            *ngFor="let productItem of productList"
            value="{{productItem.productCode}}"
          >
            {{productItem.productCode}}
          </ion-select-option>
        </ion-select>
      </ion-col>
      <ion-col>
        <ion-input [(ngModel)]="product.Quantity" type="number"></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="submit-row">
      <ion-col>
        <ion-button [disabled]="!firstName || !lastName" (click)="moveToStep2()"
          >Verify</ion-button
        >
      </ion-col>
    </ion-row>
  </div>
  <div class="content part-2" *ngIf="step === 2">
    <ion-row class="part-2-top">
      <ion-col>
        <ion-row>
          <ion-col class="cust-select-parent">
            <ion-row><strong>Customer/Dept</strong></ion-row>
            <ion-row class="cust-select-container">
              <ion-select
                interface="popover"
                [interfaceOptions]="{size: 'cover'}"
                class="cust-select"
                [(ngModel)]="selectedCdCode"
              >
                <ion-select-option *ngFor="let code of customerList" value="{{code.cdCode}}">
                  {{code.description}}
                </ion-select-option>
            </ion-select>
            </ion-row>
          </ion-col>
        </ion-row>
        <ion-row class="name-container">
          <span><strong>Name:</strong> {{firstName}} {{lastName}}</span>
        </ion-row>
        <ion-row>
          <span
            ><strong>Locker:</strong> <span *ngIf="autoGen">{{" "}} Auto Gen</span> <ion-input *ngIf="!autoGen" [(ngModel)]="lockerNumber"></ion-input> </span
          >
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row class="part-2-bottom">
      <ion-col>
        <ion-row class="size-row"><strong>Select Sizes</strong></ion-row>
        <ion-row class="products-row">Products</ion-row>
        <ion-row>
          <ion-col>
            <ion-row *ngFor="let product of verifyProducts" class="product">
              <div class="product-item">
                <ion-col>
                  <ion-row><strong>{{product.productCode}}</strong></ion-row>
                  <ion-row>{{product.productName}}</ion-row>
                  <ion-row class="check-container">
                    <ion-col class="emblem">Emblems</ion-col>
                    <ion-col>
                      <ion-row *ngFor="let emblem of product.emblems">
                        <input type="checkbox" class="checks" (input)="checkboxAdd(product, emblem, $event)"/>
                        <label>{{emblem.description}}</label>
                      </ion-row>
                    </ion-col>
                  </ion-row>
                </ion-col>
                <ion-col>
                  <ion-input
                    [(ngModel)]="product.quantity"
                    type="number"
                  ></ion-input>
                </ion-col>
                <ion-col>
                  <ion-select
                    [interfaceOptions]="{size: 'cover'}"
                    interface="popover"
                    placeholder="Select Size"
                    [(ngModel)]="product.selectedSize"
                  >
                    <ion-select-option *ngFor="let size of product.sizes" value="{{size.size1}}-{{size.size2}}">{{size.size1}}-{{size.size2}}</ion-select-option>
                  </ion-select>
                </ion-col>
              </div>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
    <ion-row class="part-2-button">
      <ion-button (click)="submit()">Submit</ion-button>
    </ion-row>
  </div>
</ion-content>
