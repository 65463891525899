<ion-content class="customer-lookup">
    <ng-container>
        <app-header [isParentPage]="true" [title]="'Customer Lookup'"></app-header>
        <ion-grid>
            <ion-row class="filter-row">
                <ion-col>
                    <div>
                        <ion-input type="text" [(ngModel)]="customerCode" placeholder="Enter the customer code" class="ion-text-left color-light"></ion-input>
                        <ion-button color="gallaghergreen" class="btn-box-shadow" (click)="getCustomerListByCode()"><span>Show</span></ion-button>
                    </div>
                </ion-col>
                <ion-col>
                    <div>
                        <ion-input type="text" [(ngModel)]="customerName" placeholder="Enter the customer name" class="ion-text-left color-light"></ion-input>
                        <ion-button color="gallaghergreen" class="btn-box-shadow" (click)="getCustomerListByName()"><span>Show</span></ion-button>
                    </div>
                </ion-col>
            </ion-row>
            <ion-row class="table-row">
                <div class="ion-padding table-container">
                    <app-customer-lookup-table [mode]="'gl'" (paging)="setPage($event)" [rows]="rows" [currentRoute]="currentRoute" [totalElements]="totalElements" [pageSize]="pageSize" [pageNumber]="currPage" ></app-customer-lookup-table>
                </div>
            </ion-row>
        </ion-grid>
    </ng-container>
</ion-content>