import { Injectable } from '@angular/core';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service'; 
import { firstValueFrom } from 'rxjs';
import { WebOrder } from '../models/web-order.model';
import { WebOrderApprovals } from '../models/web-order-approvals.model';


@Injectable({
  providedIn: 'root',
})
export class WebOrderService extends RestApiService<WebOrder> {

  protected resourcePath = '/web-orders';
  protected modelType = WebOrder;

  async getOrders(fromDate: string, toDate: string, status: string): Promise<WebOrderApprovals[]> {

    const url = this.buildEndpointUrl(`get/${fromDate}/${toDate}/${status}`);

    const response = await this.cloudApiMiddleware.cloudRequest<WebOrderApprovals[]>({
      url
    }, 'GET');
    
    return response;
  }

  async cancelOrder(orderId: number, cancelReason: string): Promise<string> {
    //post to the cancel endpoint
    const url = this.buildEndpointUrl(`cancel/${orderId}`);

    const response = await this.cloudApiMiddleware.cloudRequest<string>({
      url,
      body: cancelReason
    }, 'POST');

    return response;
  }

  async transferOrders(orders: WebOrderApprovals[]): Promise<string> {
    //post to the transfer endpoint
    const url = this.buildEndpointUrl(`transfer`);

    const response = await this.cloudApiMiddleware.cloudRequest<string>({
      url,
      body: orders
    }, 'POST');

    return response;
  }

}