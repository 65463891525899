import { Component, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ToasterService } from "../../../../common/services/toaster.service";
import { ModalController } from "@ionic/angular";
import { WebOrderApprovals } from "../../models/web-order-approvals.model";
import { WebOrderService } from "../../services/web-order.service";

@Component({
  selector: "app-web-order-cancel-modal",
  templateUrl: "web-order-cancel-modal.component.html",
  styleUrls: ["./web-order-cancel-modal.component.scss"],
})
export class WebOrderCancelModalComponent implements OnInit {
  @Input() order: WebOrderApprovals;

  cancelReason: string;

  constructor(
    private router: Router,
    private toasterService: ToasterService,
    private modalController: ModalController,
    private webOrderService: WebOrderService
  ) {}

  ngOnInit(): void {
    // throw new Error("Method not implemented.");
  }

  closeModal() {
    this.modalController.dismiss();
  }

  cancelOrder() {
    this.webOrderService
      .cancelOrder(this.order.webOrder.woId, this.cancelReason)
      .then((response) => {
        if (response === "Success") {
          this.toasterService.presentToast(
            "primary",
            "",
            "Order successfully cancelled.",
            3000
          );
          this.modalController.dismiss(true);
        } else {
          this.toasterService.presentToast(
            "danger",
            "",
            "Order could not be cancelled.",
            3000
          );
        }
      });
  }
}
