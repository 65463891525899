import { Component, OnInit } from '@angular/core';
import { MenuController, ViewWillEnter } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { GtrakAuthService } from '../../services/gtrak-auth.service';
import { GarmentDetailService } from '../../../garment-listing/services/garment-detail.service';
import { ToasterService } from '../../../../../app/common/services/toaster.service';
import { Location } from '@angular/common';
import { EmailService } from '../../../../../app/common/services/email.service';

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: 'forgot-password.page.html',
  styleUrls: [
    './forgot-password.page.scss'
  ]
})
export class ForgotPasswordPage implements ViewWillEnter, OnInit {

  email: string = null;

  loaded: boolean = false;

  constructor(
    public authService: GtrakAuthService,
    public garmentDetailService: GarmentDetailService,
    private toasterService: ToasterService,
    private location: Location,
    private emailService: EmailService
  ) { 
  
  }

  ngOnInit(): void {

  }

  navigateToBaseLogin(): void {
    this.location.back();
  }

  async submit(): Promise<void> {
    const result = await this.emailService.forgotPasswordEmail(this.email);

    if (result) {
      this.toasterService.presentToast('primary', '', 'Forgot Password Email Sent.', 2000);
      this.location.back();
    } else {
      this.toasterService.presentToast('danger', 'Please verify that the email entered is correct.', 'Something went wrong.', 4000);
    }
  }

  async ionViewWillEnter() {
    this.loaded = true;
  }

}