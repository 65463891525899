import { AppRoute } from "../routing/AppRoute";

/**
 * Defines a data structure for a section of menu items.
 * Pass null for the headerLabel parameter to not render a header element
 */
export class AppMenuSection {
  items: AppMenuItem[] = [];
  /** Text to display on the header element. Pass null to omit the header element. */
  headerLabel?: string;

  constructor(items: AppMenuItem[] = [], headerLabel: string = null) {
    this.items = items;
    this.headerLabel = headerLabel;
  }
}

export class AppMenuItem {
  label: string;
  iconSlot: string = 'start';
  ionicIcon?: string;
  customIcon?: string;
  customImg?: string;
  scale?: number;
  route?: AppRoute;
  alternativeRoute?: () => any;
  function?: string;
  canAccess: () => boolean;
  isDriver: () => boolean;

  get hasImage() {return this.customImg != null}
  get hasIcon() { return this.ionicIcon != null || this.customIcon != null; }
  get hasRoute() { return this.route != null}
  get hasFunction() { return this.function != null}

  constructor(args: {
    label: string,
    iconSlot?: string,
    ionicIcon?: string,
    customicon?: string,
    customImg?: string,
    scale?: number,
    route?: AppRoute,
    alternativeRoute?: () => any,
    function?: string,
    canAccess: () => boolean,
    isDriver: () => boolean,

  }) {
    this.label = args.label;
    this.iconSlot = args.iconSlot;
    this.ionicIcon = args.ionicIcon;
    this.customIcon = args.customicon;
    this.customImg = args.customImg;
    this.route = args.route;
    this.scale = args.scale;
    this.alternativeRoute = args.alternativeRoute;
    this.function = args.function;
    this.canAccess = args.canAccess;
    this.isDriver = args.isDriver;
  }
}