import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { catchError, firstValueFrom } from "rxjs";
import { environment } from '../../../../environments/environment';
import { GtrakAuthService } from '../../authentication/services/gtrak-auth.service';
import { CloudApiMiddleware } from '../../../../app/common/services/cloud-api-middleware.service';

@Injectable({
    providedIn: 'root'
})
export class ViewOrderService {
    API_BASE_URL: string;
  
    constructor(
        private httpClient: HttpClient,
        private authService: GtrakAuthService,
        private cloudApiMiddleware: CloudApiMiddleware
    ) {
        this.API_BASE_URL = environment.apiUrl;
    }


    async viewSizeChange(id: number, token: string): Promise<any[]> {
        // return await firstValueFrom(this.httpClient.get<any[]>(`${this.API_BASE_URL}/view-order/size-change/${id}/${token}`))
        return await this.cloudApiMiddleware.cloudRequest<any[]>({
            url: `${this.API_BASE_URL}/view-order/size-change/${id}/${token}`
        }, 'GET');
    }

    async viewRepairOrder(id: number, token: string): Promise<any[]> {
        // return await firstValueFrom(this.httpClient.get<any[]>(`${this.API_BASE_URL}/view-order/repair/${id}/${token}`))
        return await this.cloudApiMiddleware.cloudRequest<any[]>({
            url: `${this.API_BASE_URL}/view-order/repair/${id}/${token}`
        }, 'GET');
    }

    async getWoData(id: number, token: string): Promise<any> {
        // return await firstValueFrom(this.httpClient.get<any>(`${this.API_BASE_URL}/view-order/${id}/${token}`))
        return await this.cloudApiMiddleware.cloudRequest<any[]>({
            url: `${this.API_BASE_URL}/view-order/${id}/${token}`
        }, 'GET');
    }

}