import { Component, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ToasterService } from "../../../../common/services/toaster.service";
import { ModalController } from "@ionic/angular";
import { error } from "console";
import { WebOrderApprovals } from "../../models/web-order-approvals.model";

@Component({
  selector: "app-web-order-view-modal",
  templateUrl: "web-order-view-modal.component.html",
  styleUrls: ["./web-order-view-modal.component.scss"],
})
export class WebOrderViewModalComponent implements OnInit {

  @Input() order: WebOrderApprovals;
  
  constructor(
    private router: Router,
    private toasterService: ToasterService,
    private modalController: ModalController
  ) {}

  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }
  
  closeModal() {
    this.modalController.dismiss();
  }

  
}
