import { AppRoute } from '../../../core/routing/AppRoute';
import { ViewOrderPage } from './pages/size-change-order/view-order.page';

import { AuthGuard } from '../../common/rest-api/services/auth-guard.service';

// A feature route should be used in the App routing module in most cases and primarily provide
// a base url and module loading functionality.
// The feature route should employ a load children callback that loads the appropriate module
const Feature = new AppRoute({
  segment: 'view-order',
  title: 'View Order',
  // A component can also be included to be the content, or a separate route with a blank segment
  // can be defined like below to provide a dedicated route instance
  // component: GarmentListingPage,
  loadChildren: () => import('./view-order.module').then(m => m.ViewOrderModule),
  canActive: [AuthGuard]
});

const ViewOrder = new AppRoute({
  segment: ':id',
  title: 'View Order',
  component: ViewOrderPage,
  parent: Feature,
  canActive: [AuthGuard]
});


export const ViewOrderRoutes = {
  Feature: Feature,
  ViewOrder: ViewOrder
}