import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { ProductVerify } from '../models/product-verify.model';
import { ProductsGarment } from '../models/products-garments.model';
import { IProductQuantity } from '../pages/stop-info-menu/detail-pages/garment-add-wearer/garment-add-wearer.page';
  
@Injectable({
providedIn: 'root',
})
export class ProductsGarmentsService extends RestApiService<ProductsGarment> {

    protected resourcePath = '/products-garments';
    protected modelType = ProductsGarment;

    async GetProductList(stopCode: string, productQuantities: IProductQuantity[]): Promise<ProductVerify[]> {
        //add productQuantities to the header
        this.httpOptions.headers = this.httpOptions.headers.set('productQuantities', JSON.stringify(productQuantities));
        const url = this.buildEndpointUrl(`verify/${stopCode}`);
        const response = await this.cloudApiMiddleware.cloudRequest<ProductVerify[]>({
            url,
            headers: [
                {key: 'productQuantities', value: JSON.stringify(productQuantities)}
            ]
        }, 'GET');

        return response;
    }

}