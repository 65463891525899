import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter } from '@ionic/angular';
import { WorkOrderService } from '../../services/work-order.service';
import { RequestParams } from '../../../../common/rest-api/models/request-params';
import { WorkOrder } from '../../models/work-order.model';
import { WorkOrderSection } from '../../models/work-order-section.model';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';
import _ from "lodash";

@Component({
  selector: 'app-customer-work-order-report-page',
  templateUrl: 'customer-work-order-report.page.html',
  styleUrls: [
    './customer-work-order-report.page.scss',
  ],
  encapsulation: ViewEncapsulation.None
})
export class CustomerWorkOrderReportPage implements ViewWillEnter, OnInit {

  customerId: string;

  nameSearch: string = '';
  timeSearch: number = 1;

  requestParams: any;

  results: any;
  reports: WorkOrderSection[] = [];

  currentDate = new Date();

  pageSize: number;
  currentPage: number;

  loaded: boolean = false;

  public viewChange: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private workOrderService: WorkOrderService,
    private router: Router,
    private authService: GtrakAuthService,
  ) { 

  }

  ngOnInit(): void {

    if(!this.authService.user.canViewWorkOrders) {
      this.router.navigate([this.authService.user.homeLocation]);
    }

  }

  ionViewWillEnter(): void {
    this.customerId = this.route.snapshot.paramMap.get('id');

    this.currentPage = 1;
    this.pageSize = 0;

    this.requestParams = new RequestParams();

    this.requestParams.setOrdering('LastName', 'asc');
    this.requestParams.setPaging(this.currentPage, this.pageSize);
    this.requestParams.filters.DaysOffset = 14;

    this.setData();
  }

  timeRangeChange(event: any): void {
    if (this.timeSearch === 1) {
      this.requestParams.filters.DaysOffset = 14;
    } else if (this.timeSearch === 2) {
      this.requestParams.filters.DaysOffset = 365;
    } else {
      this.requestParams.filters.DaysOffset = 730;
    }

    this.setData();
  }

  changePrinterView(): void {
    this.viewChange = !this.viewChange;
    // Set a delay before opening the print dialog
    setTimeout(() => {
      print();
    }, 1000); // 2000 milliseconds = 2 seconds
  }

  async setData(): Promise<void> {
    this.loaded = false;
    this.reports = [];
    this.results = await this.workOrderService.findForCustomer(this.customerId, this.requestParams);

    _.each(this.results.results, (wo) => {
      if(!_.find(this.reports, ['employeeCode', wo.employeeCode])) {
        var section = new WorkOrderSection();
        var workOrdersList = [];

        workOrdersList.push(wo);

        section = {
          employeeCode: wo.employeeCode,
          departmentCode: wo.departmentCode,
          firstName: wo.firstName,
          lastName: wo.lastName,
          stop: wo.stopName,
          stopCode: wo.stopCode,
          workOrders: workOrdersList
        }
        this.reports.push(section);
      } else {
        _.find(this.reports, ['employeeCode', wo.employeeCode]).workOrders.push(wo);
      }
    })

    this.loaded = true;

  }

  getReports(): WorkOrderSection[] {
    return this.reports.filter(r => `${r.firstName} ${r.lastName}`.toLowerCase().includes(this.nameSearch.toLowerCase()));
  }

}