import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forwardRef, Injectable, Type } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Model, ModelId } from '../models/model';
import { QueryResult } from '../models/query-result';
import { RequestParams } from '../models/request-params';
import { CloudApiMiddleware } from '../../services/cloud-api-middleware.service';


// Josh Fletcher 3/5/2024 
// This service is being updated to comply with the new cloud function api service
// Original functions will be commented out above the editted function
// ** THIS SERVICE IS NO LONGER INDEPENDENT FROM THE GALLAGHER APPLICATION **
  
/**
 * Provider for base rest api service framework
 * 
 * Allows child service implementations to avoid constructors, or 
 * not having to inject HttpClient, by declaring
 * HttpClient a dependency of the base service.
 */
export const RestServiceProvider = {
  provide: forwardRef(() => RestApiService),
  deps: [HttpClient]
};

/**
 * Base rest api service provider that relies upon the incorporated model framework.
 * 
 * IMPORTANT: path and type properties need to be specified on
 * child classes for proper operation. Examples:
 * - protected path = 'OrderItem';
 * - protected type = OrderItem; 
 */
@Injectable({
  providedIn: 'root',
})
export class RestApiService<T extends Model> {

  constructor(
    protected client: HttpClient = null,
    protected cloudApiMiddleware: CloudApiMiddleware
  ) { }

  protected headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json');
  
  protected httpOptions = {
    headers: this.headers
  };


  /**
   * Base endpoint on the destination api this service uses.
   * 
   * Example: protected path = 'OrderItem';
   */
  protected resourcePath: string = null;

  /**
   * The class of the model this service uses
   * 
   * Example: protected type = OrderItem;
   * 
   * Used for creating model instances with the model framework from raw Data responses form
   * api endpoints.
   */
  protected modelType: Type<T> = null;
  
  /**
   * The fully qualified url of the service endpoint
   */
  protected get url() { return environment.apiUrl + this.resourcePath; }

  /**
   * Get an endpoint 
   * @param endpointPath The path to build the endpoint url for from the service resource path
   * @param requestParams Request parameters to include on the endpoint url
   * @returns The endpoint url string
   */
  protected buildEndpointUrl(
    endpointPath: string,
    requestParams?: RequestParams
  ) {
    return `${this.url}/${endpointPath}${requestParams?.queryString ?? ''}`;
  }

  /**
   * Get a model with the specified id
   * TODO Need to handle null responses correctly! Though, this should be a 404 error if so
   * @param id The id of the model to retrieve
   * @returns The model instance
   */

  // async get(id: ModelId): Promise<T> {
  //   return Model.fromType(
  //     this.modelType,
  //     await firstValueFrom(this.client.get<T>(this.url + '/' + id, this.httpOptions))
  //   );
  // }

  async get(id: ModelId): Promise<T> {
    return Model.fromType(
      this.modelType,
      await this.cloudApiMiddleware.cloudRequest<T>({url: this.url + '/' + id}, 'GET')
    );
  }

  /**
   * Returns all the models from the api
   * 
   * IMPORTANT: Be careful when using this endpoint, as it is intended to return all data.
   * It is intended to be used for smaller datasets that might be cached for use on the client,
   * such as a list of shipping methods or the such
   */

  // async list(): Promise<T[]> {
  //   let results = await firstValueFrom(this.client.get<T[]>(this.url, this.httpOptions));

  //   // The results we receive will be raw data. 
  //   // We want to transform them to model instances of the results
  //   results = results.map(i => Model.fromType(this.modelType, i));

  //   return results;
  // }

  async list(): Promise<T[]> {
    let results = await this.cloudApiMiddleware.cloudRequest<T[]>({url: this.url}, 'GET');

    // The results we receive will be raw data. 
    // We want to transform them to model instances of the results
    results = results.map(i => Model.fromType(this.modelType, i));

    return results;
  }

  /**
   * Query the service for a list of results based on the provided query parameters
   * @param requestParams
   * @returns A QueryResult object containing query information and results
   */

  // async query(
  //   requestParams?: RequestParams
  // ): Promise<QueryResult<T>> {
  //   const url = this.buildEndpointUrl('query', requestParams);
  //   const response = await firstValueFrom(this.client.get<QueryResult<T>>(url, this.httpOptions));

  //   // The results we receive will be raw data. 
  //   // We want to transform them to model instances of the results
  //   response.results = response.results.map(i => Model.fromType(this.modelType, i));

  //   return response;
  // }

  async query(
    requestParams?: RequestParams
  ): Promise<QueryResult<T>> {
    const url = this.buildEndpointUrl('query', requestParams);
    // const response = await firstValueFrom(this.client.get<QueryResult<T>>(url, this.httpOptions));
    const response = await this.cloudApiMiddleware.cloudRequest<QueryResult<T>>({url: url}, 'GET');

    // The results we receive will be raw data. 
    // We want to transform them to model instances of the results
    response.results = response.results.map(i => Model.fromType(this.modelType, i));

    return response;
  }

  /**
   * Add a model to the database
   * @param model The model to add to the database 
   * @returns The model as returned from the add endpoint
   */

  // async add(model: T): Promise<T> {
  //   return Model.fromType(
  //     this.modelType,
  //     await firstValueFrom(this.client.post<T>(this.url, model, this.httpOptions))
  //   );
  // }

  async add(model: T): Promise<T> {
    return Model.fromType(
      this.modelType,
      await this.cloudApiMiddleware.cloudRequest<T>({url: this.url, body: model}, 'POST')
    );
  }

  /**
   * Updates a model on the database
   * @param model The model to update. Please ensure the appropriate primary key field is populated.
   * @returns The model as returned from the update endpoint
   */

  // async update(model: T): Promise<T> {
  //   return Model.fromType(
  //     this.modelType,
  //     await firstValueFrom(this.client.put<T>(this.url, model, this.httpOptions))
  //   );
  // }

  async update(model: T): Promise<T> {
    return Model.fromType(
      this.modelType,
      await this.cloudApiMiddleware.cloudRequest<T>({url: this.url, body: model}, 'PUT')
    );
  }

  /**
   * Delete a model specified by id
   * @param id The id of the model to delete
   * @returns True if the model was successfully deleted
   */

  // async delete(id: ModelId): Promise<boolean> {
  //   return firstValueFrom(this.client.delete<boolean>(this.url + '/' + id, this.httpOptions));
  // }

  async delete(id: ModelId): Promise<boolean> {
    return await this.cloudApiMiddleware.cloudRequest<boolean>({url: this.url + '/' + id}, 'DELETE')
  }
}