import { GtrakAuthService } from "../../../features/authentication/services/gtrak-auth.service";
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(
        private authService: GtrakAuthService,
        private router: Router
    ) {

    }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const user = this.authService.user;
        // Checks if authenticated or there is a token in the url
        if (this.authService.isAuthenticated || (route.queryParams?.token)) {
            return true;
        } else {
            this.authService.Logout().then((status: boolean) => {
                this.router.navigate([user?.homeAuthLocation ?? '']);
            });
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const user = this.authService.user;
        // Checks if authenticated or there is a token in the url
        if(this.authService.isAuthenticated || (route.queryParams?.token)) {
            return true;
        } else {
            this.authService.Logout().then((status: boolean) => {
                this.router.navigate([user?.homeAuthLocation ?? '']);
            });
        }
    }
}