import { Component, OnInit } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController, ViewWillEnter, ViewDidLeave, ViewDidEnter } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { GtrakAuthService } from '../../../authentication/services/gtrak-auth.service';
import { GarmentSummaryService } from "../../../../features/garment-listing/services/garment-summary.service";
import { GarmentDetailService } from '../../../garment-listing/services/garment-detail.service';
import { ToasterService } from '../../../../../app/common/services/toaster.service';
import { NavController } from '@ionic/angular';
import { CreateWorkOrderService } from '../../../../../app/features/g-route/services/create-work-order.service';
import { WorkOrderProduct } from '../../../../../app/features/g-route/models/work-order-product.model';
import { EmailService } from '../../../../../app/common/services/email.service';
import { SMSService } from '../../../../../app/common/services/sms.service';

enum STEP {
  'SELECT' = 1,
  'SUBMIT' = 2
}

@Component({
  selector: 'app-size-change-page',
  templateUrl: 'size-change.page.html',
  styleUrls: [
    './size-change.page.scss'
  ]
})
export class SizeChangePage implements ViewWillEnter, OnInit {
  
  STEP = STEP;
  currentStep: STEP = STEP.SELECT;

  products: WorkOrderProduct[] = [];

  employeeId: string;
  customerId: string;
  departmentId: string;

  garmentSummary: any;
  garments: any;
  loaded: boolean = false;
  empKey: any;

  employeeName: string = null;

  userEmail: string = null;

  currentDate: Date = null;

  selectAll: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: GtrakAuthService,
    public summaryService: GarmentSummaryService,
    public detailService: GarmentDetailService,
    private toasterService: ToasterService,
    private navController: NavController,
    private createWorkOrderService: CreateWorkOrderService,
    private emailService: EmailService,
    private smsService: SMSService
  ) { 
  
  }

  async ngOnInit(): Promise<void> {
    this.loaded = false;
    const promises: any[] = [];

    this.empKey = {
      CustomerCode: this.authService.user.claims.cus_code,
      DepartmentCode: this.authService.user.claims.dep_code,
      EmployeeCode: this.authService.user.claims.emp_code,
    };

    promises.push(this.summaryService.getForEmployee(this.empKey));
    promises.push(this.detailService.getForEmployee(this.empKey));

    const data = await Promise.all(promises);

    this.garmentSummary = data[0];
    const garmentData = data[1];

    this.garments = Object.keys(garmentData).map(key => (garmentData[key]));

    this.employeeName = `${this.garmentSummary?.employeeListing?.firstName} ${this.garmentSummary?.employeeListing?.lastName}`;

    this.garments.forEach(garment => {
      garment.checked = false;
      garment.expanded = false;
      garment.reason = '';
    });

    this.products = await this.createWorkOrderService.GetWoProductList(this.empKey.CustomerCode, this.empKey.EmployeeCode, this.empKey.DepartmentCode);

    this.currentDate = new Date();
    this.userEmail = this.authService?.user?.id as string;

    this.loaded = true;
  }

  navigateBack(): void {
    this.navController.back();
  }

  async next(): Promise<void> {
    if (this.currentStep === STEP.SELECT) {
      const checkedProducts = this.products.filter(p => p.checked);
      if ((checkedProducts.length) <= 0) {
        this.toasterService.presentToast('danger', '', 'Please select at least one product to size change.', 2000);
      } else if (checkedProducts.map(c => c.selectedSize).includes(null)) {
        this.toasterService.presentToast('danger', '', 'Please select a new size for all selected products.', 2000);
      } else {
        this.currentStep = STEP.SUBMIT;

        this.products.forEach(p => {
          p.expanded = false;
        });
      }
    } else {
      
      try {
        // Submit size change request
        const sizeChangeRequest = await this.createWorkOrderService.SubmitWebCatSizeChange(
          this.authService.user.id,
          this.garmentSummary.employeeListing.customerCode,
          this.garmentSummary.employeeListing.employeeCode,
          this.garmentSummary.employeeListing.departmentCode,
          this.garmentSummary.employeeListing.stopCode,
          this.getSelectedProducts(),
          this.userEmail
        );

        if (sizeChangeRequest) {
          const data = {type: sizeChangeRequest.orderType, id: Number(sizeChangeRequest?.woId ?? 0), email: this.userEmail, token: sizeChangeRequest.sessionCode};
          // Send confimation email
          await Promise.all([
            this.emailService.sendOrderConfirmation(data),
            this.smsService.sendWebOrderSMS(Number(sizeChangeRequest?.woId ?? 0), sizeChangeRequest.sessionCode)
          ]);
          // Success popup
          this.toasterService.presentToast('primary', 'Thank you for your order! You will be getting a confimation email/text shortly.', 'Request Confirmed', 4000);
          this.navController.navigateRoot(['/mobile']);
        }

      } catch (error) {
        this.toasterService.presentToast('danger', '', 'Something went wrong.', 2000);
      }
    }
  }

  back(): void {
    this.currentStep = STEP.SELECT;
  }

  selectAllChange(event: any): void {
    this.products.forEach(product => {
      product.checked = event.target.checked;
    });
  }

  getSelectedProducts(): WorkOrderProduct[] {
    return this.products.filter(p => p.checked);
  } 

  sizeChangeEvent(product: WorkOrderProduct): void {
    product.checked = true;
  }

  ionViewWillEnter(): void {


  }


}