import { Component, OnInit } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { AccountManagementRoutes } from '../../account-management.routes';
import { Router, ActivatedRoute } from '@angular/router';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';
import { RequestParams } from '../../../../common/rest-api/models/request-params';
import { AccountManagementService } from '../../services/account-management.service';
import { WebLogin } from '../../models/web-login.model';
import { ToasterService } from '../../../../common/services/toaster.service';
import { WebLoginWithReports } from '../../models/web-login-with-reports.model';
import { Customer } from '../../../../common/models/customer-model';
import { WebLoginDept } from '../../models/web-login-dept.model';
import { CustomerDepartment } from '../../../../common/models/customer-department-model';

@Component({
  selector: 'app-edit-user-page',
  templateUrl: 'edit-user.page.html',
  styleUrls: [
    './edit-user.page.scss',
    '../../styles/account-management.styles.scss',
  ],
})
export class EditUserPage implements OnInit, ViewWillEnter {
  currentRoute: string;

  customerCancelReport: boolean = false;
  garmentReturnReview: boolean = false;

  login: string;
  password: string;
  firstName: string;
  lastName: string;
  userStatus: string = '0';
  userType: string = 'user';
  placeOrders: boolean;
  viewInvoices: boolean;
  viewTransfers: boolean;
  requestParams: any;
  results: WebLoginWithReports;
  type: any;
  customer: string;
  department: string[];
  customerList: Customer[];
  departmentList: CustomerDepartment[];

  statusCodes = {
    0: 'Active',
    1: 'Disabled',
  };

  userTypeCodes = {
    0: 'User',
    1: 'Admin',
    2: 'Internal User',
    4: 'Route Driver',
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: GtrakAuthService,
    private accountService: AccountManagementService,
    private toasterService: ToasterService
  ) {
    this.currentRoute = null;
  }

  ngOnInit(): void {
    this.requestParams = new RequestParams();
    if (!this.authService.user.canViewAccountManagement) {
      this.router.navigate([this.authService.user.homeLocation]);
    }

    this.currentRoute = this.router.url;
  }

  async ionViewWillEnter() {
    this.login = this.route.snapshot.paramMap.get('login');
    this.getAccount();
    await this.getCustomerCodes();
    if (this.customer) {
      this.getDepartmentCodes(this.customer, false);
    }
  }

  selectUserType(type): void {
    if (type.detail.value == 'User') {
      this.userType = 'user';
    } else if (type.detail.value == 'Admin') {
      this.userType = 'admin';
    } else if (type.detail.value == 'Route Driver') {
      this.userType = 'driver';
    } else {
      this.userType = 'user';
    }
  }

  async getAccount() {
    this.results = await this.accountService.GetUserByLogin(this.login);
    const user = this.results.webLogin;
    // Code 9 is cancel report and 10 is return review
    const reports = this.results.webReports;
    const departments = this.results.webLoginDept;
    console.log(this.results);

    if (reports.find((r) => r.reportId === 9)) {
      this.customerCancelReport = true;
    }

    if (reports.find((r) => r.reportId === 10)) {
      this.garmentReturnReview = true;
    }

    this.userStatus = user.disabled;

    this.firstName = user.fname;
    this.lastName = user.lname;
    this.customer = user.customerCode;
    //for each department in the array, push the department code to the department array
    this.department = [];
    departments.forEach((department) => {
      //filter any departments that are not associated with the customer code
      if (department.customerCode === user.customerCode) {
        this.department.push(department.departmentCode);
      }
    });
    console.log(this.department);

    //check if placeOrder is one or zero
    if (user.placeOrder === '1') {
      this.placeOrders = true;
    } else {
      this.placeOrders = false;
    }
    //check if viewInvoices is one or zero
    if (user.viewInvoice === '1') {
      this.viewInvoices = true;
    } else {
      this.viewInvoices = false;
    }
    //check if viewTransfers is one or zero
    if (user.viewEmpTransfer === '1') {
      this.viewTransfers = true;
    } else {
      this.viewTransfers = false;
    }
    switch (user.userType) {
      case 'user':
      case 'USER':
        this.type = 'User';
        break;
      case 'admin':
        this.type = 'Admin';
        break;
      case 'driver':
        this.type = 'Route Driver';
        break;
    }
  }

  async submit() {
    var wlr = new WebLoginWithReports();
    wlr.webLogin = new WebLogin();
    wlr.webLogin.login = this.login;
    wlr.webLogin.password = this.password;
    wlr.webLogin.fname = this.firstName;
    wlr.webLogin.lname = this.lastName;
    wlr.webLogin.disabled = this.userStatus;
    wlr.webLogin.userType = this.userType;
    if (this.placeOrders == true) {
      wlr.webLogin.placeOrder = '1';
    } else {
      wlr.webLogin.placeOrder = '0';
    }
    if (this.viewInvoices == true) {
      wlr.webLogin.viewInvoice = '1';
    } else {
      wlr.webLogin.viewInvoice = '0';
    }
    if (this.viewTransfers == true) {
      wlr.webLogin.viewEmpTransfer = '1';
    } else {
      wlr.webLogin.viewEmpTransfer = '0';
    }
    wlr.webLogin.customerCode = this.customer ?? '';
    wlr.webLogin.departmentCode = this.department[0] ?? '';
    wlr.webLogin.employeeCode = '';

    //for each department in the department array, create a new WebLoginDept object and push it to the webLoginDept array
    wlr.webLoginDept = [];
    this.department.forEach((department) => {
      var wld = new WebLoginDept();
      wld.login = this.login;
      wld.customerCode = this.customer;
      wld.departmentCode = department;
      wlr.webLoginDept.push(wld);
    });

    try {
      let resp = await this.accountService.EditUser(wlr);
      const reports = this.results.webReports;

      // Remove user from cancel report
      if (reports.find((r) => r.reportId === 9) && !this.customerCancelReport) {
        await this.accountService.RemoveReport(this.login, 9);
      }

      // Add user from cancel report
      if (!reports.find((r) => r.reportId === 9) && this.customerCancelReport) {
        await this.accountService.AddReport(this.login, 9);
      }

      // Remove user from review report
      if (reports.find((r) => r.reportId === 10) && !this.garmentReturnReview) {
        await this.accountService.RemoveReport(this.login, 10);
      }

      // Add user from review report
      if (!reports.find((r) => r.reportId === 10) && this.garmentReturnReview) {
        await this.accountService.AddReport(this.login, 10);
      }

      this.toasterService.presentToast(
        'primary',
        '',
        'User updated successfully.',
        1000
      );
      this.password = '';
      this.router.navigate(['/account-management']);
    } catch (error) {
      console.log(error);
      this.toasterService.presentToast(
        'danger',
        '',
        'An error occurred while updating user. Please try again.',
        1000
      );
    }
  }

  async getCustomerCodes() {
    this.customerList = await this.accountService.GetCustomerCodes();
    console.log(this.customerList);
  }

  async getDepartmentCodes(event, inHTML = false) {
    if (inHTML) {
      this.departmentList = [];
      this.department = [];
    }
    this.departmentList = await this.accountService.GetDepartmentsByCustomer(
      event
    );
    console.log(this.departmentList);
  }
}
