import { Component, Type } from "@angular/core";
import { Data, LoadChildrenCallback, Route } from "@angular/router";

export class AppRoute implements Route {
  segment: string;
  title: string;
  path: string;
  parent?: AppRoute;
  data?: Data;
  component?: Type<any>;
  canActivate?: any[];

  loadChildren: LoadChildrenCallback;

  get fullPath() { return this.parent ? `${this.parent.path}/${this.segment}` : `${this.segment}`; }

  constructor(args: {
    segment: string,
    title: string,
    parent?: AppRoute,
    loadChildren?: LoadChildrenCallback;
    data?: Data;
    component?: Type<any>,
    canActive?: any[],
  }) {
    this.segment = args.segment;
    this.title = args.title;
    this.loadChildren = args.loadChildren;
    this.parent = args.parent;
    this.path = this.parent ? `${this.parent.path}/${this.segment}` : `${this.segment}`;
    this.path = this.segment;
    this.data = args.data ?? {};
    this.component = args.component;
    this.canActivate = args.canActive;
    
    this.data.appRoute = this;
  };
}