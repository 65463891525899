import { Data } from "@angular/router";
import { Model, ModelId } from "../../../common/rest-api/models/model";

export class CustomerList extends Model
{
  get modelId(): ModelId {
    throw new Error("Method not implemented.");
  }

    customerCode: string;
    customerName: string;

  public assign(data: Data): void {
    Object.assign(this, data);
  }
}