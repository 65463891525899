import { AppRoute } from '../../../core/routing/AppRoute';
import { AccountManagementModule } from './account-management.module';
import { AccountManagementPage } from './pages/account-management-page/account-management.page';
import { EditUserPage } from './pages/edit-user-page/edit-user.page';
import { EditMobileUserPage } from './pages/edit-mobile-user-page/edit-mobile-user.page';
import { AddMobileUserPage } from './pages/add-mobile-user-page/add-mobile-user.page';
import { AddUserPage } from './pages/add-user-page/add-user.page';

import { AuthGuard } from '../../common/rest-api/services/auth-guard.service';

// A feature route should be used in the App routing module in most cases and primarily provide
// a base url and module loading functionality.
// The feature route should employ a load children callback that loads the appropriate module
const Feature = new AppRoute({
  segment: 'account-management',
  title: 'Account Management',
  // A component can also be included to be the content, or a separate route with a blank segment
  // can be defined like below to provide a dedicated route instance
  // component: GarmentListingPage,
  loadChildren: () => import('./account-management.module').then(m => m.AccountManagementModule),
  canActive: [AuthGuard]
});

// "Sub-routes" should be the contentful endpoints for the feature. To provide content at the base
// level of the feature url, simply leave segment blank.
// Provide the parent parameter to be able to derive the fullPath of a route that includes the base
const Report = new AppRoute({
  segment: '',
  title: 'Account Management',
  component: AccountManagementPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const EditUser = new AppRoute({
    segment: 'edit/:login',
    title: 'Edit User',
    component: EditUserPage,
    parent: Feature,
    canActive: [AuthGuard]
});

const EditMobileUser = new AppRoute({
    segment: 'edit-mobile/:login',
    title: 'Edit Mobile User',
    component: EditMobileUserPage,
    parent: Feature,
    canActive: [AuthGuard]
});

const AddUser = new AppRoute({
    segment: 'add',
    title: 'Add User',
    component: AddUserPage,
    parent: Feature,
    canActive: [AuthGuard]
});

const AddMobileUser = new AppRoute({
    segment: 'add-mobile',
    title: 'Add Mobile User',
    component: AddMobileUserPage,
    parent: Feature,
    canActive: [AuthGuard]
});

export const AccountManagementRoutes = {
  Feature: Feature,
  Report: Report,
  EditUser: EditUser,
  EditMobileUser: EditMobileUser,
  AddUser: AddUser,
  AddMobileUser: AddMobileUser,
}