<ion-content class="department-cancel">
    <ng-container>
        <app-header [title]="'Department Cancel Order'"></app-header>
        <ion-spinner name="circular" *ngIf="!loaded" class="spinner"></ion-spinner>
        <div *ngIf="loaded" class="cancel-container">
            <ion-grid *ngFor="let wo of cancelOrders" class="main-grid">
                <ion-row>
                    <ion-col class="bold">
                        {{wo[0].lastName}}, {{wo[0].firstName}}
                    </ion-col>
                    <ion-col>
                        <strong>Cancel Order:</strong> {{wo[0].woCode}}
                    </ion-col>
                    <ion-col>
                        <strong>Start Date:</strong> {{wo[0].orderDate | date: 'MM/dd/yyyy'}}
                    </ion-col>
                </ion-row>
                <ion-row class="ion-justify-content-end ion-align-items-center">
                    <ion-col class="ion-justify-content-end ion-align-items-center">
                        <strong>Stop:</strong> {{wo[0].stopName}}
                    </ion-col>
                    <ion-col class="ion-justify-content-end ion-align-items-center">
                        <strong>Gar. ID Label:</strong>
                    </ion-col>
                </ion-row>
                <ion-grid class="data-grid">
                    <ion-row class="data-headers">
                      <ion-col size="2">
                        <strong>Description</strong>
                      </ion-col>
                      <ion-col size="4">
                        <strong>Product</strong>
                      </ion-col>
                      <ion-col size="2">
                        <strong>Barcode #</strong>
                      </ion-col>
                      <ion-col size="2">
                        <strong>Date Collected</strong>
                      </ion-col>
                      <ion-col size="2">
                        <strong>Loss Charge</strong>
                      </ion-col>
                    </ion-row>
    
                    <ion-row *ngFor="let item of wo">
                      <ion-col size="2">
                        {{item.description}}
                      </ion-col>
                      <ion-col size="4">
                        {{item.product}}
                      </ion-col>
                      <ion-col size="2">
                        {{item.barcode}}
                      </ion-col>
                      <ion-col size="2">
                        {{item.dateCollected === null ? 'Not Collected' : item.dateCollected | date: 'MM/dd/yyyy'}}
                      </ion-col>
                      <ion-col size="2">
                        {{item.dateCollected === null ? (item.lossCharge | currency: 'USD') : '$0.00'}}
                      </ion-col>
                    </ion-row>
                    <ion-row class="data-footer">
                        <ion-col>
                            <strong>Final Pickup Date and Potential Charges:</strong> 
                        </ion-col>
                        <ion-col>
                            <strong>{{wo.lossCharge | currency: 'USD'}}</strong>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-grid>
            <ion-grid>
                <ion-row class="grand-total">
                    <ion-col>
                        <strong>Grand Total Potential Loss Charge</strong>
                    </ion-col>
                    <ion-col>
                        <strong>{{totalLossCharge | currency: 'USD'}}</strong>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </div>
    </ng-container>
</ion-content>