<ion-header>
    <ion-toolbar color="gallaghergreen">
      <img class="gallagher-logo" [src]="'./assets/images/Gallagher Logo -White Transparent.png'" />
    </ion-toolbar>
</ion-header>
  
<ion-content class="login-content">
  <div class="parent-container">
    <ion-card [ngClass]="{'larger-width': authService.isNative}">
      <ion-card-header>
        <span class="login-title">Log In</span>
      </ion-card-header>
      <ion-grid>
        <ion-row class="sign-in-options" *ngIf="!authService.isNative && showOptionsBar">
          <ion-col [ngClass]="{'selected': loginMode === LoginMode.Gtrak}" (click)="changeLoginForm(LoginMode.Gtrak)">
            User Id / Password
          </ion-col>
          <ion-col [ngClass]="{'selected': loginMode === LoginMode.Gmobile}" (click)="changeLoginForm(LoginMode.Gmobile)">
            Locker # / Stop #
          </ion-col>
          <ion-col [ngClass]="{'selected': loginMode === LoginMode.Groute}" (click)="changeLoginForm(LoginMode.Groute)">
            G-route
          </ion-col>
        </ion-row>
        <div *ngIf="(loginMode === LoginMode.Gtrak) || (loginMode === LoginMode.Gmobile)">
          <ion-row>
            <ion-col>
              <ion-label>User Id</ion-label>
              <ion-input autocapitalize="none" autocomplete="email" type="text" [(ngModel)]="creds.login" placeholder="Enter your user id" class="ion-text-left color-light user-id"></ion-input>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label>Password</ion-label>
              <app-show-hide-password>
                <ion-input type="password" autocomplete="password" autocapitalize="none" [(ngModel)]="creds.password" placeholder="Enter your password" class="ion-text-left color-light"></ion-input>
              </app-show-hide-password>
            </ion-col>
          </ion-row>
        </div>
        <div *ngIf="loginMode === LoginMode.GWearer">
          <ion-row>
            <ion-col>
              <ion-label>Stop #</ion-label>
              <ion-input type="text" [(ngModel)]="creds.stopCode" placeholder="Enter your stop #" class="ion-text-left color-light user-id"></ion-input>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label>Locker #</ion-label>
              <ion-input type="text" [(ngModel)]="creds.lockerNum" placeholder="Enter your locker #" class="ion-text-left color-light user-id"></ion-input>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label>Last Name</ion-label>
              <ion-input type="text" [(ngModel)]="creds.lastName" placeholder="Enter your last name" class="ion-text-left color-light user-id"></ion-input>
            </ion-col>
          </ion-row>
        </div>
        <div *ngIf="loginMode === LoginMode.Groute">
          <ion-row>
            <ion-col>
              <ion-label>User Id</ion-label>
              <ion-input autocapitalize="none" autocomplete="email"  type="text" [(ngModel)]="creds.login" placeholder="Enter your user id" class="ion-text-left color-light user-id"></ion-input>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label>Password</ion-label>
              <app-show-hide-password>
                <ion-input type="password" autocomplete="password" autocapitalize="none" [(ngModel)]="creds.password" placeholder="Enter your password" class="ion-text-left color-light"></ion-input>
              </app-show-hide-password>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label><span>Routes</span></ion-label>
              <ion-select interface="popover" placeholder="Select a Route" (ionChange)="selectRoute($event)" [interfaceOptions]="{size: 'cover'}">
                <ion-select-option *ngFor="let route of routeList" [value]="route.routeCode">{{route.description}}</ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col>
              <ion-label>For Date</ion-label>
              <ion-input type="Date" [(ngModel)]="creds.routeDate" class="ion-text-left color-light user-id"></ion-input>
            </ion-col>
          </ion-row>
        </div>
        <ion-row>
          <ion-col>
            <ion-button color="gallaghergreen" class="btn-box-shadow submit-button" (click)="submit()"><span>Log in</span></ion-button>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="(loginMode === LoginMode.Gmobile)" class="forgot-password">
          <ion-col>
            <span (click)="navigateToForgotPassword()">Forgot password?</span>
          </ion-col>
        </ion-row>
  
        <ion-row *ngIf="(loginMode === LoginMode.Gtrak) || (loginMode === LoginMode.GWearer)" class="garment-label-button">
          <ion-col></ion-col>
          <ion-col class="garment-label-data-col" size="auto">
            <span *ngIf="(loginMode === LoginMode.Gtrak)" (click)="changeLoginForm(LoginMode.GWearer)">Log in with garment label</span>
            <span *ngIf="(loginMode === LoginMode.GWearer)" (click)="changeLoginForm(LoginMode.Gtrak)">Log in with Username/Password</span>
          </ion-col>
        </ion-row>
  
        <ion-row *ngIf="(loginMode === LoginMode.Gmobile)" class="garment-label-button">
          <ion-col></ion-col>
          <ion-col class="new-to-gtrak" size="auto">
            <span>New to G-Trak?</span>
          </ion-col>
          <ion-col (click)="navigateToCreateAccount()" class="register-text" size="auto">
            <span>Register Here</span>
          </ion-col>
          <ion-col></ion-col>
        </ion-row>
      </ion-grid>
    </ion-card>
  </div>
</ion-content>