import { Injectable, Type } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { QueryResult } from '../../../common/rest-api/models/query-result';
import { RequestParams } from '../../../common/rest-api/models/request-params';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { Invoice } from '../models/invoice-details.model';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService extends RestApiService<Invoice> {

  protected resourcePath = '/invoice-details';
  protected modelType = Invoice;
  invoicePath = 'invoice';

  async getInvoiceDetails(params: RequestParams, invoiceNum: string) {
    const url = this.buildEndpointUrl(`query/${invoiceNum}`)

    const response = Invoice.from(await this.cloudApiMiddleware.cloudRequest<QueryResult<Invoice>>({
      url
    }, 'GET'));

    return response
  }

}