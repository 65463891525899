<app-top-bar></app-top-bar>
<ion-content>
    <div (click)="navigateBack()" class="back-button-mobile">
        <ion-icon name="chevron-back-outline"></ion-icon>
    </div>
    <h1 class="title">QUESTIONS</h1>
    <div class="content">
        <h2>How Can We Help?</h2>
        <textarea [(ngModel)]="content" id="text-input"></textarea>
        <div class="buttons">
            <button (click)="submitQuestion()">Submit</button>
            <button (click)="textReset()">Reset</button>
        </div>
    </div>
</ion-content>
<app-footer></app-footer>