import { Data } from "@angular/router";
import { Model, ModelId } from "../../../../../../../common/rest-api/models/model";

export class StopInvoice extends Model {
    get modelId(): ModelId {
        throw new Error("Method not implemented.");
    }

    InvoiceCode: string;
    DateOfInvoice: Date;
    RouteCode: string; 
    RouteDay: number; 
    RouteWeek: number; 
    CustomerCode: string;
    DepartmentCode: string; 
    ChargeType: string; 
    Tax1Rate: number; 
    Tax1Amount: number;
    Tax2Rate: number; 
    Tax2Amount: number; 
    PrebillAmount: number; 
    AmountDue: number; 
    MinimumDue: number; 
    AmountPaid: number; 
    Comments: string; 
    State: number; 
    PoNum: string; 
    ContractNum: string;
    StopCode: string; 
    DueDate: Date; 
    Tax3Rate: string; 
    Tax3Amount: string; 
    TermCode: string; 
    Audit: string;
    AuditCmplt: string;
}

export class StopInvoiceReport extends Model {
    get modelId(): ModelId {
        throw new Error("Method not implemented.");
    }

    invoices: StopInvoice[] = [];
}