import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { PredefinedColors } from '@ionic/core';

@Injectable({
    providedIn: 'root'
})
export class ToasterService {
  
    constructor(
      private toastr: ToastController
    ) { }

    //#region Toastr
    /**
    *
    * @param color
    * @param message
    * @param header
    * @param duration
    */
    async presentToast(color: PredefinedColors, message: string, header: string, duration: number = 5000) {
      const toast = await this.toastr.create({
        color,
        message,
        header,
        duration,
        position: 'top'
      });

      toast.present();
    }

}