import { AppRoute } from '../../../core/routing/AppRoute';
import { WorkOrderPage } from './pages/work-order-page/work-order.page';
import { CustomerWorkOrderReportPage } from './pages/customer-work-order-report/customer-work-order-report.page';
import { DepartmentWorkOrderReportPage } from './pages/department-work-order-report/department-work-order-report.page';
import { EmployeeWorkOrderReportPage } from './pages/employee-work-order-report/employee-work-order-report.page';

import { AuthGuard } from '../../common/rest-api/services/auth-guard.service';

// A feature route should be used in the App routing module in most cases and primarily provide
// a base url and module loading functionality.
// The feature route should employ a load children callback that loads the appropriate module
const Feature = new AppRoute({
  segment: 'work-order',
  title: 'Work Orders',
  // A component can also be included to be the content, or a separate route with a blank segment
  // can be defined like below to provide a dedicated route instance
  // component: GarmentListingPage,
  loadChildren: () => import('./work-order.module').then(m => m.WorkOrderModule),
  canActive: [AuthGuard]
});

// "Sub-routes" should be the contentful endpoints for the feature. To provide content at the base
// level of the feature url, simply leave segment blank.
// Provide the parent parameter to be able to derive the fullPath of a route that includes the base
const Report = new AppRoute({
  segment: '',
  title: 'Work Order Report',
  component: WorkOrderPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const Customer = new AppRoute({
  segment: 'customer/:id',
  title: 'Work Order Report Customer',
  component: CustomerWorkOrderReportPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const Department = new AppRoute({
  segment: 'department/:id/:cid',
  title: 'Work Order Report Department',
  component: DepartmentWorkOrderReportPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const Employee = new AppRoute({
  segment: 'employee/:id/:cid/:did',
  title: 'Work Order Report Employee',
  component: EmployeeWorkOrderReportPage,
  parent: Feature,
  canActive: [AuthGuard]
});


export const WorkOrderRoutes = {
  Feature: Feature,
  Report: Report,
  Customer: Customer,
  Department: Department,
  Employee: Employee
}