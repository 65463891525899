import { Model, ModelId } from "../../../common/rest-api/models/model";

export class RouteDeliveryDetails extends Model {
  get modelId(): ModelId {
    throw new Error("Method not implemented.");
  }
  routeCode: string = "";
  seqNumber: number = 0;
  createDate: Date = new Date();
  forDate: Date = new Date();
  day: number = 0;
  week: number = 0;
  stopCode: string = "";
  deliveryTime: Date = new Date();
  deliverySequence: number = 0;
  customerCode: string = "";
  departmentCode: string = "";
  custDeptName?: string;
  employeeCode: string = "";
  employeeName?: string;
  productCode: string = "";
  productType: number = 0;
  productCategory?: string;
  productName: string = "";
  productQuota: number = 0;
  productLastUsedQty: number = 0;
  invoiceRef: string = "";
  showOnCountCard: string = "";
  showOnPickCard: string = "";
  showOnLoadingCard: string = "";
  delGrade?: string;
  schId?: number;
}

export class RouteDeliveryDetailsFreq {
  routeDeliveryDetail: RouteDeliveryDetails;
  frequency: string;
}

export class RouteDeliveryDetailsPickup {
  seqNum: number;
  countDate: Date | null;
  qty: number | null;
  login: string | null;
  departmentCode: string | null;
  productCode: string | null;
  routeCode: string | null;
  customerCode: string | null;
  stopCode: string | null;
  categoryCode: string | null;
  qtyAsString: string | null;
}
