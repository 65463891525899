<ion-content class="edit-mobile-user">
    <app-header [title]="'Edit Mobile User'"></app-header>
    <ng-container>
        <ion-grid>
            <ion-row class="user-form">
                <ion-grid>
                    <ion-row>
                        <ion-col>
                            <ion-label>Login</ion-label>
                            <div>
                                <ion-input type="text" [(ngModel)]="login" placeholder="Enter login" class="ion-text-left color-light"></ion-input>
                            </div>
                        </ion-col>
                        <ion-col>
                            <ion-label>Last Login</ion-label>
                            <div>
                                <ion-input [disabled]="true" type="text" [(ngModel)]="lastLogin" class="ion-text-left mobile-disable"></ion-input>
                            </div>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-label>New Password</ion-label>
                            <div>
                                <ion-input type="text" [(ngModel)]="password" placeholder="Enter new password (not required)" class="ion-text-left color-light"></ion-input>
                            </div>
                        </ion-col>
                        <ion-col>
                            <ion-label>Customer Code</ion-label>
                            <div>
                                <ion-input type="text" [(ngModel)]="customerCode" placeholder="Enter customer code" class="ion-text-left color-light"></ion-input>
                            </div>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-label>Department Code</ion-label>
                            <div>
                                <ion-input type="text" [(ngModel)]="departmentCode" placeholder="Enter department code" class="ion-text-left color-light"></ion-input>
                            </div>
                        </ion-col>
                        <ion-col>
                            <ion-label>Employee Code</ion-label>
                            <div>
                                <ion-input type="text" [(ngModel)]="employeeCode" placeholder="Enter employee code" class="ion-text-left color-light"></ion-input>
                            </div>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-label>First Name</ion-label>
                            <div>
                                <ion-input type="text" [(ngModel)]="firstName" placeholder="Enter first name" class="ion-text-left color-light"></ion-input>
                            </div>
                        </ion-col>
                        <ion-col>
                            <ion-label>Last Name</ion-label>
                            <div>
                                <ion-input type="text" [(ngModel)]="lastName" placeholder="Enter last name" class="ion-text-left color-light"></ion-input>
                            </div>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-label><span>User Status:</span></ion-label>
                            <ion-select [(ngModel)]="active" interface="popover" placeholder="Select Active or Disabled" value="Active" (ionChange)="selectUserStatus($event)">
                                <ion-select-option value="Active">Active</ion-select-option>
                                <ion-select-option value="Disabled">Disabled</ion-select-option>
                            </ion-select>
                        </ion-col>
                        <ion-col>
                            <ion-label><span>Order Request Alerts:</span></ion-label>
                            <ion-select [(ngModel)]="notification" interface="popover" placeholder="Select Notifcation Preference" value="Email and Text" (ionChange)="selectAlertStatus($event)">
                                <ion-select-option value="Email and Text">Email and Text</ion-select-option>
                                <ion-select-option value="Email Only">Email Only</ion-select-option>
                                <ion-select-option value="Text Only">Text Only</ion-select-option>
                            </ion-select>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-label>Mobile Number</ion-label>
                            <div>
                                <ion-input type="text" [(ngModel)]="phoneNumber" placeholder="Enter phone number" class="ion-text-left color-light"></ion-input>
                            </div>
                        </ion-col>
                        <ion-col>
                            <ion-label><span>User Type</span></ion-label>
                            <ion-select [(ngModel)]="type" interface="popover" placeholder="Select User Type" value="User" (ionChange)="selectUserType($event)">
                                <ion-select-option value="User">User</ion-select-option>
                                <ion-select-option value="Admin">Admin</ion-select-option>
                                <ion-select-option value="Internal User">Internal User</ion-select-option>
                                <ion-select-option value="Route Driver">Route Driver</ion-select-option>
                            </ion-select>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col>
                            <ion-item>
                                <ion-checkbox [(ngModel)]="deliveryNotifications" slot="start"></ion-checkbox>
                                <ion-label>Delivery Notifications</ion-label>
                            </ion-item>
                        </ion-col>
                        <ion-col>
                            <ion-button color="gallaghergreen" class="btn-box-shadow btn-margin" (click)="submit()"><span>Submit</span></ion-button>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-row>

        </ion-grid>
    </ng-container>
</ion-content>