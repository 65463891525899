<ion-content class="garment-listing">
    <app-header class="app-header" [title]="'Garment Return Review'"></app-header>
    <ng-container *ngIf="loaded">
        <ion-grid>
            <ion-row class="info-header">
                <ion-col class="ion-no-padding">
                    <ion-row *ngIf="customer">
                        <ion-col class="ion-no-padding">
                            <ion-row>
                                <ion-col class="ion-no-padding">
                                    Cust: {{customerCode}}
                                </ion-col>
                                <ion-col class="ion-no-padding">
                                    {{customer.name}}
                                </ion-col>
                            </ion-row>
                        </ion-col>
                        <ion-col class="ion-no-padding">
                            <ion-row>
                                <ion-col class="ion-no-padding">

                                </ion-col>
                                <ion-col class="ion-no-padding" size="auto">
                                    Report Date: {{currentDate | date:'shortDate'}}
                                </ion-col>
                            </ion-row>
                        </ion-col>
                    </ion-row>
                    <ion-row *ngIf="department">
                        <ion-col class="ion-no-padding">
                            <ion-row>
                                <ion-col class="ion-no-padding">
                                    Dept: {{departmentCode}}
                                </ion-col>
                                <ion-col class="ion-no-padding">
                                    {{department.description}}
                                </ion-col>
                            </ion-row>
                        </ion-col>
                        <ion-col class="ion-no-padding">

                        </ion-col>
                    </ion-row>
                    <ion-row *ngIf="employee">
                        <ion-col class="ion-no-padding">
                            <ion-row>
                                <ion-col class="ion-no-padding">
                                    Emp: {{employeeCode}}
                                </ion-col>
                                <ion-col class="ion-no-padding">
                                    {{employee.lastName}}, {{employee.firstName}}
                                </ion-col>
                            </ion-row>
                        </ion-col>
                        <ion-col class="ion-no-padding">

                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col class="ion-no-padding">

                        </ion-col>
                        <ion-col class="ion-no-padding" size="auto">
                            <button class="printer-button" (click)="changePrinterView()">Print</button>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>

            <ng-container *ngFor="let group of groupedHistory">
                <div class="item-container">
                    <div class="item-header">
                        <ion-row>
                            <ion-col class="ion-no-padding">
                                <span class="bold-text">{{group.name}}</span>&nbsp;<span>{{group.departmentEmployeeCode}}</span>
                            </ion-col>
                            <ion-col class="ion-no-padding">
                                <span class="bold-text">Cancel Order: </span> <span>{{group.cancelOrder}}</span>
                            </ion-col>
                            <ion-col class="ion-no-padding">
                                <span class="bold-text">Days Allowed: </span> <span>{{group.daysAllowed}}</span>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col class="ion-no-padding">
                               
                            </ion-col>
                            <ion-col class="ion-no-padding">
                                <span class="bold-text">Stop: </span> <span>{{group.stop}}</span>
                            </ion-col>
                            <ion-col class="ion-no-padding">
                                <span class="bold-text">Gar. ID Label: </span> <span>{{group.garmentId}}</span>
                            </ion-col>
                        </ion-row>
                    </div>
                    <div class="item-content">
                        <ion-row class="content-header">
                            <ion-col size="4" class="ion-no-padding border-right">
                                <span class="bold-text">Product</span>
                            </ion-col>
                            <ion-col size="2" class="ion-no-padding border-right">
                                <span class="bold-text">Barcode #</span>
                            </ion-col>
                            <ion-col size="2" class="ion-no-padding border-right">
                                <span class="bold-text">Action</span>
                            </ion-col>
                            <ion-col size="2" class="ion-no-padding border-right">
                                <span class="bold-text">Action Date</span>
                            </ion-col>
                            <ion-col size="2" class="ion-no-padding">
                                <span class="bold-text">Late Return</span>
                            </ion-col>
                        </ion-row>

                        <ng-container *ngFor="let item of group.items">
                            <ion-row class="content-row">
                                <ion-col size="4" class="ion-no-padding border-right">
                                    <span>{{item.name}}</span>
                                </ion-col>
                                <ion-col size="2" class="ion-no-padding border-right">
                                    <span>{{item.garmentCode}}</span>
                                </ion-col>
                                <ion-col size="2" class="ion-no-padding border-right">
                                    <span>{{getActionType(item)}}</span>
                                </ion-col>
                                <ion-col size="2" class="ion-no-padding border-right">
                                    <span>{{item.actionDate | date:'shortDate'}}</span>
                                </ion-col>
                                <ion-col size="2" class="ion-no-padding">
                                    <span>{{item.lateReturn ? 'Yes' : 'No'}}</span>
                                </ion-col>
                            </ion-row>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </ion-grid>
    </ng-container>

    <ng-container *ngIf="!loaded">
        <div class="spinner-container">
            <ion-spinner></ion-spinner>
        </div>
    </ng-container>
</ion-content>
