import { AppRoute } from '../../../core/routing/AppRoute';
import { LoginPage } from './pages/login-page/login.page';
import { CreateAccountPage } from './pages/create-account/create-account.page';
import { ForgotPasswordPage } from './pages/forgot-password/forgot-password.page';
import { ResetPasswordPage } from './pages/reset-password/reset-password.page';


// A feature route should be used in the App routing module in most cases and primarily provide
// a base url and module loading functionality.
// The feature route should employ a load children callback that loads the appropriate module
const Feature = new AppRoute({
  segment: '',
  title: 'Login',
  // A component can also be included to be the content, or a separate route with a blank segment
  // can be defined like below to provide a dedicated route instance
  // component: GarmentListingPage,
  loadChildren: () => import('./authentication.module').then(m => m.AuthenticationModule)
});

// "Sub-routes" should be the contentful endpoints for the feature. To provide content at the base
// level of the feature url, simply leave segment blank.
// Provide the parent parameter to be able to derive the fullPath of a route that includes the base
const Login = new AppRoute({
  segment: '',
  title: 'Login',
  component: LoginPage,
  parent: Feature
});

const CreateAccount = new AppRoute({
  segment: 'create-account',
  title: 'Create Account',
  component: CreateAccountPage,
  parent: Feature
});

const ForgotPassword = new AppRoute({
  segment: 'forgot-password',
  title: 'Forgot Password',
  component: ForgotPasswordPage,
  parent: Feature
});

const ResetPassword = new AppRoute({
  segment: 'reset-password/:token',
  title: 'Reset Password',
  component: ResetPasswordPage,
  parent: Feature
});

export const AuthenticationRoutes = {
  Feature: Feature,
  Login: Login,
  CreateAccount: CreateAccount,
  ForgotPassword: ForgotPassword,
  ResetPassword: ResetPassword
}