import { Component, OnInit } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { AccountManagementRoutes } from '../../account-management.routes';
import { Router, ActivatedRoute } from '@angular/router';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';
import { WebLogin } from '../../models/web-login.model';
import { AccountManagementService } from '../../services/account-management.service';
//const testData = require('../../../../common/test-data/customer-data.json');

@Component({
  selector: 'app-add-mobile-user-page',
  templateUrl: 'add-mobile-user.page.html',
  styleUrls: [
    './add-mobile-user.page.scss',
    '../../styles/account-management.styles.scss'
  ]
})
export class AddMobileUserPage implements OnInit, ViewWillEnter {

  currentRoute: string;

  statusCodes = {
    0: 'Active',
    1: 'Disabled'
  };

  alertStatusCodes = {
    0: 'Email and Text',
    1: 'Email Only',
    2: 'Text Only'
  };

  userTypeCodes = {
    0: 'User',
    1: 'Admin',
    2: 'Internal User',
    4: 'Route Driver'
  };

  login: string;
  lastLogin: string;
  password: string;
  customerCode: string;
  departmentCode: string;
  employeeCode: string;
  firstName: string;
  lastName: string;
  userStatus: number = 0;
  alertStatus: number = 0;
  phoneNumber: string;
  userType: string = 'USER';
  response: any;
  deliveryNotifications: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: GtrakAuthService,
    private accountService: AccountManagementService,
  ) { 
    this.currentRoute = null;
  }

  ngOnInit(): void {
    if(!this.authService.user.canViewAccountManagement) {
      this.router.navigate([this.authService.user.homeLocation]);
    }
    
    this.currentRoute = this.router.url;
  }

  ionViewWillEnter(): void {
    this.login = this.route.snapshot.paramMap.get('login');
  }

  selectUserStatus(status): void {
    if(status.detail.value == 'Disabled'){
      this.userStatus = 1;
    } else {
      this.userStatus = 0;
    }
  }

  selectAlertStatus(status): void {
    if(status.detail.value == 'Text Only'){
      this.alertStatus = 2;
    } else if(status.detail.value == 'Email Only'){
      this.alertStatus = 1;
    } else {
      this.alertStatus = 0;
    }
  }

  selectUserType(type): void {
    if(type.detail.value == 'User'){
      this.userType = 'USER';
    } else if(type.detail.value == 'Admin'){
      this.userType = 'admin';
    } else if(type.detail.value == 'Route Driver'){
      this.userType = 'driver';
    }
    else {
      this.userType = 'USER';
    }
  }

  async AddNewUser() {
    var webLogin = new WebLogin();
    webLogin.login = this.login;
    webLogin.password = this.password;
    webLogin.fname = this.firstName;
    webLogin.lname = this.lastName;
    webLogin.disabled = this.userStatus.toString();
    webLogin.userType = this.userType;
    webLogin.cellphoneNum = this.phoneNumber;
    if(this.alertStatus === 0){
      webLogin.sendTxt = '1';
      webLogin.sendEmails = '1';
    } else if(this.alertStatus === 1){
      webLogin.sendTxt = '0';
      webLogin.sendEmails = '1';
    } else {
      webLogin.sendTxt = '1';
      webLogin.sendEmails = '0';
    }
    webLogin.customerCode = this.customerCode ?? '';
    webLogin.departmentCode = this.departmentCode ?? '';
    webLogin.employeeCode = this.employeeCode ?? '';

    if(this.deliveryNotifications == true){
      webLogin.sendDelTxt = '1';
    }
    else {
      webLogin.sendDelTxt = '0';
    }

    this.response = await this.accountService.AddUser(webLogin)
    this.router.navigate(['/account-management']);
  }

}