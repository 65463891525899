import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CancelOrderService } from '../../../../../app/features/cancel-order/services/cancel-order.service';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';

@Component({
    selector: 'app-custom-reports-report-page',
    templateUrl: 'custom-reports.page.html',
    styleUrls: [
      './custom-reports.page.scss',
    ]
})
export class CustomReportPage implements OnInit {

    constructor(
      private router: Router,
      private route: ActivatedRoute,
      private cancelOrderService: CancelOrderService,
      public authService: GtrakAuthService
    ) { }


    loaded: boolean = true;

    async ngOnInit() {
        
    }

    navigate(route: string): void {
        this.router.navigate([route]);
    }

}