import { Component, OnInit } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController, ViewWillEnter, ViewDidLeave, ViewDidEnter } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { GtrakAuthService } from '../../../authentication/services/gtrak-auth.service';
import { GarmentDetailService } from '../../../../features/garment-listing/services/garment-detail.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-garment-detail-page',
  templateUrl: 'garment-detail.page.html',
  styleUrls: [
    './garment-detail.page.scss'
  ]
})
export class GarmentDetailPage implements ViewWillEnter, OnInit {


  garmentDetail: any;
  empKey: any;
  loaded: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: GtrakAuthService,
    public garmentDetailService: GarmentDetailService,
    public navController: NavController
  ) { 
  
  }

  ngOnInit(): void {

  }


  async ionViewWillEnter() {
    this.loaded = false;

    this.empKey = {
      CustomerCode: this.authService.user.claims.cus_code,
      DepartmentCode: this.authService.user.claims.dep_code,
      EmployeeCode: this.authService.user.claims.emp_code
    }
    await this.garmentDetailService.getForEmployee(this.empKey).then((data) => {
      this.garmentDetail = data;
    });

    //convert object to array
    this.garmentDetail = Object.keys(this.garmentDetail).map(key => ({type: key, value: this.garmentDetail[key]}));

    this.generateExpanded();

    this.loaded = true;

  }

  goBack(): void {
    this.navController.back();
  }
  
  generateExpanded() {
    for (var i = 0; i < this.garmentDetail.length; i++) {
      this.garmentDetail[i].value.expanded = false;
    }
  }

  expandItem(item){
    item.value.expanded = !item.value.expanded;
  }

}