import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CancelOrderService } from '../../../../../app/features/cancel-order/services/cancel-order.service';
import { CancelOrder, CancelOrderGarment, CancelOrderItem } from '../../../../../app/features/cancel-order/models/cancel-order.model';
import { CustomerDetailsService } from '../../../../../app/features/customer-lookup/services/customer-details.service';

export type CancelOrderReportDisplayItem = {
    custCode: string, 
    depCode: string, 
    empCode: string, 
    firstName: string,
    lastName: string,
    stopCode: string,
    stopName: string,
    woCode: string,
    lockerNum: string,
    actionDate: any,
    orderDate: any,
    startDate: any,
    items: CancelOrderItem[], 
    total: number
}

@Component({
    selector: 'app-cancel-order-report-page',
    templateUrl: 'cancel-order.page.html',
    styleUrls: [
      './cancel-order.page.scss',
    ]
})
export class CancelOrderReportPage implements OnInit {

    customerCode: string = null;
    currentCustomerCode: string = null;
    submittedCustomerCode: string = null;

    customer: any = null;

    constructor(
      private router: Router,
      private route: ActivatedRoute,
      private cancelOrderService: CancelOrderService,
      private customerDetailsService: CustomerDetailsService
    ) { }

    displayData: CancelOrderReportDisplayItem[] = [];
    allItems: CancelOrderItem[] = [];

    today: Date = new Date();

    lastNameSearch: string = '';
    currentSearch: string = '';

    cancelOrders: any[] = [];
    totalLossCharge: number = 0;
    grandTotalCharge: number = 0;
    loaded: boolean = true;

    async ngOnInit() {
        
    }

    changePrinterView(): void {
        // Set a delay before opening the print dialog
        setTimeout(() => {
          print();
        }, 200);
    }

    setFilter(): void {
        this.currentSearch = this.lastNameSearch;
    }

    isValid(item: CancelOrderItem): boolean {
        return item.lastName.toLowerCase().includes(this.lastNameSearch.toLowerCase());
    }

    async getData(): Promise<void> {
        this.loaded = false;
        this.currentCustomerCode = this.customerCode;

        const data = await Promise.all([
            this.customerDetailsService.getCustomerByCustomerCode(this.customerCode),
            this.cancelOrderService.getOpenCancelReport(this.customerCode)
        ]);
        this.customer = data[0];
        this.allItems = data[1];
        
        this.submittedCustomerCode = this.customerCode;

        this.processReports();

        this.loaded = true;
    }

    processReports(): void {
        this.allItems.forEach(item => {
            const existingEntry = this.displayData.find(d => 
                d.custCode === item.customerCode && 
                d.depCode === item.departmentCode && 
                d.empCode === item.employeeCode && 
                d.woCode === item.woCode
            );

            if (existingEntry) {
                // Add item to list
                existingEntry.items.push(item);

                // Update total
                existingEntry.total += ((!item.cpsalePrice || item.cpsalePrice === -1) ? (item.pdsalePrice ?? 0) : (item.cpsalePrice ?? 0));

                // Set order date to oldest order date from items
                if (new Date(item.orderDate) > new Date(existingEntry.orderDate)) {
                    existingEntry.orderDate = item.orderDate;
                }

                // Set start date to newest delivery date from items
                if (item.stWoItems[0]?.deliveryDate && (new Date(item.stWoItems[0]?.deliveryDate) < new Date(existingEntry.startDate))) {
                    existingEntry.startDate = item.stWoItems[0]?.deliveryDate;
                }
            } else {
                this.displayData.push({
                    custCode: item.customerCode,
                    depCode: item.departmentCode,
                    empCode: item.employeeCode,
                    firstName: item.firstName,
                    lastName: item.lastName,
                    stopCode: item.stopCode,
                    stopName: item.stopName,
                    woCode: item.woCode,
                    lockerNum: item.lockerNum,
                    actionDate: item.actionDate,
                    items: [item],
                    total: (!item.cpsalePrice || item.cpsalePrice === -1) ? (item.pdsalePrice ?? 0) : (item.cpsalePrice ?? 0),
                    orderDate: item.orderDate,
                    startDate: item.stWoItems[0]?.deliveryDate
                });
            }
        });

        this.displayData = this.displayData.sort((a, b) => {
            if (a.lastName < b.lastName) return -1;
            if (b.lastName < a.lastName) return 1;
            return 0;
        });

        this.grandTotalCharge = this.displayData.reduce((prev, cur) => {
            return prev += (cur.total ?? 0)
        }, 0);
    }

    async searchCustomerCode(): Promise<void> {
        await this.getData();
    }

}