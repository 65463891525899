import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { RequestParams } from '../../../../../../common/rest-api/models/request-params';
import { GRouteService } from '../../../../../../../app/features/g-route/services/g-route.service';
import { RouteModel } from '../../../../../../../app/features/g-route/models/route.model';
import { GtrakAuthService } from '../../../../../../../app/features/authentication/services/gtrak-auth.service';

@Component({
  selector: 'app-route-info-popup',
  templateUrl: 'route-info-popup.component.html',
  styleUrls: [
    'route-info-popup.component.scss'
  ]
})
export class RouteInfoPopupComponent implements ViewWillEnter, OnInit {

    routeListParams: RequestParams = null;
    routeList: RouteModel[] = [];
    selectedRoute: string = null;;

    date: Date = null;

    constructor(
        private modalController: ModalController,
        private routeService: GRouteService,
        private authService: GtrakAuthService
    ) {

    }

    ngOnInit(): void {
        (this.date as any) = this.getCurrentDayFormatted();
    }

    ionViewWillEnter(): void {
        this.routeListParams = new RequestParams();
        this.routeListParams.setOrdering('Description', 'asc');
        this.routeListParams.setPaging(1, 0);

        this.routeService.getRoutesList(this.routeListParams).then((routes) => {
            this.routeList = routes;
        });
    }

    continue(): void {
        localStorage.setItem('admin_override_route', this.selectedRoute);
        //make date tostring into mm/dd/yyyy
        localStorage.setItem('admin_override_date', this.reorderDate(this.date.toString()));

        this.close();
    }

    close(): void {
        this.modalController.dismiss();
    }

    reorderDate(date: string): string {
        const array: string[] = date.split('-');
    
        return `${array[1]}/${array[2]}/${array[0]}`;
    }

    getCurrentDayFormatted(): string {
        const date = new Date();
        return `${date.getFullYear()}-${((date.getMonth() + 1) <= 9) ? '0' + (date.getMonth() + 1).toString() : (date.getMonth() + 1)}-${(date.getDate() <= 9) ? ('0' + date.getDate()) : date.getDate()}`;
    }

}
