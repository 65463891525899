import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { QueryResult } from '../../../common/rest-api/models/query-result';
import { RequestParams } from '../../../common/rest-api/models/request-params';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { CustomerProductsSchedule } from '../models/customer-products-schedule.model';
import { CustomerListItem } from '../models/customer-list-item.model';

  
@Injectable({
providedIn: 'root',
})
export class CustomerProductsScheduleService extends RestApiService<CustomerProductsSchedule>{

    protected resourcePath = '/cust-schedule';
    protected modelType = CustomerProductsSchedule;

    async GetOffWeekProducts(stopCode: string, customerCode: string, forDate: Date): Promise<CustomerProductsSchedule[]> {
        //convert date to yyyy-mm-dd format
        const forDateStr = forDate.toISOString().split('T')[0];
        const url = this.buildEndpointUrl(`${stopCode}/${customerCode}/${forDateStr}`);
        const response = await this.cloudApiMiddleware.cloudRequest<CustomerProductsSchedule[]>({
            url,
        }, 'GET');

        return response;
    }

    async GetCustomerList(stopCode: string): Promise<CustomerListItem[]> {
        const url = this.buildEndpointUrl(`list/${stopCode}`);
        const response = await this.cloudApiMiddleware.cloudRequest<CustomerListItem[]>({
            url,
        }, 'GET');

        return response;
    }

}