<ion-grid>
    <ion-row class="header">
        <ion-col>
            <span>Are you sure you would like to {{event}} to stop {{stop}}?</span>
        </ion-col>
    </ion-row>
    <ion-row class="button-row">
        <ion-col>
            <ion-button class="confirm" (click)="close(true)">
                Confirm
            </ion-button>
        </ion-col>
        <ion-col>
            <ion-button class="cancel" (click)="close(false)">
                Cancel
            </ion-button>
        </ion-col>
    </ion-row>
</ion-grid>