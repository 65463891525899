import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter } from '@ionic/angular';
import { EmployeeListService } from '../../../../common/services/employee-listing.service'; ///app/common/services/employee-list.service';
import { RequestParams } from '../../../../common/rest-api/models/request-params';
import { GarmentReportService } from '../../services/garment-report.service';
import { EmployeeKey } from '../../../../../app/common/models/employee-key.model';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';
import { GtrakUser } from '../../../../../app/features/authentication/models/gtrak-user';
import _ from "lodash";
import { ToasterService } from '../../../../../app/common/services/toaster.service';

@Component({
  selector: 'app-employee-garment-report-page',
  templateUrl: 'employee-garment-report.page.html',
  styleUrls: [
    './employee-garment-report.page.scss',
  ]
})
export class EmployeeGarmentReportPage implements ViewWillEnter, OnInit {

  employeeId: string;
  customerId: string;
  departmentId: string;
  garmentCode: string;

  requestParams: any;

  results: any;

  public report: any;
  public customer: any;

  empKey: EmployeeKey;

  doneLoading: boolean = false;

  garmentCodeProductCodes = [];
  garmentMessageCodes = [];
  garmentSummaryStatusCodes = [];

  summaryNotes = []

  public viewChange: boolean = false;

  user: GtrakUser;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private employeeListService: EmployeeListService,
    private garmentReportService: GarmentReportService,
    private authService: GtrakAuthService,
    private toasterService: ToasterService
  ) { 

  }

  ngOnInit(): void {

    if(!this.authService.user.canViewGarmentListing) {
      this.router.navigate([this.authService.user.homeLocation]);
    }
    
  }

  async ionViewWillEnter(): Promise<void> {
    this.employeeId = this.route.snapshot.paramMap.get('id');
    this.customerId = this.route.snapshot.paramMap.get('cid');
    this.departmentId = this.route.snapshot.paramMap.get('did');
    this.garmentCode = this.route.snapshot.paramMap.get('gid');
    
    this.empKey = {
      CustomerCode: this.customerId,
      DepartmentCode: this.departmentId,
      EmployeeCode: this.employeeId
    };

    this.setData();

  }

  changePrinterView(): void {
    this.viewChange = !this.viewChange;
    // Set a delay before opening the print dialog
    if (this.viewChange) {
      setTimeout(() => {
        print();
      }, 1000); // 2000 milliseconds = 2 seconds
    }
  }

  async setData(): Promise<void> {

    this.report = this.garmentCode ? await this.garmentReportService.findForGarment(this.garmentCode): await this.garmentReportService.getForEmployee(this.empKey);
    this.garmentCode = this.report?.garmentCode ?? this.garmentCode;

    console.log(this.authService.user.customerCode);
    console.log(this.report);

    if (this.authService.user.customerCode.length > 0 && (this.report.employeeListing?.customerCode != this.authService.user.customerCode)) {
      this.toasterService.presentToast('danger', '', 'Invalid Garment Code or Permissions.', 2000);
      this.router.navigate([this.authService.user.homeLocation]);
    }

    _.each(this.report.summary, (sum) => {
      if(sum.status) {
        if(sum.status.length > 0) {
          this.summaryNotes.push({
            'status': sum.status,
            'statusDesc': sum.statusDesc
          })
        }
      }
    });

    this.summaryNotes = this.summaryNotes.filter((test, index, array) =>
      index === array.findIndex((find) => find.status === test.status)
    );

    this.report.notes = this.report.notes.filter((test, index, array) =>
      index === array.findIndex((find) => find.code === test.code)
    );

    if(this.garmentCode) {
      _.each(this.report.details, (detail) => {
        if(detail.garmentCode == this.garmentCode) {
          this.garmentCodeProductCodes.push(detail.productCode);
          this.garmentMessageCodes.push(detail.messageCode);
        }
      });

      _.each(this.report.summary, (summary) => {
        if(this.garmentCodeProductCodes.includes(summary.productCode)) {
          this.garmentSummaryStatusCodes.push(summary.status);
        }
      });

    }

    this.customer = this.report.employeeListing;

    this.doneLoading = true;

  }

}