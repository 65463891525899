import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { EmployeeListService } from '../../../../common/services/employee-listing.service'; ///app/common/services/employee-list.service';
import { RequestParams } from '../../../../common/rest-api/models/request-params';
import { GarmentReportService } from '../../services/garment-report.service';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';
import { GarmentWeeklyHistoryModalComponent } from '../../components/garment-weekly-history-modal/garment-weekly-history-modal.component';
import _ from "lodash";
import { EmployeeListing } from '../../../../common/models/employee-listing.model';

@Component({
  selector: 'app-customer-garment-report-page',
  templateUrl: 'customer-garment-report.page.html',
  styleUrls: [
    './customer-garment-report.page.scss',
  ]
})
export class CustomerGarmentReportPage implements ViewWillEnter, OnInit {

  customerId: string;

  requestParams: any;

  results: any;
  reports: any;

  pageSize: number;
  totalPages: number;
  totalRecords: number;
  currentPage: number;
  pageNumbers = [];

  selectedRowKey: string = null;
  
  public viewChange: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private employeeListService: EmployeeListService,
    private garmentReportService: GarmentReportService,
    private authService: GtrakAuthService,
    private modalController: ModalController,
  ) { 

  }

  ngOnInit(): void {

    if(!this.authService.user.canViewGarmentListing) {
      this.router.navigate([this.authService.user.homeLocation]);
    }
    
  }

  ionViewWillEnter(): void {
    this.customerId = this.route.snapshot.paramMap.get('id');

    this.currentPage = 1;
    this.pageSize = 10;

    this.requestParams = new RequestParams();

    this.requestParams.setOrdering('LastName', 'asc');
    this.requestParams.setPaging(this.currentPage, this.pageSize);

    this.setData();
  }

  async setData(): Promise<void> {
    this.results = await this.garmentReportService.findForCustomer(this.customerId, this.requestParams);

    _.each(this.results.results, (gr) => {
      gr.notes = gr.notes.filter((test, index, array) =>
      index === array.findIndex((find) => find.code === test.code)
      );
    })

    _.each(this.results.results, (result) => {
      result.summaryNotes = [];
      _.each(result.summary, (sum) => {
        if(sum.status && sum.status.length > 0) {
          result.summaryNotes.push({
            'status': sum.status,
            'statusDesc': sum.statusDesc
          })
        }
      });

      result.summaryNotes = result.summaryNotes.filter((test, index, array) =>
        index === array.findIndex((find) => find.status === test.status)
      );
    });

    this.reports = this.results.results;
    this.totalPages = this.results.totalPages;
    this.totalRecords = this.results.totalRecords;

    this.pageNumbers = Array(this.totalPages).fill(1).map((x, i) => i)
    this.pageNumbers.push(this.totalPages);
  }

  selectPage(number: number): void {
    if(number <= 0) return;

    this.currentPage = number;

    this.requestParams.setPaging(this.currentPage, this.pageSize);

    this.selectedRowKey = null;

    this.setData();
  }

  clearFilterByRowKey(): void {
    this.selectedRowKey = null;
  }

  filterByRowKey(key: string): void {
    this.selectedRowKey = key;
  }

  goTo(location: string): void {
    window.location.hash = '';
    window.location.hash = location;
  }

  changePrinterView(): void {
    this.viewChange = !this.viewChange;
    // Set a delay before opening the print dialog
    setTimeout(() => {
      print();
    }, 1000); // 2000 milliseconds = 2 seconds
  }

  async openWeeklyHistoryModal(employee: EmployeeListing): Promise<void> {
    const modal = await this.modalController.create({
      component: GarmentWeeklyHistoryModalComponent,
      componentProps: {
        employee: employee
      }
    });

    await modal.present();
  }

}