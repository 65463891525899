import { AppRoute } from '../../../core/routing/AppRoute';
import { RoutePage } from './pages/route-page/route.page';
import { LogSheetPage } from './pages/logsheet-page/logsheet.page';
import { UpsellResultsPage } from './pages/upsell-results-page/upsell-results.page';
import { StopInfoMenuPage } from './pages/stop-info-menu/stop-info-menu.page';
import { ContactsPage } from './pages/stop-info-menu/detail-pages/contacts/contacts.page';
import { NonGarmentsPage } from './pages/stop-info-menu/detail-pages/non-garments/non-garments.page';
import { UpsellOppPage } from './pages/stop-info-menu/detail-pages/upsell-opp/upsell-opp.page';
import { InvoiceStopPage } from './pages/stop-info-menu/detail-pages/invoice/invoice.page';
import { NewDeliveriesPage } from './pages/stop-info-menu/detail-pages/garment-new-deliveries/garment-new-deliveries.page';
import { GarmentCancelReportPage } from './pages/stop-info-menu/detail-pages/garment-cancel-report/garment-cancel-report.page';
import { GarmentPickUpPage } from './pages/stop-info-menu/detail-pages/garment-pick-up/garment-pick-up.page';
import { GarmentLookupPage } from './pages/stop-info-menu/detail-pages/garment-lookup/garment-lookup.page';
import { GarmentRepairPage } from './pages/stop-info-menu/detail-pages/garment-repair/garment-repair.page';
import { GarmentEmployeeLookupPage } from './pages/stop-info-menu/detail-pages/garment-employee-lookup/garment-employee-lookup.page';
import { GarmentAddWearerPage } from './pages/stop-info-menu/detail-pages/garment-add-wearer/garment-add-wearer.page';
import { GarmentEmployeeDetailsPage } from './pages/stop-info-menu/detail-pages/garment-employee-details/garment-employee-details.page';
import { CreateWorkOrderPage } from './pages/stop-info-menu/detail-pages/create-work-order/create-work-order.page';
import { CheckOutPage } from './pages/stop-info-menu/detail-pages/check-out/check-out.page';
import { EmailReportPage } from './pages/stop-info-menu/detail-pages/email-report/email-report.page';

import { AuthGuard } from '../../common/rest-api/services/auth-guard.service';

// A feature route should be used in the App routing module in most cases and primarily provide
// a base url and module loading functionality.
// The feature route should employ a load children callback that loads the appropriate module
const Feature = new AppRoute({
  segment: 'route',
  title: 'Route',
  // A component can also be included to be the content, or a separate route with a blank segment
  // can be defined like below to provide a dedicated route instance
  // component: GarmentListingPage,
  loadChildren: () => import('./g-route.module').then(m => m.RouteModule),
  canActive: [AuthGuard]
});

// "Sub-routes" should be the contentful endpoints for the feature. To provide content at the base
// level of the feature url, simply leave segment blank.
// Provide the parent parameter to be able to derive the fullPath of a route that includes the base
const Route = new AppRoute({
  segment: '',
  title: 'Route',
  component: RoutePage,
  parent: Feature,
  canActive: [AuthGuard]
});

const Logsheet = new AppRoute({
  segment: 'logsheet',
  title: 'Logsheet',
  component: LogSheetPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const UpsellResults = new AppRoute({
  segment: 'upsell-results',
  title: 'Upsell Results',
  component: UpsellResultsPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const StopInfo = new AppRoute({
  segment: 'logsheet/stop/:id',
  title: 'Stop Details',
  component: StopInfoMenuPage,
  parent: Logsheet,
  canActive: [AuthGuard]
});

const CheckOut = new AppRoute({
  segment: 'logsheet/stop/:id/check-out',
  title: 'Check Out',
  component: CheckOutPage,
  parent: StopInfo,
  canActive: [AuthGuard]
});

const EmailReport = new AppRoute({
  segment: 'logsheet/stop/:id/email-report',
  title: 'Check Out',
  component: EmailReportPage,
  parent: StopInfo,
  canActive: [AuthGuard]
});

const Contacts = new AppRoute({
  segment: 'logsheet/stop/:id/contacts',
  title: 'Stop Contacts',
  component: ContactsPage,
  parent: StopInfo,
  canActive: [AuthGuard]
});

const UpsellOpp = new AppRoute({
  segment: 'logsheet/stop/:id/upsell-opp',
  title: 'Upsell Opp.',
  component: UpsellOppPage,
  parent: StopInfo,
  canActive: [AuthGuard]
});

const NonGarments = new AppRoute({
  segment: 'logsheet/stop/:id/non-garments',
  title: 'Non-Garments',
  component: NonGarmentsPage,
  parent: StopInfo,
  canActive: [AuthGuard]
});

const Invoice = new AppRoute({
  segment: 'logsheet/stop/:id/invoice',
  title: 'Invoice',
  component: InvoiceStopPage,
  parent: StopInfo,
  canActive: [AuthGuard]
});

const NewDeliveries = new AppRoute({
  segment: 'logsheet/stop/:id/new-deliveries',
  title: 'New Deliveries',
  component: NewDeliveriesPage,
  parent: StopInfo,
  canActive: [AuthGuard]
});

const CancelReport = new AppRoute({
  segment: 'logsheet/stop/:id/cancel-report',
  title: 'Cancel Report',
  component: GarmentCancelReportPage,
  parent: StopInfo,
  canActive: [AuthGuard]
});

const PickUp = new AppRoute({
  segment: 'logsheet/stop/:id/pick-up',
  title: 'Pick Up',
  component: GarmentPickUpPage,
  parent: StopInfo,
  canActive: [AuthGuard]
});

const Lookup = new AppRoute({
  segment: 'logsheet/stop/:id/lookup',
  title: 'Lookup',
  component: GarmentLookupPage,
  parent: StopInfo,
  canActive: [AuthGuard]
});

const LookupWithParam = new AppRoute({
  segment: 'logsheet/stop/:id/lookup/:gId',
  title: 'Lookup',
  component: GarmentLookupPage,
  parent: StopInfo,
  canActive: [AuthGuard]
});

const Repair = new AppRoute({
  segment: 'logsheet/stop/:id/repair',
  title: 'Repair',
  component: GarmentRepairPage,
  parent: StopInfo,
  canActive: [AuthGuard]
});

const EmployeeLookup = new AppRoute({
  segment: 'logsheet/stop/:id/employee-lookup',
  title: 'Employee Lookup',
  component: GarmentEmployeeLookupPage,
  parent: StopInfo,
  canActive: [AuthGuard]
});

const EmployeeDetails = new AppRoute({
  segment: 'logsheet/stop/:id/employee-lookup/:cId/:dId/:eId',
  title: 'Employee Lookup Details',
  component: GarmentEmployeeDetailsPage,
  parent: EmployeeLookup,
  canActive: [AuthGuard]
});

const CreateWorkOrder = new AppRoute({
  segment: 'logsheet/stop/:id/employee-lookup/create-work-order/:cId/:dId/:eId',
  title: 'Work Order',
  component: CreateWorkOrderPage,
  parent: EmployeeLookup,
  canActive: [AuthGuard]
});


const AddWearer = new AppRoute({
  segment: 'logsheet/stop/:id/add-wearer',
  title: 'Add Wearer',
  component: GarmentAddWearerPage,
  parent: StopInfo,
  canActive: [AuthGuard]
});

export const GRouteRoutes = {
  Feature: Feature,
  Route: Route,
  Logsheet: Logsheet,
  UpsellResults: UpsellResults,
  StopInfo: StopInfo,
  CheckOut: CheckOut,
  Contacts: Contacts,
  UpsellOpp: UpsellOpp,
  NonGarments: NonGarments,
  Invoice: Invoice,
  NewDeliveries: NewDeliveries,
  CancelReport: CancelReport,
  PickUp: PickUp,
  Lookup: Lookup,
  Repair: Repair,
  EmployeeLookup: EmployeeLookup,
  AddWearer: AddWearer,
  EmployeeDetails: EmployeeDetails,
  LookupWithParam: LookupWithParam,
  CreateWorkOrder: CreateWorkOrder,
  EmailReport: EmailReport
}