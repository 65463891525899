import { Data } from "@angular/router";
import { Model, ModelId } from "../../../common/rest-api/models/model";

export class GarmentReportDetail extends Model
{
  get modelId(): ModelId {
    throw new Error("Method not implemented.");
  }

  rowKey: string;
  customerCode: string;
  departmentCode: string;
  employeeCode: string;
  messageCode: string;
  garmentCode: string;
  productCode: string;
  description: string;
  size1: string;
  size2: string;
  lastDelivery: string;
  messageText: string;
}