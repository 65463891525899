<ion-content class="garment-cancel-report">
    <ng-container>
        <app-header [title]="(routeStopName ?? '') + ' (' + stopName + ') Cancel Report'"></app-header>
        <ion-grid>
            <ion-row class="checkbox">
                <input type="checkbox" class="checkbox-margin" (input)="showAllWO($event)"/><a>Show All WO Garments</a>
            </ion-row>
            <ion-row class="header-row">
                <ion-col size="4" class="top-row">Emp</ion-col>
                <ion-col size="4" class="top-row">Garment</ion-col>
                <ion-col size="4" class="top-row">Item</ion-col>
            </ion-row>
            <ion-row *ngFor="let wo of cancelOrderGroupedByWo" class="content-row">
                <ion-row class="name-row"><strong class="name">{{wo.employeeName}}</strong> {{stopName}} {{wo.lockerNum}}</ion-row>
                <ion-row class="wo-row">
                    <ion-col size="4"></ion-col>
                    <ion-col size="4" class="wo-col">WO: <strong>{{wo.woCode}}</strong> ({{wo.reason}})</ion-col>
                    <ion-col size="4"></ion-col>
                </ion-row>
                <ion-row class="garment-title-row" *ngFor="let garmentGroup of wo.items">
                    <ion-col size="4"></ion-col>
                    <ion-col size="4" class="garment-title-col"><strong>{{garmentGroup.name}}</strong></ion-col>
                    <ion-col size="4"></ion-col>
                    <ion-row *ngFor="let garment of garmentGroup.stWoGarments" class="garment-row">
                        <ion-row class="garment-row" *ngIf="garment.actionDate === null || showAll">
                            <ion-col size="4" class="garment-col" *ngIf="garment.actionDate === null"><input type="checkbox" (input)="addToList($event, garment)"/></ion-col>
                            <ion-col size="4" class="garment-col" *ngIf="garment.actionDate !== null">Rtn: {{garment.actionDate | date: 'MMM dd'}}</ion-col>
                            <ion-col size="4" class="garment-col">{{garment.garmentCode}}</ion-col>
                            <ion-col size="4" class="garment-col">Size: {{garmentGroup.size1}}, {{garmentGroup.size2}}</ion-col>
                        </ion-row>
                    </ion-row>
                </ion-row>
                <ion-row class="pick-up-row">
                    <ion-col size="4"></ion-col>
                    <ion-col size="4" class="pick-up-col">Final Pick-up date: <strong>{{wo.pickupDate | date: 'MM/dd/YYYY'}}</strong></ion-col>
                    <ion-col size="4"></ion-col>
                </ion-row>
            </ion-row>
            <ion-row class="submit-row">
                <ion-button (click)="submit()">Save</ion-button>
            </ion-row>
        </ion-grid>
    </ng-container>
</ion-content>