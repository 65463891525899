import { Model, ModelId } from "../../../common/rest-api/models/model";

export class WebLogin extends Model {
  get modelId(): ModelId {
    throw new Error("Method not implemented.");
  }
  login: string;
  password: string;
  userType: string;
  fname: string;
  lname: string;
  hidden: string;
  disabled: string;
  customerCode: string;
  departmentCode: string;
  employeeCode: string;
  placeOrder: string;
  viewInvoice: string;
  viewEmpTransfer: string;
  cellphoneNum: string;
  sendEmails: string;
  sendTxt: string;
  sendDelTxt: string;
}
