import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter } from '@ionic/angular';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';
import { RequestParams } from '../../../../common/rest-api/models/request-params';
import { ToasterService } from '../../../../../app/common/services/toaster.service';
import { CustomerDetailsService } from '../../services/customer-details.service';
import { EmployeeListService } from '../../../../common/services/employee-listing.service'; ///app/common/services/employee-list.service';

@Component({
  selector: 'app-customer-details-page',
  templateUrl: 'customer-details.page.html',
  styleUrls: [
    './customer-details.page.scss',
    '../../styles/customer-lookup.styles.scss'
  ]
})
export class CustomerDetailsPage implements ViewWillEnter, OnInit {

  customerCode: string;

  rows: any;
  currentRoute: string;

  public loaded: boolean = false;

  customerParams: any;

  totalPages: number = 0;
  totalElements: number = 0;

  currPage: number;
  pageSize: number;

  public customerDetails: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: GtrakAuthService,
    private toasterService: ToasterService,
    private employeeListService: EmployeeListService,
  ) { 
    this.currentRoute = null;
  }

  ngOnInit(): void {
    if(!this.authService.user.canViewCustomerLookup) {
      this.router.navigate([this.authService.user.homeLocation]);
    }

    this.customerParams = new RequestParams();


    this.customerCode = this.route.snapshot.paramMap.get('code');

    this.currPage = 1;
    this.pageSize = 20;

    
    this.rows = [];
    this.currentRoute = this.router.url;

    this.customerParams.setOrdering('lastName', 'asc');

    this.customerParams.filters.CustomerCode = this.customerCode;
    this.loaded = false;
    this.getCustomerDetails();
  }

  ionViewWillEnter(): void {
  }


  async getCustomerDetails() {
    this.customerParams.setPaging(this.currPage, this.pageSize);
    var employees = await this.employeeListService.query(this.customerParams);
    this.totalElements = employees.totalRecords;
    this.totalPages = employees.totalPages;

    this.rows = employees.results;

    this.loaded = true; 
  }


  setPage(page): void {
    this.currPage = page + 1;

    this.getCustomerDetails();
  }
}