import { Component, Input, OnInit } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { GRouteWorkOrdersService } from '../../../../../../../app/features/g-route/services/route-work-order.service';
import { GtrakAuthService } from '../../../../../../../app/features/authentication/services/gtrak-auth.service';
import { NewDeliveryModel } from '../../../../../../../app/features/g-route/models/new-delivery.model';
import { QueryResult } from '../../../../../../common/rest-api/models/query-result';

@Component({
  selector: 'app-garment-new-deliveries-page',
  templateUrl: 'garment-new-deliveries.page.html',
  styleUrls: [
     './garment-new-deliveries.page.scss',
     '../../../../styles/g-route.styles.scss'
  ]
})
export class NewDeliveriesPage implements ViewWillEnter, OnInit {

    currentRoute: string;
    stopName: string;
    routeDate: Date;

    showOpen: boolean = false;
    showNew: boolean = true;

    newDeliveries: NewDeliveryModel[] = [];
    openDeliveries: NewDeliveryModel[] = [];

    routeStopName: string = "";

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: GtrakAuthService,
        private routeWorkOrdersService: GRouteWorkOrdersService
    ) { 
        this.currentRoute = null;
    }

    ngOnInit(): void {
        this.currentRoute = this.router.url;
        this.stopName = this.route.snapshot.paramMap.get('id');

        this.routeDate = this.authService.user.routeDate;

        this.routeStopName = localStorage.getItem('routeStopName');

        this.getNewDeliveries();
    }

    ionViewWillEnter(): void {
        
    }

    async getNewDeliveries(): Promise<void> {
        this.newDeliveries = (await this.routeWorkOrdersService.getNewDeliveryList(this.stopName, this.routeDate)) ?? [];

        //go through each new delivery and if the work order delivery date is null push it to the open deliveries array
        this.newDeliveries.forEach((newDelivery) => {
            if (newDelivery.workOrder.deliveryDate == null) {
                this.openDeliveries.push(newDelivery);
            }
        });

        //now go through open deliveries and remove them from the new deliveries array
        this.openDeliveries.forEach((openDelivery) => {
            this.newDeliveries = this.newDeliveries.filter((newDelivery) => {
                return newDelivery.workOrder.woCode !== openDelivery.workOrder.woCode;
            });
        });

    }

    changeView(): void {
        this.showOpen = !this.showOpen;
        this.showNew = !this.showNew;
    }

}