import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DepartmentKey } from '../../../common/models/department-key-model';
import { EmployeeKey } from '../../../common/models/employee-key.model';
import { QueryResult } from '../../../common/rest-api/models/query-result';
import { RequestParams } from '../../../common/rest-api/models/request-params';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { GarmentReportDetail } from '../models/garment-report-detail.model';


@Injectable({
  providedIn: 'root',
})
export class GarmentDetailService extends RestApiService<GarmentReportDetail> {

  protected resourcePath = '/garment-detail';
  protected modelType = GarmentReportDetail;


  async getForEmployee(employeeKey: EmployeeKey): Promise<GarmentReportDetail> {
    const params = new RequestParams();
    params.queryParams.params.CustomerCode = employeeKey.CustomerCode;
    params.queryParams.params.DepartmentCode = employeeKey.DepartmentCode;
    params.queryParams.params.EmployeeCode = employeeKey.EmployeeCode;

    const url = this.buildEndpointUrl('', params);
    const response = GarmentReportDetail.from(await this.cloudApiMiddleware.cloudRequest<GarmentReportDetail>({
      url
    }, 'GET'));

    return response;
  }

}