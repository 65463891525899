import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { MailQueue } from '../models/mail-queue';
import { EmailStatus } from '../models/email-status';
  
@Injectable({
providedIn: 'root',
})
export class MailService extends RestApiService<MailQueue> {

    protected resourcePath = '/mail-service';
    protected modelType = MailQueue;

    async getMailStatus(stopCode: string): Promise<EmailStatus> {
        const url = this.buildEndpointUrl(`status/${stopCode}`);
        const response = await this.cloudApiMiddleware.cloudRequest<EmailStatus>({
            url
        }, 'GET');

        return response;
    }

}