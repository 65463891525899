<ion-content>
    <app-top-bar></app-top-bar>
    <div class="title">Hello, {{authService?.user?.claims?.name}}</div>
    <div class="my-grid">
        <div class="my-row">
            <div class="my-col page-button">
                <img [src]="'./assets/nav-icons/DeliveryStatus.jpg'" [routerLink]="routes.Delivery.segment" />
                <!-- <a [routerLink]="routes.Delivery.segment">Delivery Status</a> -->
            </div>
            <div class="my-col page-button">
                <img [src]="'./assets/nav-icons/GallagherWorkOrderIcon.jpg'" [routerLink]="routes.WorkOrder.segment" />
                <!-- <a [routerLink]="routes.WorkOrder.segment">Work Order</a> -->
            </div>
        </div>
        <div class="my-row">
            <div class="my-col page-button">
                <img [src]="'./assets/nav-icons/GallagherCancelOrderIcon.jpg'" [routerLink]="routes.CancelOrder.segment" />
                <!-- <a [routerLink]="routes.CancelOrder.segment">Cancel Order</a> -->
            </div>
            <div class="my-col page-button">
                <img [src]="'./assets/nav-icons/RepairReplaceIcon.jpg'" [routerLink]="routes.RepairReplace.segment" />
                <!-- <a [routerLink]="routes.RepairReplace.segment">Repair/Replace <br> Request</a> -->
            </div>
        </div>
        <div class="my-row">
            <div class="my-col page-button">
                <img [src]="'./assets/nav-icons/SizeChangeIcon.jpg'" [routerLink]="routes.SizeChange.segment" />
                <!-- <a [routerLink]="routes.SizeChange.segment">Size Change</a> -->
            </div>
            <div class="my-col page-button">
                <img [src]="'./assets/nav-icons/QuestionIcon.jpg'" [routerLink]="routes.Question.segment" />
                <!-- <a [routerLink]="routes.Question.segment">Questions?</a> -->
            </div>
        </div>
        <ion-row>
            <ion-col size="12" class="my-col">
                <div (click)="logout()" class="logout-button">
                    <span>Logout</span>
                </div>
            </ion-col>
        </ion-row>
    </div>

</ion-content>
<app-footer></app-footer>