import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DepartmentKey } from '../../../../app/common/models/department-key-model';
import { EmployeeKey } from '../../../../app/common/models/employee-key.model';
import { QueryResult } from '../../../common/rest-api/models/query-result';
import { RequestParams } from '../../../common/rest-api/models/request-params';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { NewDeliveryModel } from '../models/new-delivery.model';
import { RouteModel } from '../models/route.model';


@Injectable({
  providedIn: 'root',
})
export class GRouteWorkOrdersService extends RestApiService<NewDeliveryModel> {

  protected resourcePath = '/route-wo';
  protected modelType = NewDeliveryModel;

  async getNewDeliveryList(stopCode: string, date: Date, params?: RequestParams): Promise<NewDeliveryModel[]> {
    const url = this.buildEndpointUrl(`${stopCode}/${date.toISOString()}`);
    const response = await this.cloudApiMiddleware.cloudRequest<NewDeliveryModel[]>({
      url
    }, 'GET');
    console.log(response);

    //response.results = response.results.map(i => RouteModel.fromType(this.modelType, i));

    return response;
  }

  async getNewDeliveryNumber(stopCode: string, date: Date, params?: RequestParams): Promise<number> {
    const url = this.buildEndpointUrl(`${stopCode}/${date.toISOString()}/count`);
    const response = await this.cloudApiMiddleware.cloudRequest<number>({
      url
    }, 'GET');

    return response;
  }


}