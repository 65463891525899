import { Component, OnInit } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { AccountManagementRoutes } from '../../account-management.routes';
import { Router, ActivatedRoute } from '@angular/router';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';
import { RequestParams } from '../../../../common/rest-api/models/request-params';
import { AccountManagementService } from '../../services/account-management.service';
import { WebLogin } from '../../models/web-login.model';
import e from 'express';
import { WebLoginWithReports } from '../../models/web-login-with-reports.model';

@Component({
  selector: 'app-edit-mobile-user-page',
  templateUrl: 'edit-mobile-user.page.html',
  styleUrls: [
    './edit-mobile-user.page.scss',
    '../../styles/account-management.styles.scss'
  ]
})
export class EditMobileUserPage implements OnInit, ViewWillEnter {

  currentRoute: string;
  requestParams: any;
  results: any;
  access: any;
  type: any;


  statusCodes = {
    0: 'Active',
    1: 'Disabled'
  };

  alertStatusCodes = {
    0: 'Email and Text',
    1: 'Email Only',
    2: 'Text Only'
  };

  userTypeCodes = {
    0: 'User',
    1: 'Admin',
    2: 'Internal User',
    4: 'Route Driver'
  };

  login: string;
  lastLogin: string;
  password: string;
  customerCode: string;
  departmentCode: string;
  employeeCode: string;
  firstName: string;
  lastName: string;
  userStatus: number = 0;
  alertStatus: number;
  phoneNumber: string;
  userType: string = 'USER';
  active: any;
  notification: any;
  deliveryNotifications: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: GtrakAuthService,
    private accountService: AccountManagementService,
  ) { 
    this.currentRoute = null;
  }

  ngOnInit(): void {
    
    if(!this.authService.user.canViewAccountManagement) {
      this.router.navigate([this.authService.user.homeLocation]);
    }
    
    this.currentRoute = this.router.url;
  }

  ionViewWillEnter(): void {

    this.login = this.route.snapshot.paramMap.get('login');
    this.getAccount();
  }

  selectUserStatus(status): void {
    if(status.detail.value == 'Disabled'){
      this.userStatus = 1;
    } else {
      this.userStatus = 0;
    }
  }

  selectAlertStatus(status): void {
    if(status.detail.value == 'Text Only'){
      this.alertStatus = 2;
    } else if(status.detail.value == 'Email Only'){
      this.alertStatus = 1;
    } else {
      this.alertStatus = 0;
    }
  }

  selectUserType(type): void {
    if(type.detail.value == 'User'){
      this.userType = 'USER';
    } else if(type.detail.value == 'Admin'){
      this.userType = 'admin';
    } else if(type.detail.value == 'Route Driver'){
      this.userType = 'driver';
    }
    else {
      this.userType = 'USER';
    }
  }

  async getAccount() {
    this.requestParams = new RequestParams();

    delete this.requestParams.filters.Login
    delete this.requestParams.filters.UserId

    this.requestParams.filters.Login = this.login;
    this.requestParams.filters.UserId = this.login;

    this.results = await this.accountService.Get(this.requestParams)

    delete this.requestParams.filters.Login
    this.requestParams.setOrdering('AccessDate', 'desc')

    this.access = await this.accountService.Access(this.requestParams)

    if(this.access.results.length === 0){
      this.lastLogin = 'Never'
    }
    else{
      this.lastLogin = this.access.results[0].accessDate
    }
    this.customerCode = this.results.results[0].customerCode
    this.departmentCode = this.results.results[0].departmentCode
    this.employeeCode = this.results.results[0].employeeCode
    this.firstName = this.results.results[0].fname
    this.lastName = this.results.results[0].lname
    this.phoneNumber = this.results.results[0].cellphoneNum
    if(this.results.results[0].disabled === '0'){
      this.active = 'Active'
    }
    else {
      this.active = 'Disabled'
    }
    if(this.results.results[0].sendTxt === '1' && this.results.results[0].sendEmails === '1') {
      this.notification = 'Email and Text'
    }
    else if (this.results.results[0].sendTxt === '0' && this.results.results[0].sendEmails === '1'){
      this.notification = 'Email Only'
    }
    else if(this.results.results[0].sendTxt === '1' && this.results.results[0].sendEmails === '0') {
      this.notification = 'Text Only'
    }
    if(this.results.results[0].sendDelText === '0'){
      this.deliveryNotifications = false
    }
    else {
      this.deliveryNotifications = true
    }

    switch(this.results.results[0].userType){
      case 'user':
      case 'USER':
        this.type = 'User';
        break;
      case 'admin':
        this.type = 'Admin';
        break;
      case 'driver':
        this.type = 'Route Driver';
        break;
    }
  }

  async submit() {
    var wlr = new WebLoginWithReports();
    wlr.webLogin = new WebLogin();
    wlr.webLogin.login = this.login;
    wlr.webLogin.password = this.password;
    wlr.webLogin.fname = this.firstName;
    wlr.webLogin.lname = this.lastName;
    wlr.webLogin.disabled = this.userStatus.toString();
    wlr.webLogin.userType = this.userType;
    wlr.webLogin.cellphoneNum = this.phoneNumber;
    wlr.webLogin.customerCode = this.customerCode ?? '';
    wlr.webLogin.departmentCode = this.departmentCode ?? '';
    wlr.webLogin.employeeCode = this.employeeCode ?? '';

    if(this.notification == 'Email and Text'){
      wlr.webLogin.sendTxt = '1';
      wlr.webLogin.sendEmails = '1';
    }
    else if(this.notification == 'Email Only'){
      wlr.webLogin.sendTxt = '0';
      wlr.webLogin.sendEmails = '1';
    }
    else if(this.notification == 'Text Only'){
      wlr.webLogin.sendTxt = '1';
      wlr.webLogin.sendEmails = '0';
    }

    if(this.deliveryNotifications == true){
      wlr.webLogin.sendDelTxt = '1';
    }
    else {
      wlr.webLogin.sendDelTxt = '0';
    }

    let resp = await this.accountService.EditUser(wlr);
    this.router.navigate(['/account-management']);
  }
}