<ion-content class="department-report" [ngClass]="{ 'printing': printing }">
    <ng-container>
        <app-header [title]="'Department Report'"></app-header>
        <ion-grid>
            <ion-row>
                <ion-col>
                    <ion-grid>
                      <!--<ion-button *ngIf="!printing" (click)="print()">Print</ion-button>-->
                        <div class="table-container page-area">
                            <ion-row>
                                <ion-col></ion-col>
                                <ion-col size="auto">
                                    <ion-label>Select Page:</ion-label>
                                </ion-col>
                                <ion-col class="selectable" size="auto" (click)="selectPage(1)">&#60;&#60;</ion-col>
                                <ion-col class="selectable" size="auto" (click)="selectPage(currentPage - 1)">&#60;</ion-col>
                                <ion-col class="selectable" size="auto" *ngFor="let number of pageNumbers" 
                                    [ngStyle]="{'display': currentPage < 5 ? (number >= currentPage - 5 && number <= 10 && number > 0 && number <= totalPages) ? 'block' : 'none' 
                                        : (number > currentPage - 5 && number <= currentPage + 5 && number > 0 && number <= totalPages) ? 'block' : 'none'}"
                                    [ngClass]="{'add-underline': number === currentPage}" (click)="selectPage(number)">
                                    {{number}}
                                </ion-col>
                                <ion-col class="selectable" size="auto" (click)="selectPage(currentPage + 1)">&#62;</ion-col>
                                <ion-col class="selectable" size="auto" (click)="selectPage(totalPages)">&#62;&#62;</ion-col>
                                <ion-col size="auto">
                                    <ion-label>Employee Count: </ion-label>
                                </ion-col>
                                <ion-col size="auto">
                                    {{totalRecords}}
                                </ion-col>
                                <ion-col>
                                    <button class="printer-button" (click)="changePrinterView()">{{viewChange === true ? 'Regular Version' : 'Printer Friendly Version'}}</button>
                                </ion-col>
                            </ion-row>
                        </div>
                        <div class="table-container page-area">
                            <ion-row class="marg-top">
                                <ion-col size="auto">
                                    <ion-label>Employees Shown:</ion-label>
                                </ion-col>
                                <ion-col [ngClass]="{'selected': !selectedRowKey}" class="selectable" size="auto">
                                    <a (click)="clearFilterByRowKey()">Show All</a>
                                </ion-col>
                                <ion-col [ngClass]="{'selected': (selectedRowKey === report.employeeListing.rowKey)}" class="selectable" size="auto" *ngFor="let report of reports">
                                    <!-- <a (click)="goTo(report.employeeListing.rowKey)">{{report.employeeListing.lastName}}, {{report.employeeListing.firstName}}</a> -->
                                    <a (click)="filterByRowKey(report.employeeListing.rowKey)">{{report.employeeListing.lastName}}, {{report.employeeListing.firstName}}</a>
                                </ion-col>
                                <ion-col>

                                </ion-col>
                            </ion-row>
                        </div>
                    </ion-grid>
                </ion-col>
            </ion-row>
            <ion-row *ngFor="let report of reports" [id]="report.employeeListing.rowKey">
                <ion-col *ngIf="(report.employeeListing.rowKey === selectedRowKey) || !selectedRowKey">
                    <ion-grid>
                        <div class="table-container">
                            <ion-row class="cust-info-row">
                                <ion-col size="auto">
                                    <ion-row>
                                        <ion-col>
                                            Customer:&nbsp;{{report.employeeListing.customerCode}}&nbsp;&nbsp;<b>{{report.employeeListing.customerName}}</b>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                            Department:&nbsp;{{report.employeeListing.departmentCode}}&nbsp;&nbsp;<b>{{report.employeeListing.departmentName}}</b>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                            Employee: &nbsp;{{report.employeeListing.employeeCode}}&nbsp;&nbsp;<b>{{report.employeeListing.firstName}} {{report.employeeListing.lastName}}</b>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                            Stop: &nbsp;{{report.employeeListing.stopCode}}&nbsp;&nbsp;<b>{{report.employeeListing.stopName}}</b>
                                        </ion-col>
                                    </ion-row>
                                    <ion-row>
                                        <ion-col>
                                            Garment ID Label: &nbsp;{{report.employeeListing.stopCode}}&nbsp;{{report.employeeListing.employeeCode}}
                                        </ion-col>
                                    </ion-row>
                                </ion-col>
                            </ion-row>
                            <ion-row>
                                <ion-col>
                                    <ion-row>
                                        <ion-col class="label-col">
                                            <ion-label><strong>Garment Summary</strong></ion-label>
                                        </ion-col>
                                    </ion-row>           
                                    <app-garment-summary-table [rows]="report.summary" *ngIf="!viewChange"></app-garment-summary-table>
                                    <app-garment-summary-table-print [rows]="report.summary" *ngIf="viewChange"></app-garment-summary-table-print>
                                </ion-col>
                            </ion-row>
                            <!-- <ion-row *ngIf="report.summaryNotes.length > 0">
                                <ion-col>
                                    <ion-row>
                                        <ion-col class="label-col">
                                            <ion-label>Notes</ion-label>
                                        </ion-col>
                                    </ion-row>   
                                    <app-garment-notes-summary-table [rows]="report.summaryNotes"></app-garment-notes-summary-table>
                                </ion-col>
                            </ion-row> -->
                            <ion-row>
                                <ion-col>
                                    <ion-row>
                                        <ion-col class="label-col">
                                            <ion-label><strong>Garment Details</strong></ion-label>
                                        </ion-col>
                                    </ion-row>   
                                    <app-garment-detail-table [rows]="report.details" *ngIf="!viewChange"></app-garment-detail-table>
                                    <app-garment-detail-table-print [rows]="report.details" *ngIf="viewChange"></app-garment-detail-table-print>
                                </ion-col>
                            </ion-row>
                            <!-- <ion-row *ngIf="report.notes.length > 0">
                                <ion-col>
                                    <ion-row>
                                        <ion-col class="label-col">
                                            <ion-label>Notes</ion-label>
                                        </ion-col>
                                    </ion-row>   
                                    <app-garment-notes-table [rows]="report.notes"></app-garment-notes-table>
                                </ion-col>
                            </ion-row> -->
                        </div>
                    </ion-grid>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ng-container>
</ion-content>