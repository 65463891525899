import { Injectable } from "@angular/core";
import { RestApiService } from "../../../common/rest-api/services/rest-api.service";
import {
  RouteDeliveryDetails,
  RouteDeliveryDetailsFreq,
  RouteDeliveryDetailsPickup,
} from "../models/route-delivery-details.model";

@Injectable({
  providedIn: "root",
})
export class RouteDeliveryDetailsService extends RestApiService<RouteDeliveryDetails> {
  protected resourcePath = "/route-detail";
  protected modelType = RouteDeliveryDetails;

  async GetDeliveryList(
    stopCode: string,
    routeCode: string,
    forDate: Date
  ): Promise<RouteDeliveryDetailsFreq[]> {
    //convert date to yyyy-mm-dd format
    const forDateStr = forDate.toISOString().split("T")[0];
    const url = this.buildEndpointUrl(
      `delivery/${stopCode}/${routeCode}/${forDateStr}`
    );
    
    const response = await this.cloudApiMiddleware.cloudRequest<RouteDeliveryDetailsFreq[]>({
      url
    }, 'GET');

    return response;
  }

  async GetPickupList(
    stopCode: string,
    routeCode: string,
    forDate: Date,
    dayOfPeriod: number
  ): Promise<RouteDeliveryDetailsPickup[]> {
    //convert date to yyyy-mm-dd format
    const forDateStr = forDate.toISOString().split("T")[0];
    const url = this.buildEndpointUrl(
      `pickup/${stopCode}/${routeCode}/${forDateStr}/${dayOfPeriod}`
    );

    const response = await this.cloudApiMiddleware.cloudRequest<RouteDeliveryDetailsPickup[]>({
      url
    }, 'GET');

    return response;
  }

  async GetNumberOfPickups(
    stopCode: string,
    routeCode: string,
    forDate: Date,
    dayOfPeriod: number
  ): Promise<number> {
    //convert date to yyyy-mm-dd format
    const forDateStr = forDate.toISOString().split("T")[0];
    const url = this.buildEndpointUrl(
      `pickup-count/${stopCode}/${routeCode}/${forDateStr}/${dayOfPeriod}`
    );

    const response = await this.cloudApiMiddleware.cloudRequest<number>({
      url
    }, 'GET');

    return response;
  }

  async UpdatePickupList(pickupList: RouteDeliveryDetailsPickup[]) {
    const url = this.buildEndpointUrl(`pickup-update`);

    const response = await this.cloudApiMiddleware.cloudRequest<string>({
      url,
      body: pickupList
    }, 'PUT');
    
    return response;
  }
}
