import { Component, OnInit } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController, ViewWillEnter, ViewDidLeave, ViewDidEnter } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { GtrakAuthService } from '../../../authentication/services/gtrak-auth.service';
import { GarmentHistoryService } from '../../../../features/garment-listing/services/garment-history.service';
import { GarmentSummaryService } from '../../../../features/garment-listing/services/garment-summary.service';
import { MobileHomeRoutes } from '../../mobile.routes';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-delivery-page',
  templateUrl: 'delivery.page.html',
  styleUrls: [
    './delivery.page.scss'
  ]
})
export class DeliveryPage implements ViewWillEnter, OnInit {

  public routes = MobileHomeRoutes;

  garmentSummary: any;
  garmentHistory: any;
  loaded: boolean = false;
  notLoaded: boolean = true;
  dateArray: any[] = [];
  empKey: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: GtrakAuthService,
    public historyService: GarmentHistoryService,
    public summaryService: GarmentSummaryService,
    private navController: NavController
  ) { 
  
  }

  ngOnInit(): void {
   
  }

  async ionViewWillEnter() {
    this.loaded = false;

    this.empKey = {
      CustomerCode: this.authService.user.claims.cus_code,
      DepartmentCode: this.authService.user.claims.dep_code,
      EmployeeCode: this.authService.user.claims.emp_code
    };

    this.dateArray = this.generateDateArray();

    const promises: any[] = [];

    promises.push(this.summaryService.getForEmployee(this.empKey));
    promises.push(this.fillGarmentHistory());

    const results = await Promise.all(promises);

    this.garmentSummary = results[0];
    
    const allRNTProducts = this.garmentSummary.details.filter(d => (d.productCode as string).includes('RNT'));
    const allNonRNTProducts = this.garmentSummary.details.filter(d => !(d.productCode as string).includes('RNT'));

    this.garmentSummary.details = [...allRNTProducts, ...allNonRNTProducts];

    this.generateExpanded();

    this.loaded = true;
  }

  navigateBack(): void {
    this.navController.back();
  }

  // async ionViewWillEnter() {
  //   this.notLoaded = true;
  //   this.loaded = false;
  //   this.empKey = {
  //     CustomerCode: this.authService.user.claims.cus_code,
  //     DepartmentCode: this.authService.user.claims.dep_code,
  //     EmployeeCode: this.authService.user.claims.emp_code
  //   }
    
  //   await this.summaryService.getForEmployee(this.empKey).then((data) => {
  //     this.garmentSummary = data;
  //   });

  //   console.log(this.garmentSummary);


  //   // this.dateArray = this.makeDateArray('2021-01-01', '40');

  //   this.dateArray = this.generateDateArray();
  //   await this.fillGarmentHistory();
  //   this.generateExpanded();

  //   this.loaded = true;
  //   this.notLoaded = false;

  // }

  //create a date array from now to 90 days ago seperated by week
  generateDateArray() {
    var dateArray = [];
    var date = new Date();
    //add todays date to array
    dateArray.push({date: date.toDateString(), count: 0});
    for (var i = 0; i < 90; i+=7) {
      date.setDate(date.getDate() - 7);
      new Date(date);
      dateArray.push({date: date.toDateString(), count: 0});
      
    }
    return dateArray;
  }

  //fill garment history array with number of garments for each week
  async fillGarmentHistory() {
    //first and last date in date array
    var firstDate = new Date(this.dateArray[0].date);
    var lastDate = new Date(this.dateArray[this.dateArray.length - 1].date);
    //get history for employee based on dates
    await this.historyService.getHistoryDateRangeEmployee(this.empKey, firstDate.toISOString(), lastDate.toISOString()).then((data) => {
      this.garmentHistory = data;
    });
    //for each garment in garment history
    for (var i = 0; i < this.garmentHistory.length; i++) {
      //for each date in date array
      for (var j = 0; j < this.dateArray.length; j++) {
        //if garment date is within the week of the date in the date array
        if (new Date(this.garmentHistory[i].statusDate).getTime() > new Date(this.dateArray[j].date).getTime() - 7*24*60*60*1000 && new Date(this.garmentHistory[i].statusDate).getTime() < new Date(this.dateArray[j].date).getTime()) {
          //add garment to date array count
          this.dateArray[j].count++;
        }
      }
    }
  }



  //generate an expanded field for the details array in garment summary
  generateExpanded() {
    for (var i = 0; i < this.garmentSummary.details.length; i++) {
      this.garmentSummary.details[i].expanded = false;
    }
  }

  //toggle the expanded field for the details array in garment summary
  expandItem(item): void {
    item.expanded = !item.expanded;
  }


  //make a date array from a start date and add number of weeks to it
  makeDateArray(startDate, numberOfWeeks) {
    var dateArray = [];
    var date = new Date(startDate);
    //add todays date to array
    dateArray.push({date: date.toDateString(), count: 0});
    for (var i = 0; i < numberOfWeeks; i++) {
      date.setDate(date.getDate() + 7);
      new Date(date);
      dateArray.push({date: date.toDateString(), count: 0});
      
    }
    return dateArray;
  }


}