import { OrderParams } from "./order-params";
import { PageParams } from "./page-params";
import { QueryParams } from "./query-params";

/**
 * Class that encapsulates the various parameter sets used in a request to an api
 */
export class RequestParams {
  queryParams: QueryParams = new QueryParams();
  orderParams?: OrderParams;
  pageParams?: PageParams;

  filterParamName: string = 'filter';

  filters: { [key: string]: string | number } = {};

  /**
   * The constructed query string that includes any provided query, order, and page parameters
   */
  get queryString() {
    const params = {};
    
    if (this.queryParams)
      Object.assign(params, this.queryParams.params);

    if (this.orderParams)
      Object.assign(params, this.orderParams);
    
    if (this.pageParams)
      Object.assign(params, this.pageParams);
    
    params[this.filterParamName] = Object.keys(this.filters).map(key => key + '|' + this.filters[key]).join('||')

    return '?' + Object.keys(params).map(
      key => key + '=' + encodeURIComponent(params[key])
    ).join('&');
  }

  /**
   * Set the paging parameters for the request
   * @param page The page of the dataset to retrieve. First page = 1
   * @param pageSize The number of records to return per page
   */
  setPaging(page: number, pageSize: number) {
    if (!this.pageParams)
      this.pageParams = new PageParams();
    
    this.pageParams.page = page;
    this.pageParams.pageSize = pageSize;
  }

  /**
   * Set the ordering parameters for the request
   * @param orderBy The field to order the results by
   * @param order Whether ordering is ascending or descending
   */
  setOrdering(orderBy: string, order: 'asc' | 'desc' = 'desc') {
    if (!this.orderParams)
      this.orderParams = new OrderParams();
    
    this.orderParams.orderBy = orderBy;
    this.orderParams.order = order;
  }
}