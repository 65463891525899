<ion-content class="garment-listing">
    <app-header [title]="'Garment Return Review'"></app-header>
    <ng-container>
        <ion-grid>
            <ion-row class="filter-row">
                <ion-grid>
                    <ion-row>
                        <ion-col>
                            <div>
                                <ion-input type="text" [(ngModel)]="lastName" class="ion-text-left color-light" placeholder="{{authService.user?.isCostCenterUser ? 'Enter part of last name, stop code, or leave blank for all' : 'Enter part or all of last name, or leave blank for all'}}"></ion-input>
                                <div class="show-inactive-container">
                                    <ion-checkbox [(ngModel)]="showInactive" (ionChange)="inactiveCheckboxUpdate($event)"></ion-checkbox>
                                    <span>Include Inactive</span>
                                </div>
                                <ion-button *ngIf="!authService.user?.isCostCenterUser" color="gallaghergreen" class="btn-box-shadow" (click)="getDataByLastName()"><span>Show</span></ion-button>
                            </div>
                        </ion-col>
                    </ion-row>
                </ion-grid>

            </ion-row>

            <ion-row class="table-row">
                <div class="ion-padding table-container">
                    <app-report-table [mode]="'gl'" (paging)="setPage($event)" [pageSize]="pageSize" [pageNumber]="currPage" [rows]="employees" [currentRoute]="currentRoute" [totalElements]="totalElements"></app-report-table>
                </div>
            </ion-row>
        </ion-grid>
    </ng-container>
</ion-content>
