import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DepartmentKey } from '../../../../app/common/models/department-key-model';
import { EmployeeKey } from '../../../../app/common/models/employee-key.model';
import { QueryResult } from '../../../common/rest-api/models/query-result';
import { RequestParams } from '../../../common/rest-api/models/request-params';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { EmployeeTransfer } from '../models/employee-transfer.model';

@Injectable({
  providedIn: 'root',
})
export class EmployeeTransferService extends RestApiService<EmployeeTransfer> {

  protected resourcePath = '/employee-transfer';
  protected modelType = EmployeeTransfer;

  async findTransferReports(params?: RequestParams): Promise<QueryResult<EmployeeTransfer>> {
    const url = this.buildEndpointUrl(`query`, params);
    const response = await this.cloudApiMiddleware.cloudRequest<QueryResult<EmployeeTransfer>>({
      url
    }, 'GET')

    response.results = response.results.map(i => EmployeeTransfer.fromType(this.modelType, i));

    return response;
  }

}