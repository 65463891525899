import { Injectable } from '@angular/core';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service'; 
import { CancelOrder, CancelOrderItem } from '../models/cancel-order.model';
import { firstValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class CancelOrderService extends RestApiService<CancelOrder> {

  protected resourcePath = '/cancel-order';
  protected modelType = CancelOrder;


  async getForCustomer(customerId: string): Promise<CancelOrder[]> {
    const url = this.buildEndpointUrl(`${customerId}`);

    const response = await this.cloudApiMiddleware.cloudRequest<CancelOrder[]>({
      url
    }, 'GET');

    return response;
  }

  async getOpenCancelReport(customerId: string): Promise<CancelOrderItem[]> {
    const url = this.buildEndpointUrl(`open-cancel-report/${customerId}`);

    const response = await this.cloudApiMiddleware.cloudRequest<CancelOrderItem[]>({
      url
    }, 'GET');

    return response;
  }
  
  async getForDepartment(departmentId: string, customerId: string): Promise<CancelOrder[]> {
    const url = this.buildEndpointUrl(`${customerId}/${departmentId}`);

    const response = await this.cloudApiMiddleware.cloudRequest<CancelOrder[]>({
      url
    }, 'GET');

    return response;
  }

  async getForEmployee(employeeId: string, departmentId: string, customerId: string): Promise<CancelOrder[]> {
    
    const url = this.buildEndpointUrl(`${customerId}/${departmentId}/${employeeId}`);
    
    const response = await this.cloudApiMiddleware.cloudRequest<CancelOrder[]>({
      url
    }, 'GET');

    return response;
  }

  async getForStop(stopCode: string): Promise<CancelOrder[]> {
    const url = this.buildEndpointUrl(`stop/${stopCode}`);

    const response = await this.cloudApiMiddleware.cloudRequest<CancelOrder[]>({
      url
    }, 'GET');

    return response;
  }

  async getNumberForStop(stopCode: string): Promise<number> {
    const url = this.buildEndpointUrl(`stop/${stopCode}/count`);

    const response = await this.cloudApiMiddleware.cloudRequest<number>({
      url
    }, 'GET');

    return response;
  }

  async updateCancelReport(cancelOrders: CancelOrder[]): Promise<CancelOrder[]> {
    const url = this.buildEndpointUrl(`cancel-report`);
   
    const response = await this.cloudApiMiddleware.cloudRequest<CancelOrder[]>({
      url,
      body: cancelOrders
    }, 'PUT');
    
    return response;
  }

}