import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GtrakAuthService } from "../../../authentication/services/gtrak-auth.service";
import { CustomerCostCenterService } from "../../services/customer-cost-center.service";
import { ToasterService } from "../../../../common/services/toaster.service";
import { ModalController } from "@ionic/angular";
import { EmployeeListService } from "../../../../common/services/employee-listing.service";
import { RequestParams } from "../../../../common/rest-api/models/request-params";

@Component({
  selector: "app-cost-center-employee-view-page",
  templateUrl: "cost-center-employee-view.page.html",
  styleUrls: ["./cost-center-employee-view.page.scss"],
})
export class CostCenterEmployeeViewPage implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: GtrakAuthService,
    private costCenterService: CustomerCostCenterService,
    private toasterService: ToasterService,
    private modalController: ModalController,
    private employeeListService: EmployeeListService
  ) {}

  costCenterCode: string;

  totalPages: number = 0;
  totalElements: number = 0;

  requestParams: any;

  currPage: number;
  pageSize: number;
  public rows: any;
  submitting: boolean;
  currentRoute: string;

  ngOnInit() {
    this.currentRoute = this.router.url;
    this.requestParams = new RequestParams();
    //get the cost center code from the url
    this.costCenterCode = this.route.snapshot.paramMap.get("costCenterCode");
    //get the employees for the cost center
    this.requestParams.filters.CostCenter = this.costCenterCode;
    this.rows = [];

    this.currPage = 1;
    this.pageSize = 20;

    // For the listing view, we want to exclude any inactive employees.
    this.requestParams.filters.ExcludeInactive = true;
    this.getEmployees();
  }

  async getEmployees() {
    this.submitting = true;
    this.requestParams.setPaging(this.currPage, this.pageSize);
    var employees = await this.employeeListService.query(this.requestParams);

    this.totalElements = employees.totalRecords;
    this.totalPages = employees.totalPages;

    this.rows = employees.results;
    this.submitting = false;
  }

  setPage(page): void {
    this.currPage = page + 1;

    this.getEmployees();
  }
}
