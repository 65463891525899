import { Component, Input, OnInit } from '@angular/core';
import { ViewWillEnter } from '@ionic/angular';
import { ReportTableComponent } from '../../../../common/components/report-table/report-table.component';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Time } from '@angular/common';
import { getTime } from 'date-fns';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';
import { RequestParams } from '../../../../common/rest-api/models/request-params';
import { GRouteStopService } from '../../services/g-route-stops.service';
import { RouteStopModel } from '../../models/route-stop.model';
import { RouteModel } from '../../models/route.model';
import { GRouteService } from '../../services/g-route.service';

//Test data for ngxtable
const testData = require('../../../../common/test-data/logsheet-data.json');

@Component({
  selector: 'app-logsheet-page',
  templateUrl: 'logsheet.page.html',
  styleUrls: [
     './logsheet.page.scss',
     '../../styles/g-route.styles.scss'
  ]
})
export class LogSheetPage implements ViewWillEnter, OnInit {

    currentNavRoute: string;

    requestParams: RequestParams;

    routeStops: RouteStopModel[];
    allRouteStops: RouteStopModel[];
    currentRoute: RouteModel;

    showAllStops: boolean = false;
    loaded: boolean = false;

    constructor(
        private router: Router,
        private authService: GtrakAuthService,
        private stopService: GRouteStopService,
        private routeService: GRouteService,
    ) { 
        this.currentRoute = null;
    }

    async ngOnInit(): Promise<void> {
        this.loaded = false;
        if(!this.authService.user.canViewDriverRoutes) {
            this.router.navigate([this.authService.user.homeLocation]);
        }

        this.currentNavRoute = this.router.url;
        
        this.requestParams = new RequestParams();
        this.requestParams.setOrdering('ServiceTime', 'asc');
        this.requestParams.setPaging(1, 0);
        
        this.requestParams.filters.RouteCode = this.authService.user.routeCode;
        this.currentRoute = await this.routeService.get(this.authService.user.routeCode);

        // difference between the starttime and todays date mod 28
        this.requestParams.filters.DayOfPeriod = this.currentRoute.frequencyOffset(this.authService.user.routeDate);

        const date = this.authService.user.routeDate;
        var stopData = await this.stopService.getStops(date.toISOString(), this.requestParams);

        stopData.results.forEach((r: any) => {
            const logDate = r.routeLogs?.filter(rl => rl.logType === 2)[0]?.logDate ?? null;
            var hours = 0;

            // Adjust hours from UTC
            if (logDate) {
                hours = ((new Date(logDate).getHours() * 60) - new Date(logDate).getTimezoneOffset()) / 60;
            }
  
            r.arrival = logDate ? `${hours}:${new Date(logDate).getMinutes()}` : null;
        });

        this.allRouteStops = stopData.results.sort((a, b) => {
            if (new Date(a.deliveryTime).getTime() < new Date(b.deliveryTime).getTime()) return -1;
            if (new Date(a.deliveryTime).getTime() > new Date(b.deliveryTime).getTime()) return 1;
            // if (a.serviceTime < b.serviceTime) return 1;
            // if (a.serviceTime > b.serviceTime) return -1;
            return 0;
        });


        this.routeStops = this.allRouteStops.filter(a => !a.routeLogs.find(r => r.logType === 3));
        
        this.loaded = true;
    }

    ionViewWillEnter(): void {
        
    }

    showAllToggle(): void {
        this.routeStops = this.showAllStops ? this.allRouteStops : this.allRouteStops.filter(a => !a.routeLogs.find(r => r.logType === 3));
    }

}