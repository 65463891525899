import { Model, ModelId } from '../rest-api/models/model';//"../core/shared/rest-api/models/model";

export class EmployeeListing extends Model {
  // TODO Since this is technically a view, we may need to adjust this or create a separate type of service (similar to the API)
  get modelId(): ModelId {
    throw new Error("Method not implemented.");
  }

  rowKey: string = '';
  customerCode: string = '';
  customerName: string = '';
  departmentCode: string = '';
  departmentName: string = '';
  employeeCode: string = '';
  lastName: string = '';
  firstName: string = '';
  lockerNum?: string = '';
  stopCode: string = '';
  stopDescription: string = '';
  activeCode: string = '';

  get garmentId(): string { return `${this.stopCode} ${this.lockerNum}`}
}