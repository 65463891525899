<ion-content class="email-report">
    <app-header [title]="(routeStopName ?? '') + ' (' + stopName + ') Email Report'"></app-header>
    <ng-container>
        <div class="container">
            <div class="contact-container">
                <div class="email-to">
                    <span>Email Reports To: </span>
                </div>
                <div class="contact-item" *ngFor="let contact of contacts">
                    <span>{{contact.name}} => {{contact.email}}</span>
                </div>
            </div>

            <div class="add-comment-container">
                <div class="add-comment">
                    <span>Options</span>
                </div>

                <div class="comment-item">
                    <div class="checkbox-container">
                        <input type="checkbox" [(ngModel)]="sendInvoice" />
                    </div>
                    <div>
                        <span>Send Invoice Email</span>
                    </div>
                </div>
            </div>

            <div class="add-comment-container">
                <div class="add-comment">
                    <span>Add Comments</span>
                </div>

                <div class="comment-item" *ngFor="let comment of comments">
                    <div class="checkbox-container">
                        <input type="checkbox" [(ngModel)]="comment.selected" />
                    </div>
                    <div>
                        <span>{{comment.text}}</span>
                    </div>
                </div>
            </div>

            <div class="submit-button">
                <button [disabled]="sendingEmail" (click)="submit()">Submit</button>
            </div>
        </div>
    </ng-container>
</ion-content>