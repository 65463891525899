<ion-content class="account-management" *ngIf="loaded">
    <app-header [isParentPage]="true" [title]="'Account Management'"></app-header>
    <ng-container>
        <ion-grid class="container-grid">
            <ion-row class="filter-row">
                <ion-grid>
                    <ion-row>
                        <ion-col>
                            <ion-select interface="popover" placeholder="Select Active or Disabled" value="Active" (ionChange)="selectActiveStatus($event)">
                                <ion-select-option value="Active">Active</ion-select-option>
                                <ion-select-option value="Disabled">Disabled</ion-select-option>
                            </ion-select>
                        </ion-col>
                        <ion-col>
                            <ion-select interface="popover" dplaceholder="Select Active or Disabled" value="All" (ionChange)="selectMobileSearch($event)">
                                <ion-select-option value="All">All</ion-select-option>
                                <ion-select-option value="Mobile">Mobile</ion-select-option>
                                <ion-select-option value="Non-Mobile">Non-Mobile</ion-select-option>
                            </ion-select>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col class="search-label-col">
                            <ion-label class="ion-label-search">Search Criteria:</ion-label>
                        </ion-col>
                        <ion-col size="4.7">
                            <ion-select interface="popover" placeholder="Select Search Criteria" value="Login" (ionChange)="selectSearchCriteria($event)">
                                <ion-select-option value="Login">Login ID</ion-select-option>
                                <ion-select-option value="Last Name">Last Name</ion-select-option>
                                <ion-select-option value="Customer Access">Customer Access</ion-select-option>
                            </ion-select>
                        </ion-col>
                        <ion-col class="search-column" size="6">
                            <div>
                                <ion-input type="text" [(ngModel)]="input" 
                                [placeholder]="(searchCritieriaString === 'Customer Access') ? 'Enter Customer Code' : ('Enter ' + searchCritieriaString)"
                                 class="ion-text-left color-light"></ion-input>
                                <ion-button color="gallaghergreen" (click)="search()"><span>Show</span></ion-button>
                            </div>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <div class="new-user-container">
                            <ion-button class="btn-box-shadow separation" [routerLink]="['/account-management/add']"><span>Add New User</span></ion-button>
                            <ion-button class="btn-box-shadow " [routerLink]="['/account-management/add-mobile']"><span>Add Mobile User</span></ion-button>
                        </div>
                    </ion-row>
                </ion-grid>
            </ion-row>
            <!-- <ion-row class="table-container new-user-row">
                <div class="new-user-container">
                    <ion-button color="primary" class="btn-box-shadow separation" [routerLink]="['/account-management/add']"><span>Add New User</span></ion-button>
                    <ion-button color="primary" class="btn-box-shadow " [routerLink]="['/account-management/add-mobile']"><span>Add Mobile User</span></ion-button>
                </div>
            </ion-row> -->
            <ion-row class="table-container">
                <ion-header>User List</ion-header>
            </ion-row>
            <ion-row> 
                <div class="table-container">
                    <app-user-list-table (paging)="setPage($event)" [pageSize]="pageSize" [pageNumber]="currPage" [rows]="rows" [currentRoute]="currentRoute" [totalElements]="totalElements"></app-user-list-table>
                </div>
            </ion-row>
        </ion-grid>
    </ng-container>
</ion-content>