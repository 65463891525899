import { Data } from "@angular/router";
import { Model, ModelId } from "../../../common/rest-api/models/model";

export class CustomerEmployee extends Model
{
  get modelId(): ModelId {
    throw new Error("Method not implemented.");
  }

  customerCode: string;
    departmentCode: string;
    employeeCode: string;
    firstName: string;
    lastName: string;
    delAddress1?: string;
    delAddress2?: string;
    delAddress3?: string;
    delCity?: string;
    delStateCode?: string;
}