
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DepartmentKey } from '../../../../app/common/models/department-key-model';
import { EmployeeKey } from '../../../../app/common/models/employee-key.model';
import { QueryResult } from '../../../common/rest-api/models/query-result';
import { RequestParams } from '../../../common/rest-api/models/request-params';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { RouteStopModel } from '../models/route-stop.model';
import { CustomerContactModel } from '../models/customer-contact.model';
import { GarmentModel } from '../../garment-listing/models/garment.model';
  
@Injectable({
providedIn: 'root',
})
export class GarmnetPickupService extends RestApiService<GarmentModel> {

    protected resourcePath = '/garment';
    protected modelType = GarmentModel;

    async PickUpGarment(garmentCode: string): Promise<QueryResult<GarmentModel>> {
        const url = this.buildEndpointUrl(`pickup/${garmentCode}`);
        const response = await this.cloudApiMiddleware.cloudRequest<QueryResult<GarmentModel>>({
            url
        }, 'PUT');

        return response;
    }

    async GetGarmentList(date: string, stopCode: string): Promise<QueryResult<GarmentModel>> {
        const url = this.buildEndpointUrl(`pickup/${date}/${stopCode}`);
        const response = await this.cloudApiMiddleware.cloudRequest<QueryResult<GarmentModel>>({
            url
        }, 'GET');

        return response;
    }

}