import { Injectable, Type } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { QueryResult } from '../../../../../../common/rest-api/models/query-result';
import { RequestParams } from '../../../../../../common/rest-api/models/request-params';
import { RestApiService } from '../../../../../../common/rest-api/services/rest-api.service';
import { StopInvoice, StopInvoiceReport } from './models/stopInvoice.model';


@Injectable({
  providedIn: 'root',
})
export class StopInvoiceService extends RestApiService<StopInvoiceReport> {

  protected resourcePath = '/invoice';
  protected modelType = StopInvoiceReport;

  async getInvoices(stopCode: string, routeCode: string, params?: RequestParams): Promise<StopInvoiceReport> {
    const url = this.buildEndpointUrl(`stop/${stopCode}/${routeCode}`, params);

    const response = StopInvoiceReport.from(await this.cloudApiMiddleware.cloudRequest<QueryResult<StopInvoiceReport>>({
      url
    }, 'GET'));

    return response;
  }
  
}