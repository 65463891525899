import { Data } from "@angular/router";
import { Model, ModelId } from "../../../common/rest-api/models/model";

export class AssignedMessages extends Model
{
  get modelId(): ModelId {
    throw new Error("Method not implemented.");
  }

  amsgCode: string;
  messageCode: string;
  startDate: Date;
  endDate: Date;
  repeatType: number;
  repeatInterval: number;
  lastDisplayedIn: number;
  garmentCode: string;
  sorterFlg: boolean;
}