<ion-content class="garment-repair">
    <app-header [title]="(routeStopName ?? '') + ' (' + stopName + ') Repair'"></app-header>
    <ng-container>
        <div class="content-container">
            <ion-row>
                <ion-col class="ion-no-padding">
                    <ion-row>
                        <ion-label>Reason</ion-label>
                    </ion-row>
                    <ion-row>
                        <ion-select placeholder="Select Reason" interface="popover"
                        [interfaceOptions]="{'cssClass': 'wider-popover'}" [(ngModel)]="messageCode">
                            <ion-select-option value="06">Relable Garment ID</ion-select-option>
                            <ion-select-option value="15">Repair - Belt Loop / Drawstring</ion-select-option>
                            <ion-select-option value="11">Repair - Buttons, Button Holes, Snaps</ion-select-option>
                            <ion-select-option value="13">Repair - Check All</ion-select-option>
                            <ion-select-option value="MSG0010064">Repair - Check Seat / Inner Thigh</ion-select-option>
                            <ion-select-option value="MSG0010031">Repair - Check Zipper</ion-select-option>
                            <ion-select-option value="21">Repair - Emblems</ion-select-option>
                            <ion-select-option value="14">Repair - Pockets</ion-select-option>
                            <ion-select-option value="12">Repair - Seams or Hems</ion-select-option>
                            <ion-select-option value="MSG0010070">Repair - Sleeve/Cuff</ion-select-option>
                            <ion-select-option value="MSG0010042">Repair - Tear</ion-select-option>
                            <ion-select-option value="MSG0010080">Replace - Inspect for Replacement</ion-select-option>
                            <ion-select-option value="05">Send to Customer Service</ion-select-option>
                            <ion-select-option value="MSG0010033">Verify - All Emblems</ion-select-option>
                            <ion-select-option value="MSG0010046">Verify - Name Emblem Spelling</ion-select-option>
                        </ion-select>
                    </ion-row>
                </ion-col>
            </ion-row>
            <ion-row class="garment-row">
                <ion-col class="ion-no-padding">
                    <ion-row>
                        <ion-label>Garment Code</ion-label>
                    </ion-row>
                    <ion-row>
                        <ion-input placeholder="Enter Garment Code" [(ngModel)]="garmentCode"></ion-input>
                    </ion-row>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-button class="button" (click)="PostMessage()">
                    Save Repairs
                </ion-button>
            </ion-row>
        </div>

        <!-- <ion-grid>
            <ion-row class="title-row">
                <ion-col>
                    <ion-row>
                        <ion-item class="select" no-lines>
                            <ion-select placeholder="Select Reason" interface="popover"
                                [interfaceOptions]="{'cssClass': 'wider-popover'}" [(ngModel)]="messageCode">
                                <ion-select-option value="06">Relable Garment ID</ion-select-option>
                                <ion-select-option value="15">Repair - Belt Loop / Drawstring</ion-select-option>
                                <ion-select-option value="11">Repair - Buttons, Button Holes, Snaps</ion-select-option>
                                <ion-select-option value="13">Repair - Check All</ion-select-option>
                                <ion-select-option value="MSG0010064">Repair - Check Seat / Inner Thigh</ion-select-option>
                                <ion-select-option value="MSG0010031">Repair - Check Zipper</ion-select-option>
                                <ion-select-option value="21">Repair - Emblems</ion-select-option>
                                <ion-select-option value="14">Repair - Pockets</ion-select-option>
                                <ion-select-option value="12">Repair - Seams or Hems</ion-select-option>
                                <ion-select-option value="MSG0010070">Repair - Sleeve/Cuff</ion-select-option>
                                <ion-select-option value="MSG0010042">Repair - Tear</ion-select-option>
                                <ion-select-option value="MSG0010080">Replace - Inspect for Replacement</ion-select-option>
                                <ion-select-option value="05">Send to Customer Service</ion-select-option>
                                <ion-select-option value="MSG0010033">Verify - All Emblems</ion-select-option>
                                <ion-select-option value="MSG0010046">Verify - Name Emblem Spelling</ion-select-option>
                            </ion-select>
                        </ion-item>
                    </ion-row>
                    <ion-row>
                        <ion-item no-lines class="textarea">
                            <ion-label position="floating">Garment Code</ion-label>
                            <ion-input [(ngModel)]="garmentCode"></ion-input>
                        </ion-item>
                    </ion-row>
                    <ion-row>
                        <ion-button class="button" (click)="PostMessage()">
                            Save Repairs
                        </ion-button>
                    </ion-row>
                </ion-col>

            </ion-row>
        </ion-grid> -->
    </ng-container>
</ion-content>