import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DepartmentKey } from '../../../../app/common/models/department-key-model';
import { EmployeeKey } from '../../../../app/common/models/employee-key.model';
import { QueryResult } from '../../../common/rest-api/models/query-result';
import { RequestParams } from '../../../common/rest-api/models/request-params';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { GarmentReport } from '../models/garment-report.model';


@Injectable({
  providedIn: 'root',
})
export class GarmentReportService extends RestApiService<GarmentReport> {

  protected resourcePath = '/garment-report';
  protected modelType = GarmentReport;

  protected departmentPath = 'department';
  protected customerPath = 'customer';
  protected garmentPath = 'garment-code';


  async getForEmployee(employeeKey: EmployeeKey): Promise<GarmentReport> {
    const params = new RequestParams();
    params.queryParams.params.CustomerCode = employeeKey.CustomerCode;
    params.queryParams.params.DepartmentCode = employeeKey.DepartmentCode;
    params.queryParams.params.EmployeeCode = employeeKey.EmployeeCode;

    const url = this.buildEndpointUrl('', params);
    const response =  GarmentReport.from(await this.cloudApiMiddleware.cloudRequest<GarmentReport>({
      url
    }, 'GET'));

    return response;
  }

  async findForDepartment(departmentKey: DepartmentKey, params?: RequestParams): Promise<QueryResult<GarmentReport>> {
    if (!params)
      params = new RequestParams();
    params.queryParams.params.CustomerCode = departmentKey.CustomerCode;
    params.queryParams.params.DepartmentCode = departmentKey.DepartmentCode;

    const url = this.buildEndpointUrl(this.departmentPath, params);
    const response = await this.cloudApiMiddleware.cloudRequest<QueryResult<GarmentReport>>({
      url
    }, 'GET');

    response.results = response.results.map(i => GarmentReport.fromType(this.modelType, i));

    return response;
  }

  async findForCustomer(customerCode: string, params?: RequestParams): Promise<QueryResult<GarmentReport>> {
    const url = this.buildEndpointUrl(`${this.customerPath}/${customerCode}`, params);
    const response = await this.cloudApiMiddleware.cloudRequest<QueryResult<GarmentReport>>({
      url
    }, 'GET');

    response.results = response.results.map(i => GarmentReport.fromType(this.modelType, i));

    return response;
  }

  async findForGarment(garmentCode: string, params?: RequestParams): Promise<GarmentReport> {
    const url = this.buildEndpointUrl(`${this.garmentPath}/${garmentCode}`, params);

    const response = GarmentReport.from(await this.cloudApiMiddleware.cloudRequest<QueryResult<GarmentReport>>({
      url
    }, 'GET'));

    return response;
  }
}