<ion-grid>
    <ion-row class="header">
        <ion-col>
            <span>Select a route and date</span>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
          <ion-label><span>Routes</span></ion-label>
          <ion-select interface="popover" placeholder="Select a Route" [(ngModel)]="selectedRoute">
            <ion-select-option *ngFor="let route of routeList" [value]="route.routeCode">{{route.description}}</ion-select-option>
          </ion-select>
        </ion-col>
      </ion-row>
    <ion-row>
        <ion-col>
          <ion-label>Date</ion-label>
          <ion-input type="Date" [(ngModel)]="date" class="ion-text-left color-light user-id"></ion-input>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
            <ion-button [disabled]="!date || !selectedRoute" (click)="continue()">Continue</ion-button>
        </ion-col>
    </ion-row>
</ion-grid>