import { AppRoute } from "../../../core/routing/AppRoute";

import { AuthGuard } from "../../common/rest-api/services/auth-guard.service";
import { MainAdminPage } from "./pages/main-admin/main-admin.page";
import { WoApprovalPage } from "./pages/wo-approval/wo-approval.page";
import { CostCenterAdminPage } from "./pages/cost-center-admin/cost-center-admin.page";
import { CostCenterEmployeeViewPage } from "./pages/cost-center-employee-view/cost-center-employee-view.page";

// A feature route should be used in the App routing module in most cases and primarily provide
// a base url and module loading functionality.
// The feature route should employ a load children callback that loads the appropriate module
const Feature = new AppRoute({
  segment: "admin-page",
  title: "Admin Page",
  // A component can also be included to be the content, or a separate route with a blank segment
  // can be defined like below to provide a dedicated route instance
  // component: GarmentListingPage,
  loadChildren: () =>
    import("./admin-page.module").then((m) => m.AdminPageModule),
  canActive: [AuthGuard],
});

// "Sub-routes" should be the contentful endpoints for the feature. To provide content at the base
// level of the feature url, simply leave segment blank.
// Provide the parent parameter to be able to derive the fullPath of a route that includes the base
const Main = new AppRoute({
  segment: '',
  title: 'Admin Page',
  component: MainAdminPage,
  parent: Feature,
  canActive: [AuthGuard]
});


const CostCenter = new AppRoute({
  segment: "cost-center",
  title: "Cost Center Admin",
  component: CostCenterAdminPage,
  parent: Main,
  canActive: [AuthGuard],
});

const CostCenterEmployeeView = new AppRoute({
  segment: "cost-center/:costCenterCode",
  title: "Cost Center Employee View",
  component: CostCenterEmployeeViewPage,
  parent: Main,
  canActive: [AuthGuard],
});

const WoApproval = new AppRoute({
  segment: "wo-approval",
  title: "Work Order Approval",
  component: WoApprovalPage,
  parent: Main,
  canActive: [AuthGuard],
});

export const AdminRoutes = {
  Feature: Feature,
  Main: Main,
  CostCenter: CostCenter,
  WoApproval: WoApproval,
  CostCenterEmployeeView: CostCenterEmployeeView,
};
