import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ViewWillEnter } from '@ionic/angular';
import { WorkOrderService } from '../../services/work-order.service';
import { EmployeeKey } from '../../../../../app/common/models/employee-key.model';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';

@Component({
  selector: 'app-employee-work-order-report-page',
  templateUrl: 'employee-work-order-report.page.html',
  styleUrls: [
    './employee-work-order-report.page.scss',
  ]
})
export class EmployeeWorkOrderReportPage implements ViewWillEnter, OnInit {

  employeeId: string;
  customerId: string;
  departmentId: string;

  report: any;

  customer: any;

  empKey: EmployeeKey;

  doneLoading: boolean = false;

  currentDate = new Date();

  public viewChange: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private workOrderService: WorkOrderService,
    private authService: GtrakAuthService,
  ) { 

  }

  ngOnInit(): void {
    
    if(!this.authService.user.canViewWorkOrders) {
      this.router.navigate([this.authService.user.homeLocation]);
    }

  }

  ionViewWillEnter(): void {
    this.employeeId = this.route.snapshot.paramMap.get('id');
    this.customerId = this.route.snapshot.paramMap.get('cid');
    this.departmentId = this.route.snapshot.paramMap.get('did');


    this.empKey = {
      CustomerCode: this.customerId,
      DepartmentCode: this.departmentId,
      EmployeeCode: this.employeeId
    };

    this.setData();
  }

  changePrinterView(): void {
    this.viewChange = !this.viewChange;
    // Set a delay before opening the print dialog
    setTimeout(() => {
      print();
    }, 1000); // 2000 milliseconds = 2 seconds
  }

  async setData(): Promise<void> {
    this.doneLoading = false;
    this.report = await this.workOrderService.getForEmployee(this.empKey);

    this.customer = this.report.employeeListing;

    this.doneLoading = true;

  }

}