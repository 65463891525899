import { Data } from "@angular/router";
import { Model, ModelId } from "../../../common/rest-api/models/model";

export class GarmentReportSummary extends Model
{
  get modelId(): ModelId {
    throw new Error("Method not implemented.");
  }

  rowKey: string;
  code: string;
  customerCode: string;
  departmentCode: string;
  employeeCode: string;
  firstName: string;
  lastName: string;
  productCode: string;
  name: string;
  size1: string;
  size2: string;
  status: string;
  statusDesc: string;
  startDate?: string;
  stopDate?: string;
  inventory: number;
  assignCount: number
}