<ion-content class="garment-new-deliveries">
    <ng-container>
        <app-header [title]="(routeStopName ?? '') + ' (' + stopName + ') New Deliveries'"></app-header>
        <ion-button class="button" *ngIf="showNew" (click)="changeView()">Show Open WOs</ion-button>
        <ion-button class="button" *ngIf="showOpen" (click)="changeView()">Show Garment: New Deliveries</ion-button>
        <ion-grid>
            <div class="div-container">
                <ion-row *ngIf="showNew">
                    <ion-row class="header-row">
                        <ion-col size="3" class="top-row">WO</ion-col>
                        <ion-col size="9" class="top-row">Employee</ion-col>
                    </ion-row>
                    <ion-row *ngFor="let deliveries of newDeliveries" class="main-row">
                        <ion-col size="3" class="wo-code">{{deliveries.workOrder.woCode}}</ion-col>
                        <ion-col size="9">
                            <ion-row class="name-row"><strong class="name">{{deliveries.employee.lastName}}, {{deliveries.employee.firstName}}</strong></ion-row>
                            <ion-row>
                                <ion-col>{{deliveries.workOrder.orderDate | date: "MM/dd/YYYY"}}</ion-col>
                                <ion-col>{{deliveries.workOrder.reasonCode}}</ion-col>
                            </ion-row>
                            <ion-row class="garment-title-row" *ngFor="let garment of deliveries.items">
                                <ion-col class="garment-col" *ngIf="garment.deliveryDate === null">{{garment.qty}} - {{garment.productCode}} ({{garment.size1}}-{{garment.size2}})</ion-col>
                            </ion-row>
                        </ion-col>
                    </ion-row>

                    <ion-row class="no-data" *ngIf="newDeliveries.length <= 0">
                        <ion-col>
                            <div>
                                <span>No Data Found</span>
                            </div>
                        </ion-col>
                    </ion-row>
                </ion-row>
                <ion-row *ngIf="showOpen">
                    <ion-row class="header-row">
                        <ion-col size="3" class="top-row">WO</ion-col>
                        <ion-col size="9" class="top-row">Employee</ion-col>
                    </ion-row>
                    <ion-row *ngFor="let deliveries of openDeliveries" class="main-row">
                        <ion-col size="3" class="wo-code">{{deliveries.workOrder.woCode}}</ion-col>
                        <ion-col size="9">
                            <ion-row class="name-row"><strong class="name">{{deliveries.employee.lastName}}, {{deliveries.employee.firstName}}</strong></ion-row>
                            <ion-row>
                                <ion-col>{{deliveries.workOrder.orderDate | date: "MM/dd/YYYY"}}</ion-col>
                                <ion-col>{{deliveries.workOrder.reasonCode}}</ion-col>
                            </ion-row>
                            <ion-row class="garment-title-row" *ngFor="let garment of deliveries.items">
                                <ion-col class="garment-col" *ngIf="garment.deliveryDate === null">{{garment.qty}} - {{garment.productCode}} ({{garment.size1}}-{{garment.size2}})</ion-col>
                            </ion-row>
                        </ion-col>
                    </ion-row>

                    <ion-row class="no-data" *ngIf="openDeliveries.length <= 0">
                        <ion-col>
                            <div>
                                <span>No Data Found</span>
                            </div>
                        </ion-col>
                    </ion-row>
                </ion-row>
            </div>
        </ion-grid>
    </ng-container>
</ion-content>