import { AppRoute } from '../../../core/routing/AppRoute';
import { MobileHomePage } from './pages/home/mobile-home.page';
import { DeliveryPage } from './pages/delivery/delivery.page';
import { CancelOrderPage } from './pages/cancel-order/cancel-order.page';
import { QuestionPage } from './pages/question/question.page';
import { RepairReplacePage } from './pages/repair-replace/repair-replace.page';
import { SettingsPage } from './pages/settings/settings.page';
import { SizeChangePage } from './pages/size-change/size-change.page';
import { WorkOrderPage } from './pages/work-order/work-order.page';
import { GarmentDetailPage } from './pages/garment-detail/garment-detail.page';
import { AuthGuard } from '../../common/rest-api/services/auth-guard.service';
import { CreateAccountPage } from '../authentication/pages/create-account/create-account.page';

// A feature route should be used in the App routing module in most cases and primarily provide
// a base url and module loading functionality.
// The feature route should employ a load children callback that loads the appropriate module
const Feature = new AppRoute({
  segment: 'mobile',
  title: 'Mobile',
  // A component can also be included to be the content, or a separate route with a blank segment
  // can be defined like below to provide a dedicated route instance
  // component: GarmentListingPage,
  loadChildren: () => import('./mobile.module').then(m => m.MobileHomeModule),
  canActive: [AuthGuard]
});

// "Sub-routes" should be the contentful endpoints for the feature. To provide content at the base
// level of the feature url, simply leave segment blank.
// Provide the parent parameter to be able to derive the fullPath of a route that includes the base
const Home = new AppRoute({
  segment: '',
  title: 'Mobile',
  component: MobileHomePage,
  parent: Feature,
  canActive: [AuthGuard]
});

const Delivery = new AppRoute({
  segment: 'delivery',
  title: 'Mobile',
  component: DeliveryPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const CancelOrder = new AppRoute({
  segment: 'cancel-order',
  title: 'Mobile',
  component: CancelOrderPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const Question = new AppRoute({
  segment: 'question',
  title: 'Mobile',
  component: QuestionPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const RepairReplace = new AppRoute({
  segment: 'repair-replace',
  title: 'Mobile',
  component: RepairReplacePage,
  parent: Feature,
  canActive: [AuthGuard]
});

const Settings = new AppRoute({
  segment: 'settings',
  title: 'Mobile',
  component: SettingsPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const SizeChange = new AppRoute({
  segment: 'size-change',
  title: 'Mobile',
  component: SizeChangePage,
  parent: Feature,
  canActive: [AuthGuard]
});

const WorkOrder = new AppRoute({
  segment: 'work-order',
  title: 'Mobile',
  component: WorkOrderPage,
  parent: Feature,
  canActive: [AuthGuard]
});

const GarmentDetail = new AppRoute({
  segment: 'delivery/garment-detail',
  title: 'Mobile',
  component: GarmentDetailPage,
  parent: Delivery,
  canActive: [AuthGuard]
});

export const MobileHomeRoutes = {
  Feature: Feature,
  Home: Home,
  Delivery: Delivery,
  CancelOrder: CancelOrder,
  Question: Question,
  RepairReplace: RepairReplace,
  Settings: Settings,
  SizeChange: SizeChange,
  WorkOrder: WorkOrder,
  GarmentDetail: GarmentDetail
}