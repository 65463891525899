import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DepartmentKey } from '../../../../app/common/models/department-key-model';
import { EmployeeKey } from '../../../../app/common/models/employee-key.model';
import { QueryResult } from '../../../common/rest-api/models/query-result';
import { RequestParams } from '../../../common/rest-api/models/request-params';
import { RestApiService } from '../../../common/rest-api/services/rest-api.service';
import { WorkOrder } from '../models//work-order.model';


@Injectable({
  providedIn: 'root',
})
export class WorkOrderService extends RestApiService<WorkOrder> {

  protected resourcePath = '/wo-report';
  protected modelType = WorkOrder;

  protected departmentPath = 'department';
  protected customerPath = 'customer';

  async getForLastName(params: RequestParams): Promise<QueryResult<WorkOrder>> {

    params.filters.DaysOffset = 365*2;

    const url = this.buildEndpointUrl('query', params);

    const response = await this.cloudApiMiddleware.cloudRequest<QueryResult<WorkOrder>>({
      url
    }, 'GET');

    response.results = response.results.map(i => WorkOrder.fromType(this.modelType, i));

    return response;
  }

  async getForEmployee(employeeKey: EmployeeKey): Promise<WorkOrder> {
    const params = new RequestParams();
    params.queryParams.params.EmployeeCode = employeeKey.EmployeeCode;
    params.queryParams.params.DepartmentCode = employeeKey.DepartmentCode;
    params.queryParams.params.CustomerCode = employeeKey.CustomerCode;

    params.filters.DaysOffset = 365*2;

    const url = this.buildEndpointUrl('', params);
    const response = WorkOrder.from(await this.cloudApiMiddleware.cloudRequest<WorkOrder>({
      url
    }, 'GET'));

    return response;
  }

  async findForDepartment(departmentKey: DepartmentKey, params?: RequestParams): Promise<QueryResult<WorkOrder>> {
    if (!params)
      params = new RequestParams();
    params.queryParams.params.CustomerCode = departmentKey.CustomerCode;
    params.queryParams.params.DepartmentCode = departmentKey.DepartmentCode;

    params.filters.DaysOffset = 90;

    const url = this.buildEndpointUrl(this.departmentPath, params);
    const response = await this.cloudApiMiddleware.cloudRequest<QueryResult<WorkOrder>>({
      url
    }, 'GET');

    response.results = response.results.map(i => WorkOrder.fromType(this.modelType, i));

    return response;
  }

  async findForCustomer(customerCode: string, params?: RequestParams): Promise<QueryResult<WorkOrder>> {

    // params.filters.DaysOffset = 200;

    const url = this.buildEndpointUrl(`${this.customerPath}/${customerCode}`, params);
    const response = await this.cloudApiMiddleware.cloudRequest<QueryResult<WorkOrder>>({
      url
    }, 'GET');

    response.results = response.results.map(i => WorkOrder.fromType(this.modelType, i));

    return response;
  }
}