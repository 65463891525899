<ion-content class="cost-center-employee">
  <ng-container>
    <app-header [title]="'Cost Center Employee View'"></app-header>
    <ion-grid>
      <ion-row class="table-row">
        <div class="ion-padding table-container" *ngIf="!submitting">
          <app-cost-center-report-table  [mode]="'gl'" (paging)="setPage($event)" [pageSize]="pageSize" [pageNumber]="currPage" [rows]="rows" [currentRoute]="currentRoute" [totalElements]="totalElements"></app-cost-center-report-table>
        </div>
      </ion-row>
    </ion-grid>
  </ng-container>
</ion-content>
