<ion-content class="logsheet">
    <ng-container>
        <app-header [title]="'Logsheet'"></app-header>
        <ion-grid>
            <ion-row class="table-row">
                <ion-col class="ion-no-padding">
                    <ion-row class="checkbox-row">
                        <div>
                            <ion-checkbox (ionChange)="showAllToggle()" [(ngModel)]="showAllStops" slot="start"></ion-checkbox>
                            <span>Show All Stops</span>
                        </div>
                    </ion-row>
                    <ion-row>
                        <div *ngIf="loaded">
                            <ion-col>
                                <app-logsheet-table [rows]="routeStops" [currentRoute]="currentNavRoute"></app-logsheet-table>
                            </ion-col>
                        </div>
                        <div class="loading-container" *ngIf="!loaded">
                            <ion-spinner></ion-spinner>
                        </div>
                    </ion-row>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ng-container>
</ion-content>