import { Component, Input, OnInit } from '@angular/core';
import { EmployeeListing } from '../../../../common/models/employee-listing.model';
import { InvoiceService } from '../../../../features/invoice/services/invoice.service';
import { WeeklyHistoryItem } from '../../models/weekly-history-item.model';
import { WeeklyHistoryWeek } from '../../models/weekly-history-week.model';

@Component({
  selector: 'app-garment-weekly-history-modal',
  templateUrl: 'garment-weekly-history-modal.component.html',
  styleUrls: ['garment-weekly-history-modal.component.scss']
})
export class GarmentWeeklyHistoryModalComponent implements OnInit {

  @Input() employee: EmployeeListing;

  weeklyHistoryItems: WeeklyHistoryItem[];
  weeklyHistory: WeeklyHistoryWeek[];
  today: Date = new Date();

  constructor(
    private invoiceService: InvoiceService,
  ) { 

  }
  
  async ngOnInit(): Promise<void> {
    await this.invoiceService.getWeeklyHistory(this.employee.customerCode, this.employee.departmentCode, this.employee.employeeCode).then((response) => {
      this.weeklyHistoryItems = response;
    });

    //loop through weekly history items and populate weekly history
    this.weeklyHistory = [];
    this.weeklyHistoryItems.forEach((item) => {
      let week = this.weeklyHistory.find((x) => x.week == item.dateOfInvoice);
      if(!week) {
        week = new WeeklyHistoryWeek();
        week.week = item.dateOfInvoice;
        this.weeklyHistory.push(week);
      }
      if(item.catName == "Shirt") {
        week.shirtQty = item.washQty;
      }
      if(item.catName == "Pant") {
        week.pantsQty = item.washQty;
      }
    });
  }

}