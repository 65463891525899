import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CancelOrderService } from '../../../../../app/features/cancel-order/services/cancel-order.service';
import { GtrakAuthService } from '../../../../../app/features/authentication/services/gtrak-auth.service';
import { RequestParams } from '../../../../../app/common/rest-api/models/request-params';
import { EmployeeListService } from '../../../../../app/common/services/employee-listing.service';

@Component({
    selector: 'app-garment-return-report-page',
    templateUrl: 'garment-return.page.html',
    styleUrls: [
      './garment-return.page.scss',
    ]
})
export class GarmentReturnPage implements OnInit {

    lastName: string = null;
    employees: any[] = [];
    totalPages: number = 0;
    totalElements: number = 0;
    currentRoute: string = null;

    requestParams: any;

    currPage: number;
    pageSize: number;

    showInactive: boolean = false;

    submitting: boolean;

    constructor(
      private router: Router,
      private route: ActivatedRoute,
      private cancelOrderService: CancelOrderService,
      public authService: GtrakAuthService,
      private employeeListService: EmployeeListService
    ) { }

    async ngOnInit() {
      this.requestParams = new RequestParams();

      if (!this.authService.user.canViewGarmentReturnReview) {
        this.router.navigate([this.authService.user.homeLocation]);
      }

      this.employees = [];
      this.currentRoute = this.router.url;
  
      this.currPage = 1;
      this.pageSize = 20;
  
      // For the listing view, we want to exclude any inactive employees.
      this.requestParams.filters.ExcludeInactive = true;
      this.requestParams.setOrdering("LastName", "asc");

      this.getData();
    }

    inactiveCheckboxUpdate(event: any): void {
      if (this.showInactive) {
        delete this.requestParams.filters.ExcludeInactive;
      } else {
        this.requestParams.filters.ExcludeInactive = true;
      }
    }

    async getData(): Promise<void> {
      this.submitting = true;
      this.requestParams.setPaging(this.currPage, this.pageSize);
      var employees = await this.employeeListService.query(this.requestParams);
  
      this.totalElements = employees.totalRecords;
      this.totalPages = employees.totalPages;
  
      this.employees = employees.results;
      this.submitting = false;
    }
    
    getDataByLastName(): void {
      this.lastName = this.lastName ?? "";
  
      if (this.requestParams.filters.LastName)
        delete this.requestParams.filters.LastName;
      if (this.requestParams.filters.StopCode)
        delete this.requestParams.filters.StopCode;
      if (this.requestParams.filters.CostCenter)
        delete this.requestParams.filters.CostCenter;
  
      this.currPage = 1;
  
      if (this.lastName && (this.lastName.length > 0)) {
        this.requestParams.filters.LastName = this.lastName;
        this.requestParams.setOrdering("LastName", "asc");
      }
  
      this.getData();
    }

    setPage(page): void {
      this.currPage = page + 1;

      this.getData();
    }
}