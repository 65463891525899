<ion-content class="cost-center-admin">
  <ng-container>
    <app-header [title]="'Cost Center Admin'"></app-header>
    <ion-grid>
      <ion-row class="filter-row">
        <ion-col>
          <ion-select
            interface="popover"
            value="1"
            [interfaceOptions]="{size: 'cover'}"
            [(ngModel)]="activeCode"
          >
            <ion-select-option value="1">Active</ion-select-option>
            <ion-select-option value="0">Disabled</ion-select-option>
          </ion-select>
        </ion-col>
        <ion-col>
          <div>
            <ion-input
              type="text"
              placeholder="Enter CC code or leave blank to show all"
              class="ion-text-left color-light"
              [(ngModel)]="costCenterCode"
            ></ion-input>
            <ion-button
              color="gallaghergreen"
              class="btn-box-shadow"
              (click)="getDataCostCenter()"
              ><span>Show</span></ion-button
            >
          </div>
        </ion-col>
      </ion-row>

      <ion-row class="table-row" *ngIf="loaded">
        <div class="ion-padding table-container">
          <app-cost-center-admin-table [mode]="'gl'" (paging)="setPage($event)" [pageSize]="pageSize" [pageNumber]="currPage" [rows]="rows" [currentRoute]="currentRoute" [totalElements]="totalElements"></app-cost-center-admin-table>
        </div>
      </ion-row>

      <ion-row class="add-row">
          <ion-button
            color="gallaghergreen"
            class="btn-box-shadow"
            (click)="addCostCenter()"
            ><span>Add Cost Center</span></ion-button
          >
      </ion-row>
    </ion-grid>
  </ng-container>
</ion-content>
