import { Model, ModelId } from "../../../common/rest-api/models/model";

export class EmployeeProduct extends Model {
    get modelId(): ModelId {
        throw new Error("Method not implemented.");
    }

    primaryKey: any = null;
    code: string = null!;
    customerCode: string = null!;
    departmentCode: string = null!;
    employeeCode: string = null!;
    productCode: string = null!;
    quota?: number;
    initialGrade?: string;
    replacementGrade?: string;
    size1?: string;
    size2?: string;
    startDate?: Date;
    stopDate?: Date;
    status?: string;
    addedToPurchReq?: string;
    woCode?: string;
    holdEndDate?: Date;
}